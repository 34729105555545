import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { CONFIRMATION_NEW_USER_COOKIE } from 'helpers/constants';

// https://chownow.atlassian.net/browse/CN-21152
// Set a session-length cookie to ensure "new" users see the correct modal even once the order is accepted?
export default function useGetIsNewUser(user) {
  const userHasNoLifetimeSavings =
    !user?.lifetime_restaurant_savings?.dollar_amount;

  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    if (user && userHasNoLifetimeSavings) {
      cookies.set(CONFIRMATION_NEW_USER_COOKIE, 'true');
      setIsNewUser(true);
    }
  }, [user]);

  return isNewUser;
}
