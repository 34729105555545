/* eslint-disable complexity */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalize, get } from 'lodash';

import { MODAL_TYPE } from 'helpers/modals';
import { FULFILLMENT_METHODS } from 'helpers/order';

import usePlatform from 'hooks/usePlatform';

import { ModalContext } from 'context/ModalContext';

import { updateOrderRequest } from 'modules/order';
import { getPickupEta, getDeliveryEta } from 'modules/restaurant';

import ToggleSlide from 'components/Animations/ToggleSlide';

import styles from './styles.module.scss';

const PICKUP = FULFILLMENT_METHODS.pickup;
const CURBSIDE = FULFILLMENT_METHODS.curbside;
const DELIVERY = FULFILLMENT_METHODS.delivery;

const YELP_PICKUP_DISPLAY_LABEL = 'Takeout';

function FulfillmentToggle(props) {
  const {
    displayWhenPicker,
    fulfillmentAvailability,
    fulfillmentMethod,
    fulfillmentTime,
    logFulfillmentChange,
    pendingFulfillment,
    setPendingFulfillment,
  } = props;

  const dispatch = useDispatch();
  const { isChowNowPlatform } = usePlatform();

  const { openModal } = useContext(ModalContext);
  const pickupEta = useSelector(getPickupEta);
  const deliveryEta = useSelector(getDeliveryEta);

  const {
    isPickupAvailableNow,
    isCurbsideAvailableNow,
    isDeliveryAvailableNow,
    isPickupAheadAvailable,
    isCurbsideAheadAvailable,
    isDeliveryAheadAvailable,
  } = fulfillmentAvailability;
  const isDeliveryAvailable =
    isDeliveryAvailableNow || isDeliveryAheadAvailable;
  const isPickupAvailable = isPickupAvailableNow || isPickupAheadAvailable;
  const isCurbsideAvailable =
    isCurbsideAvailableNow || isCurbsideAheadAvailable;
  const showDelivery =
    isDeliveryAvailable || (!isCurbsideAvailable && !isDeliveryAvailable);

  const isPickupSelected = fulfillmentMethod === PICKUP;
  const isCurbsideSelected = fulfillmentMethod === CURBSIDE;
  const isDeliverySelected = fulfillmentMethod === DELIVERY;

  // these toggled states are used to determine active toggle styling and aria-current status
  const isPickupToggled = isPickupSelected && !pendingFulfillment;
  const isCurbsideToggled =
    (isCurbsideSelected && pendingFulfillment !== DELIVERY) ||
    pendingFulfillment === CURBSIDE;
  const isDeliveryToggled =
    (isDeliverySelected && pendingFulfillment !== CURBSIDE) ||
    pendingFulfillment === DELIVERY;

  const canShowPickupEta = pickupEta && isPickupAvailable && !fulfillmentTime;
  const canShowDeliveryEta =
    deliveryEta && isDeliveryAvailable && !fulfillmentTime;

  function handleUpdateFulfillmentMethod(value) {
    const isDeliverySelectedNow = value === DELIVERY;
    const isCurbsideSelectedNow = value === CURBSIDE;

    if (isDeliverySelectedNow) {
      setPendingFulfillment(DELIVERY);
      displayWhenPicker(DELIVERY);
    } else if (isCurbsideSelectedNow) {
      setPendingFulfillment(CURBSIDE);
      displayWhenPicker(CURBSIDE);
    } else {
      logFulfillmentChange(PICKUP);
      dispatch({
        type: updateOrderRequest.TYPE,
        payload: {
          fulfillmentMethod: PICKUP,
        },
      });
    }
  }

  function handleSelectFulfillmentMethod(value) {
    const isCurrentSelectedFulfillmentAvailableLater = get(
      fulfillmentAvailability,
      `is${capitalize(value)}AheadAvailable`
    );

    if (
      !isCurrentSelectedFulfillmentAvailableLater &&
      fulfillmentTime !== null
    ) {
      openModal(MODAL_TYPE.dialog, {
        title: `Order Ahead is Unavailable for ${capitalize(value)}`,
        message: `Switching to ${value} will change your order time to ASAP.`,
        confirmLabel: `Switch to ${capitalize(value)}`,
        dismissLabel: 'Cancel',
        isAlert: true,
        onConfirm: () => {
          dispatch({
            type: updateOrderRequest.TYPE,
            payload: {
              when: null,
            },
          });
          handleUpdateFulfillmentMethod(value);
        },
        noCloseOverlay: true,
      });
    } else {
      handleUpdateFulfillmentMethod(value);
    }
  }

  return (
    <div className={styles.fulfillmentMethods}>
      <ToggleSlide
        selectedOption={pendingFulfillment || fulfillmentMethod}
        isCurbsideAvailable={isCurbsideAvailable}
        isDeliveryAvailable={isDeliveryAvailable}
      />
      <button
        className={classNames(styles.toggleOption, {
          [styles.active]: isPickupToggled,
          [styles.disabled]: !isPickupAvailable,
        })}
        disabled={!isPickupAvailable}
        onClick={() => handleSelectFulfillmentMethod(PICKUP)}
        data-testid="PickupButton"
        aria-current={isPickupToggled}
        type="button"
      >
        <span>{isChowNowPlatform ? PICKUP : YELP_PICKUP_DISPLAY_LABEL}</span>
        {canShowPickupEta && <div className={styles.eta}>{pickupEta}</div>}
        {!isPickupAvailable && (
          <span className={styles.unavailable}>Unavailable</span>
        )}
      </button>

      {isCurbsideAvailable && (
        <button
          className={classNames(styles.toggleOption, {
            [styles.active]: isCurbsideToggled,
          })}
          onClick={() => handleSelectFulfillmentMethod(CURBSIDE)}
          data-testid="CurbsideButton"
          aria-current={isCurbsideToggled}
          type="button"
        >
          <span>{CURBSIDE}</span>
          {/* Curbside ETA will always equal Pickup ETA */}
          {canShowPickupEta && <div className={styles.eta}>{pickupEta}</div>}
        </button>
      )}

      {showDelivery && (
        <button
          className={classNames(styles.toggleOption, {
            [styles.active]: isDeliveryToggled,
            [styles.disabled]: !isDeliveryAvailable,
          })}
          disabled={!isDeliveryAvailable}
          onClick={() => handleSelectFulfillmentMethod(DELIVERY)}
          data-testid="DeliveryButton"
          aria-current={isDeliveryToggled}
          type="button"
        >
          <span>{DELIVERY}</span>
          {canShowDeliveryEta && (
            <div className={styles.eta}>{deliveryEta}</div>
          )}
          {!isDeliveryAvailable && (
            <span className={styles.unavailable}>Unavailable</span>
          )}
        </button>
      )}
    </div>
  );
}

FulfillmentToggle.propTypes = {
  displayWhenPicker: PropTypes.func.isRequired,
  fulfillmentAvailability: PropTypes.shape({
    isPickupAvailableNow: PropTypes.bool,
    isCurbsideAvailableNow: PropTypes.bool,
    isDeliveryAvailableNow: PropTypes.bool,
    isPickupAheadAvailable: PropTypes.bool,
    isCurbsideAheadAvailable: PropTypes.bool,
    isDeliveryAheadAvailable: PropTypes.bool,
  }),
  fulfillmentMethod: PropTypes.string.isRequired,
  fulfillmentTime: PropTypes.string,
  logFulfillmentChange: PropTypes.func.isRequired,
  pendingFulfillment: PropTypes.string,
  setPendingFulfillment: PropTypes.func.isRequired,
};

FulfillmentToggle.defaultProps = {
  fulfillmentAvailability: undefined,
  fulfillmentTime: undefined,
  pendingFulfillment: undefined,
};

export default FulfillmentToggle;
