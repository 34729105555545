import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export default function RestaurantMeta({ ogImageUrl, ogTitle, title }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={ogTitle} key="ogtitle" />
      <meta property="og:image" key="image" content={ogImageUrl} />
    </Helmet>
  );
}

RestaurantMeta.propTypes = {
  ogImageUrl: PropTypes.string.isRequired,
  ogTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
