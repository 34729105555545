/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'primitives/Checkbox';

import styles from './styles.module.scss';

const { bool, shape, string, func } = PropTypes;

function FormikCheckbox({
  field, // { name, value, onChange, onBlur }
  ...props
}) {
  const { id, label } = props;

  return (
    <div className={styles.checkboxInput}>
      <div>
        <Checkbox checked={field.value} {...props} {...field} />
      </div>

      <label htmlFor={id} className={styles.label}>
        <span>{label}</span>
      </label>
    </div>
  );
}

FormikCheckbox.propTypes = {
  field: shape({
    name: string,
    value: bool,
    onChange: func,
    onBlur: func,
  }),
  id: string,
  label: string,
};

FormikCheckbox.defaultProps = {
  field: {},
  id: undefined,
  label: undefined,
};

export default FormikCheckbox;
