import { useEffect } from 'react';

import { hasChanged } from 'helpers/order';
import { resetTipsRequest } from 'modules/order';

type UseResetTipsOnDeliveryUpdateArgs = {
  dispatch: () => void;
  fulfillmentMethod: string;
  allowDualTipping: boolean;
  prevFulfillmentMethod: string;
  prevAllowDualTipping: boolean;
  pageLoaded: boolean;
}

function useResetTipsOnDeliveryUpdate({
  dispatch,
  fulfillmentMethod,
  allowDualTipping,
  prevFulfillmentMethod,
  prevAllowDualTipping,
  pageLoaded,
}: UseResetTipsOnDeliveryUpdateArgs) {
  useEffect(() => {
    if (!pageLoaded) {
      return;
    }

    // When switching between delivery <-> pickup
    // it's possible for the TipCalculator to switch type
    // from managedDelivery to restaurant.
    // We need to clear the tips because otherwise we could
    // put a non-whole-number tip in the (now-displayed) RestaurantTip
    // component on Checkout
    const fulfillmentMethodChanged = hasChanged(
      fulfillmentMethod,
      prevFulfillmentMethod
    );

    // We also need to clear tips in cases where
    // allow_dual_tipping comes back differently from the API,
    // ie if an order goes from non-DMS to DMS on Checkout or
    // Restaurant pages
    const allowDualTippingChanged = hasChanged(
      allowDualTipping,
      prevAllowDualTipping
    );

    const shouldResetTips = allowDualTippingChanged || fulfillmentMethodChanged;

    if (shouldResetTips) {
      // @ts-expect-error dispatching TYPE
      dispatch({ type: resetTipsRequest.TYPE });
    }
  }, [fulfillmentMethod, allowDualTipping, pageLoaded]);
}

export default useResetTipsOnDeliveryUpdate;
