import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface MapGridProps {
  children: ReactNode;
  className?: string;
  isContainer?: boolean;
  isMap?: boolean;
}

// There is a maximum of 2 columns in this layout. On mobile, there is one.
function MapGrid({
  children,
  className = '',
  isContainer,
  isMap,
}: MapGridProps) {
  const classes = {
    wrapper: classNames(className, {
      [styles.container]: isContainer,
      [styles.map]: isMap,
      [styles.fixed]: !isMap && !isContainer,
    }),
  };
  return <div className={classes.wrapper}>{children}</div>;
}

export default MapGrid;
