import React, { useRef } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { TextareaProps } from './types';

const Textarea: React.FunctionComponent<TextareaProps> = ({
  className,
  label,
  maxLength,
  name,
  onChange = () => {},
  placeholder,
  shouldHideLabel,
  value,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const currentValueLength = textAreaRef.current?.value.length || 0;

  return (
    <div className={classNames(className, styles.inputWrapper)}>
      <label
        className={classNames(styles.label, {
          [styles.hideLabel]: shouldHideLabel,
        })}
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        ref={textAreaRef}
        placeholder={placeholder}
        id={name}
        value={value}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
      />
      {!!maxLength && (
        <span className={styles.characterLimit}>
          {currentValueLength}/{maxLength}
        </span>
      )}
    </div>
  );
};

export default Textarea;
