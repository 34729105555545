/* eslint-disable max-len */

import React from 'react';

export default function Cash() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.5 0.5 5 1 5H31C31.5 5 32 5.5 32 6V26C32 26.5 31.5 27 31 27H1C0.5 27 0 26.5 0 26V6ZM1 6V26H31V6H1Z"
        fill="#1F1F1F"
      />
      <line
        x1="4.5"
        y1="15.5"
        x2="9.5"
        y2="15.5"
        stroke="#1F1F1F"
        strokeLinecap="round"
      />
      <line
        x1="22.5"
        y1="15.5"
        x2="27.5"
        y2="15.5"
        stroke="#1F1F1F"
        strokeLinecap="round"
      />
      <path
        d="M15.3759 20.1837C14.6601 20.0651 14.0525 19.7728 13.5531 19.3066C13.0538 18.8405 12.7583 18.2834 12.6667 17.6355L14.1774 17.197C14.2439 17.6869 14.4562 18.0898 14.8141 18.4059C15.1803 18.714 15.6089 18.8681 16.1 18.8681C16.5577 18.8681 16.9239 18.7733 17.1986 18.5837C17.4732 18.394 17.6106 18.1412 17.6106 17.8251C17.6106 17.5802 17.5273 17.3787 17.3609 17.2207C17.2027 17.0627 16.9988 16.9363 16.7491 16.8414C16.4995 16.7466 16.1624 16.64 15.7379 16.5214C15.1553 16.3555 14.6809 16.1935 14.3147 16.0355C13.9485 15.8696 13.6322 15.6247 13.3659 15.3007C13.1079 14.9767 12.9789 14.554 12.9789 14.0326C12.9789 13.4558 13.1994 12.9659 13.6405 12.5629C14.09 12.16 14.6684 11.9071 15.3759 11.8044V10.6666H16.7741V11.8163C17.4233 11.9269 17.9685 12.1639 18.4096 12.5274C18.859 12.8908 19.142 13.3333 19.2585 13.8548L17.7354 14.4474C17.6439 14.0444 17.4441 13.7244 17.1362 13.4874C16.8365 13.2503 16.462 13.1318 16.0126 13.1318C15.5964 13.1318 15.2677 13.2148 15.0263 13.3807C14.7849 13.5387 14.6643 13.756 14.6643 14.0326C14.6643 14.2222 14.735 14.3763 14.8765 14.4948C15.018 14.6133 15.1928 14.7081 15.4008 14.7792C15.6089 14.8503 15.9127 14.9372 16.3122 15.04C16.9281 15.1822 17.4399 15.3402 17.8478 15.514C18.2556 15.68 18.6052 15.9447 18.8965 16.3081C19.1878 16.6716 19.3334 17.1654 19.3334 17.7896C19.3334 18.4296 19.1004 18.9629 18.6343 19.3896C18.1765 19.8163 17.5565 20.0849 16.7741 20.1955V21.3333L15.3759 21.3214V20.1837Z"
        fill="#1F1F1F"
      />
    </svg>
  );
}
