import { useState, useEffect } from 'react';
import { getFromChownowApi } from '@chownow/cn-web-utils/api';

export default function useFetchNativeApp(restaurantId) {
  const [restaurantFetched, setRestaurantFetched] = useState(false);
  const [restaurantHasNativeApp, setRestaurantHasNativeApp] = useState(false);
  useEffect(() => {
    async function fetchRestaurantNativeApp() {
      const { data, error } = await getFromChownowApi(
        `restaurant/${restaurantId}`
      );
      if (error) {
        setRestaurantFetched(true);
      } else {
        const {
          android_app_url: androidAppUrl,
          ios_app_store_id: iosAppStoreId,
        } = data;
        setRestaurantHasNativeApp(!!androidAppUrl || !!iosAppStoreId);
      }
      setRestaurantFetched(true);
    }
    if (restaurantId) {
      fetchRestaurantNativeApp();
    }
  }, [restaurantId]);

  return {
    restaurantFetched,
    restaurantHasNativeApp,
  };
}
