import React, { useState } from 'react';
import { formatMoney } from '@chownow/cn-web-utils/format';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { buildRoute, ROUTES } from 'helpers/routes';

import OrderToggle from 'components/OrderSummary/OrderToggle';
import ButtonLink from 'primitives/ButtonLink';

import styles from './styles.module.scss';

export default function MembershipOrderSummary({ selectedMembership }) {
  const { amount, name, percent, tax, total } = selectedMembership;
  const [showMembershipDetails, setShowMembershipDetails] = useState(true);
  const { hqId, restaurantId } = useParams();
  const history = useHistory();

  function handleGoToMembershipPlans(e) {
    e.preventDefault();
    const plansRoute = buildRoute({
      hqId,
      restaurantId,
      route: ROUTES.membershipPlans,
    });
    history.push(plansRoute);
  }

  return (
    <div className={styles.membershipOrderSummary}>
      <OrderToggle
        hideToggle={false}
        isExpanded={showMembershipDetails}
        toggleOrder={() => setShowMembershipDetails(!showMembershipDetails)}
      />
      <div className={styles.orderSummaryContent}>
        <div className={styles.orderItem}>
          <span className={styles.itemQuantity}>1</span>
          <span className={styles.itemName}>{name} Membership</span>
          <span className={styles.itemPrice}>{formatMoney(amount)}</span>
        </div>
        {showMembershipDetails && (
          <div className={styles.hideableContent}>
            <span className={styles.modifier}>{percent}% off every order</span>
            <ButtonLink
              className={styles.editBtn}
              size="small"
              onClick={(e) => handleGoToMembershipPlans(e)}
            >
              Edit Item
            </ButtonLink>
          </div>
        )}
      </div>
      <div className={styles.receipt}>
        <li className={styles.receiptItem}>
          <span>Subtotal</span>
          <span>{formatMoney(amount)}</span>
        </li>
        <li className={styles.receiptItem}>
          <span>Taxes</span>
          <span>{formatMoney(tax)}</span>
        </li>
        <li className={styles.total}>
          <span>Total</span>
          <span>{formatMoney(total)}</span>
        </li>
      </div>
    </div>
  );
}

MembershipOrderSummary.propTypes = {
  selectedMembership: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};
