import { PLATFORM } from 'helpers/constants';
import { is, includes } from 'helpers/partial';

export const isChowNow = includes([PLATFORM.direct, PLATFORM.marketplace]);

export const isStandardPayment = isChowNow;

export const isDirect = is(PLATFORM.direct);

export const isMarketplace = is(PLATFORM.marketplace);

export const isYelp = is(PLATFORM.yelp);
