/* eslint-disable complexity, no-param-reassign, camelcase, prefer-destructuring */
import { v4 as uuidv4 } from 'uuid';
import {
  findIndex,
  get,
  includes,
  pick,
  filter,
  reject,
  forOwn,
  isEmpty,
} from 'lodash';
import { createSelector } from 'reselect';

import { logAnalyticsEvent } from 'helpers/loggers';
import { apiErrors, MENU_EXPIRED } from 'helpers/api';
import { createReducer } from 'helpers/redux';
import {
  COMPLETED_ORDER_STATUSES,
  PROCESSED_ORDER_STATUSES,
  FULFILLMENT_METHODS,
  getModifierNames,
} from 'helpers/order';
import { onlyNums } from 'helpers/format';
import {
  APPLE_PAY_STRUCT,
  GOOGLE_PAY_STRUCT,
  serializeCard,
} from 'helpers/payments';

import {
  getSelectedAddress,
  getUserSessionId,
  getChannelInfoForOrderMeta,
} from 'helpers/customer';
import { getRestaurantIdFromUrl } from '@chownow/cn-web-utils/url';
import {
  ANALYTICS_EVENT_NAME,
  SELECT_ITEM_OPERATIONS,
} from 'helpers/constants';

import {
  getIsTableRequired,
  getPickupEta,
  getSelectedRestaurantId,
  getRestaurantDetails,
} from 'modules/restaurant';

import { callConfig } from 'index';

import {
  addItemToOrderSuccess,
  fetchOrderStatusXHR,
  fetchOrderDetailsXHR,
  removeOrderItemSuccess,
  removeRejectedItems,
  resetPromoCodeSuccess,
  resetOrder,
  resetSubmitOrderSuccess,
  resetTableNameSuccess,
  resetTimer,
  resetTipsSuccess,
  setActiveItemId,
  setOrderData,
  setManagedDeliveryTipSuccess,
  setPromoCodeSuccess,
  setRestaurantTipSuccess,
  setTableNameSuccess,
  setVehicleSuccess,
  setWhenSuccess,
  startOrderFailure,
  startOrderRequest,
  startOrderSuccess,
  submitOrderXHR,
  submitYelpOrderXHR,
  tipAdjustmentXHR,
  updateOrderItemSuccess,
  updateOrderFailure,
  updateOrderSuccess,
  updateOrderThrottlingMeta,
  updateTimer,
  validateOrderXHR,
  validateReorderXHR,
} from './actions';

export const INITIAL_TIMER = 120;

const initialSubmitOrderState = {
  errors: [],
  meta: {
    isProcessed: false,
    isComplete: false,
    isLoading: false,
    isFailed: false,
    channel: getChannelInfoForOrderMeta(),
  },
  timer: INITIAL_TIMER,
};

export const initialState = {
  ...initialSubmitOrderState,
  activeItemId: null,
  editingItemId: null,
  isValidating: false,
  orderData: {},
  selectedOrder: {
    billing_info: {},
    items: [],
    misc_fee: {},
  },
};

const namespace = 'order';

// This builds what gets returned in getOrderData
export function serializeOrder(order) {
  // TODO: remove 'tip' when we switch exclusively to /validate + POST /order v6:
  // https://chownow.atlassian.net/browse/CN-35607, https://chownow.atlassian.net/browse/CN-35606
  const {
    tip,
    tips,
    total,
    sales_tax,
    item_total,
    subtotal,
    misc_fee,
    total_due,
    delivery_fee,
    direct_diner_fee,
    discounts,
    available_discounts,
    credits,
    area,
    marketplace_diner_savings,
    managed_delivery_id,
    marketplace_diner_fee,
  } = order;

  return {
    tip: parseFloat(tip, 10),
    total,
    sales_tax,
    item_total,
    subtotal,
    misc_fee,
    total_due,
    delivery_fee,
    direct_diner_fee,
    discounts,
    available_discounts,
    credits,
    allows_flex_tips: get(area, 'allows_tip'),
    marketplace_diner_savings,
    managed_delivery_id,
    marketplace_diner_fee,
    tips,
  };
}

export default createReducer(initialState, {
  [addItemToOrderSuccess.TYPE](state, action) {
    const items = get(state.orderData[action.meta.orderDataId], 'items', []);

    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          activeItemId: null,
          editingItemId: null,
          items: [...items, action.payload],
        },
      },
    };
  },
  [removeOrderItemSuccess.TYPE](state, action) {
    const { orderDataId, trackingId } = action.meta;
    const index = findIndex(state.orderData[orderDataId].items, {
      tracking_id: trackingId,
    });

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...state.orderData[orderDataId],
          items: [
            ...state.orderData[orderDataId].items.slice(0, index),
            ...state.orderData[orderDataId].items.slice(index + 1),
          ],
        },
      },
    };
  },
  [setManagedDeliveryTipSuccess.TYPE](state, action) {
    const { orderDataId } = action.meta;
    const orderData = state.orderData[orderDataId] || initialState.orderData;

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...orderData,
          // TODO: remove 'tip' when we switch exclusively to /validate + POST /order v6:
          // https://chownow.atlassian.net/browse/CN-35607, https://chownow.atlassian.net/browse/CN-35606
          tip: action.payload?.managed_delivery_tip_amount,
          tips: {
            ...orderData.tips,
            managed_delivery_tip: action.payload,
          },
        },
      },
    };
  },
  [tipAdjustmentXHR.request.TYPE](state) {
    return {
      ...state,
      errors: [],
    };
  },
  [tipAdjustmentXHR.success.TYPE](state, { payload }) {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.submitAdjustTip,
      attributes: {
        current_tip_value: state.selectedOrder?.tip,
        error_reason: '',
        updated_tip_value: payload?.tip,
        is_success: true,
        order_id: payload?.id,
      },
    });

    return {
      ...state,
      selectedOrder: { ...payload },
    };
  },
  [tipAdjustmentXHR.failure.TYPE](state, action) {
    return {
      ...state,
      errors: action.payload.errors,
    };
  },
  [startOrderRequest.TYPE](state) {
    return {
      ...state,
      meta: {
        isLoading: true,
      },
    };
  },
  [startOrderSuccess.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          items: [], // initialize to empty items, but override in next line if items exist
          ...state.orderData[action.meta.orderDataId],
          ...action.payload,
        },
      },
      meta: {
        ...action.meta,
        isLoading: false,
      },
    };
  },
  [startOrderFailure.TYPE](state) {
    return {
      ...state,
      orderData: initialState.orderData,
      meta: {
        isLoading: false,
      },
    };
  },

  [updateOrderSuccess.TYPE](state, action) {
    const channel = getChannelInfoForOrderMeta();

    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          ...action.payload,
        },
      },
      meta: {
        ...state.meta,
        channel,
      },
    };
  },
  [updateOrderFailure.TYPE](state) {
    return {
      ...state,
      orderData: initialState.orderData,
      meta: {
        isLoading: false,
      },
    };
  },

  [updateOrderItemSuccess.TYPE](state, action) {
    const { index, orderDataId } = action.meta;

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...state.orderData[orderDataId],
          items: [
            ...state.orderData[orderDataId].items.slice(0, index),
            action.payload,
            ...state.orderData[orderDataId].items.slice(index + 1),
          ],
        },
      },
    };
  },

  [fetchOrderStatusXHR.request.TYPE](state) {
    return {
      ...state,
      meta: {
        ...state.meta,
        isFetching: true,
        isFailed: false,
      },
    };
  },
  [fetchOrderStatusXHR.success.TYPE](state, action) {
    const status = get(action.payload, 'status');

    return {
      ...state,
      ...action.payload,
      meta: {
        ...state.meta,
        isFetching: false,
        isProcessed: includes(PROCESSED_ORDER_STATUSES, status),
        isComplete: includes(COMPLETED_ORDER_STATUSES, status),
        isFailed: false,
      },
    };
  },
  [fetchOrderStatusXHR.failure.TYPE](state, action) {
    return {
      ...state,
      errors: action.payload.errors,
      meta: {
        ...state.meta,
        isFetching: false,
        isProcessed: false,
        isComplete: false,
        isFailed: true,
      },
    };
  },
  [fetchOrderDetailsXHR.request.TYPE](state) {
    return {
      ...state,
      errors: [],
      meta: {
        ...state.meta,
        isLoading: true,
      },
    };
  },
  [fetchOrderDetailsXHR.success.TYPE](state, { payload }) {
    return {
      ...state,
      selectedOrder: { ...payload },
      errors: [],
      meta: {
        ...state.meta,
        isLoading: false,
      },
    };
  },
  [fetchOrderDetailsXHR.failure.TYPE](state, action) {
    return {
      ...state,
      errors: action.payload.errors,
      selectedOrder: initialState.selectedOrder,
      meta: {
        ...state.meta,
        isLoading: false,
      },
    };
  },
  [removeRejectedItems.TYPE](state, action) {
    const { orderDataId } = action.meta;
    const items = get(state, `orderData[${orderDataId}].items`, []);

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...state.orderData[orderDataId],
          items: reject(
            items,
            (item) =>
              includes(action.payload, item.sizeId) ||
              includes(action.payload, item.id)
          ),
        },
      },
    };
  },
  [resetOrder.TYPE]() {
    return initialState;
  },

  [resetSubmitOrderSuccess.TYPE](state, action) {
    const { orderDataId } = action.meta;
    const channel = getChannelInfoForOrderMeta();

    return {
      activeItemId: state.activeItemId,
      editingItemId: state.editingItemId,
      isValidating: state.isValidating,
      orderData: {
        [orderDataId]: {
          ...state.orderData[orderDataId],
        },
      },
      selectedOrder: initialState.selectedOrder,
      meta: {
        channel,
      },
      ...initialSubmitOrderState,
    };
  },

  [resetTableNameSuccess.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          tableName: null,
        },
      },
    };
  },

  [resetTimer.TYPE](state) {
    return {
      ...state,
      timer: INITIAL_TIMER,
    };
  },

  [resetTipsSuccess.TYPE](state, action) {
    const { orderDataId } = action.meta;
    const orderData = state.orderData[orderDataId] || initialState.orderData;

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...orderData,
          tips: {
            ...orderData.tips,
            restaurant_tip: null,
            managed_delivery_tip: null,
          },
        },
      },
    };
  },

  [resetPromoCodeSuccess.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          promo_code: null,
        },
      },
    };
  },

  [setActiveItemId.TYPE](state, action) {
    if (!action.payload) {
      return {
        ...state,
        activeItemId: initialState.activeItemId,
        editingItemId: initialState.editingItemId,
      };
    }

    if (action.payload.operation === SELECT_ITEM_OPERATIONS.add) {
      return {
        ...state,
        activeItemId: action.payload.itemId,
      };
    }

    if (action.payload.operation === SELECT_ITEM_OPERATIONS.edit) {
      return {
        ...state,
        editingItemId: action.payload.itemId,
      };
    }

    return state;
  },

  [setOrderData.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          ...action.payload,
        },
      },
    };
  },

  [setPromoCodeSuccess.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          promo_code: action.payload,
        },
      },
    };
  },

  [setTableNameSuccess.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          tableName: action.payload,
        },
      },
    };
  },

  [setVehicleSuccess.TYPE](state, action) {
    return {
      ...state,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          vehicle: action.payload,
        },
      },
    };
  },

  [setWhenSuccess.TYPE](state, action) {
    const { orderDataId } = action.meta;
    const orderData = state.orderData[orderDataId] || initialState.orderData;

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...orderData,
          when: action.payload,
        },
      },
    };
  },

  [setRestaurantTipSuccess.TYPE](state, action) {
    const { orderDataId } = action.meta;
    const orderData = state.orderData[orderDataId] || initialState.orderData;

    return {
      ...state,
      orderData: {
        [orderDataId]: {
          ...orderData,
          // TODO: remove 'tip' when we switch exclusively to /validate + POST /order v6:
          // https://chownow.atlassian.net/browse/CN-35607, https://chownow.atlassian.net/browse/CN-35606
          tip: action.payload?.restaurant_tip_amount,
          tips: {
            ...orderData.tips,
            restaurant_tip: action.payload,
          },
        },
      },
    };
  },

  [submitOrderXHR.request.TYPE](state, action) {
    return {
      ...state,
      errors: [],
      meta: {
        ...state.meta,
        isLoading: true,
        setSubmitting: action.meta,
        paymentMethod: get(action, 'meta.paymentMethod'),
      },
    };
  },

  [submitYelpOrderXHR.request.TYPE](state, action) {
    return {
      ...state,
      errors: [],
      meta: {
        ...state.meta,
        isLoading: true,
        setSubmitting: action.meta,
        paymentMethod: get(action, 'meta.paymentMethod'),
      },
    };
  },

  [submitOrderXHR.success.TYPE](state, action) {
    return {
      ...state,
      ...action.payload,
      meta: {
        ...state.meta,
        isLoading: false,
      },
    };
  },

  [submitOrderXHR.failure.TYPE](state, action) {
    return {
      ...state,
      errors: action.payload.errors,
      meta: {
        ...state.meta,
        isLoading: false,
      },
    };
  },

  [updateTimer.TYPE](state, action) {
    return {
      ...state,
      timer: action.payload,
    };
  },

  [updateOrderThrottlingMeta.TYPE](state) {
    return {
      ...state,
      meta: {
        ...state.meta,
        hasHandledOrderThrottling: true,
      },
    };
  },

  [validateOrderXHR.request.TYPE](state) {
    return {
      ...state,
      isValidating: true,
    };
  },
  [validateOrderXHR.success.TYPE](state, action) {
    return {
      ...state,
      isValidating: false,
      errors: [],
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          ...action.payload,
        },
      },
    };
  },
  [validateOrderXHR.failure.TYPE](state, action) {
    const payloadErrors = get(action.payload, 'errors', []);

    const errors = payloadErrors.map((error) => {
      if (apiErrors[error.code]) {
        return {
          ...error,
          ...apiErrors[error.code],
        };
      }
      return error;
    });

    const onlyHasMenuExpiredError =
      errors.length === 1 &&
      errors.some((error) => error.code === MENU_EXPIRED);

    // Only take values from validate call if there is only a menu expired error
    const updateItems = onlyHasMenuExpiredError
      ? state.orderData[action.meta.orderDataId]?.items.map(
          (stateItem, index) => {
            // Compare payload items at the same index, in case of duplicate items with different prices (modifiers)
            const payloadItem = action.payload?.items[index];
            return {
              ...stateItem,
              price:
                payloadItem.per_item_total !== stateItem.price
                  ? payloadItem.per_item_total
                  : stateItem.price,
            };
          }
        )
      : state.orderData[action.meta.orderDataId]?.items;

    return {
      ...state,
      isValidating: false,
      orderData: {
        [action.meta.orderDataId]: {
          ...state.orderData[action.meta.orderDataId],
          ...serializeOrder(action.payload),
          items: updateItems,
        },
      },
      errors,
    };
  },

  [validateReorderXHR.request.TYPE](state) {
    return {
      ...state,
      errors: [],
      isValidating: true,
    };
  },
  [validateReorderXHR.success.TYPE](state, action) {
    return {
      ...state,
      warnings: action.payload.result.warnings,
      isValidating: false,
    };
  },
  [validateReorderXHR.failure.TYPE](state, action) {
    return {
      ...state,
      errors: action.payload.errors,
      isValidating: false,
    };
  },
});

export function local(state) {
  return state[namespace];
}

/**
 * Helpers
 */

export function serializeItem(item) {
  const modifierCategoriesWithModifiers = filter(
    item.modifierCategories,
    (modifierCategory) =>
      modifierCategory.modifiers && modifierCategory.modifiers.length
  );

  return {
    id: item.sizeId || item.id,
    quantity: item.quantity,
    recipient: null,
    special_instructions: item.specialInstructions || null,
    client_id: (new Date().valueOf() * 1000).toString(),
    modifier_categories: modifierCategoriesWithModifiers,
  };
}

export function getOrderDataId(state) {
  const userId = getUserSessionId();
  const restaurantId =
    getSelectedRestaurantId(state) || getRestaurantIdFromUrl();

  return restaurantId ? `${userId}_${restaurantId}` : null;
}

export function getOrderData(state) {
  const orderDataId = getOrderDataId(state);

  if (
    orderDataId &&
    local(state).orderData &&
    local(state).orderData[orderDataId]
  ) {
    return local(state).orderData[orderDataId];
  }

  return initialState.orderData;
}

export function getVehicle(state) {
  const order = getOrderData(state);
  const user = callConfig.call.UserContext?.user;
  const customerVehicle = user?.curbside?.vehicle;
  let newVehicle = null;

  // API saves vehicle info in capitolcase (for tablet), but requests to be sent in uppercase
  if (customerVehicle) {
    newVehicle = Object.keys(customerVehicle).reduce((vehicle, key) => {
      vehicle[key] = customerVehicle[key]
        ? customerVehicle[key].toUpperCase()
        : null;
      return vehicle;
    }, {});
  }

  return order.vehicle || newVehicle;
}

export function getTableName(state) {
  const order = getOrderData(state);

  return order.tableName;
}

export function buildOrderPayload(state) {
  const order = getOrderData(state);
  const user = callConfig.call.UserContext?.user;

  const customer = pick(user, [
    'email',
    'first_name',
    'id',
    'last_name',
    'phone',
  ]);

  const menu = callConfig.call.MenuContext.menu;
  const channel = getChannelInfoForOrderMeta();

  const orderBody = {
    fulfill_method: order.fulfillmentMethod,
    items: (order.items || []).map(serializeItem),
    special_instructions: null,
    // TODO: remove 'tip' when we switch exclusively to /validate + POST /order v6:
    // https://chownow.atlassian.net/browse/CN-35607, https://chownow.atlassian.net/browse/CN-35606
    tip: order.tip || null,
    promo_code: order.promo_code || null,
    restaurant_id: order.restaurantId,
    when: order.when,
    menu_id: menu.id,
    meta: {
      channel,
    },
    tips: {
      managed_delivery_tip: order?.tips?.managed_delivery_tip || null,
      restaurant_tip: order?.tips?.restaurant_tip || null,
    },
    include_single_use_items: order.include_single_use_items || false,
  };

  if (order.fulfillmentMethod === FULFILLMENT_METHODS.delivery) {
    orderBody.customer = {
      ...customer,
      id: customer.id || null,
      delivery: {
        address: {
          // this flag needs to be present for new true addresses.
          // old addresses should pass through existing values from the API
          is_google_place: true,
          ...getSelectedAddress(),
        },
      },
    };
  }

  if (order.fulfillmentMethod === FULFILLMENT_METHODS.curbside) {
    const vehicle = getVehicle(state);

    orderBody.customer = {
      ...customer,
      id: customer.id || null,
      curbside: vehicle ? { vehicle } : null, // Hermosa's json schema doesn't except vehicle as null
      delivery: null, // json schema expects delivery to be null
    };
  }

  if (order.fulfillmentMethod === FULFILLMENT_METHODS.dineIn) {
    const tableName = getTableName(state);
    const isTableRequired = getIsTableRequired(state);

    orderBody.customer = {
      ...customer,
      id: customer.id || null,
      curbside: null, // json schema expects curbside to be null
      delivery: null, // json schema expects delivery to be null
      dine_in: isTableRequired && tableName ? { table_name: tableName } : null,
    };
  }

  return orderBody;
}

export function buildPrepTimeOrderPayload(state) {
  const order = getOrderData(state);
  const orderBody = buildOrderPayload(state);
  const pickupPrepTime = order.estimated_fulfillment_time
    ? order.estimated_fulfillment_time
    : getPickupEta(state);

  // adds estimated_fulfillment_time to validate call here to trigger ETA validation
  const orderBodyWithPrepTime = {
    ...orderBody,
    estimated_fulfillment_time: pickupPrepTime,
  };

  return orderBodyWithPrepTime;
}

/**
 * Function for normalizing the shape of order item modifiers
 * @param orderItems: array of OrderItems from the api /order response
 * @returns list of augmented orderItems with modifiers reduced to a new `modifierNames` key
 */
export function formatOrderItems(orderItems) {
  const formattedOrderItems = [...orderItems];

  formattedOrderItems.forEach((item) => {
    item.modifierNames = [];
    /*
     * 'special_instructions' once an order is placed includes order ahead info (if exists) for first
     * item in order. 'instructions' contains the original special_instructions for that item
     */
    item.specialInstructions = item.instructions;
    item.modifier_categories.forEach((modifier) => {
      modifier.selections.forEach((selection) => {
        item.modifierNames.push(selection.name);
      });
    });
  });
  return formattedOrderItems;
}

const formatOrderItemsWithModifiersSelector = createSelector(
  formatOrderItems,
  (formatted) => formatted
);

export function getSelectedOrder(state) {
  const selectedOrderItems = local(state).selectedOrder.items;
  const formattedItems =
    selectedOrderItems &&
    formatOrderItemsWithModifiersSelector(selectedOrderItems);
  const formatted = {
    ...local(state).selectedOrder,
    items: formattedItems,
  };
  return formatted;
}

export function buildReorderPayload(state) {
  const { order_create_payload, fulfill_method, restaurant, items, id } =
    getSelectedOrder(state);
  const menuItems = callConfig.call.MenuContext.menuItems;
  const reorderItems = order_create_payload.items.map(
    // eslint-disable-next-line no-shadow
    ({ id, quantity, special_instructions, modifier_categories }, index) => {
      let itemId = id;
      const orderItem = items[index];
      const modifierNames = [];
      orderItem.modifier_categories.forEach((modifierCategory) => {
        modifierCategory.selections.forEach((selection) => {
          modifierNames.push(selection.name);
        });
      });

      let categoryName;
      const modifierCategories = [];

      forOwn(menuItems, (value) => {
        // if item is a sized item, find parent and set id to parent id
        const isChildItem =
          value?.meta_item_details?.serving_size_group?.child_items.find(
            (childItem) => childItem.id === id
          );
        if (isChildItem) {
          itemId = value.id;
        }

        if (itemId === value.id) {
          categoryName = value.categoryName;

          // make sure all modifier categories are available on reorder item
          value.modifier_categories.forEach((modifierCategory) => {
            // if the modcat is already in modcats dont add again
            const isDuplicateModCat = !!modifierCategories.find(
              (cat) => cat.id === modifierCategory
            );

            if (!isDuplicateModCat) {
              modifierCategories.push({ id: modifierCategory, modifiers: [] });
            }
          });
        }
      });

      // Save selected modifiers to reorder item
      // eslint-disable-next-line array-callback-return
      modifierCategories.map((category) => {
        // eslint-disable-next-line array-callback-return
        modifier_categories.map((orderModifierCategory) => {
          if (category.id === orderModifierCategory.id) {
            category.modifiers = orderModifierCategory.modifiers;
          }
        });
      });

      return {
        modifierCategories,
        id: itemId,
        modifierNames,
        name: orderItem.name,
        sizeId: id,
        categoryName,
        quantity,
        price: orderItem.item_sum_total,
        specialInstructions: special_instructions,
        tracking_id: uuidv4(),
      };
    }
  );

  return {
    items: reorderItems,
    fulfillmentMethod: fulfill_method,
    restaurantId: restaurant.id,
    originalOrderId: id,
    when: null,
    tip: 0,
  };
}

function serializeItemForSubmit(item) {
  // sort mods by id so they list properly in admin and on tablet
  const sortedMods = item.modifier_categories.map((modCat) =>
    modCat.modifiers.sort((a, b) => a.id - b.id)
  );

  return {
    ...item,
    modifier_categories: item.modifier_categories.map((modCat, i) => ({
      ...modCat,
      modifiers: sortedMods[i],
    })),
  };
}

export const buildSubmitOrderPayload = (payload) =>
  createSelector(getOrderData, buildOrderPayload, (order, orderBody) => {
    const user = callConfig.call.UserContext?.user;
    const isApplePay = get(payload, 'cardData.type') === APPLE_PAY_STRUCT.type;
    const isGooglePay =
      get(payload, 'cardData.type') === GOOGLE_PAY_STRUCT.type;
    let customer = pick(user, [
      'email',
      'first_name',
      'id',
      'last_name',
      'phone',
    ]);

    // In the case of guest checkout, this is the customer information needed to submit order
    if (isEmpty(customer) && isApplePay) {
      customer = {
        email: payload.email,
        first_name: payload.first_name,
        id: null,
        last_name: payload.last_name,
      };
    }

    // TODO: fallback to form value if new user
    // Send the the social pay phone number only for unauthed orders
    const phoneNumber =
      (isApplePay || isGooglePay) &&
      (isEmpty(customer) || !get(customer, 'phone.number'))
        ? payload.phone
        : get(customer, 'phone.number');
    const billingObject = payload
      ? {
          card:
            isApplePay || isGooglePay
              ? payload.cardData
              : serializeCard(payload),
        }
      : null;

    const orderSubmitBody = {
      ...orderBody,
      items: (orderBody.items || []).map(serializeItemForSubmit),
      customer: {
        ...orderBody.customer,
        ...customer,
        phone: {
          number: onlyNums(phoneNumber),
        },
        delivery: get(orderBody, 'customer.delivery', null),
        billing: billingObject,
      },
      validated_total: order.total,
    };
    return orderSubmitBody;
  });

/**
 * Selectors
 */

export function getFulfillmentMethod(state) {
  return getOrderData(state).fulfillmentMethod;
}

export function getFulfillmentTime(state) {
  return getOrderData(state).when;
}

export function getIsOrderValidating(state) {
  return local(state).isValidating;
}

export function getItemsInOrder(state) {
  return get(getOrderData(state), 'items', []);
}

export function getActiveItem(state) {
  return local(state).activeItemId;
}

export function getEditingItem(state) {
  return local(state).editingItemId;
}

export function getErrorsForOrder(state) {
  return local(state).errors || [];
}

export function getWarningsForReorder(state) {
  return local(state).warnings || [];
}

export function getOrderMeta(state) {
  return local(state).meta;
}

export function getIsProcessed(state) {
  return local(state).meta.isProcessed;
}

export function getIsFailed(state) {
  return local(state).meta.isFailed;
}

export function getIsComplete(state) {
  return local(state).meta.isComplete;
}

export function getIsLoading(state) {
  return local(state).meta.isLoading;
}

export function getSetSubmitting(state) {
  return local(state).meta.setSubmitting;
}

export function getOrderId(state) {
  return local(state).id;
}

export function getOrderGuid(state) {
  return local(state).guid;
}

export function getYelpOrderId(state) {
  return local(state).yelp_order_id;
}

export function getOrderStatus(state) {
  return local(state).status;
}

export function getOrderTotal(state) {
  const order = getOrderData(state);
  return order.total || 0;
}

// memoized selector to bundle item data with order data
export const getOrderItemDataSelector = createSelector(
  getItemsInOrder,
  (orderItems) => {
    // only allow order items through that exist in current menu
    const menu = callConfig.call.MenuContext.menu;
    const menuModifiers = menu.modifiers;
    const menuItems = callConfig.call.MenuContext.menuItems;
    const validOrderItems = orderItems.filter((item) =>
      menuItems.find((menuItem) => item.id === menuItem.id)
    );

    return validOrderItems.map((orderItem) => {
      const itemData = menuItems.find(
        (menuItem) => orderItem.id === menuItem.id
      );

      const modifierNames = getModifierNames(
        orderItem.modifierCategories,
        menuModifiers
      );

      const sizeItem =
        itemData.meta_item_details?.serving_size_group?.child_items.find(
          (catItem) => catItem.id === orderItem.sizeId
        ) || {};

      return {
        ...itemData,
        ...orderItem,
        size: sizeItem.size,
        price: 'price' in itemData ? itemData.price : sizeItem.price,
        priceWithModifiers: orderItem.price,
        modifierNames,
      };
    });
  }
);

export function getOrderItemData(state) {
  const menu = callConfig.call.MenuContext?.menu;
  const menuItems = callConfig.call.MenuContext?.menuItems;

  if (menu?.id && menuItems.length >= 1 && getRestaurantDetails(state)) {
    return getOrderItemDataSelector(state);
  }

  return [];
}

export function getTimer(state) {
  return local(state).timer;
}

export function getTotalCredits(state) {
  const order = getOrderData(state);

  if (!order.credits) return [];

  return order.credits.reduce(
    (totalCredits, credit) => totalCredits + credit.amount,
    0
  );
}

export function getActiveDiscount(state) {
  const order = getOrderData(state);

  if (!order.discounts) return [];

  return order.discounts[0];
}

export function getOrder(state) {
  return local(state);
}

export function getManagedDeliveryID(state) {
  return getOrderData(state).managed_delivery_id;
}

export function getIsManagedDelivery(state) {
  return Boolean(getManagedDeliveryID(state));
}

export function getSingleUseItem(state) {
  return getOrderData(state).include_single_use_items || false;
}

/**
 * Tipping-specific selectors
 */

export function allowsFlexTipping(state) {
  return getOrderData(state)?.allows_flex_tips;
}

export function getTips(state) {
  return getOrderData(state)?.tips;
}
