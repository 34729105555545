import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

function SlideInDown(props) {
  const { children, className, transformFrom } = props;

  const { transform, opacity } = useSpring({
    config: { friction: 38, tension: 350 },
    from: {
      transform: `translateY(-${transformFrom || '32px'})`,
      opacity: 0,
    },
    to: { transform: 'translateY(0)', opacity: 1 },
    delay: 125,
  });

  return (
    <animated.div style={{ transform, opacity }} className={className}>
      {children}
    </animated.div>
  );
}

SlideInDown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  transformFrom: PropTypes.string,
};

SlideInDown.defaultProps = {
  className: undefined,
  transformFrom: undefined,
};

export default SlideInDown;
