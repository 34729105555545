/* eslint-disable complexity */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { get } from 'lodash';

import { FULFILLMENT_METHODS } from 'helpers/order';
import useResizeAware from 'hooks/useResizeAware';

import styles from './styles.module.scss';

const { bool, string } = PropTypes;

function ToggleSlide({
  isCurbsideAvailable,
  isDeliveryAvailable,
  selectedOption,
}) {
  const [isImmediate, setIsImmediate] = useState(true);
  const indicatorRef = useRef();
  const defaultProps = { transform: 'translateX(0px)' };
  const [springProps, set] = useSpring(() => ({
    ...defaultProps,
    immediate: isImmediate,
    onRest: () => {
      setIsImmediate(false);
    },
  }));
  const indicatorWidth = get(indicatorRef, 'current.clientWidth');
  const toggleWidth = get(indicatorRef, 'current.parentElement.clientWidth');
  const positionRight = toggleWidth - indicatorWidth;
  const positionMiddle = (toggleWidth - indicatorWidth) / 2;
  const [resizeListener] = useResizeAware(); // resets toggle position on resize
  const hasDeliveryAndCurbside = isDeliveryAvailable && isCurbsideAvailable;

  const divStyles = {
    transform: springProps.transform,
    width: hasDeliveryAndCurbside ? '33.33%' : '50%',
  };

  if (selectedOption === FULFILLMENT_METHODS.pickup) {
    set(defaultProps);
  } else if (selectedOption === FULFILLMENT_METHODS.delivery) {
    set({
      transform: `translateX(${positionRight || 0}px)`,
      immediate: isImmediate,
    });
  } else if (isDeliveryAvailable) {
    set({
      transform: `translateX(${positionMiddle || 0}px)`,
      immediate: isImmediate,
    });
  } else {
    set({
      transform: `translateX(${positionRight || 0}px)`,
      immediate: isImmediate,
    });
  }

  return (
    <>
      {resizeListener}
      <animated.div
        ref={indicatorRef}
        style={divStyles}
        className={styles.toggleContainer}
      />
    </>
  );
}

ToggleSlide.propTypes = {
  isCurbsideAvailable: bool,
  isDeliveryAvailable: bool,
  selectedOption: string,
};

ToggleSlide.defaultProps = {
  isCurbsideAvailable: false,
  isDeliveryAvailable: false,
  selectedOption: undefined,
};

export default ToggleSlide;
