import React from 'react';

import Help from 'components/Help';
import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';

function HelpModal({ phone = '', page = '' }: { phone: string; page: string }) {
  return (
    <ModalFrame>
      <ModalHeader title="Help" isSticky />
      <Help phone={phone} page={page} />
    </ModalFrame>
  );
}

export default HelpModal;
