import React from 'react';
import PropTypes from 'prop-types';

import LocateMeDropdown from 'components/LocateMeDropdown';
import AddressSelector from 'components/AddressSelector';

import styles from './styles.module.scss';

function DefaultDropdown({
  hasError,
  onError,
  onSelectAddress,
  savedAddresses,
  hideLocateMe,
  origin,
}) {
  return (
    <div>
      {(!hideLocateMe || !!savedAddresses.length) && (
        <div className={styles.defaultDropdown}>
          {!hideLocateMe && (
            <LocateMeDropdown
              onSelectAddress={onSelectAddress}
              origin={origin}
              onError={onError}
              hasError={hasError}
            />
          )}
          {savedAddresses.length > 0 && (
            <AddressSelector
              onSelectAddress={onSelectAddress}
              savedAddresses={savedAddresses}
            />
          )}
        </div>
      )}
    </div>
  );
}

DefaultDropdown.propTypes = {
  hasError: PropTypes.bool,
  hideLocateMe: PropTypes.bool,
  onError: PropTypes.func,
  onSelectAddress: PropTypes.func,
  origin: PropTypes.string,
  savedAddresses: PropTypes.arrayOf(PropTypes.shape({})),
};

DefaultDropdown.defaultProps = {
  hasError: false,
  hideLocateMe: false,
  onError: () => {},
  onSelectAddress: () => {},
  origin: undefined,
  savedAddresses: undefined,
};

export default DefaultDropdown;
