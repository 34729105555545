import { capitalize, get, isEmpty, find } from 'lodash';
import { format, getHours, getMinutes } from 'date-fns';
import { createSelector } from 'reselect';

import { createReducer } from 'helpers/redux';
import { FULFILLMENT_METHODS, ORDER_WHEN_OPTIONS } from 'helpers/order';
import {
  getBitsTimeSinceMidnight,
  MINUTES_PER_HOUR,
  getDateForApiDateStr,
} from '@chownow/cn-web-utils/date';
import { isDineInUrl } from '@chownow/cn-web-utils/url';

import { allowsFlexTipping } from 'modules/order';

import {
  setSelectedRestaurantId,
  fetchDeliveryRangesXHR,
  fetchRestaurantXHR,
  resetRestaurant,
  saveRestaurantXHR,
} from './actions';

export const initialState = {
  deliveryRanges: [],
  isPromptingOrderTime: false,
  selectedRestaurant: null,
  selectedRestaurantId: null,
  meta: {
    errors: [],
    isLoading: false,
  },
};

const namespace = 'restaurant';

export default createReducer(initialState, {
  [fetchRestaurantXHR.request.TYPE](state) {
    return {
      ...state,
      meta: {
        ...state.meta,
        isLoading: true,
      },
    };
  },
  [fetchRestaurantXHR.success.TYPE](state, action) {
    return {
      ...initialState,
      selectedRestaurant: action.payload,
      meta: {
        ...state.meta,
        isLoading: false,
      },
    };
  },
  [fetchRestaurantXHR.failure.TYPE](state, action) {
    return {
      ...state,
      selectedRestaurant: null,
      meta: {
        ...state.meta,
        errors: action.payload.errors,
        isLoading: false,
      },
    };
  },
  [fetchDeliveryRangesXHR.request.TYPE](state) {
    return {
      ...state,
      meta: {
        ...state.meta,
        isLoadingDeliveryRanges: true,
      },
    };
  },
  [fetchDeliveryRangesXHR.success.TYPE](state, action) {
    return {
      ...state,
      deliveryRanges: action.payload.delivery_ranges,
      meta: {
        ...state.meta,
        isLoadingDeliveryRanges: false,
      },
    };
  },
  [fetchDeliveryRangesXHR.failure.TYPE](state, action) {
    return {
      ...state,
      deliveryRanges: [],
      meta: {
        ...state.meta,
        errors: action.payload.errors,
        isLoadingDeliveryRanges: false,
      },
    };
  },
  [resetRestaurant.TYPE]() {
    return initialState;
  },
  [saveRestaurantXHR.success.TYPE](state, action) {
    const { isSaved } = action.meta;
    return {
      ...state,
      selectedRestaurant: {
        ...state.selectedRestaurant,
        is_favorite: isSaved,
      },
    };
  },
  [setSelectedRestaurantId.TYPE](state, action) {
    return {
      ...state,
      selectedRestaurantId: action.payload,
    };
  },
});

function local(state) {
  return state[namespace];
}

function getMeta(state) {
  return local(state).meta || {};
}

export function getRestaurantDetails(state) {
  return local(state).selectedRestaurant;
}

export const getSelectedRestaurantShortName = createSelector(
  getRestaurantDetails,
  (restaurant) => restaurant.short_name
);

export const getSelectedRestaurantAddress = createSelector(
  getRestaurantDetails,
  (restaurant) => restaurant.address
);

export function getSelectedRestaurantId(state) {
  return (
    get(getRestaurantDetails(state), 'id') ||
    get(local(state), 'selectedRestaurantId')
  );
}

export function getSelectedRestaurantHqId(state) {
  return get(getRestaurantDetails(state), 'company_id');
}

export function getDeliveryRanges(state) {
  return local(state).deliveryRanges;
}

export function getIsPromptingOrderTime(state) {
  return local(state).isPromptingOrderTime;
}

export function getIsBusy(state) {
  return get(getRestaurantDetails(state), 'is_busy');
}

export function getRestaurantIsLoading(state) {
  return getMeta(state).isLoading;
}

export function getIsLoadingDeliveryRanges(state) {
  return getMeta(state).isLoadingDeliveryRanges;
}

export function getFulfillmentForMethod(state, fulfillmentMethod) {
  return (
    get(getRestaurantDetails(state), `fulfillment.${fulfillmentMethod}`) || {}
  );
}

export function getIsDeliveryOnly(state) {
  return get(getRestaurantDetails(state), 'delivery_only');
}

export function getIsPickupOnly(state) {
  // If fulfillment.delivery object is empty, then is pickup only
  return isEmpty(getFulfillmentForMethod(state, FULFILLMENT_METHODS.delivery));
}

export function getPickupEta(state) {
  return getRestaurantDetails(state)?.fulfillment?.pickup?.estimated_fulfillment_time;
}

export function getDeliveryEta(state) {
  return getRestaurantDetails(state)?.fulfillment?.delivery?.estimated_fulfillment_time;
}

export function getOrderAhead(state) {
  return get(getRestaurantDetails(state), 'order_ahead');
}

export function getAllowsOrderForNow(state) {
  return get(getRestaurantDetails(state), 'allows_order_for_now');
}

export function getFulfillmentAvailability(state) {
  const allowsOrderForNow = getAllowsOrderForNow(state);
  const isPickupOnly = getIsPickupOnly(state);
  const isDeliveryOnly = getIsDeliveryOnly(state);

  return {
    isPickupAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(state, FULFILLMENT_METHODS.pickup),
        'is_available_now',
        false
      ),
    isCurbsideAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(state, FULFILLMENT_METHODS.curbside),
        'is_available_now',
        false
      ),
    isDeliveryAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(state, FULFILLMENT_METHODS.delivery),
        'is_available_now',
        false
      ),
    isDineInAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(state, FULFILLMENT_METHODS.dineIn),
        'is_available_now',
        false
      ),
    isPickupAheadAvailable: get(
      getOrderAhead(state),
      'is_pickup_ahead_available',
      false
    ),
    isCurbsideAheadAvailable: get(
      getOrderAhead(state),
      'is_curbside_ahead_available',
      false
    ),
    isDeliveryAheadAvailable: get(
      getOrderAhead(state),
      'is_delivery_ahead_available',
      false
    ),
    isDineInAheadAvailable: get(
      getOrderAhead(state),
      'is_dine_in_ahead_available',
      false
    ),
    allowsAllFulfillmentMethods: !isPickupOnly && !isDeliveryOnly,
    allowsOrderForNow,
    isAsapOnly: allowsOrderForNow && !getOrderAhead(state),
    isDeliveryOnly,
    isPickupOnly,
    isOrderAheadOnly: !allowsOrderForNow,
  };
}

/**
 * Returns a string representing an order fullmethod
 * If an order fulfill method isn't passed, fall back to redux state
 * @param {object} state - Redux store.
 * @param {string} fulfillMethod - a string representing a fulfillMethod.
 */
function getFulfillMethod(state, orderFulfillMethod) {
  const { isDeliveryOnly } = getFulfillmentAvailability(state);
  const hasDineInUrl = isDineInUrl();

  if (orderFulfillMethod) {
    return orderFulfillMethod;
  }
  if (hasDineInUrl) {
    return FULFILLMENT_METHODS.dineIn;
  }
  if (isDeliveryOnly) {
    return FULFILLMENT_METHODS.delivery; // Should default to pickup unless HQ is delivery only.
  }
  return FULFILLMENT_METHODS.pickup;
}

/**
 * Returns a boolean indicating if fulfillment is avaiable now
 * for a given fulfillment method
 * @param {object} state - Redux store.
 * @param {string} fulfillMethod - a string representing a fulfillMethod.
 */
function getFulfillmentAvailabilityNow(state, fulfillMethod) {
  const hasDineInUrl = isDineInUrl();
  if (hasDineInUrl) return true;
  return get(
    getFulfillmentAvailability(state),
    `is${capitalize(fulfillMethod)}AvailableNow`,
    false
  );
}

/**
 * Returns a string representing a datetime, regardless of Daylight Savings shifts
 * Time slots come from the API as bits.
 * @param {object} an object containing the keys
 * startDate - a date string in the yyyy-mm-dd format I.E. 2021-04-15
 * firstAvailableBit - a number representing the first time slot open on a menu
 * precision - a number representing the frequency of the next time slot (in minutes)
 */
export function formatOrderAheadDate({
  startDate,
  firstAvailableBit,
  precision,
}) {
  const cleanDate = startDate.replace(/-/g, '');
  const { hours, minutes } = getBitsTimeSinceMidnight({
    startDate,
    firstAvailableBit,
    precision,
  });

  return `${cleanDate}${hours}${minutes}`;
}

/**
 * Returns an object with the date and bit of the first available time slot
 * for a given fulfillment method
 * @param {object} state - Redux store.
 * @param {string} fulfillMethod - a string representing a fulfillMethod.
 */
export function getFirstAvailableOrderTime(state, fulfillMethod) {
  const orderAhead = getOrderAhead(state);
  const { days } = orderAhead;
  let firstAvailableBit = null;
  let date = null;

  for (let i = 0; i < days.length; i += 1) {
    const time = get(days[i], `${fulfillMethod}_index_ranges[0]`);
    if (time) {
      firstAvailableBit = time.from;
      date = days[i].date;
      break;
    }
  }

  // firstAvailableBit will equal 0 when midnight is first available time
  if (date && (firstAvailableBit || firstAvailableBit === 0)) {
    return {
      firstAvailableBit,
      date,
    };
  }
  return null;
}

/* eslint-disable consistent-return, no-else-return */
export function getIsFulfillmentAvailableForWhen(state, fulfillMethod, when) {
  if (!when) {
    const fulfillmentAvailability = getFulfillmentAvailability(state);
    return get(
      fulfillmentAvailability,
      `is${capitalize(fulfillMethod)}AvailableNow`
    );
  }

  const orderAhead = getOrderAhead(state);

  const whenHour = when.substring(8, 10);
  const whenMin = when.substring(10, 12);
  const whenIndex = whenHour * 4 + (whenMin / 60) * 4;

  const formattedWhen = getDateForApiDateStr(when);
  const formattedDate = format(formattedWhen, 'yyyy-MM-dd');
  const whenDay = find(orderAhead.days, { date: formattedDate });
  const fulfillmentIndexesForWhen = get(
    whenDay,
    `${fulfillMethod}_index_ranges`
  );

  if (!fulfillmentIndexesForWhen?.length) {
    return false;
  } else {
    fulfillmentIndexesForWhen.forEach((range) => {
      const fulfillmentIsNotAvailable =
        whenIndex > range.from && whenIndex < range.to;
      if (fulfillmentIsNotAvailable) return false;
    });
  }

  return true;
}
/* eslint-enable consistent-return, no-else-return */

export function getIsClosed(state) {
  const {
    isPickupAvailableNow,
    isDeliveryAvailableNow,
    isDineInAvailableNow,
    isPickupAheadAvailable,
    isDeliveryAheadAvailable,
  } = getFulfillmentAvailability(state);
  const hasDineInUrl = isDineInUrl();

  if (hasDineInUrl && !isDineInAvailableNow) {
    return true;
  }

  return (
    !isPickupAvailableNow &&
    !isDeliveryAvailableNow &&
    !isPickupAheadAvailable &&
    !isDeliveryAheadAvailable
  );
}

/**
 * Returns an object with the date of the next available order time
 * for a given fulfillment method
 * @param {object} state - Redux store.
 * @param {string} orderFulfillMethod - a string representing a fulfillMethod.
 */
export function getNextAvailableTime(state, orderFulfillMethod) {
  const orderAhead = getOrderAhead(state);

  if (getIsClosed(state)) return {};

  let nextAvailableTime;

  const fulfillMethod = getFulfillMethod(state, orderFulfillMethod);
  const ahead = `is_${fulfillMethod}_ahead_available`;

  if (getFulfillmentAvailabilityNow(state, fulfillMethod)) {
    nextAvailableTime = null;
  } else if (orderAhead && orderAhead[ahead]) {
    const { precision } = orderAhead;
    const { firstAvailableBit, date } = getFirstAvailableOrderTime(
      state,
      fulfillMethod
    );

    nextAvailableTime = formatOrderAheadDate({
      startDate: date,
      firstAvailableBit,
      precision,
    });
  }

  return {
    fulfillMethod,
    nextAvailableTime,
  };
}

export function getNextClosedTime(state) {
  const pickup = getFulfillmentForMethod(state, FULFILLMENT_METHODS.pickup);
  const delivery = getFulfillmentForMethod(state, FULFILLMENT_METHODS.delivery);

  return format(
    getDateForApiDateStr(
      get(pickup, 'next_closed_time') || get(delivery, 'next_closed_time')
    ),
    'h:mma'
  );
}

export function getIsOpen(state) {
  const { allowsOrderForNow, isPickupAvailableNow, isDeliveryAvailableNow } =
    getFulfillmentAvailability(state);

  return allowsOrderForNow && (isPickupAvailableNow || isDeliveryAvailableNow);
}

export function getHasDineIn(state) {
  return !!getFulfillmentForMethod(state, FULFILLMENT_METHODS.dineIn);
}

export function getIsTableRequired(state) {
  const dineIn = getFulfillmentForMethod(state, FULFILLMENT_METHODS.dineIn);
  return dineIn && dineIn.has_table_names;
}

function getIsFulfillmentAheadAvailable(orderAhead, when, fulfillmentMethod) {
  const date = getDateForApiDateStr(when);
  const formattedDate = format(date, 'yyyy-MM-dd');
  const orderAheadDay = find(orderAhead.days, { date: formattedDate });
  const timeIndex =
    (getHours(date) * MINUTES_PER_HOUR + getMinutes(date)) /
    orderAhead.precision;

  if (!orderAheadDay) {
    return false;
  }

  return !!get(orderAheadDay, fulfillmentMethod)[timeIndex];
}

export function getIsPickupAvailable(state, when) {
  const isPickupAvailableNow = get(
    getFulfillmentForMethod(state, FULFILLMENT_METHODS.pickup),
    'is_available_now',
    false
  );

  /**
   * If a time is provided, check availability at that time. When no time is
   * provided check if option is ever available.
   * `when` === `state.cart.when`. `null` for ASAP or timestamp for scheduled
   * order (order ahead) in `YYYYMMDDHHmm` where hours are 24hr format.
   */
  if (when === ORDER_WHEN_OPTIONS.now.value) {
    // Check if available now
    return isPickupAvailableNow;
  }

  if (when) {
    return getIsFulfillmentAheadAvailable(
      getOrderAhead(state),
      when,
      FULFILLMENT_METHODS.pickup
    );
  }

  // Check if fulfillMethod is ever available (now or later)
  return (
    isPickupAvailableNow ||
    get(getOrderAhead(state), 'is_pickup_ahead_available', false)
  );
}

export function getIsDeliveryAvailable(state, when) {
  const isDeliveryAvailableNow = get(
    getFulfillmentForMethod(state, FULFILLMENT_METHODS.delivery),
    'is_available_now',
    false
  );

  /**
   * If a time is provided, check availability at that time. When no time is
   * provided check if option is ever available.
   * `when` === `state.cart.when`. `null` for ASAP or timestamp for scheduled
   * order (order ahead) in `YYYYMMDDHHmm` where hours are 24hr format.
   */
  if (when === ORDER_WHEN_OPTIONS.now.value) {
    // Check if available now
    return isDeliveryAvailableNow;
  }

  if (when) {
    return getIsFulfillmentAheadAvailable(
      getOrderAhead(state),
      when,
      FULFILLMENT_METHODS.delivery
    );
  }

  // Check if fulfillMethod is ever available (now or later)
  return (
    isDeliveryAvailableNow ||
    get(getOrderAhead(state), 'is_delivery_ahead_available', false)
  );
}

export function getIsTippingEnabled(state, fulfillmentMethod) {
  const fulfillmentData = getFulfillmentForMethod(state, fulfillmentMethod);
  const allowsTipForFulfillment = get(fulfillmentData, 'tip.allows_tip');

  // if flex tipping isn't explicitly boolean, defer to fulfillment object
  // flex tipping should only be honored for delivery orders
  return typeof allowsFlexTipping(state) === 'boolean' &&
    fulfillmentMethod === FULFILLMENT_METHODS.delivery
    ? allowsFlexTipping(state)
    : allowsTipForFulfillment;
}

export function getMinMaxOrderAmount(state, fulfillmentMethod) {
  const fulfillmentDetails = getFulfillmentForMethod(state, fulfillmentMethod);

  return {
    minAmount: fulfillmentDetails.min_order_amt,
    maxAmount: fulfillmentDetails.max_order_amt,
  };
}

export function getMiscFee(state) {
  const restaurant = getRestaurantDetails(state);

  return get(restaurant, 'misc_fee');
}

// We need to check both that the membership is enabled AND that the restaurant has plans
export function getRestaurantMembershipEnabled(state) {
  const restaurant = getRestaurantDetails(state);
  const isEnabled = get(restaurant, 'membership.is_enabled', false);
  const hasPlans = !!get(restaurant, 'membership.plans', []).length;

  return isEnabled && hasPlans;
}

export function getRestaurantMembershipPlans(state) {
  const restaurant = getRestaurantDetails(state);

  return get(restaurant, 'membership.plans', []);
}
