/* eslint-disable max-len */
import React from 'react';

export default function ApplePay() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.5 0.5 5 1 5H31C31.5 5 32 5.5 32 6V26C32 26.5 31.5 27 31 27H1C0.5 27 0 26.5 0 26V6ZM1 6V26H31V6H1Z"
        fill="black"
      />
      <path
        d="M8.53586 12.2895C8.81842 11.9424 9.01016 11.4763 8.95959 11C8.54596 11.0202 8.04121 11.268 7.74899 11.6154C7.4866 11.913 7.25437 12.3986 7.31491 12.8549C7.77923 12.8945 8.24311 12.6269 8.53586 12.2895Z"
        fill="black"
      />
      <path
        d="M8.95433 12.944C8.28003 12.9046 7.70671 13.3199 7.3847 13.3199C7.06251 13.3199 6.5694 12.9639 6.03607 12.9735C5.3419 12.9835 4.69779 13.369 4.34553 13.9822C3.62099 15.2088 4.15433 17.0282 4.8589 18.0272C5.20106 18.5215 5.61342 19.0657 6.15677 19.0461C6.67014 19.0263 6.87136 18.7196 7.49539 18.7196C8.11895 18.7196 8.30021 19.0461 8.84365 19.0362C9.4072 19.0263 9.75952 18.5417 10.1017 18.047C10.4942 17.4836 10.6549 16.9396 10.665 16.9098C10.6549 16.8999 9.57829 16.4942 9.56831 15.2778C9.55815 14.2593 10.4136 13.7748 10.4538 13.7448C9.97076 13.043 9.21599 12.9639 8.95433 12.944Z"
        fill="black"
      />
      <path
        d="M14.8257 11.5657C16.2913 11.5657 17.3118 12.558 17.3118 14.0026C17.3118 15.4525 16.2703 16.4499 14.7889 16.4499H13.1663V18.9846H11.9939V11.5657L14.8257 11.5657ZM13.1663 15.4833H14.5115C15.5322 15.4833 16.1132 14.9436 16.1132 14.0078C16.1132 13.0721 15.5322 12.5374 14.5167 12.5374H13.1663V15.4833Z"
        fill="black"
      />
      <path
        d="M17.6182 17.4474C17.6182 16.5013 18.3562 15.9204 19.6649 15.8484L21.1723 15.761V15.3446C21.1723 14.743 20.7587 14.3832 20.0679 14.3832C19.4135 14.3832 19.0052 14.6916 18.9059 15.1749H17.8381C17.9009 14.198 18.7488 13.4782 20.1097 13.4782C21.4445 13.4782 22.2976 14.1723 22.2976 15.2571V18.9846H21.2141V18.0951H21.188C20.8688 18.6967 20.1725 19.0771 19.4503 19.0771C18.372 19.0771 17.6182 18.419 17.6182 17.4474ZM21.1723 16.959V16.5322L19.8165 16.6145C19.1413 16.6608 18.7593 16.9538 18.7593 17.4165C18.7593 17.8895 19.1571 18.198 19.7642 18.198C20.5546 18.198 21.1723 17.6633 21.1723 16.959Z"
        fill="black"
      />
      <path
        d="M23.3206 20.9743V20.0746C23.4042 20.0951 23.5926 20.0951 23.6869 20.0951C24.2103 20.0951 24.493 19.8792 24.6656 19.3239C24.6656 19.3136 24.7651 18.9949 24.7651 18.9897L22.7762 13.5759H24.0009L25.3933 17.9769H25.4141L26.8066 13.5759H28L25.9375 19.2673C25.4666 20.5784 24.9222 21 23.7812 21C23.6869 21 23.4042 20.9897 23.3206 20.9743Z"
        fill="black"
      />
    </svg>
  );
}
