/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { BETTER_DEAL_APPLIED, INVALID_PROMO_CODE } from 'helpers/api';
import { MODAL_TYPE } from 'helpers/modals';

import { getErrorsForOrder, getActiveDiscount } from 'modules/order';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as IconPromo } from 'images/icon-promo.svg';

import styles from './styles.module.scss';

function PromoCode() {
  const { openModal } = useContext(ModalContext);
  const { isLoggedIn } = useContext(UserContext);

  const discount = useSelector(getActiveDiscount);
  const errors = useSelector(getErrorsForOrder);

  const promoCode = discount?.promo_code;
  const betterPromo = errors.filter(
    (error) => error.code === BETTER_DEAL_APPLIED
  ).length;
  const invalidPromo = errors.filter(
    (error) => error.code === INVALID_PROMO_CODE
  ).length;

  function getPromoDesc() {
    if (promoCode || (betterPromo && !invalidPromo)) {
      return (
        <span className={styles.success}>
          {betterPromo
            ? 'A better promo code has been automatically applied'
            : `The promo code “${promoCode}” has been applied`}
        </span>
      );
    }
    return 'Add promo';
  }

  function handleOpenPromoModal() {
    if (!isLoggedIn) {
      return openModal(MODAL_TYPE.auth);
    }

    return openModal(MODAL_TYPE.promo);
  }

  return (
    <button
      type="button"
      className={styles.promoWrapper}
      onClick={handleOpenPromoModal}
    >
      <div className={styles.promoDesc}>
        <IconPromo className={styles.icon} />
        {getPromoDesc()}
      </div>

      <IconEdit data-testid="EditIcon" />
    </button>
  );
}

export default PromoCode;
