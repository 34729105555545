import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as IconCheck } from 'images/icon-check-white-16.svg';

import styles from './styles.module.scss';

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

function Checkbox({
  checked = false,
  disabled = false,
  name,
  onChange = () => {},
  value = '',
}: CheckboxProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    onChange(event);
  };

  return (
    <div className={styles.checkboxWrapper}>
      <input
        checked={isChecked}
        className={classNames(styles.checkbox, {
          [styles.disabled]: disabled,
        })}
        name={name}
        onChange={handleOnChange}
        type="checkbox"
        value={value}
        disabled={disabled}
      />
      <div className={styles.checkboxVisible}>
        <IconCheck className={styles.iconCheckmark} />
      </div>
    </div>
  );
}

export default Checkbox;
