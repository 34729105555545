import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { MODAL_TYPE } from 'helpers/modals';

import EmptyMenu from 'components/EmptyMenu';
import { ReactComponent as IconMenu } from 'images/icon-menu.svg';

import styles from './styles.module.scss';

const MARKETPLACE_CLOSURE_COPY = `We’re closed or very busy right now. \
Please try ordering again later.`;

const ORDER_VOLUME_CLOSURE_COPY = `We’re currently experiencing higher than normal order volume. \
To maintain a high level of service, we’ve temporarily paused new orders. \
Please check back later. Thank you for understanding and sorry for the inconvenience.`;

function getCopy(isOrderVolumeClosure, isLive) {
  if (isOrderVolumeClosure) return ORDER_VOLUME_CLOSURE_COPY;
  return `Online Ordering Not Open ${isLive ? 'Yet' : ''}`;
}

export default function OrderingNotOpen(props) {
  const {
    isShowingSessionModal,
    isOrderVolumeClosure,
    is_live: isLive,
    isMarketplacePlatform,
    restaurantName,
  } = props;

  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    if (isMarketplacePlatform) {
      // Show a modal directing users back to Marketplace Search
      openModal(MODAL_TYPE.dialog, {
        title: `${restaurantName} is currently not accepting orders.`,
        message: MARKETPLACE_CLOSURE_COPY,
        confirmLabel: 'Browse Other Local Eats',
        onConfirm: () => {
          window.location.href = '/discover';
        },
        noCloseOverlay: true,
        isAlert: true,
      });
    }
  }, []);

  return (
    <div className={styles.emptyMenu} data-testid="EmptyMenu">
      {!isShowingSessionModal && !isMarketplacePlatform && (
        <h3 className={styles.emptyCaption} data-testid="MenuUnavailMessage">
          <IconMenu className={styles.iconMenu} />
          <span
            className={classNames({
              [styles.tempCloseCaption]: isOrderVolumeClosure,
            })}
          >
            {getCopy(isOrderVolumeClosure, isLive)}
          </span>
        </h3>
      )}

      <EmptyMenu className={styles.blurredMenu} />
    </div>
  );
}

OrderingNotOpen.propTypes = {
  isMarketplacePlatform: PropTypes.bool,
  isOrderVolumeClosure: PropTypes.bool,
  isShowingSessionModal: PropTypes.bool,
  is_live: PropTypes.bool,
  restaurantName: PropTypes.string,
};

OrderingNotOpen.defaultProps = {
  isMarketplacePlatform: false,
  isOrderVolumeClosure: false,
  isShowingSessionModal: false,
  is_live: false,
  restaurantName: undefined,
};
