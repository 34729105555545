import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';

import FormikInput from 'components/FormFields/FormikInput';
import CreditCardForm from 'components/CreditCardForm';

import { ReactComponent as IconLock } from 'images/icon-lock.svg';
import iconSecurity from 'images/icon-securitycode.svg';
import iconSecurityAmex from 'images/icon-securitycode-front.svg';

import styles from './styles.module.scss';

function InvalidCard(props) {
  const { setFieldValue, isAmex, selectedCardId, isDeclinedCard, isExpired } =
    props;

  function getCardMessaging() {
    if (isExpired) {
      return 'Please update your expiration date.';
    }

    return `Please enter your security code for a secure checkout.`;
  }

  function handleClearField(field) {
    setFieldValue(field, '');
  }

  return (
    <div
      className={classNames(styles.invalidCardWrapper, {
        [styles.noPadding]: isDeclinedCard,
      })}
    >
      {!isDeclinedCard && (
        <div className={styles.invalidCardSectionLabel}>
          <IconLock className={styles.iconLock} />
          {getCardMessaging()}
        </div>
      )}
      <div>
        {isDeclinedCard ? (
          <CreditCardForm
            setFieldValue={setFieldValue}
            cardId={selectedCardId}
            hideSaveCardCheckbox
          />
        ) : (
          <div className={styles.inputWrapper}>
            {isExpired && (
              <div className={styles.expiredInput}>
                <Field
                  type="tel"
                  name="expDate"
                  autoComplete="cc-exp"
                  placeholder="MM / YY"
                  maxLength="5"
                  component={FormikInput}
                  onClear={handleClearField}
                />
              </div>
            )}
            <div className={styles.cvvInput}>
              <Field
                type="tel"
                name="cvv"
                maxLength="4"
                autoComplete="off"
                component={FormikInput}
                icon={isAmex ? iconSecurityAmex : iconSecurity}
                onClear={handleClearField}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

InvalidCard.propTypes = {
  isAmex: PropTypes.bool,
  isDeclinedCard: PropTypes.bool,
  isExpired: PropTypes.bool,
  selectedCardId: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
};

InvalidCard.defaultProps = {
  isAmex: false,
  isDeclinedCard: false,
  isExpired: false,
  selectedCardId: undefined,
};

export default InvalidCard;
