/* eslint-disable prefer-object-spread, consistent-return */
import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { CompanyContext } from 'context/CompanyContext';
import { ModalContext } from 'context/ModalContext';

import {
  CAN_ALPHA2_COUNTRY_CODE,
  CAN_CURRENCY,
  USA_ALPHA2_COUNTRY_CODE,
  USA_CURRENCY,
} from 'helpers/constants';
import { CC_ERROR_MODAL_COPY, MODAL_TYPE } from 'helpers/modals';
import { getGooglePaymentsClient, serializeGooglePay } from 'helpers/payments';
import usePlatform from 'hooks/usePlatform';
import { isIE } from '@chownow/cn-web-utils/url';

import { getOrderData } from 'modules/order';

const MODAL_COPY = {
  googlePayNameError: {
    title: "Let's Take a Second Look.",
    message:
      'Please enter both first and last name in Google Pay contact information.',
  },
  googlePayTotalError: {
    title: "Let's Take a Second Look.",
    message:
      'Google Pay is not available for order totals of $0, please select another payment method.',
  },
};

function useGooglePay(submitOrder) {
  const [isGooglePayLoading, setIsGooglePayLoading] = useState(true);
  const [isGooglePayEnabled, setIsGooglePayEnabled] = useState(false);
  const [isAttemptingGooglePay, setIsAttemptingGooglePay] = useState(false);

  const { isCanadian } = useContext(CompanyContext);
  const { openModal } = useContext(ModalContext);

  const { isYelpPlatform } = usePlatform();

  const order = useSelector(getOrderData);

  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'stripe',
      'stripe:version': '2018-10-31',
      'stripe:publishableKey': process.env.REACT_APP_STRIPE_API_KEY,
    },
  };

  const allowedCardNetworks = ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'];
  const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];
  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks,
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true,
      },
    },
  };

  const cardPaymentMethod = Object.assign(
    { tokenizationSpecification },
    baseCardPaymentMethod
  );

  const paymentsClient = window.google && getGooglePaymentsClient();

  const googlePayCurrency = isCanadian ? CAN_CURRENCY : USA_CURRENCY;
  const googlePayCountryCode = isCanadian
    ? CAN_ALPHA2_COUNTRY_CODE
    : USA_ALPHA2_COUNTRY_CODE;

  function checkIsGooglePayAllowed() {
    const isReadyToPayRequest = Object.assign({}, baseRequest);
    isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
    isReadyToPayRequest.existingPaymentMethodRequired = true;

    paymentsClient
      .isReadyToPay(isReadyToPayRequest)
      .then((response) => {
        // response.paymentMethodPresent will always return `true` in TEST env
        if (response.result && response.paymentMethodPresent) {
          setIsGooglePayEnabled(true);
        }
      })
      .catch((err) => {
        console.error(`Google Pay not allowed: ${err}`); // eslint-disable-line no-console
      })
      .finally(() => {
        setIsGooglePayLoading(false);
      });
  }

  // IE11 does not support promise.finally and throws an ErrorBoundary. Do not try to
  // set up GooglePay if on IE11
  // TO DO: remove IE11 check once we officially stop support of IE11 browser
  //
  // We also disallow Yelp since 1. they handle all payment and 2. they don't add
  // the allow="payment" attribute to the iframe where we're embedded
  useEffect(() => {
    const shouldCheckGooglePay = paymentsClient && !isIE() && !isYelpPlatform;

    if (shouldCheckGooglePay) {
      checkIsGooglePayAllowed();
    } else {
      setIsGooglePayLoading(false);
    }
  }, []);

  function handleError(errorCopy) {
    openModal(MODAL_TYPE.dialog, {
      ...errorCopy,
      noCloseOverlay: true,
      isAlert: true,
    });
  }

  function initiateGooglePay() {
    setIsAttemptingGooglePay(true);

    const paymentDataRequest = Object.assign(
      {
        allowedPaymentMethods: [cardPaymentMethod],
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: String(order.total_due),
          currencyCode: googlePayCurrency,
          countryCode: googlePayCountryCode,
        },
        merchantInfo: {
          merchantName: 'STRIPE',
          merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID,
        },
        emailRequired: true,
      },
      baseRequest
    );

    if (order.total_due === 0) {
      setIsAttemptingGooglePay(false);
      return handleError(MODAL_COPY.googlePayTotalError);
    }

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then((result) => {
        setIsAttemptingGooglePay(false);

        const parsedPayload = serializeGooglePay(result);

        if (!parsedPayload.first_name || !parsedPayload.last_name) {
          return handleError(MODAL_COPY.googlePayNameError);
        }

        submitOrder(parsedPayload);
      })
      .catch((error) => {
        setIsAttemptingGooglePay(false);

        if (error.statusCode === 'CANCELED') return;

        handleError(CC_ERROR_MODAL_COPY, error);
      });
  }

  return {
    isAttemptingGooglePay,
    isGooglePayLoading,
    onGooglePay: initiateGooglePay,
    defaultToGooglePay: isGooglePayEnabled,
  };
}

export default useGooglePay;
