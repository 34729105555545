/* eslint-disable complexity */
import { takeLatest, put, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { paths, request } from 'helpers/api';
import { MODAL_TYPE } from 'helpers/modals';
import {
  isOnPage,
  isDineInUrl,
  isMarketplacePlatform,
  isYelpPlatform,
} from '@chownow/cn-web-utils/url';
import {
  FAVORITED_TYPE,
  MAX_ORDER_CLOSURE_DESCRIPTION,
} from 'helpers/constants';
import { getCnChannel, setSelectedAddress } from 'helpers/customer';
import { FULFILLMENT_METHODS } from 'helpers/order';

import { getOrderData, startOrderRequest } from 'modules/order';
import { getIsClosed, getIsDeliveryOnly } from 'modules/restaurant';

import { callConfig } from 'index';

import {
  fetchRestaurantXHR,
  fetchDeliveryRangesXHR,
  setSelectedRestaurantId,
  saveRestaurantXHR,
} from './actions';
import {
  getRestaurantDetails,
  getNextAvailableTime,
  getFulfillmentAvailability,
} from './store';

import geocodeAddressFromURL from './geocode';

export function getChannelParam() {
  const cnChannel = isYelpPlatform() ? 'yelp' : getCnChannel();
  return cnChannel ? `?cn_channel=${cnChannel}` : '';
}

function* handleSelectRestaurant({ payload }) {
  yield put(fetchRestaurantXHR.request(payload));

  const path = isYelpPlatform()
    ? paths.yelp.restaurant({ id: payload })
    : paths.restaurant({ id: payload });
  const endpoint = `${path}${getChannelParam()}`;

  yield request({ requestAction: fetchRestaurantXHR, path: endpoint });
}

function* handleFetchDeliveryRanges({ payload }) {
  yield request({
    requestAction: fetchDeliveryRangesXHR,
    path: `restaurant/${payload.id}/delivery-ranges`,
    params: payload.params,
    method: 'POST',
  });
}

function getRestaurantHasDelivery(restaurant) {
  if (!restaurant) return false;

  const delivery = restaurant.fulfillment?.delivery;
  const deliveryOrderAhead = restaurant.order_ahead?.is_delivery_ahead_available;

  const deliveryIsUnavailable =
    !delivery || (!delivery.is_available_now && !deliveryOrderAhead);

  if (deliveryIsUnavailable) return false;

  return delivery.is_available_now || delivery.next_available_time;
}

function getFulfillmentFromUrlQuery(hasDelivery) {
  const { deliversToMe } = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );

  if (deliversToMe === '1' && hasDelivery) {
    return FULFILLMENT_METHODS.delivery;
  }

  return FULFILLMENT_METHODS.pickup;
}

function* populateDeliveryAddressFromUrl(isDeliveryOrder) {
  const { loc: urlLocation } = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  const { savedAddressId } = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  // An address is in the url, but it is not a referenced to saved address from the user's account
  const shouldUseUrlAddress = isDeliveryOrder && urlLocation;
  const existingCustomerDeliveryAddresses = yield callConfig.call.UserContext
    .user.delivery?.addresses || [];
  const savedAddressObject = existingCustomerDeliveryAddresses.find(
    (address) => address.id === savedAddressId
  );
  // there is an address param in the url and we've found a saved address matching
  const shouldUseSavedAddressFromUrl =
    isDeliveryOrder && urlLocation && !!savedAddressObject;

  if (shouldUseSavedAddressFromUrl) {
    yield setSelectedAddress({ ...savedAddressObject });
  } else if (shouldUseUrlAddress) {
    // This causes a slight delay in rendering the menu
    const geocodedAddress = yield geocodeAddressFromURL(urlLocation);
    yield setSelectedAddress({ ...geocodedAddress });
  }
}

export function* handleDeliveryOrderFromURLParam() {
  const restaurant = yield select(getRestaurantDetails);
  const hasDelivery = getRestaurantHasDelivery(restaurant);
  const urlQueryFulfillMethod = getFulfillmentFromUrlQuery(hasDelivery) || '';
  const isDeliveryOrder =
    urlQueryFulfillMethod === FULFILLMENT_METHODS.delivery;

  if (!hasDelivery && isDeliveryOrder) {
    return;
  }

  const isMarketplace = isMarketplacePlatform();
  if (isMarketplace) {
    yield populateDeliveryAddressFromUrl(isDeliveryOrder);
  }
}

function* handleFetchRestaurantSuccess() {
  const restaurant = yield select(getRestaurantDetails);
  const order = yield select(getOrderData);
  const isDeliveryOnly = yield select(getIsDeliveryOnly);
  const hasDineInUrl = yield select(isDineInUrl);
  const isDineInOrder =
    order && order.fulfillmentMethod === FULFILLMENT_METHODS.dineIn;
  const isWrongOrderType =
    (isDineInOrder && !hasDineInUrl) || (!isDineInOrder && hasDineInUrl);

  const {
    isPickupAvailableNow,
    isDeliveryAvailableNow,
    isPickupAheadAvailable,
    isDeliveryAheadAvailable,
  } = yield select(getFulfillmentAvailability);
  const { fulfillMethod, nextAvailableTime } = yield select(
    getNextAvailableTime
  );
  const hasDelivery = getRestaurantHasDelivery(restaurant);
  // Ignore any query params for dine-in orders
  // dine-in orders are indicated by url *path* as opposed to a query param
  const urlQueryFulfillMethod = !hasDineInUrl
    ? getFulfillmentFromUrlQuery(hasDelivery)
    : '';

  const defaultFulfillMethod = urlQueryFulfillMethod || fulfillMethod;
  const isClosed = yield select(getIsClosed);
  const isClosedNow = !isPickupAvailableNow && !isDeliveryAvailableNow;
  const shouldBeginNewOrder =
    !isClosed &&
    restaurant.is_live &&
    (isEmpty(order) ||
      order.restaurantId !== restaurant.id ||
      isWrongOrderType);

  // TO DO: remove account once BE updates membership api with restaurant info
  if (isOnPage('membership') || isOnPage('history') || isOnPage('account')) {
    return;
  }

  // CN-10963 if there's an OrderBot temp closure don't start order
  if (
    restaurant &&
    restaurant.closures.length &&
    restaurant.closures.find(
      (closure) => closure.description === MAX_ORDER_CLOSURE_DESCRIPTION
    )
  ) {
    return;
  }

  // Don't attempt to intiate a new order if we've somehow accessed a marketplace
  // themed menu when the resto doesn't have the option live in admin.
  if (shouldBeginNewOrder) {
    // Marketplace delivery fulfillment flow
    if (!isOnPage('checkout')) {
      yield handleDeliveryOrderFromURLParam();
    }

    // Begin the order
    yield put(
      startOrderRequest({
        restaurantId: restaurant.id,
        fulfillmentMethod: defaultFulfillMethod,
        when: nextAvailableTime,
      })
    );

    if (isDeliveryOnly) {
      yield callConfig.call.ModalContext.openModal(
        MODAL_TYPE.fulfillmentDetails,
        {
          fulfillmentMethod: FULFILLMENT_METHODS.delivery,
          nextWhen: nextAvailableTime,
          noCloseOverlay: true,
        }
      );
    } else if (
      nextAvailableTime &&
      isClosedNow &&
      (isPickupAheadAvailable || isDeliveryAheadAvailable)
    ) {
      callConfig.call.ModalContext.openModal(MODAL_TYPE.orderingUnavailable, {
        noCloseOverlay: true,
      });
    }
  }
}

export function* handleFetchRestaurant({ payload }) {
  yield request({
    requestAction: fetchRestaurantXHR,
    path: `restaurant/${payload}`,
  });
}

export function* handleSaveRestaurant({
  payload: { userId, restaurantId, isSaved },
}) {
  const method = isSaved ? 'PUT' : 'DELETE';

  yield request({
    requestAction: saveRestaurantXHR,
    path: `customer/${userId}/favorites`,
    params: {
      favorited_type: FAVORITED_TYPE.restaurant,
      favorited_id: restaurantId,
    },
    method,
    meta: { isSaved },
  });
}

export function* restaurantSaga() {
  yield takeLatest(setSelectedRestaurantId.TYPE, handleSelectRestaurant);
  yield takeLatest(fetchRestaurantXHR.request.TYPE, handleFetchRestaurant);
  yield takeLatest(
    fetchRestaurantXHR.success.TYPE,
    handleFetchRestaurantSuccess
  );
  yield takeLatest(
    fetchDeliveryRangesXHR.request.TYPE,
    handleFetchDeliveryRanges
  );
  yield takeLatest(saveRestaurantXHR.request.TYPE, handleSaveRestaurant);
}

export default [restaurantSaga];

/* eslint-enable */
