import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

import breakpoints from 'helpers/breakpoints';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { calculateNumItems } from 'helpers/order';

import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';

import {
  getHqIdFromUrl,
  getRestaurantIdFromUrl,
} from '@chownow/cn-web-utils/url';

import useIsMobile from 'hooks/useIsMobile';

import { getOrderData, getOrderItemData } from 'modules/order';

import BagEmpty from 'components/BagEmpty';
import OrderToggle from 'components/OrderSummary/OrderToggle';
import OrderSummary from 'components/OrderSummary';

import Button from 'primitives/Button';

import styles from './styles.module.scss';

export function getSourcePage({ isOnMenu, isModal, isCheckout, isMobile }) {
  if (isOnMenu) {
    return 'desktop_menu_order_summary';
  }
  if (isModal) {
    return 'mobile_menu_order_summary';
  }
  if (isCheckout) {
    return isMobile
      ? 'mobile_checkout_order_summary'
      : 'desktop_checkout_order_summary';
  }
  return null;
}

function Order(props) {
  const {
    hasDisclaimer,
    isCheckout,
    isEmptyBag,
    isExpandedForced,
    isModal,
    isOnMenu,
    showOrderToggle,
  } = props;

  const { menu } = useContext(MenuContext);

  const items = useSelector(getOrderItemData);
  const order = useSelector(getOrderData);

  const { closeModal } = useContext(ModalContext);
  const isMobile = useIsMobile();
  const hasItems = !!items.length;
  const numItems = calculateNumItems(items);
  const hqId = getHqIdFromUrl();
  const restaurantId = getRestaurantIdFromUrl();
  const canShowAddItemsButton = isMobile && !isCheckout;

  const sourcePages = {
    isModal,
    isCheckout,
    isOnMenu,
    isMobile,
  };
  function handleAddItems() {
    if (isModal) {
      closeModal();
    }
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectAddItemsOrderSummary,
      attributes: {
        menu_id: menu.id,
        restaurant_location_id: restaurantId,
        restaurant_brand_id: hqId,
        order_subtotal: order.item_total,
        total_item_quantity: numItems,
      },
    });
  }

  return (
    <>
      <div
        className={classNames(styles.order, {
          [styles.menuOrder]: isOnMenu && !isEmptyBag,
          [styles.isCheckout]: isCheckout,
        })}
      >
        {hasItems ? (
          <OrderSummary
            items={items}
            hasDisclaimer={hasDisclaimer}
            order={order}
            sourcePage={getSourcePage(sourcePages)}
            showLineItems={isCheckout}
            showOrderToggle={showOrderToggle}
            isCheckout={isCheckout}
            isExpandedForced={isExpandedForced}
          />
        ) : (
          <>
            <MediaQuery minWidth={breakpoints.smMin}>
              <OrderToggle hideToggle isCheckout={isCheckout} />
            </MediaQuery>
            <BagEmpty />
          </>
        )}
      </div>
      {canShowAddItemsButton && (
        <div className={styles.addItemsButton}>
          <Button
            isFullWidth
            variant="outline"
            onClick={() => handleAddItems()}
          >
            Add Items
          </Button>
        </div>
      )}
    </>
  );
}

Order.propTypes = {
  hasDisclaimer: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isEmptyBag: PropTypes.bool,
  isExpandedForced: PropTypes.bool,
  isModal: PropTypes.bool,
  isOnMenu: PropTypes.bool,
  showOrderToggle: PropTypes.bool,
};

Order.defaultProps = {
  hasDisclaimer: false,
  isCheckout: false,
  isEmptyBag: false,
  isExpandedForced: false,
  isModal: false,
  isOnMenu: false,
  showOrderToggle: false,
};

export default Order;
