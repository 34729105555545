import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface MenuSimpleProps {
  dataTestId?: string;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  icon?: React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  label: string;
  selected?: boolean;
}

function MenuSimple({
  dataTestId = '',
  handleClick,
  icon: Icon,
  label,
  selected = false,
}: MenuSimpleProps) {
  return (
    <button
      className={classNames(styles.menuSimpleBtn, {
        [styles.selected]: selected,
      })}
      onClick={handleClick}
      type="button"
      data-testid={dataTestId}
    >
      <div className={styles.contentWrapper}>
        {Icon && <Icon />}
        <p className={styles.header}>{label}</p>
      </div>
    </button>
  );
}

export default MenuSimple;
