import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { displayHoursType } from 'helpers/prop-types';
import { isSameDayOfWeek } from 'helpers/date';
import { formatTime } from 'helpers/format';

import styles from './styles.module.scss';

function HoursList({ displayHours }) {
  return (
    <div className={styles.hours}>
      {displayHours.map(({ ranges, dow, day_id: dayId }) => (
        <li
          key={dayId}
          className={classNames(styles.dayItem, {
            [styles.active]: isSameDayOfWeek(dow),
          })}
        >
          <span className={styles.day}>
            {isSameDayOfWeek(dow) ? 'Today' : dow}
          </span>
          <ol className={styles.hoursRange}>
            {isEmpty(ranges) ? (
              <span>Currently Unavailable</span>
            ) : (
              ranges.map((range, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} data-testid="HoursList">
                  {formatTime(range.from)} - {formatTime(range.to)}
                </li>
              ))
            )}
          </ol>
        </li>
      ))}
    </div>
  );
}

HoursList.propTypes = {
  displayHours: displayHoursType.isRequired,
};

export default HoursList;
