import React from 'react';

import { CANCELED_SUPPORT_URL } from '@chownow/cn-web-utils/paths';

import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

export default function SupportBanner() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Need some help?</div>
      <div className={styles.body}>
        If you have a question about your order or need to talk to our support
        team, we&apos;re here to help.
      </div>
      <Outlink to={CANCELED_SUPPORT_URL}>
        <div className={styles.outlink}>Contact Support</div>
      </Outlink>
    </div>
  );
}
