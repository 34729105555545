import scrollSmooth from 'scroll-smooth';
import { isYelpPlatform } from '@chownow/cn-web-utils/url';

import {
  YELP_EXPECTED_SEARCH_PARAMS,
  YELP_LOCALES,
  YELP_SITES,
} from 'helpers/constants';
import { logException } from 'helpers/loggers';

import ModalWrapperStyles from 'components/ModalWrapper/styles.module.scss';
import RestaurantStyles from 'containers/Restaurant/styles.module.scss';
import CheckoutStyles from 'containers/Checkout/styles.module.scss';

const YELP_DESKTOP_SCROLL_OFFSET = 380;
const YELP_MOBILE_SCROLL_OFFSET = -135;
const YELP_STICKY_CATEGORY_NAV_SCROLL_OFFSET = -110;

let yelpSite;
let targetOrigin;
let mobileHeightSet = false;
let isInitialized = false;

/**
 * Called once in the App component to initialize Yelp data
 * and set up iframe communication.
 */
const initializeYelp = () => {
  if (!isInitialized && isYelpPlatform()) {
    isInitialized = true;

    // Yelp passes request params that indicate data such as
    // delivery info, opportunity token, etc.  We parse these
    // and store most of them in session storage.
    // Ref:  https://docs.developer.yelp.com/docs/iframe-interactions#request-params
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.forEach((value, key) => {
      if (YELP_EXPECTED_SEARCH_PARAMS.includes(key)) {
        sessionStorage.setItem(key, value);
      }
    });

    // The yelp_site request param passed by Yelp must be either www or m.
    // In case of invalid value, we log an error and default to www.
    const yelpSiteParam = searchParams.get('yelp_site');

    if (!YELP_SITES.includes(yelpSiteParam)) {
      logException({
        message: 'Invalid yelp site value provided in search URL',
        context: { yelpSiteParam },
      });
      yelpSite = 'www';
    } else {
      yelpSite = yelpSiteParam;
    }

    // In case of error, yelp_locale should default to "en_US"
    const yelpLocaleParam = searchParams.get('yelp_locale');

    if (!(yelpLocaleParam in YELP_LOCALES)) {
      logException({
        message: 'Invalid yelp locale value provided in search URL', 
        context: { yelpLocaleParam },
      });
      // eslint-disable-next-line dot-notation
      targetOrigin = `https://${yelpSite}.${YELP_LOCALES['en_US']}`;
    } else {
      targetOrigin = `https://${yelpSite}.${YELP_LOCALES[yelpLocaleParam]}`;
    }
  }
};

/**
 * Returns a value that indicates whether Yelp is running as a mobile site
 *
 * @returns true if yelpSite indicates mobile
 */
const isYelpMobile = () => yelpSite === 'm';

/**
 * Retrieves all Yelp data stored in session storage
 *
 * @returns an object whose keys match YELP_EXPECTED_SEARCH_PARAMS
 */
const getYelpData = () => {
  const yelpData = {};

  YELP_EXPECTED_SEARCH_PARAMS.forEach((key) => {
    yelpData[key] = sessionStorage.getItem(key);
  });

  yelpData.yelpSite = yelpSite;
  yelpData.targetOrigin = targetOrigin;

  return yelpData;
};

const setHeight = () => {
  // On desktop, we always set the height as content height
  // changes.  This is mostly called from Restaurant and Checkout
  // container components, but can also be called when filtering
  // by menu categories and certain error conditions.
  if (yelpSite === 'www') {
    // To determine the scrollable height of the content, we look for
    // either the restaurant wrapper or checkout wrapper classes, and
    // obtain the scrollable height.
    let newHeight;

    const restaurantWrapper = document.querySelector(
      `.${RestaurantStyles.restaurantWrapper}`
    );
    const checkoutWrapper = document.querySelector(
      `.${CheckoutStyles.checkout}`
    );

    if (restaurantWrapper) {
      newHeight = restaurantWrapper.scrollHeight;
    } else if (checkoutWrapper) {
      newHeight = checkoutWrapper.scrollHeight;
    }

    if (newHeight) {
      const setHeightEvent = JSON.stringify({
        eventType: 'setHeight',
        height: newHeight,
      });

      window.top.postMessage(setHeightEvent, targetOrigin);
    }

    return;
  }

  // If not on desktop, then we assume mobile (tablet form factor is not considered)
  // On mobile, we really only want to set the height once.  However, we cannot set
  // the height directly.  We have to post a message to Yelp to "ask" it for
  // viewport data.  That response comes back as an asynchronous message and is handled
  // by the handleYelpMessage function.
  if (!mobileHeightSet) {
    const getViewportHeight = JSON.stringify({
      eventType: 'getViewportHeight',
    });

    window.top.postMessage(getViewportHeight, targetOrigin);
  }
};

/**
 * UI interactions that change the content height require
 * a message to be posted to Yelp so that the height of the
 * iframe can be set accordingly.
 * Ref: https://docs.developer.yelp.com/docs/iframe-api-specification#interactions-that-change-html-body-height-dropdown-accordion-etc
 *
 *
 */
const onHeightChangedHandler = () => {
  if (isYelpPlatform() && targetOrigin) {
    setHeight();
  }
};

/**
 * Called when customer wishes to proceed to Yelp's payment screen
 * (web-ordering does not handle payment when running Yelp platform)
 *
 * @param {string} yelpOrderId
 */
const checkoutRedirect = (yelpOrderId) => {
  if (yelpOrderId) {
    const checkoutRedirectEvent = JSON.stringify({
      eventType: 'checkoutRedirect',
      orderID: yelpOrderId,
    });

    if (targetOrigin) {
      window.top.postMessage(checkoutRedirectEvent, targetOrigin);
    }
  }
};

/**
 * Called when modal dialogs are presented to the customer.  In order
 * to get the proper positioning, we must provide the height of the modal
 * to Yelp.  Yelp will use that height to do the math and post a message to
 * inform the app where it should be vertically positioned.
 * @param {numnber} popupHeight
 */
const getPopupPosition = (popupHeight) => {
  const getPopupPositionEvent = JSON.stringify({
    eventType: 'getPopupPosition',
    popupHeight,
  });

  if (targetOrigin) {
    window.top.postMessage(getPopupPositionEvent, targetOrigin);
  }
};

/**
 * Scrolls viewport to the given Y offset (0 is top of iframe).
 *
 * @param {number} offset
 */
const yelpScrollToOffset = (offset) => {
  const scrollEvent = JSON.stringify({
    eventType: 'scrollTop',
    offset,
  });

  if (targetOrigin) {
    window.top.postMessage(scrollEvent, targetOrigin);
  }
};

/**
 * Given a top value, will locate the active modal and position
 * it accordingly.  This value is provided to us by Yelp via
 * a message posted to our app.
 *
 * @param {number} top
 */
const setModalPosition = (top) => {
  if (top) {
    // A "top" property indicates an event that tells us where to position
    // modal dialog popups.
    const topStyle = `${top}px`;

    const styleSelector = ModalWrapperStyles.modalWrapper;
    const selectorClass = `.${styleSelector}`;
    const modalElement = document.querySelector(selectorClass);
    if (modalElement) {
      modalElement.style.top = topStyle;

      const modalBottomLeft = top + modalElement.offsetHeight;
      const modalPercentTowardsBottom = modalBottomLeft / window.innerHeight;
      const tolerance = 0.98;
      const offsetFromElement = 180;
      const atBottomOfIframe = modalPercentTowardsBottom >= tolerance;

      if (atBottomOfIframe) {
        yelpScrollToOffset(modalElement.offsetTop + offsetFromElement);
      }
    }
  }
};

/*
 *  This is the message processor for messages sent from yelp.com to our app.
 *  In our use cases, we may receive a message that gives us modal dialog
 *  positioning info, or viewport dimension info.
 *
 *  Ref:  https://docs.developer.yelp.com/docs/iframe-api-specification
 */
const handleYelpMessage = (eventData) => {
  if (eventData && eventData.origin === targetOrigin) {
    try {
      const data = JSON.parse(eventData.data);

      if (data.top) {
        setModalPosition(data.top);
        return;
      }

      if (data.viewportHeight) {
        // a "viewportHeight" property indicates a message that tells us
        // how tall we need to set Yelp's iframe.  We only do this on mobile
        // and we only need to do it once.
        mobileHeightSet = true;

        const setHeightEvent = JSON.stringify({
          eventType: 'setHeight',
          height: data.viewportHeight - data.iframeTopOffset,
        });

        window.top.postMessage(setHeightEvent, targetOrigin);

        return;
      }
    } catch (e) {
      logException({
        message: 'Unable to parse Yelp event data message',
        context: { eventData },
      });
    }
  }
};

/**
 * Scrolls viewport to the specified selector.
 *
 * @param {string} selector
 */
const yelpScrollToElement = (selector, isCategoryNavSticky) => {
  const targetElement = document.querySelector(selector);
  const rootScrollableElement = document.querySelector('.theme-yelp');

  if (!targetElement || !rootScrollableElement) {
    return;
  }

  // For mobile site, we can scroll the window directly
  if (yelpSite === 'm') {
    const offset = isCategoryNavSticky
      ? YELP_STICKY_CATEGORY_NAV_SCROLL_OFFSET
      : YELP_MOBILE_SCROLL_OFFSET;

    scrollSmooth.to(targetElement, {
      duration: 1500,
      context: rootScrollableElement,
      offset,
    });
  } else {
    // For desktop site, we must post a message to Yelp to instruct
    // it to scroll to a particular location.  We add an an addiitonal
    // amount to account for Yelp's header elements.
    yelpScrollToOffset(targetElement.offsetTop + YELP_DESKTOP_SCROLL_OFFSET);
  }
};

/**
 * For mobile Yelp, scrolls viewport to top.  For desktop Yelp,
 * posts a message to Yelp to scroll to the top of the page.
 */
const yelpScrollToTop = () => {
  if (isYelpMobile()) {
    const rootScrollableElement = document.querySelector('.theme-yelp');
    scrollSmooth.to(0, {
      duration: 500,
      context: rootScrollableElement,
    });
  } else {
    yelpScrollToOffset(0);
  }
};

export {
  checkoutRedirect,
  getPopupPosition,
  handleYelpMessage,
  initializeYelp,
  isYelpMobile,
  getYelpData,
  onHeightChangedHandler,
  yelpScrollToElement,
  yelpScrollToTop,
};
