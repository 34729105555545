/* eslint-disable complexity */
import classNames from 'classnames';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { findIndex } from 'lodash';

import { formatMoney } from '@chownow/cn-web-utils/format';

import { menuItemType } from 'helpers/prop-types';
import {
  SELECT_ITEM_OPERATIONS,
  KEY_VALUES,
  REORDER_CATEGORY_NAME,
} from 'helpers/constants';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { isItemInPopularCategory } from 'helpers/menu';
import { getRepeatedItem } from 'helpers/order';
import { getIsDirectToMpRedirect } from 'helpers/configureRedirects';

import {
  addItemToOrderRequest,
  getOrderItemData,
  updateOrderItemRequest,
} from 'modules/order';

import { MenuContext } from 'context/MenuContext';

import useSelectItem from 'hooks/useSelectItem';
import usePlatform from 'hooks/usePlatform';

import MenuItemImage from 'components/MenuItemImage';

import { ReactComponent as IconBadgeCheck } from 'images/icon-badge-check.svg';
import { ReactComponent as IconBadgePlus } from 'images/icon-badge-plus.svg';

import styles from './styles.module.scss';

const BADGE_TIMEOUT = 2000;
let badgeTimer;

function MenuItem(props) {
  const { item, isRecentlyOrdered, itemIndex } = props;

  const {
    description,
    id,
    name,
    image,
    user_selection: userSelection,
    last_ordered_date_label: lastOrderedDateLabel,
  } = item;
  const price = item.items ? item.items[0].price : item.price;

  const dispatch = useDispatch();
  const descriptionRef = useRef();
  const [hasImageError, setHasImageError] = useState(false);
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const onSelectItem = useSelectItem();

  const orderItems = useSelector(getOrderItemData);

  const { menu } = useContext(MenuContext);

  const { hqId, restaurantId } = useParams();
  const { isMarketplacePlatform } = usePlatform();

  const showImage = !!image && !hasImageError;
  const oneClick = true;

  function handleAddRecentlyOrderedItem(isOneClick) {
    let itemData = {
      modifierCategories: userSelection.modifier_categories,
      id,
      image,
      name,
      sizeId: userSelection.selected_size_id,
      categoryId: 'recently_ordered_items',
      categoryName: REORDER_CATEGORY_NAME,
      quantity: 1, // always set to 1 instead of previous quantity ordered
      price: userSelection.per_item_total,
      menuCategoryItem: null,
      modifierNames: userSelection.modifierNames,
      specialInstructions: userSelection.special_instructions,
      isOneClick,
      itemIndex,
    };

    const repeatedItem = getRepeatedItem(itemData, orderItems);
    const isItemRepeated = !!repeatedItem;

    if (isItemRepeated) {
      const itemPrice = repeatedItem.priceWithModifiers
        ? repeatedItem.priceWithModifiers / repeatedItem.quantity
        : repeatedItem.price;

      itemData = {
        ...repeatedItem,
        price: itemPrice * (repeatedItem.quantity + 1),
        quantity: repeatedItem.quantity + 1,
      };

      const index = findIndex(orderItems, {
        tracking_id: itemData.tracking_id,
      });

      dispatch({
        type: updateOrderItemRequest.TYPE,
        payload: itemData,
        meta: index,
      });
    } else {
      dispatch({
        type: addItemToOrderRequest.TYPE,
        payload: itemData,
      });
    }
  }

  // this logs an mParticle event when user selects a menu item via handleSelectMenuItem()
  function logMParticleEvent(isOneClick) {
    // if menu item is from recently ordered carousel, we log the source of the click: either
    // from the one click reorder button or if user clicks on menu card to bring up details modal
    const menuItemSource =
      isRecentlyOrdered &&
      (isOneClick ? 'one_click_reorder' : 'reorder_carousel');
    let carouselItemIndex = itemIndex;
    // increase index by 1 for mParticle logging so first item starts at 1 instead of 0
    const selectedIndex = isRecentlyOrdered ? (carouselItemIndex += 1) : null;
    // only send atttribute when not on MP
    const isEmbedded = isMarketplacePlatform
      ? undefined
      : window.top !== window;
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectMenuItem,
      attributes: {
        menu_id: menu.id,
        menu_item_id: id,
        menu_item_source: menuItemSource,
        menu_item_location: !!menuItemSource && selectedIndex, // only return index if reorder item
        restaurant_location_id: restaurantId,
        restaurant_brand_id: hqId,
        item_has_photo: !!item.image,
        item_is_popular_item: !!isItemInPopularCategory(
          id,
          menu.menu_categories
        ),
        is_embedded_site: isEmbedded,
        is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
      },
    });
  }

  function handleSelectMenuItem() {
    logMParticleEvent();
    // Only send item if recently ordered bc we don't need to append any additional data
    // for add new items and order items there's still info needed that onSelectItem adds
    onSelectItem({
      itemId: id,
      operation: SELECT_ITEM_OPERATIONS.add,
      recentlyOrderedItem: isRecentlyOrdered && item,
      itemIndex: isRecentlyOrdered && itemIndex,
    });
  }

  function handleKeyPress(event) {
    if (event.key === KEY_VALUES.enter) {
      handleSelectMenuItem();
    }
  }

  function handleOneClickOrdering(e) {
    e.stopPropagation(); // prevents one click from also triggering menu card modal
    handleAddRecentlyOrderedItem(oneClick);
    setShowAddedToCart(true);
    logMParticleEvent(oneClick);
  }

  function handleKeyPressOneClick(event) {
    if (event.key === KEY_VALUES.enter) {
      handleOneClickOrdering(event);
    }
  }

  useEffect(() => clearTimeout(badgeTimer), []);

  useEffect(() => {
    if (showAddedToCart) {
      setShowAddedToCart(true);

      badgeTimer = setTimeout(() => {
        setShowAddedToCart(false);
      }, BADGE_TIMEOUT);
    }
  }, [showAddedToCart]);

  return (
    <div
      className={classNames(styles.itemContainer, {
        [styles.small]: isRecentlyOrdered,
      })}
      onClick={() => handleSelectMenuItem()}
      onKeyDown={(event) => handleKeyPress(event)}
      aria-label={`Select ${name}`}
      role="button"
      tabIndex={0}
    >
      <div className={styles.itemContent}>
        <div
          className={classNames(styles.itemText, {
            [styles.withThumbnail]: showImage,
          })}
          data-testid="MenuCard"
        >
          <h4 className={styles.name} data-testid="ItemName">
            {name}
          </h4>
          {isRecentlyOrdered && userSelection.modifierNames && (
            <div
              className={classNames(styles.description, {
                [styles.descriptionSmall]: isRecentlyOrdered,
              })}
              ref={descriptionRef}
            >
              {userSelection.modifierNames.join(', ')}
            </div>
          )}
          {description && !isRecentlyOrdered && (
            <div className={styles.description} ref={descriptionRef}>
              {description}
            </div>
          )}
          {!isRecentlyOrdered && (
            <p className={styles.price} data-testid="ItemPrice">
              {formatMoney(price || 0)}
            </p>
          )}
        </div>
        {!!image && (
          <div
            className={classNames(styles.imageContainer, {
              [styles.hidden]: hasImageError,
            })}
          >
            <MenuItemImage
              handlePhotoError={() => {
                setHasImageError(true);
              }}
              image={image}
              alt={name}
              height={136}
              width={136}
              isRecentlyOrdered={isRecentlyOrdered}
              isOnMenu
            />
          </div>
        )}
        {isRecentlyOrdered && (
          <div className={styles.carouselPriceContainer}>
            <span
              className={classNames(styles.orderDate, {
                [styles.success]: showAddedToCart,
                [styles.fadeOut]: showAddedToCart,
                [styles.fadeIn]: !showAddedToCart,
              })}
            >
              {showAddedToCart && 'Added to Order'}
              {!showAddedToCart && lastOrderedDateLabel}
            </span>
            <div
              className={classNames(styles.oneClick, {
                [styles.success]: showAddedToCart,
                [styles.fadeOut]: showAddedToCart,
                [styles.fadeIn]: !showAddedToCart,
              })}
              onClick={handleOneClickOrdering}
              onKeyDown={(event) => handleKeyPressOneClick(event)}
              role="button"
              tabIndex={0}
            >
              <p
                className={classNames(styles.carouselPrice, {
                  [styles.success]: showAddedToCart,
                  [styles.fadeOut]: showAddedToCart,
                  [styles.fadeIn]: !showAddedToCart,
                })}
                data-testid="ItemPrice"
              >
                {formatMoney(userSelection.per_item_total || 0)}
              </p>
              <div className={styles.badgeIcon}>
                {showAddedToCart ? (
                  <IconBadgeCheck />
                ) : (
                  <IconBadgePlus className={styles.plusIcon} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

MenuItem.propTypes = {
  itemIndex: PropTypes.number,
  isRecentlyOrdered: PropTypes.bool,
  item: menuItemType,
};

MenuItem.defaultProps = {
  itemIndex: null,
  isRecentlyOrdered: false,
  item: [],
};

export default MenuItem;
