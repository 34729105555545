/* eslint-disable consistent-return */
/**
 * Custom hook to imitate window.setInterval. Needed as React hooks do not allow callbacks
 * inside setIntervals to retrieve updated redux state.
 * @param callback - Function that will be called every delay
 * @param delay - Number representing the delay in ms. Set to null to stop the interval.
 */
import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
}

export default useInterval;
