import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserContext } from 'context/UserContext';

import { buildRoute, ROUTES } from 'helpers/routes';
import { MEMBERSHIP_PLANS, MEMBERSHIP_STATUS } from 'helpers/constants';
import { userMembershipPlanType } from 'helpers/prop-types';
import { parseExpirationDate } from '@chownow/cn-web-utils/date';

import { getRestaurantMembershipEnabled } from 'modules/restaurant';

import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

function CustomerMembershipBanner(props) {
  const { userMembershipPlan = {}, onSelectRenew } = props;

  const { membership: userMembership } = useContext(UserContext);

  const { hqId, restaurantId } = useParams();

  const isRestaurantMembershipEnabled = useSelector(
    getRestaurantMembershipEnabled
  );
  const isExpired = userMembershipPlan.status === MEMBERSHIP_STATUS.expired;
  // FIXME: customer membership plan is missing info see CN-21244 for more info
  const membership = MEMBERSHIP_PLANS.find(
    (plan) =>
      (isExpired && plan.id === userMembershipPlan.plan_xid) ||
      (userMembership.hasActiveMembership && plan.id === userMembershipPlan.id)
  );

  const expirationDate = parseExpirationDate(userMembershipPlan.expires_at);
  const isMembershipExpired = isExpired && !userMembership.hasActiveMembership;
  const shouldShowRenewButton =
    isMembershipExpired && isRestaurantMembershipEnabled;

  function handleGoToMembershipPlans() {
    onSelectRenew();
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.infoWrapper, {
          [styles.withButton]: shouldShowRenewButton,
        })}
      >
        <div>
          <h3 className={styles.memberType}>{membership?.name} member</h3>
          <p className={styles.validThru}>
            {isMembershipExpired
              ? `Expired on ${expirationDate}`
              : `Valid through ${expirationDate}`}
          </p>
        </div>
        {shouldShowRenewButton && (
          <Outlink
            to={buildRoute({
              hqId,
              restaurantId,
              route: ROUTES.membershipPlans,
            })}
            className={styles.button}
            onClick={handleGoToMembershipPlans}
          >
            Renew
          </Outlink>
        )}
      </div>
    </div>
  );
}

CustomerMembershipBanner.propTypes = {
  onSelectRenew: PropTypes.func,
  userMembershipPlan: userMembershipPlanType,
};

CustomerMembershipBanner.defaultProps = {
  onSelectRenew: () => {},
  userMembershipPlan: {},
};

export default CustomerMembershipBanner;
