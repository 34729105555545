import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type SmallGridColumns = 1 | 2 | 3 | 4;
type LargeGridColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface GridProps {
  children: React.ReactNode;
  className?: string;
  container?: boolean;
  sm?: SmallGridColumns;
  md?: LargeGridColumns;
  lg?: LargeGridColumns;
  smOffset?: SmallGridColumns;
  mdOffset?: LargeGridColumns;
  lgOffset?: LargeGridColumns;
}

function Grid({
  children,
  className = '',
  container,
  sm = 4,
  md,
  lg,
  smOffset,
  mdOffset,
  lgOffset,
}: GridProps) {
  const gridItem = !container;
  return (
    <div
      className={classNames(className, {
        [styles.grid]: container,
        [styles.gridItem]: gridItem,
        [styles[`sm-${sm}`]]: sm,
        [styles[`md-${md}`]]: md,
        [styles[`lg-${lg}`]]: lg,
        [styles[`sm-offset-${smOffset}`]]: smOffset,
        [styles[`md-offset-${mdOffset}`]]: mdOffset,
        [styles[`lg-offset-${lgOffset}`]]: lgOffset,
      })}
    >
      {children}
    </div>
  );
}

export default Grid;
