/* eslint-disable complexity, consistent-return */
import * as Sentry from '@sentry/browser';

import { getRestaurantIdFromUrl } from '@chownow/cn-web-utils/url';
import { getFromChownowApi, postToChownowApi } from 'helpers/api';
import {
  setUserAuthStatus,
  checkDuplicateAddressesAgainstStorage,
  clearSelectedAddress,
} from 'helpers/customer';
import { siftPageView } from 'helpers/loggers';
import { FULFILLMENT_METHODS } from 'helpers/order';

export async function handleFetchUserProfile({
  setUser,
  sessionId,
  setIsLoaded,
}) {
  const { data, error } = await getFromChownowApi({
    endpoint: 'customer/me',
  });
  if (!error) {
    setUser(data);

    setUserAuthStatus(true);

    await checkDuplicateAddressesAgainstStorage(data.delivery?.addresses);

    // mParticle
    if (window.mParticle) {
      // check to see if mParticle already has the user email logged (see CN-20466)
      try {
        const currentUser = window.mParticle.Identity.getCurrentUser();
        const currentUserEmail =
          currentUser && currentUser.getUserIdentities().userIdentities.email;
        if (!currentUserEmail) {
          const identityRequest = {
            userIdentities: {
              email: data.email,
              customerid: data.id,
            },
          };
          // if no email is logged, send another identify request to log user info
          window.mParticle.Identity.identify(identityRequest);
        }
      } catch (err) {
        // fail silently when error occurs
        return;
      }
    }

    // Sentry
    Sentry.setUser({ id: data.id });

    siftPageView(sessionId, data.id);
  } else {
    siftPageView(sessionId);
  }

  setIsLoaded(true);
}

export async function handleFetchUpdateUser(values, user, fetchUserValues) {
  // For user forms we are converting null to empty strings, curbside cannot be an empty string
  if (!values.curbside) {
    values.curbside = null; // eslint-disable-line no-param-reassign
  }

  const userMobileNumber = user.phone?.number;

  // if phone number updating, set mParticle user attribute
  if (userMobileNumber !== values.phone.number && window.mParticle) {
    // mParticle
    try {
      const currentUser = window.mParticle.Identity.getCurrentUser();

      currentUser?.setUserAttribute('$Mobile', values.phone.number);
    } catch (err) {
      // fail silently when error occurs
    }
  }

  const { error } = await postToChownowApi({
    endpoint: `customer/${user.id}/contact-info`,
    body: values,
    method: 'PUT',
  });

  if (!error) {
    // mParticl IDSync
    if (window.mParticle) {
      // supported identity types
      // https://docs.mparticle.com/developers/sdk/web/idsync/#supported-identity-types
      const identityRequest = {
        userIdentities: {
          email: user.email,
          customerid: user.id,
        },
      };
      window.mParticle.Identity.login(identityRequest, (result) => {
        const mparticleUser = result.getUser();

        mparticleUser?.setUserAttribute('$Mobile', values.phone.number);
        mparticleUser?.setUserAttribute('user_is_optout', user.is_optout);
      });
    }

    handleFetchUserProfile(fetchUserValues);
  } else {
    return error;
  }
}

export async function handleFetchLogout({
  setUser,
  setCardForm,
  setSelectedCardId,
  setSessionId,
  order,
  isDeliveryOnly,
  dispatch,
  resetPromoCodeRequest,
  updateOrderRequest,
  fetchRestaurantXHR,
  triggerValidate,
}) {
  // Reset user's promo code and don't do additional validate when resetting on logout
  if (order.promo_code) {
    dispatch({
      type: resetPromoCodeRequest.TYPE,
      payload: null,
      meta: { disableValidation: true },
    });
  }

  // mParticl IDSync
  if (window.mParticle) {
    // mParticle requires passing an empty user identity object to clear session
    const identityRequest = {
      userIdentities: {},
    };
    window.mParticle.Identity.identify(identityRequest);
  }

  const { error } = await postToChownowApi({
    endpoint: `customer/logout`,
  });

  if (!error) {
    setUserAuthStatus(false);

    if (
      order.fulfillmentMethod === FULFILLMENT_METHODS.delivery &&
      !isDeliveryOnly
    ) {
      dispatch({
        type: updateOrderRequest.TYPE,
        payload: {
          fulfillmentMethod: FULFILLMENT_METHODS.pickup,
        },
      });
    }

    // remove selected address when user logs out
    clearSelectedAddress();

    // Reset state on logout
    setUser({});
    setCardForm();
    setSelectedCardId(null);
    setSessionId('');

    const restaurantId = getRestaurantIdFromUrl();
    if (restaurantId) {
      dispatch({
        type: fetchRestaurantXHR.request.TYPE,
        payload: restaurantId,
      });
    }

    dispatch({ type: triggerValidate.TYPE, meta: { isLoggingOut: true } });
  }
}
