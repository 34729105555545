/* eslint-disable no-prototype-builtins, default-param-last */
import { camelCase } from 'lodash';

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };
}

export function Action(type) {
  const name = camelCase(type);

  // preserves function name on call stack
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/name
  const actionCreator = {
    [name](payload, meta) {
      return { type, payload, meta };
    },
  };

  actionCreator[name].TYPE = type;

  return actionCreator[name];
}

export function RequestAction(type) {
  return {
    request: Action(`${type}_REQUEST`),
    success: Action(`${type}_SUCCESS`),
    failure: Action(`${type}_FAILURE`),
  };
}
