import { Action, RequestAction } from 'helpers/redux';

const namespace = 'modules/order/';

export const addItemToOrderRequest = Action(
  `${namespace}ADD_ITEM_TO_ORDER_REQUEST`
);
export const addItemToOrderSuccess = Action(
  `${namespace}ADD_ITEM_TO_ORDER_SUCCESS`
);
export const initiateCheckout = Action(`${namespace}INITIATE_CHECKOUT`);
export const removeOrderItemRequest = Action(
  `${namespace}REMOVE_ORDER_ITEM_REQUEST`
);
export const removeOrderItemSuccess = Action(
  `${namespace}REMOVE_ORDER_ITEM_REQUEST_SUCCESS`
);
export const removeRejectedItems = Action(`${namespace}REMOVE_REJECTED_ITEMS`);
export const resetPromoCodeRequest = Action(
  `${namespace}RESET_PROMO_CODE_REQUEST`
);
export const resetPromoCodeSuccess = Action(
  `${namespace}RESET_PROMO_CODE_SUCCESS`
);
export const resetOrder = Action(`${namespace}RESET_ORDER`);
export const resetSubmitOrderRequest = Action(
  `${namespace}RESET_SUBMIT_ORDER_REQUEST`
);
export const resetSubmitOrderSuccess = Action(
  `${namespace}RESET_SUBMIT_ORDER_SUCCESS`
);
export const resetTableNameRequest = Action(`${namespace}RESET_TABLE_REQUEST`);
export const resetTableNameSuccess = Action(`${namespace}RESET_TABLE_SUCCESS`);
export const resetTimer = Action(`${namespace}RESET_TIMER`);
export const resetWhen = Action(`${namespace}RESET_WHEN`);
export const setActiveItemId = Action(`${namespace}SET_ACTIVE_ITEM_ID`);
export const setOrderCompleted = Action(`${namespace}SET_ORDER_COMPLETED`);
export const setPromoCodeRequest = Action(`${namespace}SET_PROMO_CODE_REQUEST`);
export const setPromoCodeSuccess = Action(`${namespace}SET_PROMO_CODE_SUCCESS`);
export const setRestaurantTipRequest = Action(
  `${namespace}SET_RESTAURANT_TIP_REQUEST`
);
export const setRestaurantTipSuccess = Action(
  `${namespace}SET_RESTAURANT_TIP_SUCCESS`
);
export const setTableNameRequest = Action(`${namespace}SET_TABLE_NAME_REQUEST`);
export const setTableNameSuccess = Action(`${namespace}SET_TABLE_NAME_SUCCESS`);
export const setManagedDeliveryTipRequest = Action(
  `${namespace}SET_MANAGED_DELIVERY_TIP_REQUEST`
);
export const setManagedDeliveryTipSuccess = Action(
  `${namespace}SET_MANAGED_DELIVERY_TIP_SUCCESS`
);
export const resetTipsRequest = Action(`${namespace}RESET_TIPS_REQUEST`);
export const resetTipsSuccess = Action(`${namespace}RESET_TIPS_SUCCESS`);
export const setVehicleRequest = Action(`${namespace}SET_VEHICLE_REQUEST`);
export const setVehicleSuccess = Action(`${namespace}SET_VEHICLE_SUCCESS`);
export const setWhenRequest = Action(`${namespace}SET_WHEN_REQUEST`);
export const setWhenSuccess = Action(`${namespace}SET_WHEN_SUCCESS`);
export const startOrderFailure = Action(`${namespace}START_ORDER_FAILURE`);
export const startOrderRequest = Action(`${namespace}START_ORDER_REQUEST`);
export const startOrderSuccess = Action(`${namespace}START_ORDER_SUCCESS`);
export const updateOrderThrottlingMeta = Action(
  `${namespace}UPDATE_ORDER_THROTTLING_META`
);
export const updateTimer = Action(`${namespace}UPDATE_TIMER`);
export const updateOrderFailure = Action(`${namespace}UPDATE_ORDER_FAILURE`);
export const updateOrderRequest = Action(`${namespace}UPDATE_ORDER_REQUEST`);
export const updateOrderSuccess = Action(`${namespace}UPDATE_ORDER_SUCCESS`);
export const updateOrderItemRequest = Action(
  `${namespace}UPDATE_ORDER_ITEM_REQUEST`
);
export const updateOrderItemSuccess = Action(
  `${namespace}UPDATE_ORDER_ITEM_SUCCESS`
);
export const triggerValidate = Action(`${namespace}TRIGGER_VALIDATE`);
export const setOrderData = Action(`${namespace}SET_ORDER_DATA`);

export const validateReorderXHR = RequestAction(`${namespace}VALIDATE_REORDER`);
export const fetchOrderStatusXHR = RequestAction(
  `${namespace}FETCH_ORDER_STATUS`
);
export const submitOrderXHR = RequestAction(`${namespace}SUBMIT_ORDER`);
export const validateOrderXHR = RequestAction(`${namespace}VALIDATE_ORDER`);
export const fetchOrderDetailsXHR = RequestAction(
  `${namespace}FETCH_ORDER_DETAILS`
);

export const submitYelpOrderXHR = RequestAction(
  `${namespace}SUBMIT_YELP_ORDER`
);
export const yelpOpportunityXHR = RequestAction(
  `${namespace}FETCH_YELP_OPPORTUNITY`
);
export const tipAdjustmentXHR = RequestAction(`${namespace}TIP_ADJUSTMENT`);
