import React, { ChangeEvent } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface SelectStandardProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  helpText?: string;
  label?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string | undefined;
}

function SelectStandard({
  children,
  className,
  disabled = false,
  helpText,
  label,
  onChange,
  placeholder,
  value = '',
}: SelectStandardProps) {
  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className={className}>
      {label && (
        <div
          className={classNames(styles.label, { [styles.disabled]: disabled })}
        >
          {label}
        </div>
      )}
      <select
        className={classNames(styles.select, {
          [styles.withValue]: !!value,
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onChange={handleOnChange}
        value={value}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {helpText && <div className={styles.helpText}>{helpText}</div>}
    </div>
  );
}

export default SelectStandard;
