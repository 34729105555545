/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import usePlatform from 'hooks/usePlatform';

import { getSelectedAddress, setSelectedAddress } from 'helpers/customer';
import { FULFILLMENT_METHODS } from 'helpers/order';

import {
  getFulfillmentTime,
  updateOrderRequest,
  getVehicle,
  setVehicleRequest,
} from 'modules/order';
import {
  getFulfillmentAvailability,
  getIsFulfillmentAvailableForWhen,
  getNextAvailableTime,
} from 'modules/restaurant';

import DateTimePicker from 'components/DateTimePicker';
import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import AddressSelect from './AddressSelect';
import VehicleSelect from './VehicleSelect';

import styles from './styles.module.scss';

function FulfillmentDetailsModal({
  fulfillmentMethod,
  nextWhen,
  noCloseOverlay,
  onClose,
  shouldDefaultToPickup,
}) {
  const dispatch = useDispatch();
  const { isYelpPlatform } = usePlatform();

  const { closeModal } = useContext(ModalContext);

  const vehicle = useSelector(getVehicle);
  const when = useSelector(getFulfillmentTime);
  const fulfillmentAvailability = useSelector(getFulfillmentAvailability);
  const savedAddress = getSelectedAddress();
  const savedStreetAddress2 = get(savedAddress, 'street_address2', '');
  const nextAvailableWhen = useSelector((state) => {
    const nextTime = getNextAvailableTime(state, fulfillmentMethod);
    return nextTime.nextAvailableTime;
  });

  const [selectedWhen, setSelectedWhen] = useState(nextWhen || when || null);
  const [hasError, setHasError] = useState(false);
  const [hasAddressErrors, setHasAddressErrors] = useState(false);
  const [activeType, setActiveType] = useState(
    vehicle ? vehicle.vehicle_type : null
  );
  const [activeColor, setActiveColor] = useState(
    vehicle ? vehicle.color : null
  );
  const [userSelectedAddress, setUserSelectedAddress] = useState(savedAddress);
  const [streetAddress2, setStreetAddress2] = useState(
    savedStreetAddress2 || ''
  );
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    userSelectedAddress?.delivery_instructions || ''
  );

  const isCurbside = fulfillmentMethod === FULFILLMENT_METHODS.curbside;
  const isDelivery = fulfillmentMethod === FULFILLMENT_METHODS.delivery;

  // We're disabling order-ahead on Yelp for now
  const isSelectedFulfillmentAvailableLater =
    !isYelpPlatform &&
    get(
      fulfillmentAvailability,
      `is${capitalize(fulfillmentMethod)}AheadAvailable`
    );
  const isSelectedFulfillmentAvailableNow = get(
    fulfillmentAvailability,
    `is${capitalize(fulfillmentMethod)}AvailableNow`
  );
  const isSelectedFulfillmentAvailableForWhen = useSelector((state) =>
    getIsFulfillmentAvailableForWhen(state, fulfillmentMethod, when)
  );

  useEffect(() => {
    if (!isSelectedFulfillmentAvailableForWhen) {
      setSelectedWhen(nextAvailableWhen);
    }
  }, [isSelectedFulfillmentAvailableForWhen]);

  function handleOnClose() {
    if (isCurbside) {
      if (!activeType || !activeColor) {
        return setHasError(true);
      }
      dispatch({
        type: setVehicleRequest.TYPE,
        payload: { vehicle_type: activeType, color: activeColor },
      });
    }

    if (isDelivery) {
      if (hasAddressErrors || !userSelectedAddress) {
        return setHasError(true);
      }

      if (userSelectedAddress?.formatted_address) {
        setSelectedAddress({
          ...userSelectedAddress,
          street_address2: streetAddress2,
          delivery_instructions: deliveryInstructions || null,
        });
      }
    }

    dispatch({
      type: updateOrderRequest.TYPE,
      payload: {
        fulfillmentMethod,
        when: selectedWhen,
      },
    });

    if (hasError) setHasError(false);

    onClose();
    return closeModal();
  }

  function handleExit() {
    if (isDelivery && shouldDefaultToPickup) {
      dispatch({
        type: updateOrderRequest.TYPE,
        payload: {
          fulfillmentMethod: FULFILLMENT_METHODS.pickup,
          when: selectedWhen || null,
        },
      });
    }

    onClose();
  }

  return (
    <ModalFrame
      className={classNames(styles.modalContentWrapper, {
        [styles.noClose]: noCloseOverlay,
      })}
      onModalClose={handleExit}
    >
      <ModalHeader
        title={`${capitalize(fulfillmentMethod)} Details`}
        isSticky
      />
      <div className={styles.modalDetails}>
        {isSelectedFulfillmentAvailableLater && (
          <div
            className={classNames({
              [styles.whenWrapper]: isCurbside || isDelivery,
            })}
          >
            <div className={styles.sectionTitle}>
              {isDelivery ? 'Delivery' : 'Pickup'} Time
            </div>
            <DateTimePicker
              fulfillMethod={fulfillmentMethod}
              isSelectedFulfillmentAvailableNow={
                isSelectedFulfillmentAvailableNow
              }
              onWhenChange={setSelectedWhen}
            />
          </div>
        )}
        {isCurbside && (
          <VehicleSelect
            activeColor={activeColor}
            activeType={activeType}
            hasError={hasError}
            setActiveColor={setActiveColor}
            setActiveType={setActiveType}
          />
        )}
        {isDelivery && (
          <AddressSelect
            deliveryInstructions={deliveryInstructions}
            hasError={hasError}
            setHasAddressErrors={setHasAddressErrors}
            selectedAddress={userSelectedAddress}
            setDeliveryInstructions={setDeliveryInstructions}
            setSelectedAddress={setUserSelectedAddress}
            setStreetAddress2={setStreetAddress2}
            streetAddress2={streetAddress2}
          />
        )}
      </div>
      <ModalFooter bottomFixed>
        <ModalFooterButton
          handleClick={handleOnClose}
          dataTestId="UpdateFulfillment"
          disabled={
            isSelectedFulfillmentAvailableLater && selectedWhen === undefined
          }
        >
          Update
        </ModalFooterButton>
      </ModalFooter>
    </ModalFrame>
  );
}

FulfillmentDetailsModal.propTypes = {
  fulfillmentMethod: PropTypes.string.isRequired,
  nextWhen: PropTypes.string,
  noCloseOverlay: PropTypes.bool,
  onClose: PropTypes.func,
  shouldDefaultToPickup: PropTypes.bool,
};

FulfillmentDetailsModal.defaultProps = {
  nextWhen: undefined,
  noCloseOverlay: false,
  onClose: () => {},
  shouldDefaultToPickup: false,
};

export default FulfillmentDetailsModal;
