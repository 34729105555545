import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import classNames from 'classnames';

function FadeIn({ children, className, duration, delay }) {
  const { opacity } = useSpring({
    config: { tension: 400, friction: 35, duration },
    from: {
      opacity: 0,
    },
    to: { opacity: 1 },
    delay,
  });

  return (
    <animated.div
      style={{ opacity, height: 'inherit' }}
      className={classNames(className)}
    >
      {children}
    </animated.div>
  );
}

FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

FadeIn.defaultProps = {
  className: undefined,
};

export default FadeIn;
