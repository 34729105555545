import React from 'react';

import { ReactComponent as IconCheck } from './icon-checkmark.svg';
import { ReactComponent as IconClose } from './icon-close-8.svg';

import styles from './styles.module.scss';

interface SwitchProps {
  onToggle: (value: boolean) => void;
  isToggled: boolean;
}

function Switch({ onToggle, isToggled }: SwitchProps) {
  return (
    <div>
      <button
        className={styles.toggle}
        type="button"
        id="toggle"
        aria-pressed={isToggled}
        onClick={() => onToggle(!isToggled)}
        data-testid="toggleButton"
      >
        <span
          className={styles.toggleButton}
          data-testid="NotificationButton"
          hidden
        >
          <IconCheck />
          <IconClose />
        </span>
      </button>
    </div>
  );
}

export default Switch;
