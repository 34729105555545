import useFeatureFlagRequest from 'hooks/useFeatureFlagRequest';

import { FEATURE_FLAGS } from 'helpers/constants';

// https://chownow.atlassian.net/browse/CN-33836

function useShowRestaurantSaving() {
  const { showFeature: canShowRestaurantSaving } = useFeatureFlagRequest({
    doLoad: true,
    flag: FEATURE_FLAGS.showRestaurantSaving,
  });

  return canShowRestaurantSaving;
}

export default useShowRestaurantSaving;
