import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import Input from 'primitives/Input';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import styles from './styles.module.scss';

function NameForm(props) {
  const { user } = props;

  const { closeModal } = useContext(ModalContext);
  const { handleUpdateUser } = useContext(UserContext);

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  function handleUpdate() {
    const apiValues = {
      id: user.id,
      first_name: firstName,
      last_name: lastName,
      phone: {
        number: user.phone?.number || '',
      },
    };

    handleUpdateUser(apiValues);

    closeModal();
  }

  return (
    <>
      <div>
        <Input
          type="text"
          label="First Name"
          name="firstName"
          value={firstName}
          onChange={setFirstName}
        />
        <Input
          className={styles.input}
          type="text"
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={setLastName}
        />
      </div>
      <ModalFooter>
        <ModalFooterButton
          handleClick={handleUpdate}
          disabled={!firstName || !lastName}
        >
          Update
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
}

NameForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.shape({ number: PropTypes.string }),
  }).isRequired,
};

export default NameForm;
