import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function ContentTwo(props) {
  const { order, isNewUser } = props;

  const customerName = order.customer.first_name;
  const restaurantName = order.restaurant.short_name;
  const orderSavings = order.order_restaurant_savings.toFixed(2);
  const dinerSavings = order.diner_restaurant_savings.toFixed(2);

  function getModalHeadline() {
    if (isNewUser) {
      return `Thanks for saving ${restaurantName} $${orderSavings}.`;
    }

    const showCustomerName =
      customerName.length <= 10 ? `${customerName}, way` : 'Way';
    const showRestaurantName =
      restaurantName.length <= 19 ? restaurantName : 'the restaurant';
    return `${showCustomerName} to go! Your order saved ${showRestaurantName} $${orderSavings}.`;
  }

  function getModalSubContent() {
    if (isNewUser) {
      return 'We’re commission-free for restaurants (unlike other apps). Welcome to better takeout.';
    }

    return `With ChowNow, you’ve saved independent restaurants $${dinerSavings} in commissions.`;
  }

  return (
    <div>
      <div className={styles.modalHeadline}>{getModalHeadline()}</div>
      <p className={styles.modalSubcontent}>{getModalSubContent()}</p>
    </div>
  );
}

ContentTwo.propTypes = {
  order: PropTypes.shape({
    customer: PropTypes.shape({ first_name: PropTypes.string }),
    diner_restaurant_savings: PropTypes.number,
    restaurant: PropTypes.shape({ short_name: PropTypes.string.isRequired })
      .isRequired,
    order_restaurant_savings: PropTypes.number.isRequired,
  }).isRequired,
  isNewUser: PropTypes.bool.isRequired,
};

export default ContentTwo;
