import { isArray, isPlainObject, mapValues } from 'lodash';

export const numberRegex = /^[0-9]*$/;

export function nullToEmptyString(object) {
  return mapValues(object, (val) => {
    if (isPlainObject(val)) {
      return nullToEmptyString(val);
    }

    if (isArray(val)) {
      return val.map((item) => nullToEmptyString(item));
    }

    return val === null || val === undefined ? '' : val;
  });
}

export function removeSpaces(string) {
  return string.replace(/\s/g, '');
}

export const NOTIFICATION_PERMISSIONS = {
  granted: 'granted',
  denied: 'denied',
};

function isNewNotificationSupported() {
  /*
  Using detection method as suggested on the chromium support forum
  https://bugs.chromium.org/p/chromium/issues/detail?id=481856
  */
  if (
    !window.Notification ||
    !Notification.requestPermission ||
    Notification.permission === NOTIFICATION_PERMISSIONS.granted
  ) {
    return false;
  }

  try {
    return new Notification('');
  } catch (e) {
    if (e.name === 'TypeError') return false;
  }
  return true;
}

// eslint-disable-next-line consistent-return
function notify(message, options, supportsNew) {
  if (!supportsNew) {
    try {
      return new Notification(message, options);
    } catch (e) {
      return false; // prevent from logging error when feature not supported;
    }
  } else {
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification('Order Update', {
        body: message,
        icon: options.icon,
        vibrate: [200, 100, 600],
      });
    });
  }
}

export function showNotification(message, options) {
  const supportsNew = isNewNotificationSupported();

  if ('Notification' in window) {
    if (Notification.permission === NOTIFICATION_PERMISSIONS.granted) {
      notify(message, options, supportsNew);
    } else if (Notification.permission !== NOTIFICATION_PERMISSIONS.denied) {
      Notification.requestPermission((permission) => {
        if (permission === NOTIFICATION_PERMISSIONS.granted) {
          notify(message, options, supportsNew);
        }
      });
    }
  }
}
