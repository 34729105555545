import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import dummyMenu from './dummyMenu.json';

const { string } = PropTypes;

const MENU_DATA = { ...dummyMenu };

function EmptyMenu({ className }) {
  return (
    <div className={classNames(styles.menu, className)}>
      {MENU_DATA.categories.map((category) => (
        <div key={category.id} className={styles.categorySection}>
          <span className={styles.categoryName}>{category.category_name}</span>
          <ul className={styles.itemsList}>
            {category.items.map((item) => (
              <li key={item.id} className={styles.itemContainer}>
                <h4 className={styles.name}>{item.name}</h4>
                <p>{item.description}</p>
                <p className={styles.price}>{item.price}</p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

EmptyMenu.propTypes = {
  className: string,
};

EmptyMenu.defaultProps = {
  className: undefined,
};

export default EmptyMenu;
