import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from 'helpers/modals';

import { ModalContext } from 'context/ModalContext';

import { getTableName, setTableNameRequest } from 'modules/order';

import { ReactComponent as IconEdit } from 'images/icon-edit.svg';

import styles from './styles.module.scss';

function DiningInformation({ hasError }) {
  // what should we do if there is no table info on order submit? (the hasError)
  const dispatch = useDispatch();
  const { openModal, closeModal } = useContext(ModalContext);

  const tableName = useSelector(getTableName);

  function getTableDetails() {
    if (tableName) {
      return `Table ${tableName}`;
    }

    if (hasError) {
      return <span className={styles.tableError}>Table number required</span>;
    }

    return <span className={styles.instructions}>No table number</span>;
  }

  function handleSetTableName(table) {
    dispatch({
      type: setTableNameRequest.TYPE,
      payload: table || null,
    });
    closeModal();
  }

  function handleOpenTableModal() {
    openModal(MODAL_TYPE.table, {
      onSubmitTable: handleSetTableName,
      savedTable: tableName,
    });
  }

  return (
    <div className={styles.tableWrapper}>
      <h3 className={styles.tableHeader}>Table Information</h3>
      <button
        type="button"
        onClick={handleOpenTableModal}
        className={styles.tableDetails}
      >
        {getTableDetails()}
        <IconEdit data-testid="EditIcon" className={styles.iconEdit} />
      </button>
    </div>
  );
}

DiningInformation.propTypes = {
  hasError: PropTypes.bool,
};

DiningInformation.defaultProps = {
  hasError: false,
};

export default DiningInformation;
