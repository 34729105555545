import React, { MouseEventHandler } from 'react';

import Outlink from 'primitives/Outlink';

import { ReactComponent as IconClose } from 'images/icon-close-16.svg';

import styles from './styles.module.scss';

interface ToastProps {
  ariaLabel?: string;
  dataTestId?: string;
  title?: string;
  message?: string;
  linkText?: string;
  linkTo?: string;
  handleClick?: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement>;
  handleOnClickClose?: MouseEventHandler<HTMLButtonElement>;
}

function Toast({
  ariaLabel = 'click here to learn more',
  dataTestId = 'toastLink',
  title,
  message,
  linkText,
  linkTo,
  handleClick,
  handleOnClickClose,
}: ToastProps) {
  return (
    <div className={styles.toast}>
      <div className={styles.toastContent}>
        <div className={styles.title}>{title}</div>
        {message && <div className={styles.message}>{message}</div>}
        {linkText && (
          <div className={styles.link} data-testid={dataTestId}>
            {linkTo ? (
              <Outlink
                to={linkTo}
                ariaLabel={ariaLabel}
                handleClick={handleClick}
              >
                {linkText}
              </Outlink>
            ) : (
              <button
                className={styles.toastButton}
                aria-label={ariaLabel}
                onClick={handleClick}
                type="button"
              >
                {linkText}
              </button>
            )}
          </div>
        )}
      </div>
      <button
        className={styles.iconCloseWrapper}
        onClick={handleOnClickClose}
        type="button"
      >
        <IconClose
          aria-label="close toast"
          className={styles.closeIcon}
          role="img"
        />
      </button>
    </div>
  );
}
export default Toast;
