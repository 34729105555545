import React from 'react';
import PropTypes from 'prop-types';

import { menuItemType } from 'helpers/prop-types';

import MenuItem from 'components/MenuItem';

import styles from './styles.module.scss';

function MenuItems(props) {
  const { items } = props;

  return (
    <ul className={styles.itemsList}>
      {items.map((item) => (
        <MenuItem item={item} price={item.price} key={item.id} />
      ))}
    </ul>
  );
}

MenuItems.propTypes = {
  items: PropTypes.arrayOf(menuItemType).isRequired,
};

export default MenuItems;
