/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalize, get } from 'lodash';

import { getSelectedAddress } from 'helpers/customer';
import { FULFILLMENT_METHODS } from 'helpers/order';
import { getNextAvailableDisplayText } from 'helpers/date';

import usePlatform from 'hooks/usePlatform';

import { getVehicle } from 'modules/order';

import Button from 'primitives/Button';

import { ReactComponent as IconTime } from 'images/icon-time-16.svg';
import { ReactComponent as IconDate } from 'images/icon-date-16.svg';

import styles from './styles.module.scss';

function OrderInfoButton(props) {
  const {
    displayWhenPicker,
    fulfillmentAvailability,
    fulfillmentMethod,
    fulfillmentTime,
    isClosed,
  } = props;

  const { isYelpPlatform } = usePlatform();
  const vehicle = useSelector(getVehicle);
  const selectedAddress = getSelectedAddress();

  // We're disabling order-ahead on Yelp for now
  const isSelectedFulfillmentAvailableLater =
    !isYelpPlatform &&
    get(
      fulfillmentAvailability,
      `is${capitalize(fulfillmentMethod)}AheadAvailable`
    );

  function getOrderDetails() {
    if (fulfillmentMethod === FULFILLMENT_METHODS.curbside) {
      return (
        <span className={styles.fulfillmentDetails}>
          {capitalize(vehicle.color)} {capitalize(vehicle.vehicle_type)}
        </span>
      );
    }

    if (
      fulfillmentMethod === FULFILLMENT_METHODS.delivery &&
      selectedAddress &&
      selectedAddress.street_address1 !== 'undefined' // in some cases google address results will have undefined as a string
    ) {
      return (
        <span className={styles.fulfillmentDetails}>
          {selectedAddress.street_address1}
        </span>
      );
    }

    return '';
  }

  return (
    <div className={styles.fulfillmentWhen} data-testid="OpenFulfillmentTime">
      <Button
        icon={fulfillmentTime ? IconDate : IconTime}
        disabled={isClosed || !isSelectedFulfillmentAvailableLater}
        variant="outline"
        className={classNames(styles.fulfillmentWhen, {
          [styles.overrideBtnDisable]: !isSelectedFulfillmentAvailableLater,
        })}
        onClick={() => displayWhenPicker()}
      >
        {fulfillmentTime ? (
          <>
            {getNextAvailableDisplayText({
              nextAvailableDateString: fulfillmentTime,
              showDateTimeOnly: true,
            })}
            {getOrderDetails()}
          </>
        ) : (
          <span data-testid="ASAPOrderTime">ASAP{getOrderDetails()}</span>
        )}
      </Button>
    </div>
  );
}

OrderInfoButton.propTypes = {
  displayWhenPicker: PropTypes.func.isRequired,
  fulfillmentAvailability: PropTypes.shape({}),
  fulfillmentMethod: PropTypes.string.isRequired,
  fulfillmentTime: PropTypes.string,
  isClosed: PropTypes.bool,
};

OrderInfoButton.defaultProps = {
  fulfillmentAvailability: undefined,
  fulfillmentTime: '',
  isClosed: false,
};

export default OrderInfoButton;
