/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { capitalize, partial } from 'lodash';
import classNames from 'classnames';

import { getNextAvailableDisplayText } from 'helpers/date';
import { KEY_VALUES, NO_DIRECT_LIVE_CHANNEL } from 'helpers/constants';
import {
  redirectToMarketplaceMenu,
  shouldDirectToWebsiteChannel,
  shouldRedirectToMarketplace,
} from 'helpers/configureRedirects';
import { FULFILLMENT_METHODS } from 'helpers/order';
import { restaurantBaseType } from 'helpers/prop-types';
import { buildRoute, ROUTES } from 'helpers/routes';

import styles from './styles.module.scss';

function LocationsListItem({ location, onLocationHover }) {
  const {
    address,
    allows_order_for_now: allowsOrderForNow,
    company_id: companyId,
    fulfillment,
    id,
    locale_name: localeName,
    order_ahead: orderAhead,
  } = location;
  const history = useHistory();

  async function handleItemClick() {
    if (await shouldRedirectToMarketplace(id, location)) {
      return redirectToMarketplaceMenu({
        restoId: id,
        hqId: companyId,
        source: 'locations-list-click',
      });
    }

    const query = shouldDirectToWebsiteChannel(location) && {
      name: 'cn_channel',
      value: NO_DIRECT_LIVE_CHANNEL,
    };
    return history.push(
      buildRoute({
        hqId: companyId,
        restaurantId: id,
        route: ROUTES.menu,
        query,
      })
    );
  }

  function getFulfillmentTypes() {
    const { curbside, delivery, pickup } = fulfillment;
    const fulfillmentTypes = [];

    if (pickup) fulfillmentTypes.push(capitalize(FULFILLMENT_METHODS.pickup));
    if (curbside) {
      fulfillmentTypes.push(capitalize(FULFILLMENT_METHODS.curbside));
    }
    if (delivery) {
      fulfillmentTypes.push(capitalize(FULFILLMENT_METHODS.delivery));
    }

    return fulfillmentTypes;
  }

  function statusTag() {
    const isOpen =
      allowsOrderForNow &&
      (fulfillment?.pickup?.is_available_now ||
        fulfillment?.delivery?.is_available_now);
    const allowsOrderAhead =
      orderAhead?.is_delivery_ahead_available ||
      orderAhead?.is_pickup_ahead_available;
    const nextAvailableDate =
      fulfillment?.pickup?.next_available_time ||
      fulfillment?.delivery?.next_available_time;

    let message;
    let disabled;

    if (!isOpen && !nextAvailableDate && !allowsOrderAhead) {
      message = 'Ordering unavailable';
      disabled = true;
    } else if (
      !isOpen &&
      nextAvailableDate &&
      allowsOrderAhead &&
      !allowsOrderForNow
    ) {
      message = 'Order ahead';
    } else if (!isOpen && nextAvailableDate && allowsOrderForNow) {
      message = (
        <>
          {allowsOrderAhead && (
            <span className={styles.orderAhead}>Order ahead</span>
          )}
          {getNextAvailableDisplayText({
            nextAvailableDateString: nextAvailableDate,
          })}
        </>
      );
      disabled = true;
    } else {
      message = 'Open now';
    }

    return (
      <span
        className={classNames(styles.tag, {
          [styles.disabled]: disabled,
        })}
      >
        {message}
      </span>
    );
  }

  return (
    <div
      onMouseEnter={partial(onLocationHover, address)}
      onClick={handleItemClick}
      onKeyDown={(e) => {
        if (e.key === KEY_VALUES.enter) handleItemClick();
      }}
      className={styles.locationsListItem}
      role="button"
      tabIndex={0}
    >
      <div className={styles.restaurantName} data-testid="LocationName">
        {localeName}
      </div>
      <div className={styles.address}>{address.formatted_address}</div>
      <div data-testid="LocationFulfillment">
        {getFulfillmentTypes().map((fulfillmentType) => (
          <span key={fulfillmentType} className={styles.fulfillmentType}>
            {fulfillmentType}
          </span>
        ))}
      </div>
      <div className={styles.tagWrap} data-testid="LocationStatus">
        {statusTag()}
      </div>
    </div>
  );
}

LocationsListItem.propTypes = {
  location: restaurantBaseType.isRequired,
  onLocationHover: PropTypes.func,
};

LocationsListItem.defaultProps = {
  onLocationHover: () => {},
};

export default LocationsListItem;
