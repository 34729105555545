/* eslint-disable max-len */
import React from 'react';

export default function MasterCard() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.5 0.5 5 1 5H31C31.5 5 32 5.5 32 6V26C32 26.5 31.5 27 31 27H1C0.5 27 0 26.5 0 26V6ZM1 6V26H31V6H1Z"
        fill="black"
      />
      <path d="M19 10H13V22H19V10Z" fill="#FF5F00" />
      <path
        d="M13.1673 16C13.1662 14.8642 13.4207 13.7431 13.9115 12.7216C14.4023 11.7 15.1165 10.8047 16 10.1035C14.9059 9.23384 13.5919 8.69302 12.2082 8.54283C10.8245 8.39265 9.42692 8.63917 8.17522 9.25422C6.92352 9.86928 5.86819 10.828 5.12986 12.0209C4.39153 13.2138 4 14.5927 4 16C4 17.4073 4.39153 18.7862 5.12986 19.9791C5.86819 21.172 6.92352 22.1307 8.17522 22.7458C9.42692 23.3608 10.8245 23.6073 12.2082 23.4572C13.5919 23.307 14.9059 22.7662 16 21.8965C15.1165 21.1953 14.4023 20.3 13.9115 19.2784C13.4208 18.2568 13.1662 17.1357 13.1673 16Z"
        fill="#EB001B"
      />
      <path
        d="M28 16C28.0001 17.4073 27.6085 18.7861 26.8702 19.979C26.1319 21.1719 25.0766 22.1307 23.8249 22.7458C22.5732 23.3608 21.1756 23.6073 19.7919 23.4572C18.4081 23.307 17.0941 22.7662 16 21.8965C16.8828 21.1946 17.5964 20.2992 18.0871 19.2777C18.5778 18.2563 18.8328 17.1356 18.8328 16C18.8328 14.8644 18.5778 13.7437 18.0871 12.7223C17.5964 11.7008 16.8828 10.8054 16 10.1035C17.0941 9.23384 18.4081 8.69301 19.7919 8.54283C21.1756 8.39265 22.5732 8.63918 23.8249 9.25424C25.0766 9.8693 26.1319 10.8281 26.8702 12.021C27.6085 13.2139 28.0001 14.5927 28 16Z"
        fill="#F79E1B"
      />
    </svg>
  );
}
