/* eslint-disable max-len */
import React from 'react';

export default function GooglePay() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4894 8H8.51064C4.38207 8 1 11.3821 1 15.5106C1 19.6392 4.38207 23.0213 8.51064 23.0213H23.4894C27.6179 23.0213 31 19.6392 31 15.5106C31 11.3821 27.6179 8 23.4894 8ZM8.51064 7H23.4894C28.1702 7 32 10.8298 32 15.5106C32 20.1915 28.1702 24.0213 23.4894 24.0213H8.51064C3.82979 24.0213 0 20.1915 0 15.5106C0 10.8298 3.82979 7 8.51064 7Z"
        fill="#3C4043"
      />
      <path
        d="M15.2594 16.1149V18.6894H14.4424V12.3319H16.6083C17.1573 12.3319 17.6254 12.5149 18.0083 12.8808C18.3998 13.2468 18.5956 13.6936 18.5956 14.2213C18.5956 14.7617 18.3998 15.2085 18.0083 15.5702C17.6296 15.9319 17.1615 16.1106 16.6083 16.1106H15.2594V16.1149ZM15.2594 13.1149V15.3319H16.6254C16.9488 15.3319 17.2211 15.2213 17.4339 15.0043C17.6509 14.7872 17.7615 14.5234 17.7615 14.2255C17.7615 13.9319 17.6509 13.6723 17.4339 13.4553C17.2211 13.2298 16.953 13.1191 16.6254 13.1191H15.2594V13.1149Z"
        fill="#3C4043"
      />
      <path
        d="M20.7319 14.1958C21.3361 14.1958 21.8127 14.3575 22.1616 14.6809C22.5106 15.0043 22.685 15.4469 22.685 16.0086V18.6894H21.9063V18.0852H21.8723C21.5361 18.583 21.085 18.8298 20.5233 18.8298C20.0425 18.8298 19.6425 18.6894 19.3191 18.4043C18.9957 18.1192 18.834 17.766 18.834 17.3405C18.834 16.8894 19.0042 16.532 19.3446 16.2681C19.685 16.0001 20.1404 15.8681 20.7063 15.8681C21.1914 15.8681 21.5914 15.9575 21.9021 16.1362V15.949C21.9021 15.6639 21.7914 15.4256 21.5659 15.2256C21.3404 15.0256 21.0765 14.9277 20.7744 14.9277C20.3191 14.9277 19.9574 15.1192 19.6936 15.5064L18.9744 15.0554C19.3702 14.4809 19.9574 14.1958 20.7319 14.1958ZM19.6765 17.3532C19.6765 17.566 19.7659 17.7447 19.9489 17.8852C20.1276 18.0256 20.3404 18.0979 20.5829 18.0979C20.9276 18.0979 21.234 17.9703 21.5021 17.7149C21.7702 17.4596 21.9063 17.1618 21.9063 16.8171C21.651 16.6171 21.2978 16.5149 20.8425 16.5149C20.5106 16.5149 20.234 16.5958 20.0127 16.7532C19.7872 16.9192 19.6765 17.1192 19.6765 17.3532Z"
        fill="#3C4043"
      />
      <path
        d="M27.1277 14.3362L24.4043 20.6H23.5618L24.5746 18.4085L22.7788 14.3362H23.6682L24.9618 17.4596H24.9788L26.2384 14.3362H27.1277Z"
        fill="#3C4043"
      />
      <path
        d="M12.0096 15.5957C12.0096 15.3293 11.9858 15.0745 11.9415 14.8293H8.51685V16.2336L10.4892 16.234C10.4092 16.7013 10.1517 17.0996 9.75727 17.3651V18.2762H10.9313C11.6168 17.6417 12.0096 16.7038 12.0096 15.5957Z"
        fill="#4285F4"
      />
      <path
        d="M9.75788 17.365C9.43107 17.5855 9.01022 17.7144 8.51788 17.7144C7.56682 17.7144 6.76001 17.0735 6.47107 16.2097H5.26001V17.1493C5.86001 18.3399 7.0932 19.157 8.51788 19.157C9.50256 19.157 10.3298 18.8331 10.9319 18.2757L9.75788 17.365Z"
        fill="#34A853"
      />
      <path
        d="M6.35699 15.5128C6.35699 15.2702 6.39742 15.0357 6.47104 14.8153V13.8757H5.25997C5.01189 14.3681 4.87231 14.9238 4.87231 15.5128C4.87231 16.1017 5.01231 16.6574 5.25997 17.1498L6.47104 16.2102C6.39742 15.9898 6.35699 15.7553 6.35699 15.5128Z"
        fill="#FABB05"
      />
      <path
        d="M8.51788 13.3106C9.05533 13.3106 9.5366 13.4957 9.9166 13.8574L10.957 12.8178C10.3251 12.2293 9.50129 11.868 8.51788 11.868C7.09363 11.868 5.86001 12.6851 5.26001 13.8757L6.47107 14.8153C6.76001 13.9514 7.56682 13.3106 8.51788 13.3106Z"
        fill="#E94235"
      />
    </svg>
  );
}
