/* eslint-disable complexity */
import {
  DEVICE_TYPE,
  getHqIdFromUrl,
  getRestaurantIdFromUrl,
} from '@chownow/cn-web-utils/url';
import {
  DEPLOY_ENVIRONMENTS,
  MARKETPLACE_ORIGINS,
  FIND_MORE_RESTAURANTS,
} from 'helpers/constants';
import { FULFILLMENT_METHODS } from 'helpers/order';

export function getFbPixelScript(pixelId) {
  return `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${pixelId}');
  fbq('track', 'PageView');`;
}

/**
 * Returns the url of the mobile app download link based on user device
 * @param device {string} Takes in user device
 * @param restaurant {object} Takes in restaurant object
 * @returns {string} will return url as string of device appropriate app download link
 */
export function getRestaurantAppUrl(device, restaurant) {
  if (!device || !restaurant) return null;

  const isIos = device === DEVICE_TYPE.IOS;
  const iosAppId = restaurant && restaurant.ios_app_store_id;

  if (isIos && iosAppId) return `https://apps.apple.com/us/app/id${iosAppId}`;

  const isAndroid = device === DEVICE_TYPE.ANDROID;
  const androidAppUrl = restaurant && restaurant.android_app_url;

  if (isAndroid && androidAppUrl) return androidAppUrl;

  return null;
}

export function isDirectUrl() {
  const DIRECT_REGEX = /(direct)|(order)/;
  return DIRECT_REGEX.test(window.location.hostname);
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

/**
 * Correctly rewrite and format a hostname for Marketplace
 * accounting for both the app name and prod vs QA environments.
 * @returns {string} of hostname
 */
export function getHostnameForMarketplace() {
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
  if (deployEnv === DEPLOY_ENVIRONMENTS.prod) {
    return `${MARKETPLACE_ORIGINS.prod}.chownow.com`;
  }

  return `${MARKETPLACE_ORIGINS.qa}.${deployEnv}.svpn.chownow.com`;
}

export function concatAddressWithAddressLine2({
  formattedAddress,
  addressLine2,
}) {
  const indexOfFirstComma = formattedAddress.indexOf(',');
  return [
    formattedAddress.slice(0, indexOfFirstComma + 1),
    ' ',
    addressLine2,
    formattedAddress.slice(indexOfFirstComma),
  ].join('');
}

function getFormattedAddress({ selectedAddress }) {
  if (!selectedAddress) return null;

  const addressLine2 =
    selectedAddress.types?.some((type) => type === 'premise') &&
    selectedAddress?.street_address2;

  if (addressLine2) {
    const formattedAddress = selectedAddress?.formatted_address;
    return concatAddressWithAddressLine2({ formattedAddress, addressLine2 });
  }

  return selectedAddress?.formatted_address;
}

export function getFindMoreRestaurantsUrl({
  cityAddress,
  fulfillmentMethod,
  selectedAddress,
}) {
  const deliveryFulfillmentParam =
    fulfillmentMethod === FULFILLMENT_METHODS.delivery ? 1 : 0;

  const addressForSearchListing = selectedAddress
    ? getFormattedAddress({ selectedAddress })
    : cityAddress.city;
  const encodedAddress = encodeURIComponent(addressForSearchListing);

  return `https://${getHostnameForMarketplace()}/search${
    FIND_MORE_RESTAURANTS.deliveryFulfillmentQuery
  }${deliveryFulfillmentParam}${
    FIND_MORE_RESTAURANTS.deliveryLocationQuery
  }${encodedAddress}`;
}

export function getIsClient() {
  return typeof window !== 'undefined';
}

export function isOnPage(path) {
  if (!getIsClient()) return false;
  return window.location.pathname.includes(path);
}

export function isOnMenu() {
  const hqId = getHqIdFromUrl();
  const restaurantId = getRestaurantIdFromUrl();
  const isCheckout = isOnPage('checkout');

  return !!hqId && !!restaurantId && !isCheckout;
}

export function isOnLocations() {
  const hqId = getHqIdFromUrl();
  const restaurantId = getRestaurantIdFromUrl();
  const isSupport = isOnPage('support');

  return !!hqId && !restaurantId && !isSupport;
}
