/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

/*
  Context used for google maps. Currently it only provides a variable
  that tells if the maps failed to load.
*/
const GoogleMapsContext = createContext({
  hasMapError: false,
  hasMapLoaded: false,
  setHasMapError: () => {},
  setHasMapLoaded: () => {},
});

export function GoogleMapsProvider(props) {
  const { children, hasMapError, hasMapLoaded } = props;
  const [hasMapErrorState, setHasMapErrorState] = useState(hasMapError);
  const [hasMapLoadedState, setHasMapLoadedState] = useState(hasMapLoaded);

  return (
    <GoogleMapsContext.Provider
      value={{
        hasMapError: hasMapErrorState,
        hasMapLoaded: hasMapLoadedState,
        setHasMapError: setHasMapErrorState,
        setHasMapLoaded: setHasMapLoadedState,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
}

GoogleMapsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  hasMapError: PropTypes.bool,
  hasMapLoaded: PropTypes.bool,
};

GoogleMapsProvider.defaultProps = {
  hasMapError: false,
  hasMapLoaded: false,
};

export default GoogleMapsContext;
