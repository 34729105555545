import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { postToChownowApi } from 'helpers/api';
import { getSelectedAddress, removeSelectedAddressId } from 'helpers/customer';

import Button from 'primitives/Button';
import Input from 'primitives/Input';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';
import Textarea from 'primitives/Textarea';

import { ReactComponent as IconMapPin } from 'images/icon-mappin.svg';

import { getUpdatedAddressList } from './helpers.ts';
import styles from './styles.module.scss';

function UpdateAddressForm(props) {
  const { address } = props;

  const { closeModal } = useContext(ModalContext);
  const { setUser, user } = useContext(UserContext);

  const [streetAddress2, setStreetAddress2] = useState(address.street_address2);
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    address.delivery_instructions
  );

  async function handleUpdateAddress() {
    const apiValues = {
      ...address,
      street_address2: streetAddress2,
      delivery_instructions: deliveryInstructions,
    };

    const { error } = await postToChownowApi({
      endpoint: `customer/${user.id}/delivery-address/${address.id}`,
      body: apiValues,
      method: 'PUT',
    });

    const updatedAddressList = getUpdatedAddressList({
      currentUserAddresses: user.delivery?.addresses || [],
      updatedUserAddress: apiValues,
    });
    if (!error) {
      setUser({
        ...user,
        delivery: {
          addresses: updatedAddressList,
        },
      });
    }

    closeModal();
  }

  async function handleRemoveAddress() {
    const { error } = await postToChownowApi({
      endpoint: `customer/${user.id}/delivery-address/${address.id}`,
      method: 'DELETE',
    });

    if (!error) {
      const selectedAddress = getSelectedAddress();
      if (selectedAddress) {
        // remove address ID tied to user if address user deletes is tied to selectedAddress
        if (address.id === selectedAddress.id) {
          removeSelectedAddressId(selectedAddress);
        }
      }

      setUser({
        ...user,
        delivery: {
          addresses: user.delivery?.addresses?.filter(
            (savedAddress) => savedAddress.id !== address.id
          ),
        },
      });
    }
    closeModal();
  }

  return (
    <>
      <div>
        <Input
          type="text"
          label="Address"
          name="streetAddress1"
          value={address.street_address1}
          icon={<IconMapPin className={styles.icon} />}
          disabled
        />
        <Input
          className={styles.input}
          type="text"
          label="Apt / Ste / Floor"
          name="streetAddress2"
          value={streetAddress2}
          onChange={setStreetAddress2}
        />
        <Textarea
          className={styles.input}
          maxLength={140}
          label="Delivery Instructions"
          name="deliveryInstructions"
          value={deliveryInstructions}
          onChange={setDeliveryInstructions}
          placeholder="Add dropoff notes"
        />
      </div>
      <ModalFooter>
        <ModalFooterButton handleClick={handleUpdateAddress}>
          Update
        </ModalFooterButton>
        <Button
          className={styles.secondaryButton}
          variant="caution"
          isFullWidth
          onClick={handleRemoveAddress}
        >
          Remove
        </Button>
      </ModalFooter>
    </>
  );
}

UpdateAddressForm.propTypes = {
  address: PropTypes.shape({
    id: PropTypes.string,
    street_address1: PropTypes.string,
    street_address2: PropTypes.string,
    delivery_instructions: PropTypes.string,
  }).isRequired,
};

export default UpdateAddressForm;
