import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

export default function POAToast({ closeToast, isDockShowing }) {
  return (
    <div
      className={classNames(styles.POAToast, {
        [styles.higherToast]: isDockShowing,
      })}
    >
      <p className={styles.POAToastInner}>Your tip was updated</p>
      <button
        type="button"
        className={styles.POAToastButton}
        onClick={closeToast}
      >
        <IconClose />
      </button>
    </div>
  );
}

POAToast.propTypes = {
  closeToast: PropTypes.func.isRequired,
  isDockShowing: PropTypes.bool,
};

POAToast.defaultProps = {
  isDockShowing: false,
};
