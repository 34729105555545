/* eslint-disable complexity, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { last, some } from 'lodash';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import usePrevious from 'hooks/usePrevious';

import { creditCardType } from 'helpers/prop-types';
import { MODAL_TYPE } from 'helpers/modals';
import { CVV_REQUIRED } from 'helpers/api';
import {
  APPLE_PAY_STRUCT,
  GOOGLE_PAY_STRUCT,
  NEW_CARD_VALUE,
  getIsCardExpired,
} from 'helpers/payments';
import { getSubmitOrderCount, FULFILLMENT_METHODS } from 'helpers/order';
import { getSelectedAddress } from 'helpers/customer';
import { reverseFormatExpDate } from 'helpers/format';

import { getErrorsForOrder, getFulfillmentMethod } from 'modules/order';

import { ReactComponent as IconEdit } from 'images/icon-edit.svg';

import InvalidCard from './InvalidCard';
import PaymentOptions from './PaymentOptions';
import PromoCode from './PromoCode';
import { getHasUserEditedDeliveryAddress } from '../helpers';

import styles from './styles.module.scss';

function PaymentSelection(props) {
  const {
    cardForm,
    isCashOnly,
    setFieldValue,
    setTouched,
    resetForm,
    defaultToApplePay,
    defaultToGooglePay,
    showPromoField,
  } = props;

  const paymentSectionRef = useRef(null);

  const { openModal } = useContext(ModalContext);
  const { selectedCard, selectedCardId, setSelectedCardId, user } =
    useContext(UserContext);

  const fulfillmentMethod = useSelector(getFulfillmentMethod);
  const orderErrors = useSelector(getErrorsForOrder);

  const prevOrderErrors = usePrevious(orderErrors);
  const prevDefaultToApplePay = usePrevious(defaultToApplePay);
  const prevDefaultToGooglePay = usePrevious(defaultToGooglePay);
  const prevSelectedCardId = usePrevious(selectedCardId);

  const userId = user?.id;
  const savedCards = user.billing?.cards || [];
  const hasSelectedCard = selectedCardId && selectedCard;
  const savedAddresses = user?.delivery?.addresses;

  const submittedOrderCount = getSubmitOrderCount();
  const isSelectedCardDeclined =
    !!submittedOrderCount?.ids.includes(selectedCardId);

  const savedAddress = getSelectedAddress();
  const isDelivery = fulfillmentMethod === FULFILLMENT_METHODS.delivery;
  const isNewDeliveryAddress = isDelivery && !savedAddress?.id;
  const isEditedDdeliveryAddress =
    isDelivery &&
    getHasUserEditedDeliveryAddress({
      savedAddresses,
      savedAddress,
    });

  const isSocialPaySelected =
    selectedCardId === APPLE_PAY_STRUCT.type ||
    selectedCardId === GOOGLE_PAY_STRUCT.type;

  // selected card is expired
  const isCardExpired = getIsCardExpired(
    selectedCard?.exp_month,
    selectedCard?.exp_year
  );
  // selected card is cvv invalid or if card is expired
  const shouldShowCVVInput =
    !selectedCard?.cvv_valid ||
    (selectedCard?.cvv_valid &&
      (isNewDeliveryAddress || isEditedDdeliveryAddress)) ||
    isCardExpired;
  const showInvalidCardForm =
    !isCashOnly &&
    hasSelectedCard &&
    selectedCardId !== NEW_CARD_VALUE &&
    !isSocialPaySelected &&
    shouldShowCVVInput &&
    !cardForm?.id;
  const showPaymentEditIcon =
    !isCashOnly &&
    (savedCards.length || defaultToApplePay || defaultToGooglePay);

  useEffect(() => {
    // May need to set selectedCard on load when deleting cards from account page
    if (selectedCardId && selectedCardId !== NEW_CARD_VALUE && !selectedCard) {
      setSelectedCardId(selectedCardId);
    }
  }, []);

  useEffect(() => {
    if (!selectedCardId) {
      if (savedCards.length) {
        setSelectedCardId(last(savedCards).id);
      }
      if (defaultToApplePay) {
        setSelectedCardId(APPLE_PAY_STRUCT.id);
      } else if (defaultToGooglePay) {
        setSelectedCardId(GOOGLE_PAY_STRUCT.id);
      }
    }
  }, [userId]);

  useEffect(() => {
    // Only set ApplePay as default if card isn't already selected
    if (!prevDefaultToApplePay && defaultToApplePay && !selectedCard) {
      setSelectedCardId(APPLE_PAY_STRUCT.id);
    }
  }, [defaultToApplePay]);

  useEffect(() => {
    // Only set GooglePay as default if card isn't already selected & no defaultToApplePay
    if (
      !prevDefaultToGooglePay &&
      defaultToGooglePay &&
      !selectedCard &&
      !defaultToApplePay
    ) {
      setSelectedCardId(GOOGLE_PAY_STRUCT.id);
    }
  }, [defaultToGooglePay]);

  useEffect(() => {
    const hasCardChanged =
      prevSelectedCardId && selectedCardId !== prevSelectedCardId;
    // need to update card form validation fields when we have a selected card that is not social pay and
    // either upon initial load of selected card or if selected card changes
    if (
      selectedCardId &&
      (hasCardChanged || !prevSelectedCardId) &&
      !isSocialPaySelected
    ) {
      const isCardDeclined =
        !!submittedOrderCount?.ids.includes(selectedCardId);

      if (isCardDeclined) {
        resetForm();
      } else {
        const expDate =
          isCardExpired || selectedCardId === NEW_CARD_VALUE
            ? ''
            : selectedCard.expDate ||
              reverseFormatExpDate(
                selectedCard.exp_month,
                selectedCard.exp_year
              );
        const cvvValid = isCardExpired ? false : selectedCard.cvv_valid;
        setFieldValue('expDate', expDate);
        setFieldValue('cvv', '');
        setFieldValue('number', '');
        setFieldValue('type', selectedCard?.type);
        setFieldValue('address.zip', selectedCard.address.zip);
        setFieldValue('cvv_valid', cvvValid);
      }

      setFieldValue('id', selectedCardId);
      setFieldValue('is_visible', true);
    }
  }, [selectedCardId]);

  useEffect(() => {
    if (
      !some(prevOrderErrors, { code: CVV_REQUIRED }) &&
      some(orderErrors, { code: CVV_REQUIRED })
    ) {
      paymentSectionRef.current.scrollIntoView();
      setFieldValue('id', selectedCardId);
      setFieldValue('cvv', '').then(() => {
        setTouched({ cvv: true });
      });
    }
  }, [orderErrors]);

  function handleOpenPayments(isNew) {
    if (userId) {
      openModal(MODAL_TYPE.payment, {
        cardId: !hasSelectedCard || isNew ? NEW_CARD_VALUE : undefined,
        cardForm,
        setFieldValue,
        defaultToApplePay,
        defaultToGooglePay,
      });
    } else {
      openModal(MODAL_TYPE.auth);
    }
  }

  return (
    <div className={styles.paymentWrapper}>
      <h3 className={styles.paymentHeader} ref={paymentSectionRef}>
        Payment
      </h3>
      <div className={styles.paymentDetailsWrapper}>
        <div className={styles.paymentOptionsWrapper}>
          {isCashOnly ? (
            <div data-testid="CashOnlyPayment">
              Cash Only (payment due at pickup)
            </div>
          ) : (
            <PaymentOptions
              selectedCard={selectedCard}
              defaultToApplePay={defaultToApplePay}
              defaultToGooglePay={defaultToGooglePay}
              lastSavedCard={last(savedCards)}
              onSelectNewCard={handleOpenPayments}
              setFieldValue={setFieldValue}
            />
          )}
        </div>
        {showPaymentEditIcon && (
          <button
            className={styles.iconEdit}
            type="button"
            data-testid="EditPayment"
            onClick={() => handleOpenPayments(false)}
          >
            <IconEdit />
          </button>
        )}
      </div>
      {showInvalidCardForm && (
        <InvalidCard
          setFieldValue={setFieldValue}
          isAmex={selectedCard.type === 'American Express'}
          selectedCardId={selectedCardId}
          isDeclinedCard={isSelectedCardDeclined}
          isExpired={isCardExpired}
        />
      )}
      {showPromoField && <PromoCode />}
    </div>
  );
}

PaymentSelection.propTypes = {
  cardForm: creditCardType,
  defaultToApplePay: PropTypes.bool,
  defaultToGooglePay: PropTypes.bool,
  isCashOnly: PropTypes.bool,
  resetForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  setTouched: PropTypes.func,
  showPromoField: PropTypes.bool,
};

PaymentSelection.defaultProps = {
  cardForm: undefined,
  defaultToApplePay: false,
  defaultToGooglePay: false,
  isCashOnly: false,
  resetForm: undefined,
  setFieldValue: undefined,
  setTouched: undefined,
  showPromoField: false,
};

export default PaymentSelection;
