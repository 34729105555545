import React from 'react';
import PropTypes from 'prop-types';

import { FULFILLMENT_METHODS } from 'helpers/order';

import PickupAndCurbside from './PickupAndCurbside';
import Delivery from './Delivery';

import styles from './styles.module.scss';

export default function Address({
  deliveryAddress,
  order: {
    fulfill_method: fulfillMethod,
    delivery_instructions: deliveryInstructions,
    restaurant,
  },
  isOrderFulfilled,
  isOrderCancelled,
}) {
  if (fulfillMethod === FULFILLMENT_METHODS.dineIn) return false;
  const isPickupOrCurbside =
    fulfillMethod === FULFILLMENT_METHODS.pickup ||
    fulfillMethod === FULFILLMENT_METHODS.curbside;

  if (isPickupOrCurbside) {
    return (
      <address className={styles.wrapper}>
        <PickupAndCurbside
          restaurant={restaurant}
          isOrderCancelled={isOrderCancelled}
        />
      </address>
    );
  }

  if (fulfillMethod === FULFILLMENT_METHODS.delivery) {
    return (
      <address className={styles.wrapper}>
        <Delivery
          restaurant={restaurant}
          deliveryAddress={deliveryAddress}
          deliveryInstructions={deliveryInstructions}
          isOrderFulfilled={isOrderFulfilled}
        />
      </address>
    );
  }
  return false;
}

Address.propTypes = {
  deliveryAddress: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    street_address1: PropTypes.string,
    street_address2: PropTypes.string,
  }),
  isOrderCancelled: PropTypes.bool.isRequired,
  isOrderFulfilled: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    delivery_instructions: PropTypes.string,
    fulfill_method: PropTypes.string,
    restaurant: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        street_address1: PropTypes.string,
        street_address2: PropTypes.string,
      }).isRequired,
      phone: PropTypes.string.isRequired,
      short_name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

Address.defaultProps = {
  deliveryAddress: {},
};
