import { pick, upperFirst } from 'lodash';

import {
  formatExpDate,
  onlyNums,
  removePhoneCountryCode,
} from 'helpers/format';

import iconVisa from 'images/icon-visa.svg';
import iconMasterCard from 'images/icon-mastercard.svg';
import iconAmex from 'images/icon-amex.svg';
import iconDiscover from 'images/icon-discover.svg';
import iconApplePay from 'images/icon-applepay.svg';
import iconGooglePay from 'images/icon-googlepay.svg';
import iconJCB from 'images/icon-jcb.svg';
import iconDinersClub from 'images/icon-dinersclub.svg';
import iconUnionPay from 'images/icon-unionpay.svg';

const GOOGLE_PAY_COOKIE = 'google_pay_debug';

export const creditCardTypeToImage = {
  1: iconVisa,
  2: iconMasterCard,
  3: iconAmex,
  4: iconDiscover,
  5: iconApplePay,
  6: iconGooglePay,
  7: iconGooglePay,
  8: iconApplePay,
  10: iconJCB,
  11: iconDinersClub,
  12: iconUnionPay,
};

export const creditCardStringToType = {
  Visa: '1',
  MasterCard: '2',
  'American Express': '3',
  Discover: '4',
  ApplePay: '5',
  AndroidPay: '6',
  GooglePay: '7',
  'ChowNow Apple Pay': '8',
  JCB: '10',
  'Diners Club': '11',
  UnionPay: '12',
};

export const creditCardTypeFormattedName = {
  visa: 'Visa',
  mastercard: 'MasterCard',
  amex: 'American Express',
  discover: 'Discover',
  applepay: 'ApplePay',
  googlepay: 'GooglePay',
  jcb: 'JCB',
  diners: 'Diners Club',
  unionPay: 'UnionPay',
};

export const APPLE_PAY_STRUCT = {
  id: creditCardTypeFormattedName.applepay,
  type_id: creditCardStringToType.ApplePay,
  type: 'ApplePay',
};

export const GOOGLE_PAY_STRUCT = {
  id: creditCardTypeFormattedName.googlepay,
  type_id: creditCardStringToType.GooglePay,
  type: 'GooglePay',
};

export const NEW_CARD_VALUE = 'new';

export const serializeCard = (card) => {
  const isNewCard = card.id && card.id === NEW_CARD_VALUE;
  // eslint-disable-next-line camelcase
  const { exp_month, exp_year } = card.expDate
    ? formatExpDate(card.expDate)
    : { exp_month: card.exp_month, exp_year: card.exp_year };

  return {
    address: pick(card.address, ['id', 'zip']),
    cvv: card.cvv || null,
    exp_month,
    exp_year,
    id: isNewCard ? null : card.id,
    is_visible: card.is_visible,
    last_four: card.last_four,
    number: card.number ? card.number.replace(/\s/g, '') : null,
    type: upperFirst(card.type) || null,
    type_id: card.type_id,
  };
};

/**
 * Takes response from Stripe and converts to payment type needed for our order submit API
 * @param {object} { shippingContact, token } - https://stripe.com/docs/stripe-js/v2#collecting-card-details
 * @param {string} paymentProcessorId - our Stripe API key
 * @returns {object} Returns payment info in format for our order submit API
 */
export function serializeApplePay(
  { shippingContact, token } = {}, // eslint-disable-line default-param-last
  paymentProcessorId
) {
  const phone = onlyNums(shippingContact.phoneNumber);
  return {
    email: shippingContact.emailAddress,
    first_name: shippingContact.givenName,
    last_name: shippingContact.familyName,
    phone: removePhoneCountryCode(phone),
    cardData: {
      id: null,
      address: {
        zip: token.card.address_zip,
        street_address1: token.card.address_line1,
        city: token.card.address_city,
        state: token.card.address_state,
      },
      type: APPLE_PAY_STRUCT.type,
      token: token.id,
      payment_processor_id: paymentProcessorId,
    },
  };
}

export function serializeGooglePay(data = {}) {
  const { paymentMethodData, email } = data;
  const token = JSON.parse(paymentMethodData.tokenizationData.token);

  const fullName = paymentMethodData.info.billingAddress.name.split(' ');
  const firstName = fullName[0];
  const lastName = fullName[fullName.length - 1];

  return {
    phone: removePhoneCountryCode(
      paymentMethodData.info.billingAddress.phoneNumber
    ),
    first_name: firstName,
    last_name: lastName,
    email,
    cardData: {
      id: null,
      address: {
        zip: token.card.address_zip,
      },
      type: GOOGLE_PAY_STRUCT.type,
      token: token.id,
    },
  };
}

/**
 * Uses window.ApplePaySession to check if user has an active Apple Pay card
 * NOTE: this functionality is built into v3 Stripe, but will need to do this for now as we use v2
 * @returns {boolean}
 */
// eslint-disable-next-line consistent-return
export async function hasApplePayCard() {
  if (window.ApplePaySession) {
    const merchantIdentifier =
      process.env.REACT_APP_STRIPE_APPLEPAY_MERCHANT_ID;

    const response =
      window.ApplePaySession &&
      (await window.ApplePaySession.canMakePayments(merchantIdentifier));
    if (response) {
      return response;
    }

    return false;
  }
}

function getGooglePayEnvironment() {
  let env = process.env.REACT_APP_DEPLOY_ENV;

  if (document.cookie.includes(GOOGLE_PAY_COOKIE)) {
    const cookie = document.cookie.split('; ').reduce((acc, current) => {
      const [name, value] = current.split('=');
      acc[name] = value;
      return acc;
    }, {});
    env = cookie[GOOGLE_PAY_COOKIE];
  }

  return env === 'prod' ? 'PRODUCTION' : 'TEST';
}

/**
 * Uses window.google.payments to set paymentsClient for Google Pay
 * @returns {string}
 */
export function getGooglePaymentsClient() {
  // Due to automated testing, need to set Google Pay to production environment as default. To
  // override and put Google Pay into testing environment, add a session cookie
  // 'google_pay_debug' and set to true (uncomment out next line for local production testing)
  // document.cookie = 'google_pay_debug=prod';
  const environment = getGooglePayEnvironment();
  const { google } = window;

  let paymentsClient;

  if (
    google.payments &&
    google.payments.api &&
    typeof google.payments.api.PaymentsClient !== 'undefined'
  ) {
    paymentsClient = new google.payments.api.PaymentsClient({
      environment,
    });
  }

  return paymentsClient;
}

export function getIsCardExpired(expMonth, expYear) {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  if (
    expYear < currentYear ||
    (expYear === currentYear && expMonth < currentMonth)
  ) {
    return true;
  }
  return false;
}

export function getAnalyticsPaymentName(id, type) {
  if (type) return type.toLowerCase().replace(/ /g, '_');
  if (id === GOOGLE_PAY_STRUCT.id) return 'google_pay';
  if (id === APPLE_PAY_STRUCT.id) return 'apple_pay';
  return '';
}
