import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getRestaurantDetails } from 'modules/restaurant';

import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';
import Tabs from 'primitives/Tabs';

import RestaurantInfoBlock from './RestaurantInfoBlock';
import HoursList from './HoursList';

import { ClosureType, getClosureText } from './helpers';

import styles from './styles.module.scss';

function HoursModal() {
  const restaurant = useSelector(getRestaurantDetails);

  const deliveryDisplayHours = get(
    restaurant.fulfillment.delivery,
    'display_hours'
  );
  const pickupDisplayHours = get(
    restaurant.fulfillment.pickup,
    'display_hours'
  );

  return (
    <ModalFrame>
      <ModalHeader
        title="Hours & Map"
        className={styles.modalHeader}
        isSticky
      />
      <RestaurantInfoBlock />
      {!!restaurant.closures.length && (
        <div className={styles.closureCard}>
          <div className={styles.closureHeading}>Ordering Unavailable</div>
          {restaurant.closures.map((closure: ClosureType) => (
            <div
              key={closure.description}
              className={styles.closure}
              data-testid="TempClosure"
            >
              {getClosureText(closure)}
            </div>
          ))}
        </div>
      )}
      <div className={styles.tabs}>
        <Tabs
          tabs={[
            {
              label: 'Pickup Hours',
              name: 'pickup',
              body: pickupDisplayHours ? (
                <HoursList displayHours={pickupDisplayHours} />
              ) : (
                <div className={styles.fulfillmentUnavailable}>
                  Pickup unavailable
                </div>
              ),
            },
            {
              label: 'Delivery Hours',
              name: 'delivery',
              body: deliveryDisplayHours ? (
                <HoursList displayHours={deliveryDisplayHours} />
              ) : (
                <div className={styles.fulfillmentUnavailable}>
                  Delivery unavailable
                </div>
              ),
            },
          ]}
        />
      </div>
    </ModalFrame>
  );
}

export default HoursModal;
