/* eslint-disable consistent-return, complexity */
import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import { CompanyContext } from 'context/CompanyContext';
import { buildRoute, ROUTES } from 'helpers/routes';
import { getHostnameForMarketplace } from 'helpers/url';

import usePlatform from 'hooks/usePlatform';

function Forgot() {
  const { company = {} } = useContext(CompanyContext);

  const history = useHistory();
  const location = useLocation();

  const queryParams = parse(location.search);

  const { next, restaurant_id: restaurantId } = queryParams;
  const hqId = next || company.id;

  const { isMarketplacePlatform } = usePlatform();

  useEffect(() => {
    // we should redirect to homepage if on MP and don't have hqid or restaurant id
    // OR if the hqId equals 1 (The H Cafe - this would be the case in legacy apps)
    const shouldRedirectToHomePage =
      (isMarketplacePlatform && !hqId && !restaurantId) || hqId === '1';
    if (shouldRedirectToHomePage) {
      const redirectTo = getHostnameForMarketplace();
      return window.location.replace(`https://${redirectTo}${location.search}`);
    }
    const route = restaurantId ? ROUTES.menu : ROUTES.locations;
    const fullRoute = buildRoute({
      hqId,
      restaurantId,
      route: { ...route },
    });
    history.replace(`${fullRoute}${location.search}`);
  }, []);
}

export default Forgot;
