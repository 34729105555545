/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Field } from 'formik';

import {
  creditCardTypeFormattedName,
  creditCardStringToType,
  creditCardTypeToImage,
} from 'helpers/payments';
import { MODAL_TYPE } from 'helpers/modals';
import { isOnPage } from '@chownow/cn-web-utils/url';

import usePlatform from 'hooks/usePlatform';

import { CompanyContext } from 'context/CompanyContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import FormikCheckbox from 'components/FormFields/FormikCheckbox';
import FormikInput from 'components/FormFields/FormikInput';

import iconBlankMarketplace from 'images/icon-creditcard-marketplace.svg';
import iconBlankDirect from 'images/icon-creditcard-direct.svg';

import styles from './styles.module.scss';

type CreditCardNameAPI =
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'discover'
  | 'applepay'
  | 'googlepay'
  | 'jcb'
  | 'diners'
  | 'unionPay';

type CreditCardNameFormatted =
  | 'Visa'
  | 'MasterCard'
  | 'American Express'
  | 'Discover'
  | 'ApplePay'
  | 'GooglePay'
  | 'JCB'
  | 'Diners Club'
  | 'UnionPay';

interface CreditFardFormProps {
  cardId: string;
  isInvalid: boolean;
  isModal: boolean;
  hideSaveCardCheckbox: boolean;
  setFieldValue: (a: string, b: string) => void;
  setTouched: ({ cvv }: { cvv: boolean }) => void;
}

interface ModalContextInterface {
  openModal: (arg: string) => void;
  isModalOpen: boolean;
  closeModal: () => void;
}

function CreditCardForm({
  cardId,
  isInvalid,
  isModal,
  hideSaveCardCheckbox,
  setFieldValue,
  setTouched,
}: CreditFardFormProps) {
  const { isCanadian } = useContext(CompanyContext);
  const { openModal } = useContext<ModalContextInterface>(ModalContext);
  const { user } = useContext(UserContext);

  const location = useLocation();
  const isAccountSettings = isOnPage('account');

  const { isMarketplacePlatform } = usePlatform();

  const [type, setType] = useState<null | CreditCardNameFormatted>(null);

  const iconBlank = isMarketplacePlatform
    ? iconBlankMarketplace
    : iconBlankDirect;

  useEffect(() => {
    // NOTE: if default CC is invalid, trigger form validation
    if (isInvalid) {
      setTouched({ cvv: true });
    }
  }, []);

  useEffect(() => {
    // if on Account Settings, do not show credit card info entered from Checkout.
    if (isAccountSettings) {
      setFieldValue('number', '');
      setFieldValue('expDate', '');
      setFieldValue('cvv', '');
      setFieldValue('address.zip', '');
    }
  }, [location.pathname]);

  function handleChangeCardType(ccType: CreditCardNameAPI) {
    const cardTypeVal = String(creditCardTypeFormattedName[ccType]);

    setFieldValue('type', cardTypeVal);
    setType(cardTypeVal as CreditCardNameFormatted);
  }

  function handleClearField(field: string) {
    setFieldValue(field, '');
  }

  function onFormClick() {
    if (!user.id) {
      openModal(MODAL_TYPE.auth);
    }
  }

  function getIcon() {
    if (!type) return iconBlank;
    const index: string = creditCardStringToType[type];

    const image =
      creditCardTypeToImage[
        index as unknown as keyof typeof creditCardTypeToImage
      ];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return image;
  }

  return (
    <div>
      <div
        className={classNames(styles.formOverlay, { [styles.hide]: !!user.id })}
        onClick={onFormClick}
        role="button"
        data-testid="formOverlay"
      />
      <div>
        <Field
          id="cc-field"
          label="Card Number"
          name="number"
          autoComplete="cc-number"
          icon={getIcon()}
          component={FormikInput}
          onChangeCardType={handleChangeCardType}
          onClear={handleClearField}
        />
      </div>
      <div
        className={classNames(styles.inlineFields, {
          [styles.inlineWrap]: isModal,
        })}
      >
        <Field
          type="tel"
          label="Exp"
          name="expDate"
          autoComplete="cc-exp"
          placeholder="MM / YY"
          maxLength="5"
          component={FormikInput}
          onClear={handleClearField}
        />
        <Field
          type="tel"
          label="CVV"
          name="cvv"
          maxLength="4"
          autoComplete="cc-csc"
          component={FormikInput}
          onClear={handleClearField}
        />
        <Field
          type={isCanadian ? 'text' : 'tel'}
          label={isCanadian ? 'Postal Code' : 'Billing Zip Code'}
          name="address.zip"
          maxLength="7"
          autoComplete="postal-code"
          component={FormikInput}
          onClear={handleClearField}
        />
      </div>
      {!isAccountSettings && !hideSaveCardCheckbox && (
        <Field
          name="is_visible"
          id={`is_visible_${cardId}`}
          label="Remember card for future use."
          component={FormikCheckbox}
        />
      )}
    </div>
  );
}

export default CreditCardForm;
