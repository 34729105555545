import { Action, RequestAction } from 'helpers/redux';

const namespace = 'modules/restaurant/';

export const resetRestaurant = Action(`${namespace}RESET_RESTAURANT`);
export const setSelectedRestaurantId = Action(
  `${namespace}SET_SELECTED_RESTAURANT_ID`
);
export const fetchRestaurantXHR = RequestAction(`${namespace}FETCH_RESTAURANT`);
export const fetchDeliveryRangesXHR = RequestAction(
  `${namespace}FETCH_DELIVERY_RANGES`
);
export const saveRestaurantXHR = RequestAction(`${namespace}SAVE_RESTAURANT`);
