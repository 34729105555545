import React from 'react';

import InfoTooltip from 'components/InfoTooltip';
import TipLineItem from 'components/OrderSummary/OrderTotals/TipLineItem';

import styles from './styles.module.scss';

interface OrderTotalsTipProps {
  getTotalAmount: (totalTip?: number) => string;
  isCanceled: boolean;
  isTipShown: (tip?: number) => boolean;
  managedDeliveryTip?: number;
  restaurantTip?: number;
  totalTip: number;
  styles: { [key: string]: string };
}

export default function OrderTotalsTip({
  getTotalAmount,
  isCanceled,
  isTipShown,
  managedDeliveryTip,
  restaurantTip,
  totalTip,
  styles: parentStyles,
}: OrderTotalsTipProps) {
  return (
    <li className={parentStyles.item}>
      <span className={parentStyles.label} data-testid="TotalTipLabel">
        {isCanceled ? (
          <span className={parentStyles.label}>Total Tip</span>
        ) : (
          <InfoTooltip
            id={styles.totalTip}
            label="Total Tip"
            showIcon={false}
            className={parentStyles.actionableLink}
          >
            {isTipShown(managedDeliveryTip) && (
              <TipLineItem
                label="Driver Tip"
                dataTestId="DriverTip"
                value={managedDeliveryTip || 0}
              />
            )}
            {isTipShown(restaurantTip) && (
              <TipLineItem
                label="Restaurant Tip"
                dataTestId="RestaurantTip"
                value={restaurantTip || 0}
              />
            )}
            <TipLineItem
              className={styles.totalTipLineItem}
              label="Total Tip"
              dataTestId="TotalTip"
              value={totalTip}
            />
          </InfoTooltip>
        )}
      </span>
      <span className={parentStyles.itemValue} data-testid="OuterTotalTipValue">
        {getTotalAmount(totalTip)}
      </span>
    </li>
  );
}
