import { useEffect } from 'react';

import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';

// NOTE: Our integration with BranchIO is tricky.
// The logic for showing the banner is a combination of rendering this
// component AND the "journeys" configured via their dashboard, which can evaluate URLs and metadata we send.
// Ideally, this logic would live in a hook,
// but we render a component so that we can track where the banner is relative to the viewport.

export default function BranchIOBanner({ restaurant, orderId }) {
  const metadata = {
    restaurant_name: restaurant.short_name,
    restaurant_id: !orderId && restaurant.id,
    restaurant_city: restaurant.address.city,
    route: !!orderId && 'receipt_confirmation',
    order_id: orderId || '',
  };

  function handleBranchClick() {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: {
        source: orderId ? 'order_confirmation' : 'menu',
        name: 'branch_chownow_app_banner',
      },
    });
  }

  // initilaize branch when script is ready
  useEffect(() => {
    if (window.branch) {
      window.branch.init(process.env.REACT_APP_BRANCH_IO_KEY, {
        metadata,
      });
      window.branch.setBranchViewData({ data: metadata });
      window.branch.addListener('didClickJourneyCTA', handleBranchClick);
    }

    // remove banner when unmounted
    return () => {
      // super hack for removing transition animation when banner is removed
      const bodyTag = document.getElementsByTagName('body')[0];
      bodyTag.style.transition = '';
      bodyTag.style['margin-top'] = '0';

      // Hide the banner before removing it to avoid seeing banner for a split
      // second on the checkout page before it is removed
      const branchBanner = document.getElementById('branch-banner-iframe');
      if (branchBanner) {
        branchBanner.style.display = 'none';

        // close the banner
        if (window.branch) {
          window.branch.closeJourney();
        }
      }
    };
    /* eslint-enable no-undef */
  }, []);

  // this component doesn't render anything
  return null;
}
