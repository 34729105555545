/* eslint-disable import/prefer-default-export */
import { CustomerAddress } from 'types/customer';

export function getUpdatedAddressList({
  currentUserAddresses,
  updatedUserAddress,
}: {
  currentUserAddresses: CustomerAddress[];
  updatedUserAddress: CustomerAddress;
}): CustomerAddress[] {
  // find user's address that was updated, and update user data with new info
  return currentUserAddresses.map((savedAddress) =>
    savedAddress.id === updatedUserAddress.id
      ? updatedUserAddress
      : savedAddress
  );
}
