import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ABOVE_ORDER_DELIVERY_MAX,
  ABOVE_ORDER_PICKUP_MAX,
  BELOW_ORDER_PICKUP_MIN,
  BELOW_ORDER_DELIVERY_MIN,
  BELOW_PICKUP_MINIMUM_WITH_DISCOUNT,
  BELOW_DELIVERY_MINIMUM_WITH_DISCOUNT,
} from 'helpers/api';

import { getErrorsForOrder } from 'modules/order';

import TagNotice from 'primitives/TagNotice';

import styles from './styles.module.scss';

function BagErrors(props) {
  const { linkTo, isCheckout } = props;

  const errors = useSelector(getErrorsForOrder);

  const ERRORS_TO_DISPLAY = [
    ABOVE_ORDER_DELIVERY_MAX,
    ABOVE_ORDER_PICKUP_MAX,
    BELOW_ORDER_DELIVERY_MIN,
    BELOW_ORDER_PICKUP_MIN,
    BELOW_PICKUP_MINIMUM_WITH_DISCOUNT,
    BELOW_DELIVERY_MINIMUM_WITH_DISCOUNT,
  ];
  const filteredErrors = errors.filter((error) =>
    ERRORS_TO_DISPLAY.includes(error.code)
  );

  function getLabel(error) {
    if (isCheckout) {
      return (
        <span>
          {error.message}.{' '}
          <a href={linkTo} className={styles.backLink}>
            Back to menu
          </a>
        </span>
      );
    }
    return error.message;
  }

  return (
    <div>
      {filteredErrors.map((error) => (
        <div key={error.code}>
          <TagNotice variant="caution">{getLabel(error)}</TagNotice>
        </div>
      ))}
    </div>
  );
}

BagErrors.propTypes = {
  isCheckout: PropTypes.bool,
  linkTo: PropTypes.string,
};

BagErrors.defaultProps = {
  isCheckout: false,
  linkTo: undefined,
};

export default BagErrors;
