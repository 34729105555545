import { FIND_MORE_RESTAURANTS } from 'helpers/constants';

export function shouldShowFindMoreRestaurants(
  isMarketplacePlatform: boolean
): boolean {
  const { utm_campaign: utmCampaign } = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  const isPlaceAction = utmCampaign === FIND_MORE_RESTAURANTS.placeActionParam;
  return isMarketplacePlatform && isPlaceAction;
}

export function getIsNavbarSticky({
  isYelpPlatform,
  isMenu,
  isSticky,
  isDesktop,
}: {
  isDesktop: boolean;
  isMenu: boolean;
  isSticky: boolean;
  isYelpPlatform: boolean;
}): boolean {
  if (isYelpPlatform) {
    // On Yelp Mobile we want Restaurant navbar to be sticky when it wants it,
    // and otherwise always sticky (ie on Checkout)
    if (isMenu) {
      return isSticky;
    }

    // On Yelp Desktop we don't want Checkout navbar to be sticky
    return !isDesktop;
  }

  // always sticky unless restaurant mobile
  return !isMenu || (isMenu && (isSticky || isDesktop));
}
