import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { BASE_SUPPORT_URL } from '@chownow/cn-web-utils/paths';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';

import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

function UnsupportedBrowser(props) {
  const { hqId, isUnableToLoadSite, isEmbedded, isMpRedirect, restoId } = props;

  useEffect(() => {
    if (isMpRedirect) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.viewOpenTabPage,
        attributes: {
          restaurant_brand_id: hqId,
          restaurant_location_id: restoId,
          reason: 'direct_to_marketplace_redirect',
        },
      });
    }
  }, []);

  function getHeadingText() {
    if (isUnableToLoadSite) {
      return isEmbedded ? `Let's get started!` : 'Hang on one second!';
    }
    return 'Browser not supported.';
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>{getHeadingText()}</h1>
      <p className={styles.subtext}>
        {isUnableToLoadSite ? (
          <>
            {isEmbedded
              ? `We need to open a new tab to start your order.`
              : `Looks like your browser doesn't support cookies. You'll need to allow cookies from
                 ChowNow so you can log in and place your order.`}
            <Outlink
              className={styles.btn}
              dataTestId="ConfirmButton"
              to={isEmbedded ? window.location.href : BASE_SUPPORT_URL}
            >
              {isEmbedded ? 'Start Order' : 'Contact Support'}
            </Outlink>
          </>
        ) : (
          <>
            It looks like you&apos;re using a web browser that we do not
            actively support. Please use{' '}
            <Outlink
              to="https://www.google.com/chrome/"
              ariaLabel="Google Chrome Link"
              className={styles.link}
            >
              Google Chrome
            </Outlink>{' '}
            or{' '}
            <Outlink
              to="https://www.apple.com/safari/"
              ariaLabel="Safari Link"
              className={styles.link}
            >
              Safari
            </Outlink>{' '}
            to improve your experience.
          </>
        )}
      </p>
    </div>
  );
}

UnsupportedBrowser.propTypes = {
  hqId: PropTypes.string,
  isEmbedded: PropTypes.bool,
  isMpRedirect: PropTypes.bool,
  isUnableToLoadSite: PropTypes.bool,
  restoId: PropTypes.string,
};

UnsupportedBrowser.defaultProps = {
  hqId: '',
  isEmbedded: false,
  isMpRedirect: false,
  isUnableToLoadSite: false,
  restoId: '',
};

export default UnsupportedBrowser;
