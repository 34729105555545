/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { buildRoute, ROUTES } from 'helpers/routes';
import { parseExpirationDate } from '@chownow/cn-web-utils/date';
import { userMembershipPlanType } from 'helpers/prop-types';
import { MEMBERSHIP_LABEL, MEMBER_NAME } from 'helpers/constants';

import { getRestaurantMembershipPlans } from 'modules/restaurant';

import { ReactComponent as IconGold } from 'images/icon-member-gold.svg';
import { ReactComponent as ArrowRight } from 'images/icon-arrowright.svg';

import styles from './styles.module.scss';

function MembershipBanner(props) {
  const { hqId, restaurantId, isMinimized, isRenewBanner, expiredMembership } =
    props;

  const membershipPlans = useSelector(getRestaurantMembershipPlans);

  const highestMembershipPercent = membershipPlans.reduce(
    (max, plan) => (plan.percent > max ? plan.percent : max),
    0
  );

  const membership =
    expiredMembership &&
    membershipPlans.find((plan) => plan.id === expiredMembership.plan_xid);
  const expirationDate =
    expiredMembership && parseExpirationDate(expiredMembership.expires_at);
  const membershipPlansRoute = buildRoute({
    hqId,
    restaurantId,
    route: ROUTES.membershipPlans,
  });

  function getHeaderText() {
    if (isMinimized) {
      return `Become a ${MEMBER_NAME}`;
    }

    if (isRenewBanner) {
      return `Renew your ${MEMBERSHIP_LABEL} and save ${highestMembershipPercent}% on every order.`;
    }

    return `Support us by becoming a ${MEMBER_NAME} and save up to ${highestMembershipPercent}% on every order.`;
  }

  return (
    <div
      className={classNames(styles.banner, {
        [styles.minimized]: isMinimized,
      })}
    >
      {!isRenewBanner && <IconGold className={styles.icon} />}
      <p className={styles.heading}>{getHeaderText()}</p>
      {!isMinimized && (
        <a
          className={styles.btnBrowse}
          href={membershipPlansRoute}
          target="_blank"
          rel="noreferrer"
        >
          {isRenewBanner ? 'Renew' : 'Browse Plans'}
        </a>
      )}
      {isMinimized && (
        <a href={membershipPlansRoute} target="_blank" rel="noreferrer">
          <ArrowRight data-testid="ArrowRight" />
        </a>
      )}
      {isRenewBanner && !isMinimized && (
        <p className={styles.renew}>
          Your {membership.name.toUpperCase()} Membership Expired on{' '}
          {expirationDate}
        </p>
      )}
    </div>
  );
}

MembershipBanner.propTypes = {
  expiredMembership: userMembershipPlanType,
  hqId: PropTypes.string.isRequired,
  isMinimized: PropTypes.bool,
  isRenewBanner: PropTypes.bool,
  restaurantId: PropTypes.string.isRequired,
};

MembershipBanner.defaultProps = {
  expiredMembership: undefined,
  isMinimized: false,
  isRenewBanner: false,
};

export default MembershipBanner;
