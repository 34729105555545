/* eslint-disable complexity, @typescript-eslint/no-floating-promises, consistent-return */
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import {
  redirectToMarketplaceMenu,
  shouldDirectToWebsiteChannel,
  shouldRedirectToMarketplace,
} from 'helpers/configureRedirects';
import { BACK_BTN_LABELS, NO_DIRECT_LIVE_CHANNEL } from 'helpers/constants';
import { MODAL_TYPE } from 'helpers/modals';
import { buildRoute, ROUTES } from 'helpers/routes';
import { isOnLocations, isOnMenu, isOnPage } from 'helpers/url';

import useIsMobile from 'hooks/useIsMobile';
import usePlatform from 'hooks/usePlatform';

import { CompanyContext } from 'context/CompanyContext';
import { ModalContext } from 'context/ModalContext';

import { resetOrder } from 'modules/order';
import { setSelectedRestaurantId } from 'modules/restaurant';

import { ReactComponent as IconBack } from 'images/icon-arrow-left.svg';
import { ReactComponent as Logo } from 'images/logo.svg';

import DownloadAppLink from './DownloadAppLink';
import FindMoreRestaurants from './FindMoreRestaurants';
import NavMenu from './NavMenu';
import RestaurantName from './RestaurantName';

import { getIsNavbarSticky, shouldShowFindMoreRestaurants } from './helpers';
import styles from './styles.module.scss';

interface NavbarProps {
  address?: { formatted_address: string };
  isSticky?: boolean;
  hasOrderItems?: boolean;
  restaurantName?: string;
}

function Navbar({
  address,
  isSticky = false,
  hasOrderItems = false,
  restaurantName,
}: NavbarProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hqId } = useParams();

  const { locations } = useContext(CompanyContext);
  const { openModal } = useContext(ModalContext);

  const [showLocationDropdown, setShowLocationDropdown] = useState(false);

  const isMobile = useIsMobile();
  const { isMarketplacePlatform, isYelpPlatform } = usePlatform();

  const isLocations = isOnLocations();
  const isMenu = isOnMenu();
  const isMembership = isOnPage('membership') && !isOnPage('checkout'); // membership plans and membership confirmation

  const showFindMoreRestaurants = shouldShowFindMoreRestaurants(
    isMarketplacePlatform
  );
  const showRestaurantName =
    (isLocations || isMenu || isMembership) && !showFindMoreRestaurants;
  const showBackButton = !showRestaurantName && !showFindMoreRestaurants;

  const isNavbarSticky = getIsNavbarSticky({
    isYelpPlatform,
    isMenu,
    isSticky,
    isDesktop: !isMobile,
  });

  async function changeLocation(newRestaurant: { id: string }) {
    const newRestaurantId = newRestaurant?.id;
    // if we have specific restaurant update restaurant info and route to that menu,
    // otherwise go back to location picker
    if (newRestaurantId) {
      if (await shouldRedirectToMarketplace(newRestaurantId, newRestaurant)) {
        setShowLocationDropdown(false);
        // @ts-expect-error remove this once configureRedirects is converted to TS
        return redirectToMarketplaceMenu({
          restoId: newRestaurantId,
          hqId,
          source: 'navbar',
        });
      }

      // @ts-expect-error dispatching TYPE
      dispatch({ type: resetOrder.TYPE });
      dispatch({
        // @ts-expect-error dispatching TYPE
        type: setSelectedRestaurantId.TYPE,
        payload: newRestaurantId,
      });

      const query = shouldDirectToWebsiteChannel(newRestaurant) && {
        name: 'cn_channel',
        value: NO_DIRECT_LIVE_CHANNEL,
      };
      history.push(
        buildRoute({
          hqId,
          restaurantId: newRestaurantId,
          route: ROUTES.menu,
          query,
        })
      );
    } else {
      history.push(buildRoute({ hqId, route: ROUTES.locations }));
    }
  }

  function handleLocationSwitch(newRestaurant: { id: string }) {
    if (hasOrderItems) {
      openModal(MODAL_TYPE.dialog, {
        title: 'Select a Different Location',
        message:
          'Are you sure you want to select a different location? Doing so will clear your current order.',
        confirmLabel: 'Select a Different Location',
        dismissLabel: 'Keep this Order',
        onConfirm: () => changeLocation(newRestaurant),
        isAlert: true,
      });
    } else {
      changeLocation(newRestaurant);
    }
  }

  return (
    <div className={styles.navbar}>
      <div
        className={classNames(styles.navWrapper, {
          [styles.sticky]: isNavbarSticky,
          [styles.noBorder]: isYelpPlatform && !isMobile && !isMenu, // Yelp checkout desktop only
        })}
      >
        <div className={styles.navContent}>
          <div
            className={classNames(styles.contentLeft, {
              [styles.short]: isLocations,
              [styles.addressNoWrap]: isMarketplacePlatform,
            })}
          >
            {isMarketplacePlatform && (
              <a
                href="/discover"
                className={styles.logo}
                data-testid="ChowNowLogo"
              >
                <Logo />
              </a>
            )}
            {showFindMoreRestaurants && (
              <FindMoreRestaurants address={address} />
            )}
            {showRestaurantName && restaurantName && (
              <RestaurantName
                restaurantName={restaurantName}
                showLocationDropdown={showLocationDropdown}
                setShowLocationDropdown={setShowLocationDropdown}
                locations={locations}
                handleLocationSwitch={handleLocationSwitch}
                formattedAddress={address?.formatted_address}
              />
            )}
            {showBackButton && (
              <button
                className={styles.btnBack}
                onClick={() => history.goBack()} // eslint-disable-line @typescript-eslint/no-unsafe-return
                data-testid="Back"
                type="button"
              >
                <IconBack className={styles.iconBack} aria-hidden="true" />
                {BACK_BTN_LABELS.default}
              </button>
            )}
          </div>
          <div className={styles.rightWrap}>
            <DownloadAppLink />
            <NavMenu
              isMultiLocation={locations.length > 1}
              handleLocationSwitch={handleLocationSwitch}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
