import React from 'react';
import {
  BASE_CHOWNOW_URL,
  BASE_SUPPORT_URL,
  TERMS_URL,
  PRIVACY_URL,
  CCPA_URL,
  CHOWNOW_URL,
} from '@chownow/cn-web-utils/paths';

import Outlink from 'primitives/Outlink';

import usePlatform from 'hooks/usePlatform';

import { ReactComponent as IconSocialInstagram } from 'images/Icon-SocialInstagram.svg';
import { ReactComponent as IconSocialFacebook } from 'images/Icon-SocialFacebook.svg';
import { ReactComponent as IconSocialTwitter } from 'images/Icon-SocialTwitter.svg';
import { ReactComponent as IconPoweredLogo } from 'images/icon-poweredby.svg';

import styles from './styles.module.scss';

export default function Footer() {
  const { isMarketplacePlatform } = usePlatform();

  const poweredOutlink = (
    <Outlink to={CHOWNOW_URL} ariaLabel="Powered by ChowNow">
      <IconPoweredLogo />
    </Outlink>
  );

  return (
    <footer className={styles.footer} data-testid="Footer">
      <div className={styles.footerContent}>
        {!isMarketplacePlatform && (
          <div className={styles.logoWrapper}>{poweredOutlink}</div>
        )}
        <div className={styles.textOutlinks}>
          <Outlink ariaLabel="About Us" to={`${BASE_CHOWNOW_URL}/about`}>
            About Us
          </Outlink>
          <Outlink ariaLabel="Help" to={`${BASE_SUPPORT_URL}/customer/s/`}>
            Help
          </Outlink>
          <Outlink ariaLabel="Terms of Use" to={TERMS_URL}>
            Terms of Use
          </Outlink>
          <Outlink ariaLabel="Privacy Policy" to={PRIVACY_URL}>
            Privacy Policy
          </Outlink>
          <Outlink
            ariaLabel="Do Not Sell or Share My Personal Information"
            to={CCPA_URL}
          >
            Do Not Sell or Share My Personal Information
          </Outlink>
          <Outlink
            ariaLabel="Limit The Use Of My Sensitive Personal Information"
            to={CCPA_URL}
          >
            Limit The Use Of My Sensitive Personal Information
          </Outlink>
        </div>
        {isMarketplacePlatform ? (
          <div className={styles.socialLinks}>
            <Outlink
              ariaLabel="Instagram"
              className={styles.socialIcon}
              to="https://instagram.com/ChowNow/"
            >
              <IconSocialInstagram />
            </Outlink>
            <Outlink
              ariaLabel="Twitter"
              className={styles.socialIcon}
              to="https://twitter.com/ChowNow/"
            >
              <IconSocialTwitter />
            </Outlink>
            <Outlink
              ariaLabel="Facebook"
              className={styles.socialIcon}
              to="https://facebook.com/ChowNow/"
            >
              <IconSocialFacebook />
            </Outlink>
          </div>
        ) : (
          <div className={styles.mobileLogo}>{poweredOutlink}</div>
        )}
      </div>
    </footer>
  );
}
