import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import CurrencyInput from './CurrencyInput';

import styles from './styles.module.scss';

const MAX_TIP = 10000;

function TipModal({ customTip = 0, onSubmitTip }) {
  const inputRef = useRef(null);
  const [activeCustomTip, setCustomTip] = useState(
    customTip ? customTip.toFixed(2) : 0
  );

  useEffect(() => {
    inputRef.current.getElementsByTagName('input')[0].focus();
  }, []);

  function handleTipChange(_, value) {
    setCustomTip(value);
  }

  return (
    <Modal>
      <ModalHeader title="Add a Tip" />
      <div>
        <p className={styles.prompt}>How much would you like to tip?</p>
        <div className={styles.tipWrapper} ref={inputRef}>
          <CurrencyInput
            type="tel"
            className={styles.customTip}
            placeholder="$0.00"
            value={activeCustomTip}
            onInputChange={handleTipChange}
            autoComplete="false"
            max={MAX_TIP}
          />
        </div>
      </div>
      <ModalFooter>
        <ModalFooterButton
          handleClick={() => onSubmitTip(activeCustomTip)}
          dataTestId="ApplyCustomTip"
        >
          Apply
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
}

TipModal.propTypes = {
  customTip: PropTypes.number,
  onSubmitTip: PropTypes.func.isRequired,
};

TipModal.defaultProps = {
  customTip: 0,
};

export default TipModal;
