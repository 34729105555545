import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize, get } from 'lodash';

import { ModalContext } from 'context/ModalContext';

import { getFulfillmentAvailability } from 'modules/restaurant';
import { getFulfillmentMethod, updateOrderRequest } from 'modules/order';

import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import DateTimePicker from 'components/DateTimePicker';

import styles from './styles.module.scss';

function TimeUnavailableModal() {
  const { closeModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  const [selectedWhen, setSelectedWhen] = useState();

  const fulfillmentMethod = useSelector(getFulfillmentMethod);
  const fulfillmentAvailability = useSelector(getFulfillmentAvailability);

  const isSelectedFulfillmentAvailableNow = get(
    fulfillmentAvailability,
    `is${capitalize(fulfillmentMethod)}AvailableNow`
  );

  function handleUpdateWhen() {
    dispatch({
      type: updateOrderRequest.TYPE,
      payload: {
        when: selectedWhen,
      },
    });

    closeModal();
  }

  return (
    <Modal shouldShowCloseIcon={false}>
      <ModalHeader title="We need a little extra time" />
      <div>
        <p className={styles.description}>
          We&apos;re sorry, the time you selected is no longer available. Please
          select a new {fulfillmentMethod} time for your order.
        </p>
        <DateTimePicker
          isErrorModal
          fulfillMethod={fulfillmentMethod}
          onWhenChange={setSelectedWhen}
          isSelectedFulfillmentAvailableNow={isSelectedFulfillmentAvailableNow}
          showLabels
        />
      </div>
      <ModalFooter>
        <ModalFooterButton
          handleClick={handleUpdateWhen}
          dataTestId="UpdateFulfillment"
          disabled={selectedWhen === undefined}
        >
          Update
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
}

export default TimeUnavailableModal;
