/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';

import { PLATFORM } from 'helpers/constants';

/*
  Context used for storing which platform we are viewing.
  Used by App.js to apply a CSS class to the root of the app with the platform theme.
  Also used by components to customize the look or experience of a component
  based on which platform we are viewing.
*/

interface PlatformProviderProps {
  children: React.ReactNode;
  platform?: string;
}

export type PlatformContextProps = {
  platform?: string;
  setPlatform: (platform: string) => void;
};

const PlatformContext = createContext<PlatformContextProps>({
  platform: PLATFORM.direct,
  setPlatform: () => {},
});

export function PlatformProvider({
  children,
  platform,
}: PlatformProviderProps) {
  const [platformState, setPlatFormState] = useState(platform);

  return (
    <PlatformContext.Provider
      value={{
        platform: platformState,
        setPlatform: setPlatFormState,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
}

export default PlatformContext;
