import { ATTRIBUTES as A } from 'helpers/analyticsAttributes';
import { ANALYTICS_EVENT_NAME as EVENTS, PLATFORM } from 'helpers/constants';

const CommonSchema = {
  events: {
    [EVENTS.selectMenuItem]: [
      A.menuId,
      A.menuItemId,
      A.menuItemSource,
      A.menuItemLocation,
      A.restaurantLocationId,
      A.restaurantBrandId,
      A.itemHasPhoto,
      A.itemIsPopularItem,
      A.isEmbeddedSite,
      A.isDirectToMarketplaceRedirect,
    ],
  },
};

// Modify these as-needed if we find there are different attributes per-platform
export const DirectSchema = {
  platform: PLATFORM.direct,
  events: {
    ...CommonSchema.events,
    [EVENTS.addToCart]: [
      A.itemHasPhoto,
      A.itemHasNestedMods,
      A.menuId,
      A.menuItemSource,
      A.menuItemLocation,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderType,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.isDelete,
      A.isEmbeddedSite,
      A.isCartUpdate,
      A.isDirectToMarketplaceRedirect,
    ],
    [EVENTS.checkout]: [
      A.deliveryFeeAmount,
      A.hasDeliveryFee,
      A.hasMiscFee,
      A.isDirectToMarketplaceRedirect,
      A.isEmbeddedSite,
      A.managedDeliveryId,
      A.menuId,
      A.miscFeeAmount,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderIsReorder,
      A.orderType,
      A.paymentTypeDisplayed,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.totalAmount,
    ],
    [EVENTS.purchaseFailure]: [
      A.errorReason,
      A.paymentType,
      A.isEmbeddedSite,
      A.isDirectToMarketplaceRedirect,
      A.restaurantLocationId,
    ],
    [EVENTS.viewMenu]: [
      A.menuId,
      A.hasCoverPhoto,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.hasAutoPromo,
      A.url,
      A.isEmbeddedSite,
      A.isDirectToMarketplaceRedirect,
    ],
  },
};

export const MarketplaceSchema = {
  platform: PLATFORM.marketplace,
  events: {
    ...CommonSchema.events,
    [EVENTS.addToCart]: [
      A.itemHasPhoto,
      A.itemHasNestedMods,
      A.menuId,
      A.menuItemSource,
      A.menuItemLocation,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderType,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.isDelete,
      A.isEmbeddedSite,
      A.isCartUpdate,
      A.isDirectToMarketplaceRedirect,
    ],
    [EVENTS.checkout]: [
      A.deliveryFeeAmount,
      A.hasDeliveryFee,
      A.hasMarketplaceDinerFee,
      A.hasMiscFee,
      A.isDirectToMarketplaceRedirect,
      A.managedDeliveryId,
      A.marketplaceDinerFeeAmount,
      A.menuId,
      A.miscFeeAmount,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderIsReorder,
      A.orderType,
      A.paymentTypeDisplayed,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.totalAmount,
    ],
    [EVENTS.purchaseFailure]: [
      A.errorReason,
      A.paymentType,
      A.isDirectToMarketplaceRedirect,
      A.restaurantLocationId,
    ],
    [EVENTS.selectSaveRestaurant]: [A.isSaved, A.restaurantLocationId],
    [EVENTS.viewMenu]: [
      A.menuId,
      A.hasCoverPhoto,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.hasAutoPromo,
      A.url,
      A.isDirectToMarketplaceRedirect,
    ],
  },
};

export const YelpSchema = {
  platform: PLATFORM.yelp,
  events: {
    ...CommonSchema.events,
    [EVENTS.addToCart]: [
      A.itemHasPhoto,
      A.menuId,
      A.menuItemSource,
      A.menuItemLocation,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderType,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
    ],
    [EVENTS.checkout]: [
      A.deliveryFeeAmount,
      A.hasDeliveryFee,
      A.hasMiscFee,
      A.menuId,
      A.miscFeeAmount,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.orderType,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
    ],
    [EVENTS.purchaseFailure]: [A.errorReason, A.paymentType],
    [EVENTS.selectMenuItem]: [
      A.menuId,
      A.menuItemId,
      A.menuItemSource,
      A.menuItemLocation,
      A.restaurantLocationId,
      A.restaurantBrandId,
      A.itemHasPhoto,
      A.itemIsPopularItem,
    ],
    [EVENTS.viewMenu]: [
      A.menuId,
      A.orderAheadDatetime,
      A.orderIsAsap,
      A.restaurantBrandId,
      A.restaurantLocationCategory,
      A.restaurantLocationId,
      A.restaurantLocationName,
      A.url,
    ],
  },
};
