/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { postToChownowApi } from 'helpers/api';
import { setSelectedAddress } from 'helpers/customer';
import { isAddressValid } from 'helpers/validation';

import AddressBar from 'components/AddressBar';
import Input from 'primitives/Input';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';
import Textarea from 'primitives/Textarea';

import styles from './styles.module.scss';

function AddAddressForm() {
  const { closeModal } = useContext(ModalContext);
  const { setUser, user } = useContext(UserContext);

  const [address, setAddress] = useState();
  const [streetAddress2, setStreetAddress2] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState('');
  const [hasAddressErrors, setHasAddressErrors] = useState();

  async function handleAddAddress() {
    if (!isAddressValid(address)) {
      return setHasAddressErrors(true);
    }

    setHasAddressErrors(false);

    const newAddressFull = {
      ...address,
      street_address2: streetAddress2,
      delivery_instructions: deliveryInstructions,
    };

    const { data, error } = await postToChownowApi({
      endpoint: `customer/${user.id}/delivery-address`,
      body: { ...newAddressFull, is_google_place: true },
    });

    if (!error) {
      setUser({
        ...user,
        delivery: {
          addresses: [
            ...user.delivery.addresses,
            { ...newAddressFull, id: data.id },
          ],
        },
      });
    }

    setSelectedAddress(newAddressFull);

    return closeModal();
  }

  return (
    <>
      <div>
        <label className={styles.label} htmlFor="geosuggest__input">
          Address
        </label>
        <div className={styles.inputWrapper}>
          <AddressBar
            hideDefaultDropdown
            hideLocateMe
            clearOnMount
            onSelectAddress={setAddress}
          />
          {hasAddressErrors && (
            <div className={styles.error}>Incomplete Address</div>
          )}
        </div>
        <Input
          className={styles.input}
          type="text"
          label="Apt / Ste / Floor"
          name="streetAddress2"
          value={streetAddress2}
          onChange={setStreetAddress2}
        />
        <Textarea
          className={styles.input}
          type="text"
          maxLength={140}
          label="Delivery Instructions"
          name="deliveryInstructions"
          value={deliveryInstructions}
          onChange={setDeliveryInstructions}
          placeholder="Add dropoff notes"
        />
      </div>
      <ModalFooter>
        <ModalFooterButton handleClick={handleAddAddress} disabled={!address}>
          Save
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
}

export default AddAddressForm;
