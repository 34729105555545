import React, { useContext } from 'react';

import { ACCOUNT_MODAL_TYPE } from 'helpers/modals';

import { UserContext } from 'context/UserContext';

import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';

import AddAddressForm from './AddAddressForm';
import NameForm from './NameForm';
import PhoneForm from './PhoneForm';
import UpdateAddressForm from './UpdateAddressForm';

function AccountModal({
  accountModalType,
  address,
}: {
  accountModalType: string;
  address?: {
    id: string;
    street_address1: string;
    street_address2?: string;
    delivery_instructions?: string;
  };
}) {
  const { user } = useContext(UserContext);

  const isUpdateName = accountModalType === ACCOUNT_MODAL_TYPE.name;
  const isUpdatePhone = accountModalType === ACCOUNT_MODAL_TYPE.phone;
  const isUpdateAddress = accountModalType === ACCOUNT_MODAL_TYPE.updateAddress;
  const isAddAddress = accountModalType === ACCOUNT_MODAL_TYPE.addAddress;

  function getModalTitle() {
    switch (accountModalType) {
      case ACCOUNT_MODAL_TYPE.name:
        return 'Name';
      case ACCOUNT_MODAL_TYPE.phone:
        return 'Phone Number';
      case ACCOUNT_MODAL_TYPE.updateAddress:
        return 'Edit Address';
      case ACCOUNT_MODAL_TYPE.addAddress:
        return 'Add Address';
      default:
        return '';
    }
  }

  return (
    <Modal>
      <ModalHeader
        title={getModalTitle()}
        subtitle={
          isUpdatePhone
            ? 'Enter your phone number. We’ll send you a verification code to complete the update.'
            : ''
        }
      />
      {isUpdateName && <NameForm user={user} />}
      {isUpdatePhone && <PhoneForm user={user} />}
      {isUpdateAddress && address && <UpdateAddressForm address={address} />}
      {isAddAddress && <AddAddressForm />}
    </Modal>
  );
}

export default AccountModal;
