import React, { ReactNode, useContext } from 'react';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { ReactComponent as CloseIcon } from 'images/icon-close-24.svg';

import styles from './styles.module.scss';

export interface ModalProps {
  centerAlign?: boolean;
  children: ReactNode;
  className?: string;
  isDark?: boolean;
  shouldShowCloseIcon?: boolean;
}

function Modal({
  centerAlign,
  children,
  className = '',
  isDark = false,
  shouldShowCloseIcon = true,
}: ModalProps) {
  const { closeModal } = useContext(ModalContext);

  return (
    <div className={classNames(className, { [styles.dark]: isDark })}>
      {shouldShowCloseIcon && (
        <button
          type="button"
          className={styles.iconWrapper}
          onClick={closeModal}
        >
          <CloseIcon className={styles.iconClose} data-testid="Close" />
        </button>
      )}
      <div
        className={classNames(styles.modal, {
          [styles.center]: centerAlign,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Modal;
