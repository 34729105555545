import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Hearts(props) {
  const { customerInitials, imgSrc, previouslyPlayed } = props;

  const [assetLoaded, setAssetLoaded] = useState(false);

  return (
    <div className={styles.heartsWrapper}>
      <img
        alt=""
        className={styles.heartsAnimation}
        src={imgSrc}
        onLoad={() => {
          setAssetLoaded(true);
        }}
      />
      <div
        className={classNames(styles.initials, [
          {
            [styles.animated]: !previouslyPlayed && assetLoaded,
            [styles.static]: previouslyPlayed,
          },
        ])}
      >
        {customerInitials}
      </div>
    </div>
  );
}

Hearts.propTypes = {
  customerInitials: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  previouslyPlayed: PropTypes.bool.isRequired,
};

export default Hearts;
