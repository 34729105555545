/* eslint-disable complexity */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import breakpoints from 'helpers/breakpoints';

import MenuItem from 'components/MenuItem';

import ButtonIcon from 'primitives/ButtonIcon';

import { ReactComponent as Arrow } from 'images/icon-caret-left.svg';

import styles from './styles.module.scss';

const SMALL_CARD_WIDTH = 272;
const DEFAULT_CARD_WIDTH = 293;

function MenuItemCarousel(props) {
  const { items } = props;

  const carouselSection = useRef();
  const carouselItem = useRef();

  const [firstActiveItem, setFirstActiveItem] = useState(1);
  const [transformPx, setTransformPx] = useState(0);
  const [carouselSectionWidth, setCarouselSectionWidth] = useState();
  const [responsiveCarouselItemWidth, setResponsiveCarouselItemWidth] =
    useState();
  const [isHovering, setIsHovering] = useState(false);

  // small screen = under 1200px
  const isSmallScreen = window.innerWidth <= breakpoints.lgMin;
  // medium screen = above 1200px and below 1440px
  const isMediumScreen =
    window.innerWidth > breakpoints.lgMin &&
    window.innerWidth < breakpoints.xlMin;
  // responsiveCarouselItemWidth applies to menu cards in the medium screen size
  // in that screen size, the cards grow and shrink to fit the column
  const mediumScreenCardWidth = isMediumScreen && responsiveCarouselItemWidth;

  const menuCardWidth = (() => {
    if (isSmallScreen) return SMALL_CARD_WIDTH;
    if (isMediumScreen) return mediumScreenCardWidth + 14; // add gutter - border
    return DEFAULT_CARD_WIDTH;
  })();

  // show arrow buttons for 2 items for small screens
  const smallScreen = window.innerWidth <= breakpoints.lgMin;
  const minItemCount = smallScreen ? 2 : 3;
  const hasMoreThanMin = items.length > minItemCount;
  const shouldShowArrows =
    hasMoreThanMin ||
    (items.length > 1 && carouselSectionWidth < menuCardWidth * 2);

  // if 2 items can fit in container width, stop arrow scroll when firstActiveItem is
  // third to last in array, otherwise stop with last item
  const lastFirstActiveItem =
    carouselSectionWidth / 2 > menuCardWidth ? items.length - 2 : items.length;

  useEffect(() => {
    if (carouselSection.current) {
      setCarouselSectionWidth(carouselSection.current?.clientWidth);
    }
  }, [carouselSection]);

  useEffect(() => {
    if (carouselItem.current) {
      setResponsiveCarouselItemWidth(
        isMediumScreen
          ? carouselItem.current.clientWidth + 2
          : carouselItem.current.clientWidth
      );
    }
  }, [carouselItem]);

  function handleResize() {
    setCarouselSectionWidth(carouselSection.current.clientWidth);
    setResponsiveCarouselItemWidth(
      isMediumScreen
        ? carouselItem.current.clientWidth + 2
        : carouselItem.current.clientWidth
    );
    setTransformPx(0); // reset carousel to first card on any resize to prevent weird states
    setFirstActiveItem(1); // reset first active item to 1 again
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleNavigateCarouselAnalytics() {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.navigateCarousel,
      attributes: {
        source: 'menu',
        carousel_name: 'diner_recently_ordered_items',
      },
    });
  }

  function handleCarouselBack() {
    if (firstActiveItem === 1) return;

    handleNavigateCarouselAnalytics();
    setFirstActiveItem(firstActiveItem - 1);
    setTransformPx(transformPx + menuCardWidth);
  }

  function handleCarouselForward() {
    if (firstActiveItem === lastFirstActiveItem) return;

    handleNavigateCarouselAnalytics();
    setFirstActiveItem(firstActiveItem + 1);
    setTransformPx(transformPx - menuCardWidth);
  }

  function toggleArrows(e) {
    e.preventDefault(); // needed so arrows show on hover again after user closes item modal
    setIsHovering(!isHovering);
  }

  return (
    <div
      className={styles.section}
      ref={carouselSection}
      onMouseEnter={toggleArrows}
      onMouseLeave={toggleArrows}
    >
      <ul className={styles.carouselWrapper}>
        {items.map((item, index) => (
          <div
            className={classNames(styles.item, {
              // styling for when there are only 2 items
              [styles.twoItemsStyling]: items.length === 2,
            })}
            style={{
              transform: `translateX(${transformPx}px)`,
              transition: 'transform 0.8s cubic-bezier(0.22, 1, 0.36, 1)',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={item.id + index} // FIXME: Once BE ticket CN-22917 we want to update this
            ref={carouselItem}
          >
            <MenuItem item={item} itemIndex={index} isRecentlyOrdered />
          </div>
        ))}
      </ul>
      <div
        className={classNames(styles.buttonWrapper, {
          [styles.showArrows]: isHovering,
        })}
      >
        {shouldShowArrows && (
          <>
            <ButtonIcon
              className={classNames(styles.iconWrapper, {
                [styles.disabled]: firstActiveItem === 1,
              })}
              onClick={handleCarouselBack}
            >
              <Arrow />
            </ButtonIcon>
            <ButtonIcon
              className={classNames(styles.iconWrapper, styles.right, {
                [styles.disabled]: firstActiveItem === lastFirstActiveItem,
              })}
              onClick={handleCarouselForward}
            >
              <Arrow />
            </ButtonIcon>
          </>
        )}
      </div>
    </div>
  );
}

MenuItemCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
};

MenuItemCarousel.defaultProps = {
  items: [],
};

export default MenuItemCarousel;
