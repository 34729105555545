import React from 'react';
import PropTypes from 'prop-types';

import { VEHICLE_COLORS, VEHICLE_TYPES } from 'helpers/constants';

import SelectStandard from 'primitives/SelectStandard';
import Tag from 'primitives/Tag';

import { ReactComponent as IconCaution } from 'images/icon-caution.svg';

import styles from './styles.module.scss';

function VehicleSelect(props) {
  const { activeColor, activeType, hasError, setActiveColor, setActiveType } =
    props;

  return (
    <>
      <div className={styles.sectionTitle}>
        Vehicle Details
        <Tag icon={hasError && <IconCaution />} isCaution={hasError}>
          Required
        </Tag>
      </div>
      <SelectStandard
        onChange={setActiveColor}
        value={activeColor || ''}
        className={styles.topSelect}
      >
        <option value="" disabled>
          Color
        </option>
        {VEHICLE_COLORS.map((color) => (
          <option value={color.value} key={color.value}>
            {color.label}
          </option>
        ))}
      </SelectStandard>
      <SelectStandard onChange={setActiveType} value={activeType || ''}>
        <option value="" disabled>
          Type
        </option>
        {VEHICLE_TYPES.map((type) => (
          <option value={type.value} key={type.value}>
            {type.label}
          </option>
        ))}
      </SelectStandard>
    </>
  );
}

VehicleSelect.propTypes = {
  activeColor: PropTypes.string,
  activeType: PropTypes.string,
  hasError: PropTypes.bool,
  setActiveColor: PropTypes.func.isRequired,
  setActiveType: PropTypes.func.isRequired,
};

VehicleSelect.defaultProps = {
  activeColor: undefined,
  activeType: undefined,
  hasError: false,
};

export default VehicleSelect;
