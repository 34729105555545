import React from 'react';
import classnames from 'classnames';

import Button from 'primitives/Button';

import styles from './styles.module.scss';

interface ModalFooterButtonProps {
  children: string;
  dataTestId?: string;
  disabled?: boolean;
  handleClick: () => void;
  isFixed?: boolean;
  isSecondary?: boolean;
}

function ModalFooterButton({
  children,
  dataTestId,
  disabled = false,
  handleClick,
  isFixed = false,
  isSecondary = false,
}: ModalFooterButtonProps) {
  return (
    <Button
      className={classnames({
        [styles.secondary]: isSecondary,
        [styles.fixed]: isFixed,
      })}
      onClick={handleClick}
      isFullWidth
      variant={isSecondary ? 'outline' : 'fill'}
      dataTestId={dataTestId}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default ModalFooterButton;
