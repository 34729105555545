import { useState, useEffect } from 'react';
import { getFromChownowApi } from '@chownow/cn-web-utils/api';

function useFetchManifest() {
  const [manifestData, setManifestData] = useState(false);

  useEffect(() => {
    async function fetchManifest() {
      const { data, error } = await getFromChownowApi('web/manifest');
      if (!error) {
        setManifestData(data);
      }
    }
    fetchManifest();
  }, []);

  return manifestData;
}

export default useFetchManifest;
