/* eslint-disable max-len */
import React from 'react';

export default function ChatIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1398_86)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2C0 1.5 0.5 1 1 1H15C15.5 1 16 1.5 16 2V13C16 13.5 15.5 14 15 14C14.5 14 14.5 14 14.5 14H9.81806L6.2947 16.9062C6.14266 17.0172 5.94122 17.0333 5.77345 16.9481C5.60568 16.8628 5.5 16.6905 5.5 16.5023V14H1C0.5 14 0 13.5 0 13V7.5V2ZM1 2V7.5V13H6.5V13.5V15.5186L9.56307 13H9.81806H15V2H1Z"
          fill="#142138"
        />
        <path
          d="M5.75 7.75C5.75 8.16421 5.41421 8.5 5 8.5C4.58579 8.5 4.25 8.16421 4.25 7.75C4.25 7.33579 4.58579 7 5 7C5.41421 7 5.75 7.33579 5.75 7.75Z"
          fill="#142138"
        />
        <path
          d="M8.75 7.75C8.75 8.16421 8.41421 8.5 8 8.5C7.58579 8.5 7.25 8.16421 7.25 7.75C7.25 7.33579 7.58579 7 8 7C8.41421 7 8.75 7.33579 8.75 7.75Z"
          fill="#142138"
        />
        <path
          d="M11.75 7.75C11.75 8.16421 11.4142 8.5 11 8.5C10.5858 8.5 10.25 8.16421 10.25 7.75C10.25 7.33579 10.5858 7 11 7C11.4142 7 11.75 7.33579 11.75 7.75Z"
          fill="#142138"
        />
      </g>
      <defs>
        <clipPath id="clip0_1398_86">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
