import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export default function Delivery({
  restaurant: {
    address: {
      city,
      street_address1: streetAddress1,
      street_address2: streetAddress2,
      state,
    },
    short_name: shortName,
  },
  deliveryAddress,
  deliveryInstructions,
  isOrderFulfilled,
}) {
  const classes = {
    topDot: classNames(styles.topDot, {
      [styles.active]: !isOrderFulfilled,
    }),
    bottomDot: classNames(styles.bottomDot, {
      [styles.active]: isOrderFulfilled,
    }),
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.restaurantWrapper}>
          <div>
            <div className={classes.topDot} />
            <div className={styles.topConnector} />
          </div>
          <div className={styles.addressWrapperTop}>
            <h3 className={styles.header}>{shortName}</h3>
            <div
              className={styles.address}
              data-testid="Address"
            >{`${streetAddress1}, ${city}, ${state}`}</div>
            {streetAddress2 && (
              <div className={styles.address}> {streetAddress2}</div>
            )}
          </div>
        </div>

        <div className={styles.restaurantWrapper}>
          <div>
            <div className={classes.bottomDot} />
            <div className={styles.bottomConnector} />
          </div>
          <div className={styles.addressWrapperBottom}>
            <h3 className={styles.header}>Delivery Location</h3>
            <div
              className={styles.address}
              data-testid="DeliveryAddress"
            >{`${deliveryAddress.street_address1}, ${deliveryAddress.city}, ${deliveryAddress.state}`}</div>
            {deliveryAddress.street_address2 && (
              <div className={styles.address}>
                {deliveryAddress.street_address2}
              </div>
            )}
          </div>
        </div>
        {deliveryInstructions && (
          <p
            data-testid="DeliveryInstructions"
            className={styles.deliveryInstructions}
          >
            {deliveryInstructions}
          </p>
        )}
      </div>
    </div>
  );
}

Delivery.propTypes = {
  deliveryAddress: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    street_address1: PropTypes.string,
    street_address2: PropTypes.string,
  }).isRequired,
  deliveryInstructions: PropTypes.string,
  isOrderFulfilled: PropTypes.bool.isRequired,
  restaurant: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      street_address1: PropTypes.string,
      street_address2: PropTypes.string,
    }).isRequired,
    short_name: PropTypes.string.isRequired,
  }).isRequired,
};

Delivery.defaultProps = {
  deliveryInstructions: undefined,
};
