import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import {
  getUrlQualifiesForRedirectToMarketplace,
  redirectToMarketplaceHomepage,
} from 'helpers/configureRedirects';

import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';

import Footer from 'components/Footer';
import LoadingSpinner from 'primitives/LoadingSpinner';

import { ReactComponent as ImageNotFound } from 'images/notfound.svg';

import styles from './styles.module.scss';

function NotFound({ isNotOnMP }) {
  const shouldRedirectToMarketplace = getUrlQualifiesForRedirectToMarketplace({
    url: window.location.href,
  });

  useEffect(() => {
    // Don't log 404 error page view if redirecting to MP
    if (!shouldRedirectToMarketplace) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.viewErrorPage,
        attributes: {
          type: isNotOnMP ? 'marketplace_disabled_page' : '404',
          url: window.location.href,
        },
      });
    }
  }, []);

  if (shouldRedirectToMarketplace) {
    redirectToMarketplaceHomepage();
    // if landing on direct base url, instead of showing 404 page, redirect users to MP where there can find another restaurant

    // need to show loading spinner before redirecting to prevent flash of Support page
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.notFoundWrapper}>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className={styles.container}>
        <ImageNotFound className={styles.image} />
        <p className={styles.headline}>
          Oops! Something is not right.
          <br />
          We can&apos;t find the page you&apos;re looking for.
        </p>
        <a href="/" className={styles.button}>
          Go to Homepage
        </a>
      </div>
      <Footer />
    </div>
  );
}

NotFound.propTypes = {
  isNotOnMP: PropTypes.bool,
};

NotFound.defaultProps = {
  isNotOnMP: false,
};

export default NotFound;
