/* eslint-disable complexity, no-param-reassign, consistent-return, prefer-template */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { ModalContext } from 'context/ModalContext';

import { formatSimplePhone, onlyNums } from 'helpers/format';
import { AUTH_MODAL_TYPE, MODAL_TYPE } from 'helpers/modals';
import { phoneValidator } from 'helpers/validation';

import Input from 'primitives/Input';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

export function formatPhone(phone, focused) {
  if (focused) return phone.replace(/[^\d]/g, '');

  return formatSimplePhone(phone);
}

function PhoneForm(props) {
  const { user } = props;

  const { closeModal, openModal } = useContext(ModalContext);

  const [phoneNumber, setPhoneNumber] = useState(user.phone?.number || '');
  const [errors, setErrors] = useState();
  const [apiError, setApiError] = useState([]);
  const [focused, setFocused] = useState(false);

  async function handleUpdate() {
    setApiError([]);
    const apiValues = {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: {
        number: onlyNums(phoneNumber),
      },
    };

    const validationErrors = phoneValidator(apiValues);
    setErrors(validationErrors);

    if (isEmpty(validationErrors)) {
      await closeModal();

      openModal(MODAL_TYPE.auth, {
        activeAuthModal: AUTH_MODAL_TYPE.verification,
        email: user.email,
        phone: onlyNums(phoneNumber),
        userId: user.id,
      });
    }
  }

  function handleChange(value) {
    if (onlyNums(value).length > 10) return;
    setPhoneNumber(onlyNums(value));
  }

  return (
    <>
      <Input
        label="Phone Number"
        name="phoneNumber"
        value={formatPhone(phoneNumber, focused)}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type="tel"
        placeholder="555-555-5555"
        errorText={errors?.phone?.number || apiError[0]?.message}
      />
      <ModalFooter>
        <ModalFooterButton
          disabled={!phoneNumber || phoneNumber === user.phone?.number}
          handleClick={handleUpdate}
        >
          Update
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
}

PhoneForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.shape({ number: PropTypes.string }),
    email: PropTypes.string,
  }).isRequired,
};

export default PhoneForm;
