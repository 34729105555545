import React from 'react';
import PropTypes from 'prop-types';

import { prettifyPhoneNumber } from '@chownow/cn-web-utils/format';

import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

export default function PickupAndCurbside({ restaurant, isOrderCancelled }) {
  const {
    address: {
      city,
      formatted_address: formattedAddress,
      state,
      street_address1: streetAddress1,
      street_address2: streetAddress2,
    },
    phone,
    short_name: shortName,
  } = restaurant;
  const encodedURI = encodeURIComponent(formattedAddress);
  const directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${encodedURI}`;

  return (
    <div>
      <ul>
        <li className={styles.header}>Pickup Location</li>
        <li className={styles.addressLine}>{shortName}</li>
        <li className={styles.addressLine} data-testid="Address">
          <span>{`${streetAddress1}, ${city}, ${state}`}</span>
        </li>
        {streetAddress2 && (
          <li className={styles.addressLine}>{streetAddress2}</li>
        )}
        <li className={styles.addressLine}>
          <a
            className={styles.phone}
            data-testid="RestaurantPhone"
            href={`tel:${phone}`}
          >
            {prettifyPhoneNumber(phone)}
          </a>
        </li>
      </ul>

      {!isOrderCancelled && (
        <Outlink className={styles.outlink} to={directionsLink}>
          Get Directions
        </Outlink>
      )}
    </div>
  );
}

PickupAndCurbside.propTypes = {
  isOrderCancelled: PropTypes.bool.isRequired,
  restaurant: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      formatted_address: PropTypes.string.isRequired,
      state: PropTypes.string,
      street_address1: PropTypes.string,
      street_address2: PropTypes.string,
    }).isRequired,
    phone: PropTypes.string.isRequired,
    short_name: PropTypes.string.isRequired,
  }).isRequired,
};
