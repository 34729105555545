import { formatClosure } from 'helpers/format';
import { FULFILLMENT_METHODS } from 'helpers/order';

export interface ClosureType {
  description: string;
  from: string;
  to: string;
  fulfill_method?: string | null;
}

export function getClosureText(closure: ClosureType): string {
  const from = formatClosure(closure.from);
  const to = formatClosure(closure.to);

  const isOnlyDelivery =
    closure.fulfill_method === FULFILLMENT_METHODS.delivery;
  const methodUnavailable = isOnlyDelivery ? ' (Delivery only)' : '';

  return `${from} - ${to}${methodUnavailable}`;
}
