import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { userMembershipPlanType } from 'helpers/prop-types';
import { ReactComponent as BadgeGold } from 'images/icon-member-gold.svg';
import { ReactComponent as BadgeSilver } from 'images/icon-member-silver.svg';
import { ReactComponent as BadgeBronze } from 'images/icon-member-bronze.svg';
import styles from './styles.module.scss';

const Badges = {
  Gold: <BadgeGold />,
  Silver: <BadgeSilver />,
  Bronze: <BadgeBronze />,
};

function MembershipBadge(props) {
  const { userMembershipPlan = {}, withName, isAuthMenu } = props;
  const { name } = userMembershipPlan;

  return (
    <div
      className={classNames(styles.membershipBadge, {
        [styles.withName]: withName,
        [styles.small]: isAuthMenu,
      })}
    >
      {Badges[name]}
      {withName && <div>You&apos;re a {name.toLowerCase()} member</div>}
    </div>
  );
}

MembershipBadge.propTypes = {
  isAuthMenu: PropTypes.bool,
  userMembershipPlan: userMembershipPlanType.isRequired,
  withName: PropTypes.bool,
};

MembershipBadge.defaultProps = {
  isAuthMenu: false,
  withName: false,
};

export default MembershipBadge;
