/* eslint-disable react/jsx-no-useless-fragment,complexity */
import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import SaveButton from 'components/SaveButton';

import { ModalContext } from 'context/ModalContext';

import { MODAL_TYPE } from 'helpers/modals';
import { restaurantBaseType } from 'helpers/prop-types';
import { getOpenTime } from 'helpers/date';

import useIsMobile from 'hooks/useIsMobile';
import usePlatform from 'hooks/usePlatform';
import useRestaurantSaving from 'hooks/featureFlags/useShowRestaurantSaving';

import { ReactComponent as PhotoError } from 'images/photo-error.svg';
import { ReactComponent as IconPoweredBy } from 'images/icon-poweredby.svg';

import styles from './styles.module.scss';

function MenuBanner(props) {
  const { restaurant } = props;

  const [hasError, setHasError] = useState(false);
  const isMobile = useIsMobile();

  const hasBannerPhoto = !!restaurant.media?.cover_image_url;

  const { openModal } = useContext(ModalContext);
  const { isChowNowPlatform, isMarketplacePlatform } = usePlatform();

  const canSaveRestaurants = useRestaurantSaving();
  const canSaveThisRestaurant =
    canSaveRestaurants && isMarketplacePlatform && restaurant;

  function getImageSrc() {
    const photoUrl = restaurant.media.cover_image_url;
    // will want to add back height to options once image quality is improved from cloudflare
    const options = {
      quality: 85,
      fit: 'contain',
    };
    const params = new URLSearchParams(options).toString();

    return `${photoUrl}&${params}`;
  }

  function handleShowHoursModal() {
    openModal(MODAL_TYPE.hours);
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.withImage]: hasBannerPhoto,
      })}
    >
      {hasBannerPhoto && (
        <div className={styles.imageContainer}>
          {!hasError ? (
            <img
              src={getImageSrc()}
              alt={`${restaurant.short_name} food banner`}
              className={styles.image}
              onError={() => {
                setHasError(true);
              }}
            />
          ) : (
            <PhotoError />
          )}
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div
          className={classNames(styles.restaurantContent, {
            [styles.fullWidth]: !isChowNowPlatform,
          })}
        >
          <h1 className={styles.restaurantName}>{restaurant.short_name}</h1>
          {isChowNowPlatform ? (
            <>
              <div className={styles.restaurantInfoWrapper}>
                <div className={styles.restaurantInfo}>
                  <span className={styles.address}>
                    {restaurant.address.street_address1}
                  </span>
                  <span className={classNames([styles.closeTime, styles.dot])}>
                    {getOpenTime(restaurant)}
                  </span>
                  <button
                    className={classNames(styles.hoursButton, {
                      [styles.dot]: true,
                      [styles.hasSaveButton]: canSaveThisRestaurant,
                    })}
                    onClick={handleShowHoursModal}
                    data-testid="HoursMapButton"
                    aria-label="show hours modal"
                    type="button"
                  >
                    More Info
                  </button>
                </div>
                {canSaveThisRestaurant && (
                  <div className={styles.restaurantActions}>
                    <SaveButton
                      className={styles.saveButton}
                      restaurant={restaurant}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            isMobile && (
              <div className={styles.iconsWrapper}>
                <IconPoweredBy />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

MenuBanner.propTypes = {
  restaurant: restaurantBaseType,
};

MenuBanner.defaultProps = {
  restaurant: {},
};

export default MenuBanner;
