/* eslint-disable complexity */
import React from 'react';
import { format } from 'date-fns';
import { partial } from 'lodash';

import {
  ORDER_STATUSES,
  getOrderReadyTime,
  getEstimationText,
  getOrderTimeEstimate,
} from 'helpers/order';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { formatFulfillMethod } from 'helpers/format';
import { formatMoney } from '@chownow/cn-web-utils/format';
import { KEY_VALUES } from 'helpers/constants';

import ProgressBar from 'components/ProgressBar';
import Tag from 'primitives/Tag';

import styles from './styles.module.scss';

function formatOrderDate(order) {
  const { requested_fulfillment: requestedFulfillment, created_at: createdAt } =
    order.fulfillment_times;
  const date = getDateForApiDateStr(requestedFulfillment || createdAt);
  const currentYear = new Date().getFullYear();
  const dateYear = format(date, 'yyyy');

  return dateYear < currentYear
    ? format(date, 'EEE, MMM d, yyyy')
    : format(date, 'EEE, MMM d');
}

function OrderEntries({ onOrderClick, orders, current }) {
  return orders.map((order) => {
    const isCanceled = order.status === ORDER_STATUSES.canceled;

    const orderReadyTime = getOrderReadyTime(order);

    return (
      <div
        className={styles.entryWrapper}
        data-testid={current ? 'CurrentOrder' : 'PastOrder'}
        onClick={partial(onOrderClick, order)}
        onKeyDown={(e) => {
          if (e.key === KEY_VALUES.enter) onOrderClick(order);
        }}
        role="button"
        tabIndex="0"
      >
        <div className={styles.tagWrapper}>
          <Tag isCaution={isCanceled} dataTestId="OrderHeader">
            {isCanceled
              ? 'Canceled'
              : `${formatFulfillMethod(order.fulfill_method)}`}
          </Tag>
        </div>
        <div className={styles.orderDetails}>
          <div
            className={styles.restaurantName}
            data-testid="OrderRestaurantName"
          >
            {order.restaurant.short_name}
          </div>
          <div className={styles.orderItems} data-testid="OrderItems">
            {order.item_list.map(
              (item, index) =>
                `${item.name}${index + 1 < order.item_list.length ? ', ' : ''}`
            )}
          </div>
          {current && (
            <>
              <div className={styles.progress}>
                <ProgressBar order={order} />
              </div>
              <div className={styles.status} data-testid="FulfillmentTime">
                {getEstimationText(order.fulfill_method, order.is_order_ahead)}{' '}
                <span
                  className={styles.orderTimeText}
                  data-testid={orderReadyTime ? 'EstimatedTime' : 'PendingTime'}
                >
                  {getOrderTimeEstimate(orderReadyTime)}
                </span>
              </div>
            </>
          )}
          {!current && (
            <div className={styles.entryTotal}>
              <span className={styles.orderDate}>{formatOrderDate(order)}</span>
              <span data-testid="OrderTotal">{formatMoney(order.total)}</span>
            </div>
          )}
        </div>
      </div>
    );
  });
}

export default OrderEntries;
