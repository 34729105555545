import React from 'react';
import classNames from 'classnames';

import { formatMoney } from '@chownow/cn-web-utils/format';

import styles from './styles.module.scss';

interface TipLineItemProps {
  className?: string;
  label: string;
  value: number;
  dataTestId: string;
}

export default function TipLineItem({
  label,
  value,
  dataTestId,
  className,
}: TipLineItemProps) {
  return (
    <div
      className={classNames([className, styles.tipLineItem])}
      data-testid={dataTestId}
    >
      <span className={styles.tipLabel} data-testid={`${dataTestId}Label`}>
        {label}
      </span>
      <span className={styles.tipValue} data-testid={`${dataTestId}Value`}>
        {formatMoney(value)}
      </span>
    </div>
  );
}
