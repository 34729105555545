/* eslint-disable complexity */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get, capitalize } from 'lodash';

import { ModalContext } from 'context/ModalContext';

import { FULFILLMENT_METHODS } from 'helpers/order';
import { MODAL_TYPE } from 'helpers/modals';

import usePlatform from 'hooks/usePlatform';

import { getVehicle } from 'modules/order';
import { getFulfillmentAvailability } from 'modules/restaurant';

import Outlink from 'primitives/Outlink';
import FulfillmentPicker from 'components/FulfillmentPicker';

import { ReactComponent as IconRestaurant } from 'images/icon-restaurant.svg';

import DeliveryAddress from './DeliveryAddress';
import OrderTime from './OrderTime';
import VehicleDisplay from './VehicleDisplay';

import styles from './styles.module.scss';

function OrderFulfullmentDetails(props) {
  const { order, restaurant } = props;
  const { fulfillmentMethod } = order;
  const { address, name } = restaurant;
  const {
    street_address1: streetAddress1,
    street_address2: streetAddress2,
    city,
    state,
  } = address;

  const { isYelpPlatform } = usePlatform();

  const vehicle = useSelector(getVehicle);
  const fulfillmentAvailability = useSelector(getFulfillmentAvailability);

  const isDelivery = fulfillmentMethod === FULFILLMENT_METHODS.delivery;
  const isCurbside = fulfillmentMethod === FULFILLMENT_METHODS.curbside;

  // We're disabling order-ahead on Yelp for now
  const isSelectedFulfillmentAvailableLater =
    !isYelpPlatform &&
    get(
      fulfillmentAvailability,
      `is${capitalize(fulfillmentMethod)}AheadAvailable`
    );

  // We aren't showing on Yelp because they don't grant
  // allow-popups and allow-popups-to-escape-sandbox in the sandbox attr of our iframe
  const showDirectionsLink = !isDelivery && !isYelpPlatform;
  const { openModal } = useContext(ModalContext);

  function handleOpenWhen() {
    openModal(MODAL_TYPE.fulfillmentDetails, {
      fulfillmentMethod,
    });
  }

  return (
    <>
      <FulfillmentPicker isCheckingOut />
      <div className={styles.orderDetailsWrapper}>
        <h3 className={styles.orderDetailsHeader}>
          {capitalize(fulfillmentMethod)} Details
        </h3>
        <div className={styles.restaurantDetailsWrapper}>
          <IconRestaurant className={styles.icon} />
          <div className={styles.restaurantDetails}>
            <p data-testid="RestaurantName">{name}</p>
            {!isDelivery && (
              <p>
                {streetAddress1}, {streetAddress2 && `${streetAddress2}, `}
                {city}, {state}
              </p>
            )}
            {showDirectionsLink && (
              <Outlink
                to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                  address.formatted_address
                )}`}
                className={styles.outlink}
              >
                Directions
              </Outlink>
            )}
          </div>
        </div>
        <OrderTime
          openWhen={handleOpenWhen}
          isSelectedFulfillmentAvailableLater={
            isSelectedFulfillmentAvailableLater
          }
        />
        {isCurbside && (
          <VehicleDisplay vehicle={vehicle} onVehicleChange={handleOpenWhen} />
        )}
        {isDelivery && <DeliveryAddress onAddressChange={handleOpenWhen} />}
      </div>
    </>
  );
}

OrderFulfullmentDetails.propTypes = {
  order: PropTypes.shape({
    fulfillmentMethod: PropTypes.string.isRequired,
  }).isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street_address1: PropTypes.string,
      street_address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      formatted_address: PropTypes.string,
    }),
  }),
};

OrderFulfullmentDetails.defaultProps = {
  restaurant: {
    address: {
      street_address1: undefined,
      street_address2: undefined,
      city: undefined,
      state: undefined,
      formatted_address: undefined,
    },
  },
};

export default OrderFulfullmentDetails;
