/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isEqual, partition } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { parse } from 'query-string';

import { getFromChownowApi } from 'helpers/api';
import {
  redirectToMarketplaceMenu,
  shouldRedirectToMarketplace,
} from 'helpers/configureRedirects';
import { ORDER_STATUSES, getIsOrderFulfilled } from 'helpers/order';
import { buildRoute, ROUTES } from 'helpers/routes';

import useRequiresAuth from 'hooks/useRequiresAuth';
import usePrevious from 'hooks/usePrevious';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';

import { ReactComponent as IconEmptyBag } from 'images/icon-empty-bag.svg';

import OrderEntries from './OrderEntries';

import styles from './styles.module.scss';

const PAGE_LIMIT = 10;

function OrderHistory() {
  const auth = useRequiresAuth();

  const history = useHistory();
  const location = useLocation();

  const [filteredOrders, setFilteredOrders] = useState({
    current: [],
    past: [],
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [orderHistory, setOrderHistory] = useState({});
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const prevFilteredOrders = usePrevious(filteredOrders);

  const queryParams = parse(location.search);
  const { company } = queryParams;

  const hasCurrentOrders = !!filteredOrders.current.length;
  const hasPastOrders = !!filteredOrders.past.length;

  function handleFetchOrderHistory(offset) {
    async function fetchOrderHistory() {
      setIsLoadingHistory(true);
      const { error, data } = await getFromChownowApi({
        endpoint: 'customer/me/orders',
        params: { offset: offset || 0, limit: 10, company_id: company },
      });

      if (!error) {
        const results = data.previous
          ? [...orderHistory.results, ...data.results]
          : data.results;
        setOrderHistory({ ...data, results });
      }
      setIsLoadingHistory(false);
    }
    fetchOrderHistory();
  }

  useEffect(() => {
    handleFetchOrderHistory();
  }, []);

  useEffect(() => {
    // Splitting order history into current/past orders
    // eslint-disable-next-line consistent-return
    const orders = partition(orderHistory.results, (order) => {
      if (
        order.status === ORDER_STATUSES.submitted ||
        (order.status === ORDER_STATUSES.accepted &&
          !getIsOrderFulfilled(order))
      ) {
        return order;
      }
    });

    setFilteredOrders({ current: orders[0], past: orders[1] });
  }, [orderHistory]);

  useEffect(() => {
    if (!isEqual(prevFilteredOrders, filteredOrders) && isLoadingMore) {
      setIsLoadingMore(false);
    }
  }, [filteredOrders]);

  function handleLoadMore() {
    // Need to make sure we're not in the middle of loading more orders before fetching more
    if (!isLoadingMore) {
      const offset = page * PAGE_LIMIT;

      setIsLoadingMore(true);
      handleFetchOrderHistory(offset);

      // Previously, InfiniteScroll component was handling page numbers. After converting to functional
      // component, it rerenders too often and messes up the page count so keeping track ourselves
      // See: https://github.com/danbovey/react-infinite-scroller/issues/261
      setPage(page + 1);
    }
  }

  async function handleOnOrderClick(order) {
    const { restaurant } = order;

    if (await shouldRedirectToMarketplace(restaurant.id)) {
      return redirectToMarketplaceMenu({
        restoId: restaurant.id,
        hqId: company,
        orderId: order.id,
        source: 'order-history-list-click',
      });
    }

    return history.push(
      buildRoute({ orderId: order.id, route: ROUTES.confirmation })
    );
  }

  if (!auth) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Order History</title>
      </Helmet>
      {isLoadingHistory && (
        <div className={styles.fixedSpinner}>
          <LoadingSpinner overlay />
        </div>
      )}
      <div className={styles.historyWrapper}>
        <div>
          <Navbar />
          <div className={styles.header}>
            <Grid container className={styles.heading}>
              <Grid sm={4} md={12} lg={10} lgOffset={2}>
                <span data-testid="OrderHistoryTitle">Order History</span>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid sm={4} md={12} lg={10} lgOffset={2}>
              {hasCurrentOrders && (
                <div className={styles.section}>
                  <div className={styles.sectionHeading}>Current Order</div>
                  <OrderEntries
                    orders={filteredOrders.current}
                    onOrderClick={handleOnOrderClick}
                    current
                  />
                </div>
              )}
              {hasPastOrders && (
                <div className={styles.section}>
                  <div className={styles.sectionHeading}>Past Order</div>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={handleLoadMore}
                    hasMore={!!orderHistory.next && !isLoadingHistory}
                    loader={
                      isLoadingHistory && (
                        <div key="spinner" className={styles.fixedSpinner}>
                          <LoadingSpinner />
                        </div>
                      )
                    }
                  >
                    <OrderEntries
                      orders={filteredOrders.past}
                      onOrderClick={handleOnOrderClick}
                    />
                  </InfiniteScroll>
                </div>
              )}
              {!hasCurrentOrders && !hasPastOrders && (
                <div className={styles.empty}>
                  <IconEmptyBag className={styles.emptyBag} />
                  <div className={styles.emptyText} data-testid="EmptyBagLabel">
                    You have no orders placed
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div className={styles.section}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default OrderHistory;
