import React, { ReactNode, useContext } from 'react';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { ReactComponent as CloseIcon } from 'images/icon-close-24.svg';

import styles from './styles.module.scss';

interface AlertProps {
  children: ReactNode;
  shouldShowCloseIcon?: boolean;
  centerAlign?: boolean;
}

function Alert({
  children,
  shouldShowCloseIcon = true,
  centerAlign,
}: AlertProps) {
  const { closeModal } = useContext(ModalContext);

  return (
    <div>
      {shouldShowCloseIcon && (
        <button
          className={styles.iconWrapper}
          type="button"
          onClick={closeModal}
        >
          <CloseIcon className={styles.iconClose} data-testid="Close" />
        </button>
      )}
      <div
        className={classNames(styles.alert, {
          [styles.center]: centerAlign,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Alert;
