import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { restaurantBaseType } from 'helpers/prop-types';
import { MODAL_TYPE } from 'helpers/modals';
import { FAQ_LIST } from 'helpers/constants';
import { buildRoute, ROUTES } from 'helpers/routes';

import Navbar from 'components/Navbar';
import Terms from 'components/Terms';
import Footer from 'components/Footer';

import Button from 'primitives/Button';
import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';
import Tag from 'primitives/Tag';

import { ReactComponent as BadgeGold } from 'images/icon-member-gold.svg';
import { ReactComponent as BadgeSilver } from 'images/icon-member-silver.svg';
import { ReactComponent as BadgeBronze } from 'images/icon-member-bronze.svg';

import Card from './Card';

import styles from './styles.module.scss';

function Membership(props) {
  const {
    restaurant: { address, id, short_name: shortName, membership },
  } = props;

  const { openModal } = useContext(ModalContext);
  const { user, membership: userMembership } = useContext(UserContext);
  const { setSelectedMembership, userMembershipPlan } = userMembership;

  const { hqId, restaurantId } = useParams();
  const history = useHistory();

  const Badges = {
    Gold: <BadgeGold />,
    Silver: <BadgeSilver />,
    Bronze: <BadgeBronze />,
  };

  function redirectToCheckout() {
    const checkoutRoute = buildRoute({
      hqId,
      restaurantId,
      route: ROUTES.membershipCheckout,
    });
    history.push(checkoutRoute);
  }

  function handleGoToCheckout(e, plan) {
    e.preventDefault();
    setSelectedMembership({ ...plan, restaurantId });

    if (user.id) {
      redirectToCheckout();
    } else {
      openModal(MODAL_TYPE.auth, {
        authRedirect: redirectToCheckout,
      });
    }
  }

  if (id !== restaurantId)
    return (
      <div className={styles.fixedSpinner}>
        <LoadingSpinner />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>{shortName} Loyalty Membership Plans</title>
      </Helmet>
      <Navbar address={address} restaurantName={shortName} isMembership />
      <div className={styles.membershipPlans}>
        <div className={styles.header}>
          <Grid container className={styles.heading}>
            <Grid sm={4} md={12}>
              <span>
                It’s not just a membership. <br /> It’s a relationship.
              </span>
            </Grid>
          </Grid>
        </div>
        <Grid container className={styles.description}>
          <Grid sm={4} md={6} lg={8}>
            <span>
              When you become a member of our restaurant, you demonstrate your
              heartfelt support for us. We truly appreciate that. And in return,
              we’ll discount every order you make with us for an entire year.
              Now that’s mutual support.
            </span>
          </Grid>
          <Grid sm={4} md={6} lg={4}>
            <div className={styles.restaurantInfo}>
              Membership is only valid at this location
              <p className={styles.locationName}>{shortName}</p>
              <p className={styles.address}>{address.formatted_address}</p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.cardSection}>
          {membership.plans.map((plan) => {
            const isMembershipActive = userMembershipPlan?.id === plan.id;
            return (
              <Grid sm={4} md={12} lg={4} key={plan.id}>
                <div className={styles.membershipCard}>
                  <div className={styles.membershipHeader}>
                    <div className={styles.membershipTitle}>{plan.name}</div>
                    <div className={styles.tagAndBadge}>
                      {isMembershipActive && <Tag>Current Membership</Tag>}
                      {Badges[plan.name]}
                    </div>
                  </div>
                  <div className={styles.membershipContent}>
                    <div className={styles.firstContent}>
                      <span className={styles.percent}>{plan.percent}%</span>
                      <span className={styles.percentCta}>
                        &nbsp;off every order
                      </span>
                    </div>
                    <div className={styles.secondContent}>
                      <p className={styles.price}>${plan.amount} / 1 year</p>
                      <Button
                        className={styles.buyNowBtn}
                        onClick={(e) => {
                          handleGoToCheckout(e, plan);
                        }}
                        size="small"
                      >
                        Buy Now
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid container className={styles.cardSection}>
          <Grid sm={4} md={12} className={styles.sectionHeader}>
            Here’s how it works
          </Grid>
          <Card
            header="Step 1"
            subheader="Select your membership plan"
            content="Select your membership plan based on how much you want to save off each order."
          />
          <Card
            header="Step 2"
            subheader="Checkout with a one-time fee"
            content="When you become a member the restaurant receives 100% of the membership fee immediately."
          />
          <Card
            header="Step 3"
            subheader="Start enjoying savings"
            content="You automatically receive an ongoing discount on every order you place for the entire year."
          />
        </Grid>
        <Grid container className={styles.cardSection}>
          <Grid sm={4} md={12} className={styles.sectionHeader}>
            Frequently asked questions
          </Grid>
          {FAQ_LIST.map((item) => (
            <Card
              key={item.id}
              subheader={item.question}
              content={item.answer}
            />
          ))}
        </Grid>
        <Grid container className={styles.privacyPolicy}>
          <Grid sm={4} md={12}>
            <Terms />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}

Membership.propTypes = {
  restaurant: restaurantBaseType.isRequired,
};

export default Membership;
