/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface RadioButtonProps {
  checked?: boolean;
  className?: string;
  dataTestId?: string | undefined;
  disabled?: boolean;
  name: string;
  onChange?: (event: object) => void;
  value: string;
  icon?: React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  label?: string;
  sublabel?: string | React.ReactElement;
}

function RadioButton({
  checked = false,
  className = '',
  dataTestId,
  disabled = false,
  name,
  onChange = () => {},
  value,
  icon: Icon,
  label,
  sublabel,
}: RadioButtonProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  function handleOnChange(event: object) {
    onChange(event);
  }

  return (
    <div
      className={classNames(className, styles.radioButtonWrapper)}
      onClick={handleOnChange}
    >
      <input
        checked={isChecked}
        readOnly
        className={classNames(styles.radioButtonInput, {
          [styles.disabled]: disabled,
        })}
        data-testid={dataTestId}
        disabled={disabled}
        name={name}
        type="radio"
        value={value}
        id={name}
      />
      <label className={styles.radioButtonVisible} htmlFor={name}>
        {Icon && <Icon className={styles.icon} />}
        {label && (
          <div
            className={classNames(styles.label, {
              [styles.noMargin]: !Icon,
            })}
          >
            {label}
          </div>
        )}
        {sublabel && (
          <div
            className={classNames(styles.sublabel, {
              [styles.noMargin]: !(Icon || label),
            })}
          >
            {sublabel}
          </div>
        )}
      </label>
    </div>
  );
}

export default RadioButton;
