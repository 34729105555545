import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';

import { ModalContext } from 'context/ModalContext';
import {
  ORDER_ADUSTMENT_EXPIRED,
  TIP_ADJUSTMENT_MINIMUM,
  TIP_ADJUSTMENT_MAXIMUM,
} from 'helpers/api';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';

import { getErrorsForOrder } from 'modules/order';
import { tipAdjustmentXHR } from 'modules/order/actions';

import TipOptions from 'components/TipOptions';
import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';
import CurrencyInput from '../TipModal/CurrencyInput';

import styles from './styles.module.scss';

export default function TipAdjustmentModal({
  currentTip,
  orderId,
  tipOptions,
}) {
  const dispatch = useDispatch();
  const TIP_PLACEHOLDER = '--';

  const { closeModal, openModal } = useContext(ModalContext);
  const [updatedTip, setUpdatedTip] = useState(TIP_PLACEHOLDER);
  const modalTitle = currentTip ? 'Select New Tip' : 'Add Tip';
  const { rate, amount } = currentTip || {};
  const [isCustomTipActive, setIsCustomTipActive] = useState(false);
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [selectedOption, setSelectedOption] = useState(rate);
  const [error, setError] = useState('');

  const submitIsDisabled = updatedTip === TIP_PLACEHOLDER;
  const errors = useSelector(getErrorsForOrder);
  const updatedTipValue = Number(updatedTip);

  const TIP_ADJUSTMENT_ERRORS = [
    ORDER_ADUSTMENT_EXPIRED,
    TIP_ADJUSTMENT_MINIMUM,
    TIP_ADJUSTMENT_MAXIMUM,
  ];

  const tipAdjusmentErrors = errors.filter((e) =>
    includes(TIP_ADJUSTMENT_ERRORS, e.code)
  );

  useEffect(() => {
    if (tipAdjusmentErrors.length) {
      setError(tipAdjusmentErrors[0].message);
    }

    if (tipAdjusmentErrors.length === 0 && errors.length) {
      // For all other errors other than the 3 specified tip adjustment errors
      closeModal();
      openModal(MODAL_TYPE.dialog);
    }

    // Log analytics after submit if there is an error
    if (errors.length > 0) {
      const currentTipValue = amount ? Number(amount) : 0;
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.submitAdjustTip,
        attributes: {
          current_tip_value: currentTipValue,
          error_reason: errors[0].message,
          updated_tip_value: updatedTipValue,
          is_custom_tip_value: showCustomTip,
          is_success: false,
          order_id: orderId,
        },
      });
    }
  }, [errors.length]);

  function handleSelectOption(selectedRate, selectedAmount) {
    setError('');
    setIsCustomTipActive(false);
    setShowCustomTip(false);
    setSelectedOption(selectedRate);
    if (selectedAmount === amount) {
      setUpdatedTip(TIP_PLACEHOLDER);
    } else {
      setUpdatedTip(selectedAmount.toFixed(2));
    }
  }

  function handleSelectCustomTip() {
    setIsCustomTipActive(!isCustomTipActive);
    setShowCustomTip(!showCustomTip);
    setSelectedOption(0);
    setUpdatedTip(TIP_PLACEHOLDER);
  }

  function getIsOptionSelected(selectedRate) {
    // The logic needed to show which tip option is selected
    return selectedRate === selectedOption;
  }

  function handleTipChange(_, value) {
    if (Number.isNaN(value) || _ === 0) {
      setUpdatedTip(TIP_PLACEHOLDER);
    } else {
      setUpdatedTip(value);
    }
  }

  function handleSubmit() {
    setError('');
    dispatch({
      type: tipAdjustmentXHR.request.TYPE,
      payload: {
        tip: updatedTipValue,
      },
    });
  }

  return (
    <ModalFrame>
      <ModalHeader title={modalTitle} isSticky />
      <div className={styles.modalContent}>
        <TipOptions
          customTip={0.0}
          handleSelectCustomOption={handleSelectCustomTip}
          handleSelectOption={handleSelectOption}
          isCustomTipActive={isCustomTipActive}
          tipOptions={tipOptions}
          getIsOptionSelected={getIsOptionSelected}
        />
        {showCustomTip && (
          <CurrencyInput
            type="tel"
            className={classNames(styles.customTip, {
              [styles.errorInput]: error,
            })}
            placeholder="$0.00"
            value={0.0}
            onInputChange={handleTipChange}
            autoComplete="false"
          />
        )}
        {error && <p className={styles.errorMessage}>{error}</p>}
        {currentTip && (
          <div className={styles.tipComparisonModule}>
            <div
              className={classNames(styles.tipComparison, styles.currentTip)}
            >
              <p>Current Tip</p>
              <p>${amount.toFixed(2)}</p>
            </div>
            <div
              className={classNames(styles.tipComparison, styles.updatedTip)}
            >
              <p>Updated Tip</p>
              <p>${updatedTip}</p>
            </div>
          </div>
        )}
      </div>
      <ModalFooter bottomFixed>
        <ModalFooterButton
          handleClick={handleSubmit}
          disabled={submitIsDisabled}
        >
          Submit
        </ModalFooterButton>
      </ModalFooter>
    </ModalFrame>
  );
}

TipAdjustmentModal.propTypes = {
  currentTip: PropTypes.shape({
    amount: PropTypes.number,
    rate: PropTypes.number,
    is_current_tip: PropTypes.bool,
  }),
  orderId: PropTypes.string.isRequired,
  tipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      rate: PropTypes.number,
      is_current_tip: PropTypes.bool,
    })
  ).isRequired,
};

TipAdjustmentModal.defaultProps = {
  currentTip: undefined,
};
