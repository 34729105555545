import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getCustomerInitials } from 'containers/Confirmation/helpers';
import { removeTriggerPostPurchaseModal } from 'helpers/order';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';

import { ModalContext } from 'context/ModalContext';

import { getSelectedOrder } from 'modules/order';

import Hearts from 'components/Hearts';
import Modal from 'primitives/Modals/Modal';

import ButtonTimer from './ButtonTimer';
import ContentTwo from './ContentTwo';
import heartsAnimation from './pp-impact-heart.gif';

import styles from './styles.module.scss';

function PostPurchaseImpactModal(props) {
  const { isNewUser } = props;
  const { closeModal } = useContext(ModalContext);

  const [showContentOne, setShowContentOne] = useState(true);
  const [showContentTwo, setShowContentTwo] = useState(false);

  const order = useSelector(getSelectedOrder);

  function handleClose() {
    removeTriggerPostPurchaseModal();
    closeModal();
  }

  function handleArrowClick() {
    const attributes = {
      name: 'arrow_skip_button',
      source: 'diner_impact_interstitial',
    };

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes,
    });

    handleClose();
  }

  useEffect(() => {
    // The first copy displays for a longer time for a new user
    if (isNewUser) {
      setTimeout(() => {
        setShowContentOne(false);
        setShowContentTwo(true);
      }, 3700);
    } else {
      setTimeout(() => {
        setShowContentOne(false);
        setShowContentTwo(true);
      }, 2900);
    }

    const closeModalTimer = setTimeout(() => {
      handleClose();
    }, 14000);

    return () => clearTimeout(closeModalTimer);
  }, []);

  return (
    <Modal isDark shouldShowCloseIcon={false}>
      <div className={styles.modalContent}>
        <div className={styles.modalCopy}>
          {showContentOne && (
            <div
              className={classNames(styles.modalHeadline, {
                [styles.newUserAnimation]: isNewUser,
                [styles.returningUserAnimation]: !isNewUser,
              })}
            >
              We’ve sent your order
              <br />
              to the kitchen.
            </div>
          )}
          {showContentTwo && (
            <div className={styles.contentTwo}>
              <Hearts
                previouslyPlayed={false}
                imgSrc={heartsAnimation}
                customerInitials={getCustomerInitials(order.customer)}
              />
              <ContentTwo order={order} isNewUser={isNewUser} />
            </div>
          )}
        </div>
        <button
          className={styles.buttonWrapper}
          onClick={handleArrowClick}
          type="button"
        >
          <ButtonTimer />
          View Order
        </button>
      </div>
    </Modal>
  );
}

PostPurchaseImpactModal.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
};

export default PostPurchaseImpactModal;
