/* eslint-disable complexity, consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import usePrevious from 'hooks/usePrevious';

import { isAddressValid } from 'helpers/validation';
import { getRestaurantIdFromUrl } from '@chownow/cn-web-utils/url';

import {
  fetchDeliveryRangesXHR,
  getDeliveryRanges,
  getIsLoadingDeliveryRanges,
} from 'modules/restaurant';

import AddressBar from 'components/AddressBar';
import Input from 'primitives/Input';
import Tag from 'primitives/Tag';
import Textarea from 'primitives/Textarea';

import { ReactComponent as IconCaution } from 'images/icon-caution.svg';

import styles from './styles.module.scss';

function AddressSelect(props) {
  const {
    deliveryInstructions,
    setHasAddressErrors,
    hasError,
    selectedAddress,
    streetAddress2,
    setDeliveryInstructions,
    setSelectedAddress,
    setStreetAddress2,
  } = props;

  const dispatch = useDispatch();
  const restaurantId = getRestaurantIdFromUrl();

  const [isInvalidAddress, setIsInvalidAddress] = useState(false);

  const deliveryRanges = useSelector(getDeliveryRanges);
  const isLoadingDeliveryRanges = useSelector(getIsLoadingDeliveryRanges);

  const prevSelectedAddress = usePrevious(selectedAddress);

  const hasAddressErrors =
    (!isLoadingDeliveryRanges &&
      !deliveryRanges?.length &&
      !isEmpty(selectedAddress)) ||
    isInvalidAddress;

  function getRestaurantDeliveryRanges(address) {
    dispatch({
      type: fetchDeliveryRangesXHR.request.TYPE,
      payload: {
        id: restaurantId,
        params: address,
      },
    });
  }

  useEffect(() => {
    // in the case that selected address is missing information
    if (selectedAddress && !isAddressValid(selectedAddress)) {
      return setIsInvalidAddress(true);
    }

    if (selectedAddress) {
      getRestaurantDeliveryRanges(selectedAddress);
    }
  }, []);

  useEffect(() => {
    if (selectedAddress && selectedAddress !== prevSelectedAddress) {
      setDeliveryInstructions(selectedAddress?.delivery_instructions || '');
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (hasAddressErrors) {
      setHasAddressErrors(true);
    } else {
      setHasAddressErrors(false);
    }
  }, [hasAddressErrors]);

  function handleSelectAddress(address) {
    // if we do not have all address info, throw error and do not call getRestaurantDeliveryRanges
    // note: if no street_address1, our formatting currently
    // returns street_address1 'undefined' as a string
    if (!isAddressValid(address)) {
      return setIsInvalidAddress(true);
    }

    setIsInvalidAddress(false);

    setSelectedAddress(address);
    setStreetAddress2(get(address, 'street_address2') || '');

    return getRestaurantDeliveryRanges(address);
  }

  return (
    <>
      <div className={styles.sectionTitle}>
        Deliver To
        <Tag icon={hasError && <IconCaution />} isCaution={hasError}>
          Required
        </Tag>
      </div>
      <AddressBar
        hideLocateMe
        onSelectAddress={handleSelectAddress}
        hasErrors={hasAddressErrors}
      />
      {hasAddressErrors && (
        <div
          id="invalidAddressError"
          className={styles.error}
          data-testid="AddressError"
        >
          {isInvalidAddress
            ? 'Incomplete Address'
            : 'Address outside of delivery zone.'}
        </div>
      )}
      <Input
        type="text"
        className={styles.input}
        label="Street address 2"
        name="streetAddress2"
        shouldHideLabel
        value={streetAddress2}
        placeholder="Ste, Apt, Floor"
        onChange={setStreetAddress2}
      />
      <Textarea
        className={styles.input}
        maxLength={140}
        label="delivery instructions"
        name="deliveryInstructions"
        shouldHideLabel
        value={deliveryInstructions}
        placeholder="Delivery Instructions"
        onChange={setDeliveryInstructions}
      />
    </>
  );
}

AddressSelect.propTypes = {
  deliveryInstructions: PropTypes.string,
  setHasAddressErrors: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  selectedAddress: PropTypes.shape({
    street_address1: PropTypes.string,
    delivery_instructions: PropTypes.string,
  }),
  streetAddress2: PropTypes.string,
  setDeliveryInstructions: PropTypes.func.isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
  setStreetAddress2: PropTypes.func.isRequired,
};

AddressSelect.defaultProps = {
  deliveryInstructions: undefined,
  hasError: false,
  selectedAddress: undefined,
  streetAddress2: undefined,
};

export default AddressSelect;
