/* eslint-disable complexity, react/jsx-no-useless-fragment, jsx-a11y/label-has-associated-control, arrow-body-style */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';
import { getSubmitOrderCount } from 'helpers/order';
import {
  APPLE_PAY_STRUCT,
  GOOGLE_PAY_STRUCT,
  NEW_CARD_VALUE,
  getAnalyticsPaymentName,
} from 'helpers/payments';

import CreditCardDisplay from 'components/CreditCardDisplay';
import CreditCardForm from 'components/CreditCardForm';
import ButtonLink from 'primitives/ButtonLink';
import Radio from 'primitives/Radio';

import { ReactComponent as IconCreditCard } from 'images/icon-creditcard.svg';
import { ReactComponent as IconLightning } from 'images/icon-lightning.svg';

import styles from './styles.module.scss';

function PaymentOptions(props) {
  const {
    defaultToGooglePay,
    defaultToApplePay,
    lastSavedCard,
    onSelectNewCard,
    selectedCard,
    setFieldValue,
  } = props;

  const [visibleCard, setVisibleCard] = useState();

  const { openModal } = useContext(ModalContext);
  const { isLoggedIn, setCardForm, setSelectedCardId } =
    useContext(UserContext);

  const submittedOrderCount = getSubmitOrderCount();
  const isCardDeclined =
    visibleCard && !!submittedOrderCount?.ids.includes(visibleCard.id);

  const selectedCardId = selectedCard?.id;
  const isSocialPaySelected =
    selectedCardId === APPLE_PAY_STRUCT.type ||
    selectedCardId === GOOGLE_PAY_STRUCT.type;
  const showCardForm =
    !visibleCard && !defaultToApplePay && !defaultToGooglePay;

  useEffect(() => {
    return () => {
      // reset card form to initial state
      setCardForm();
    };
  }, []);

  useEffect(() => {
    if (!isSocialPaySelected && selectedCard?.type) {
      setVisibleCard(selectedCard);
    }

    if (!selectedCard?.type) {
      setVisibleCard();
    }
  }, [selectedCard]);

  useEffect(() => {
    // set visible card to last saved card on initial load of component or if user logs in
    if (!visibleCard) {
      setVisibleCard(lastSavedCard);
    }
    // reset visibleCard to "add new card" if last saved payment is deleted
    if (visibleCard && !lastSavedCard) {
      setVisibleCard();
    }
  }, [lastSavedCard]);

  function handleSelectCard(id) {
    setSelectedCardId(id);

    const savedCardType = id === visibleCard?.id ? visibleCard.type : null;

    const attributes = {
      name: getAnalyticsPaymentName(id, savedCardType),
      source: 'checkout_payment_module',
    };

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes,
    });
  }

  function handleOpenAuth() {
    openModal(MODAL_TYPE.auth);
  }

  return (
    <>
      {showCardForm ? (
        <CreditCardForm setFieldValue={setFieldValue} cardId={NEW_CARD_VALUE} />
      ) : (
        <div>
          {!!visibleCard && (
            <div className={styles.radioWrapper}>
              <Radio
                name={visibleCard.type}
                checked={selectedCardId === visibleCard.id}
                onChange={() => handleSelectCard(visibleCard.id)}
              />
              <label htmlFor={visibleCard.type}>
                <CreditCardDisplay
                  card={visibleCard}
                  className={styles.card}
                  error={
                    isCardDeclined && 'Re-enter your card information below'
                  }
                />
              </label>
            </div>
          )}
          {defaultToApplePay && (
            <div className={styles.radioWrapper}>
              <Radio
                name="applePay"
                checked={selectedCardId === APPLE_PAY_STRUCT.type}
                onChange={() => handleSelectCard(APPLE_PAY_STRUCT.id)}
              />
              <label className={styles.socialOption} htmlFor="applePay">
                <CreditCardDisplay
                  card={APPLE_PAY_STRUCT}
                  className={styles.card}
                />
                {!isLoggedIn && (
                  <span className={styles.express}>
                    <IconLightning className={styles.lightning} />
                    Express
                  </span>
                )}
              </label>
            </div>
          )}
          {defaultToGooglePay && (
            <div className={styles.radioWrapper}>
              <Radio
                name="googlePay"
                checked={selectedCardId === GOOGLE_PAY_STRUCT.type}
                onChange={() => handleSelectCard(GOOGLE_PAY_STRUCT.id)}
              />
              <label className={styles.socialOption} htmlFor="googlePay">
                <CreditCardDisplay
                  card={GOOGLE_PAY_STRUCT}
                  className={styles.card}
                />
              </label>
            </div>
          )}
          {!visibleCard && (
            <div className={styles.radioWrapper}>
              <button
                type="button"
                onClick={() => onSelectNewCard(true)}
                className={styles.mockRadioBtn}
                aria-label="Add Credit Card"
              />
              <span className={styles.noCardLabel}>
                <IconCreditCard className={styles.icon} />
                Add Credit Card
                {!isLoggedIn && (
                  <ButtonLink
                    className={styles.loginLink}
                    secondary
                    size="small"
                    onClick={handleOpenAuth}
                  >
                    Login
                  </ButtonLink>
                )}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

PaymentOptions.propTypes = {
  defaultToGooglePay: PropTypes.bool,
  defaultToApplePay: PropTypes.bool,
  lastSavedCard: PropTypes.shape({}),
  onSelectNewCard: PropTypes.func,
  selectedCard: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  setFieldValue: PropTypes.func,
};

PaymentOptions.defaultProps = {
  defaultToGooglePay: false,
  defaultToApplePay: false,
  lastSavedCard: undefined,
  onSelectNewCard: undefined,
  selectedCard: undefined,
  setFieldValue: undefined,
};

export default PaymentOptions;
