/* eslint-disable complexity */
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DS_PLATFORM } from 'helpers/constants';
import { logAnalyticsEvent } from 'helpers/loggers';
import {
  getHqIdFromUrl,
  getRestaurantIdFromUrl,
} from '@chownow/cn-web-utils/url';

import { triggerValidate } from 'modules/order';
import { fetchRestaurantXHR } from 'modules/restaurant';

import { CompanyContext } from 'context/CompanyContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import usePlatform from 'hooks/usePlatform';

import Modal from 'primitives/Modals/Modal';
import { Auth } from '@chownow/cn-web-diner-auth';

interface MParticleResultType {
  getUser: () => {
    setUserAttribute: (
      attribute: string,
      data: string | boolean | undefined
    ) => void;
  };
}

export type AuthModalType =
  | 'LOGIN_SIGN_UP'
  | 'LOGIN_FORM'
  | 'SIGN_UP_FORM'
  | 'RESET_PASSWORD'
  | 'FORGOT'
  | 'VERIFICATION';

interface AuthModalProps {
  activeAuthModal: AuthModalType;
  authRedirect?: (id?: string) => void;
  email?: string;
  phone?: string;
  resetVerifyCode?: string;
  userId?: string;
}

function AuthSharedModal({
  activeAuthModal = 'LOGIN_SIGN_UP',
  authRedirect,
  email,
  phone,
  resetVerifyCode,
  userId,
}: AuthModalProps) {
  const { isCanadian } = useContext(CompanyContext);
  const { closeModal } = useContext(ModalContext);
  const { user, handleFetchUserProfile } = useContext(UserContext);

  const dispatch = useDispatch();
  const { platform, isMarketplacePlatform } = usePlatform();

  const hqId = getHqIdFromUrl();
  const restaurantId = getRestaurantIdFromUrl();

  useEffect(() => {
    if (user?.id) {
      // mParticle IDSync
      if (window.mParticle) {
        // supported identity types
        // https://docs.mparticle.com/developers/sdk/web/idsync/#supported-identity-types
        const identityRequest = {
          userIdentities: {
            email: user.email,
            customerid: user.id,
          },
        };
        window.mParticle.Identity.login(
          identityRequest,
          (result: MParticleResultType) => {
            const analyticsUser = result.getUser();

            analyticsUser?.setUserAttribute('$Mobile', user.phone?.number);
            analyticsUser?.setUserAttribute('user_is_optout', user.is_optout);
          }
        );
      }
    }
  }, [user]);

  function handleLoadUser() {
    // fetch restaurant to get redeemed_discounts
    if (restaurantId) {
      dispatch({
        // @ts-expect-error dispatching TYPE
        type: fetchRestaurantXHR.request.TYPE,
        payload: restaurantId,
      });
    }

    // @ts-expect-error dispatching TYPE
    dispatch({ type: triggerValidate.TYPE });

    handleFetchUserProfile();
  }

  function onAuthSuccessHandler(data: {
    email?: string;
    id?: string;
    phone?: { number?: string };
    is_optout?: boolean;
  }) {
    // if we do not have email, user is requesting verification code
    if (!data.email) return;

    if (data.id) handleLoadUser(); // only load user when successful login
  }

  function onCompleteHandler() {
    handleLoadUser();

    if (authRedirect) authRedirect(user.id);

    closeModal();
  }

  return (
    <Modal centerAlign>
      <Auth
        authType={activeAuthModal}
        hqId={hqId}
        platform={DS_PLATFORM[platform as string]}
        onAuthSuccess={onAuthSuccessHandler}
        onComplete={onCompleteHandler}
        restaurant={{ id: restaurantId, isCanadian }}
        deployEnv={process.env.REACT_APP_DEPLOY_ENV}
        AppleID={window.AppleID}
        GoogleAuth={window.google}
        logAnalyticsEvent={logAnalyticsEvent}
        googleKey={
          isMarketplacePlatform
            ? process.env.REACT_APP_MARKETPLACE_GOOGLE_AUTH_CLIENT_ID
            : process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
        }
        email={email}
        phone={phone}
        userId={userId}
        resetVerifyCode={resetVerifyCode}
      />
    </Modal>
  );
}

export default AuthSharedModal;
