// ticket to fix: https://chownow.atlassian.net/browse/CN-23523
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import GetCurrentPosition from 'components/GetCurrentPosition';
import MenuSimple from 'primitives/MenuSimple';

import { ReactComponent as IconMylocation } from 'images/icon-mylocation.svg';

import styles from './styles.module.scss';

function LocateMeDropdown(props) {
  const { hasError, onError, onSelectAddress, origin } = props;

  return hasError ? (
    <div className={styles.error}>
      Unable to determine current location. Please enter an address.
    </div>
  ) : (
    <GetCurrentPosition
      onSelectAddress={onSelectAddress}
      origin={origin}
      onError={onError}
    >
      {(calculateCurrentPosition) => (
        <div className={styles.locateMeItem}>
          <MenuSimple
            handleClick={calculateCurrentPosition}
            dataTestId="LocateMe"
            label="Current Location"
            icon={IconMylocation}
          />
        </div>
      )}
    </GetCurrentPosition>
  );
}

LocateMeDropdown.propTypes = {
  hasError: PropTypes.bool,
  onError: PropTypes.func,
  onSelectAddress: PropTypes.func.isRequired,
  origin: PropTypes.string,
};

LocateMeDropdown.defaultProps = {
  hasError: false,
  onError: () => {},
  origin: undefined,
};

export default LocateMeDropdown;
