import { deserializeAddress } from 'helpers/address';
import { geocode } from 'helpers/geocode';

export default function* geocodeAddressFromURL(locationFromUrl) {
  // If there is a # character, we remove it so the geocode api does not fail
  const validLocation = locationFromUrl.replace(/#/g, '');
  if (window.google?.maps) {
    try {
      const geocodeResult = yield geocode({ address: validLocation });
      const geocodeAddress = deserializeAddress(geocodeResult[0]);

      // Set the address if it is invalid or not
      if (geocodeAddress) {
        return geocodeAddress;
      }
    } catch {
      return {};
    }
  }

  return {};
}
