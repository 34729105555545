/* eslint-disable complexity, react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { capitalize, get } from 'lodash';
import { format } from 'date-fns';

import {
  getOrderReadyTime,
  getEstimationText,
  getOrderTimeEstimate,
} from 'helpers/order';
import { PLATFORM } from 'helpers/constants';
import { isWithin24Hours } from 'helpers/date';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';

import PlatformContext from 'context/PlatformContext';

import HelpToggle from 'components/HelpToggle';
import ProgressBar from 'components/ProgressBar';

import styles from './styles.module.scss';
import ManagedDeliveryLink from '../ManagedDeliveryLink';

function OrderStatus({
  canShowFullReceipt,
  handleOnNotification,
  isDineIn,
  isInProgress,
  isOrderCancelled,
  order,
  restaurant,
  trackingUrl,
}) {
  const {
    fulfill_method: fulfillMethod,
    fulfillment_times: fulfillmentTimes,
    is_order_ahead: isOrderAhead,
    id,
  } = order;

  const { platform } = useContext(PlatformContext);
  const isMarketplacePlatform = platform === PLATFORM.marketplace;

  const orderReadyTime = getOrderReadyTime(order);
  const orderCreatedTime = getDateForApiDateStr(fulfillmentTimes.created_at);
  const canShowHelpLink = fulfillmentTimes && isMarketplacePlatform;
  const canShowManagedDeliveryLink =
    canShowFullReceipt &&
    !!trackingUrl &&
    isWithin24Hours(fulfillmentTimes.updated_at);

  const tableNumber =
    isDineIn && canShowFullReceipt && order.customer?.dine_in?.table_name;

  function getFulfillMethod() {
    return isDineIn ? 'Dine-in' : capitalize(fulfillMethod);
  }

  function getDateFormat() {
    return format(
      isOrderCancelled || isDineIn ? orderCreatedTime : orderReadyTime,
      "EEE, MMM d 'at' h:mmaaa"
    );
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.orderProgress, {
          [styles.divider]: canShowManagedDeliveryLink,
        })}
      >
        <div className={styles.header}>
          <div className={styles.orderId}>Order #{id}</div>
          {canShowHelpLink && (
            <HelpToggle
              page="confirmation"
              phone={restaurant ? get(restaurant, 'phone') : ''}
            />
          )}
        </div>
        {isInProgress && !isDineIn ? (
          <>
            <ProgressBar onNotification={handleOnNotification} />
            <p className={styles.estimatedText} data-testid="OrderStatus">
              {getEstimationText(fulfillMethod, isOrderAhead)}{' '}
              <span
                className={styles.orderTimeText}
                data-testid={orderReadyTime ? 'EstimatedTime' : 'PendingTime'}
              >
                {getOrderTimeEstimate(orderReadyTime)}
              </span>
            </p>
          </>
        ) : (
          <>
            {(restaurant || (!restaurant && !isOrderCancelled)) && (
              <div className={styles.fulfillmentDetails}>
                <span className={styles.inlineBullet}>
                  {getFulfillMethod()}
                </span>
                {tableNumber && (
                  <span className={styles.inlineBullet}>
                    Table {tableNumber}
                  </span>
                )}
                <span>{getDateFormat()}</span>
              </div>
            )}
          </>
        )}
      </div>
      {canShowManagedDeliveryLink && (
        <ManagedDeliveryLink trackingUrl={trackingUrl} />
      )}
    </div>
  );
}

export default OrderStatus;

OrderStatus.propTypes = {
  canShowFullReceipt: PropTypes.bool,
  handleOnNotification: PropTypes.func.isRequired,
  isDineIn: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isOrderCancelled: PropTypes.bool,
  order: PropTypes.shape({
    customer: PropTypes.shape({
      dine_in: PropTypes.shape({
        table_name: PropTypes.string,
      }),
    }),
    fulfill_method: PropTypes.string,
    fulfillment_times: PropTypes.shape({
      estimated_arrival: PropTypes.string,
      estimated_pickup: PropTypes.string,
      created_at: PropTypes.string,
      requested_fulfillment: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    id: PropTypes.string,
    is_order_ahead: PropTypes.bool,
    status: PropTypes.string,
  }).isRequired,
  restaurant: PropTypes.shape({
    phone: PropTypes.string,
    short_name: PropTypes.string,
  }),
  trackingUrl: PropTypes.string,
};

OrderStatus.defaultProps = {
  canShowFullReceipt: false,
  isDineIn: false,
  isInProgress: false,
  isOrderCancelled: false,
  restaurant: {},
  trackingUrl: undefined,
};
