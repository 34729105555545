/* eslint-disable max-len */
import React from 'react';

export default function IconClose() {
  return (
    <svg
      aria-label="close icon"
      role="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14645 4.87301C4.34171 4.67775 4.65829 4.67775 4.85355 4.87301L19.8536 19.873C20.0488 20.0683 20.0488 20.3849 19.8536 20.5801C19.6583 20.7754 19.3417 20.7754 19.1464 20.5801L4.14645 5.58012C3.95118 5.38485 3.95118 5.06827 4.14645 4.87301Z"
        fill="#142138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8536 4.87301C20.0488 5.06827 20.0488 5.38485 19.8536 5.58012L4.85355 20.5801C4.65829 20.7754 4.34171 20.7754 4.14645 20.5801C3.95118 20.3849 3.95118 20.0683 4.14645 19.873L19.1464 4.87301C19.3417 4.67775 19.6583 4.67775 19.8536 4.87301Z"
        fill="#142138"
      />
    </svg>
  );
}
