import React from 'react';
import { partial } from 'lodash';

import MenuSimple from 'primitives/MenuSimple';

import styles from './styles.module.scss';

function AddressSelector({
  savedAddresses,
  onSelectAddress,
  maxSavedAddresses = 3,
}: {
  savedAddresses: {
    street_address1: string;
    street_address2?: string;
    city: string;
    state: string;
  }[];
  onSelectAddress: (address: object) => void;
  maxSavedAddresses?: number;
}) {
  return (
    <div className={styles.addressList}>
      {savedAddresses.slice(0, maxSavedAddresses).map((address) => (
        <MenuSimple
          handleClick={partial(onSelectAddress, address)}
          dataTestId="SavedAddress"
          label={`${address.street_address1} ${address.street_address2}, ${address.city}, ${address.state}`}
        />
      ))}
    </div>
  );
}

export default AddressSelector;
