import React from 'react';

import AddressBar from 'components/AddressBar';

import styles from './styles.module.scss';

function LocationSearch() {
  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchHeader} data-testid="FindALocation">
        Find a Location
      </div>
      <div className={styles.searchSubtext}>
        Browse and select a location to view a menu below.
      </div>
      <AddressBar />
    </div>
  );
}

export default LocationSearch;
