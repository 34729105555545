import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

import { formatMoney } from '@chownow/cn-web-utils/format';

import Button from 'primitives/Button';
import Stepper from 'primitives/Stepper';
import TagNotice from 'primitives/TagNotice';

import styles from './styles.module.scss';

function ItemFooter(props) {
  const {
    handleAddItem,
    handleBackBtn,
    handleContinueBtn,
    handleUpdateQuantity,
    isMenuPreview,
    price,
    showNestedModControls,
    quantity,
    trackingId,
  } = props;

  const { transform } = useSpring({
    config: { tension: 382, friction: 29 },
    delay: 300,
    from: {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    to: { transform: 'translateY(0)' },
  });

  function getButtonText() {
    if (trackingId) {
      return 'Update Item';
    }

    return 'Add to Order';
  }

  return (
    <animated.div style={{ transform }}>
      <div className={styles.modalFooter}>
        {isMenuPreview && (
          <div className={styles.previewNotice}>
            <TagNotice variant="caution">
              Currently not accepting orders
            </TagNotice>
          </div>
        )}
        <div className={styles.footerControls}>
          {showNestedModControls ? (
            <>
              <Button
                className={styles.backBtn}
                onClick={handleBackBtn}
                isFullWidth
                disabled={isMenuPreview}
                variant="outline"
              >
                Back
              </Button>
              <Button
                className={styles.continueBtn}
                onClick={handleContinueBtn}
                isFullWidth
                disabled={isMenuPreview}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              {!isMenuPreview && (
                <Stepper
                  quantity={quantity}
                  minQuantity={trackingId ? 0 : 1}
                  onUpdateQuantity={handleUpdateQuantity}
                  dataTestId="ItemModalQuantity"
                  className={styles.stepper}
                />
              )}
              <Button
                onClick={handleAddItem}
                isFullWidth
                className={styles.btnAddItem}
                dataTestId="AddToOrder"
                disabled={isMenuPreview}
              >
                {getButtonText()}
                <span className={styles.secondaryButtonContent}>
                  {formatMoney(price)}
                </span>
              </Button>
            </>
          )}
        </div>
      </div>
    </animated.div>
  );
}

ItemFooter.propTypes = {
  handleAddItem: PropTypes.func.isRequired,
  handleBackBtn: PropTypes.func,
  handleContinueBtn: PropTypes.func,
  handleUpdateQuantity: PropTypes.func.isRequired,
  isMenuPreview: PropTypes.bool,
  price: PropTypes.number,
  showNestedModControls: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  trackingId: PropTypes.string,
};

ItemFooter.defaultProps = {
  handleBackBtn: () => {},
  handleContinueBtn: () => {},
  isMenuPreview: false,
  price: undefined,
  showNestedModControls: false,
  trackingId: undefined,
};

export default ItemFooter;
