import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  icon?: React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  isFullWidth?: boolean;
  onClick: () => void;
  size?: 'small' | 'regular';
  variant?: 'fill' | 'outline' | 'caution' | 'inverse';
}

function Button({
  children,
  className,
  dataTestId,
  disabled = false,
  icon: Icon,
  isFullWidth,
  onClick,
  size = 'regular',
  variant = 'fill',
}: ButtonProps) {
  return (
    <button
      className={classNames(
        styles.button,
        className,
        styles[variant],
        styles[size],
        {
          [styles.disabled]: disabled,
          [styles.fullWidth]: isFullWidth,
        }
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {Icon && <Icon className={styles.icon} />}
      {children}
    </button>
  );
}

export default Button;
