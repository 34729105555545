import useFeatureFlagRequest from 'hooks/useFeatureFlagRequest';

import { FEATURE_FLAGS } from 'helpers/constants';

// AB TEST https://chownow.atlassian.net/browse/CN-31825

function useShowPostOrderAdjustments() {
  const { showFeature: canShowPostOrderAdjustments } = useFeatureFlagRequest({
    doLoad: true,
    flag: FEATURE_FLAGS.showPostOrderAdjustments,
  });

  return canShowPostOrderAdjustments;
}

export default useShowPostOrderAdjustments;
