import { useSelector } from 'react-redux';

import { getTips } from 'modules/order';
import useShowRestaurantTipping from 'hooks/featureFlags/useShowRestaurantTipping';

function useDualTippingAllowed() {
  const tips = useSelector(getTips);
  const isRestaurantTippingEnabled = useShowRestaurantTipping();
  const isDualTippingAllowed = tips?.allow_dual_tipping;

  return isRestaurantTippingEnabled && isDualTippingAllowed;
}

export default useDualTippingAllowed;
