import React from 'react';
import PropTypes from 'prop-types';
import { restaurantType } from 'helpers/prop-types';

import LocationsListItem from '../LocationsListItem';

import styles from './styles.module.scss';

function LocationsList({ locations, onLocationHover }) {
  return (
    <div className={styles.locationList}>
      {locations.map((location) => (
        <LocationsListItem
          key={location.id}
          location={location}
          onLocationHover={onLocationHover}
        />
      ))}
    </div>
  );
}

LocationsList.propTypes = {
  locations: PropTypes.arrayOf(restaurantType),
  onLocationHover: PropTypes.func,
};

LocationsList.defaultProps = {
  locations: [],
  onLocationHover: () => {},
};

export default LocationsList;
