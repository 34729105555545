/* eslint-disable import/prefer-default-export, complexity */

import { forEach } from 'lodash';
import { COUNTRIES } from 'helpers/constants';

function extractGoogleMapComponents(
  addressComponents: {
    long_name: string;
    short_name: string;
    types: string[];
  }[],
  formattedAddress: string
): {
  street_address1?: string;
} {
  // Split the address components into an object keyed by type
  // ie, route: { short_name: ..., long_name: ... }
  const googleComponents = addressComponents.reduce(
    (acc: { [key: string]: object }, component) => {
      forEach(component.types, (type: string) => {
        acc[type] = component;
      });

      return acc;
    },
    {}
  );

  // Given a CN key, define how to grab it from the above `googleComponents` object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addressKeySelector: { [key: string]: any } = {
    street_address1: ({
      street_number: streetNumber = {},
      route = {},
    }: {
      street_number: { short_name?: string };
      route: { short_name?: string; long_name?: string };
    }) => {
      // Ensure closest match possible to formatted address; default to long_name if no match on short name
      const selectedRoute = formattedAddress.includes(route.short_name || '')
        ? route.short_name
        : route.long_name;
      return `${streetNumber.short_name || ''} ${selectedRoute}`.trim();
    },
    street_address2: ({
      subpremise = {},
    }: {
      subpremise: { short_name?: string };
    }) => subpremise.short_name || null,
    country_alpha2: ({ country = {} }: { country: { short_name?: string } }) =>
      country.short_name,
    // eslint-disable-next-line consistent-return
    country: ({ country = {} }: { country: { short_name?: string } }) => {
      if (country.short_name === COUNTRIES.usa.alpha2) {
        return COUNTRIES.usa.alpha3;
      }
      if (country.short_name === COUNTRIES.canada.alpha2) {
        return COUNTRIES.canada.alpha3;
      }
    },
    zip: ({
      postal_code: zip = {},
    }: {
      postal_code: { short_name?: string };
    }) => zip.short_name,
    city: ({
      locality,
      sublocality,
    }: {
      locality: { long_name?: string };
      sublocality: { long_name?: string };
    }) => {
      // preferred location of key; it can sometimes be under sublocality
      const googleKey = locality || sublocality || {};
      if (googleKey.long_name) {
        return googleKey.long_name;
      }
      const addrParts = formattedAddress.split(',');
      return addrParts[addrParts.length - 3];
    },
    state: ({
      administrative_area_level_1: state = {},
    }: {
      administrative_area_level_1: { short_name?: string };
    }) => state.short_name,
  };

  // Reduce over the keys we want to return to form an object comprised of the values we need
  return Object.keys(addressKeySelector).reduce(
    (acc: { [key: string]: object }, key) => {
      acc[key] = addressKeySelector[key](googleComponents);
      return acc;
    },
    {}
  );
}

function extractLatLng(gmapsAddress: {
  geometry: {
    location: {
      lat: () => string;
      lng: () => string;
    };
  };
  latitude: string;
  longitude: string;
}): { latitude: string; longitude: string } {
  if (gmapsAddress.geometry) {
    return {
      latitude:
        typeof gmapsAddress.geometry.location.lat === 'function'
          ? gmapsAddress.geometry.location.lat()
          : gmapsAddress.geometry.location.lat,
      longitude:
        typeof gmapsAddress.geometry.location.lng === 'function'
          ? gmapsAddress.geometry.location.lng()
          : gmapsAddress.geometry.location.lng,
    };
  }
  return {
    latitude: gmapsAddress.latitude,
    longitude: gmapsAddress.longitude,
  };
}

export function deserializeAddress(address: {
  gmaps: {
    address_components: {
      types: string[];
      long_name: string;
      short_name: string;
    }[];
    types: string[];
    formatted_address: string;
    place_formatted_address: string;
    geometry: {
      location: {
        lat: () => string;
        lng: () => string;
      };
    };
    latitude: string;
    longitude: string;
  };
}) {
  const gmapsAddress = address.gmaps || address;
  const addressComponents = gmapsAddress.address_components || [];

  if (!gmapsAddress.address_components) return address;

  let gmapsComponents = extractGoogleMapComponents(
    addressComponents,
    gmapsAddress.formatted_address
  );

  // if we do not have a street address, but do have a plus code, use plus code for street address
  // https://maps.google.com/pluscodes/
  if (
    gmapsComponents.street_address1 === 'undefined' &&
    gmapsAddress.types.includes('plus_code')
  ) {
    const plusCode = addressComponents.find((component) =>
      component.types.includes('plus_code')
    );
    gmapsComponents = {
      ...gmapsComponents,
      street_address1: plusCode?.long_name,
    };
  }

  return {
    formatted_address:
      gmapsAddress.place_formatted_address || gmapsAddress.formatted_address,
    types: gmapsAddress.types || [],
    ...extractLatLng(gmapsAddress),
    ...gmapsComponents,
  };
}
