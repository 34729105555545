import React, { MouseEventHandler, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface OutlinkProps {
  ariaLabel: string;
  className?: string;
  children: string | ReactElement;
  dataTestId?: string;
  handleClick?: MouseEventHandler<HTMLAnchorElement>;
  to: string;
}

function Outlink({
  ariaLabel,
  className,
  children,
  dataTestId,
  handleClick,
  to,
}: OutlinkProps) {
  return (
    <a
      href={to}
      data-testid={dataTestId || 'Outlink'}
      aria-label={ariaLabel}
      className={classNames(styles.outlink, className)}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {children}
    </a>
  );
}

export default Outlink;
