import React from 'react';
import classNames from 'classnames';

import ButtonLink from 'primitives/ButtonLink';

import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';
import { InputProps } from './types';

const Input: React.FunctionComponent<InputProps> = ({
  className = '',
  disabled = false,
  errorText,
  label,
  link,
  maxLength,
  name,
  onBlur,
  onChange = () => {},
  onFocus,
  placeholder,
  shouldHideLabel,
  type,
  value,
}) => {
  const showClearLink = !!value?.length && !disabled;

  return (
    <div className={classNames(className, styles.inputWrapper)}>
      <label
        className={classNames(styles.label, {
          [styles.hideLabel]: shouldHideLabel,
        })}
        htmlFor={name}
      >
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        id={name}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        className={classNames(styles.input, {
          [styles.error]: !!errorText,
          [styles.withClearIcon]: showClearLink,
        })}
      />
      {link && (
        <div className={styles.link}>
          <ButtonLink size="xsmall" onClick={link.onClick}>
            {link.label}
          </ButtonLink>
        </div>
      )}
      {showClearLink && (
        <div
          className={classNames(styles.clearIconWrapper, {
            [styles.withLink]: link,
            [styles.withNoLabel]: shouldHideLabel,
          })}
        >
          <IconClose
            className={styles.clearIcon}
            onClick={() => {
              onChange('');
            }}
            role="button"
          />
        </div>
      )}
      {!!errorText && <span className={styles.errorText}>{errorText}</span>}
    </div>
  );
};

export default Input;
