import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ButtonIconProps {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  onClick: () => void;
  variant?: 'fill' | 'outline';
}

function ButtonIcon({
  children,
  className,
  dataTestId,
  disabled = false,
  onClick,
  variant = 'fill',
}: ButtonIconProps) {
  return (
    <button
      className={classNames(styles.buttonIcon, className, styles[variant], {
        [styles.disabled]: disabled,
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
}

export default ButtonIcon;
