/* eslint-disable complexity */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { find, pick } from 'lodash';

import { formatMoney } from '@chownow/cn-web-utils/format';
import {
  ATTRIBUTES,
  orderAttributes,
  restaurantAttributes,
} from 'helpers/analytics';
import { orderItemType } from 'helpers/prop-types';
import { MODAL_TYPE } from 'helpers/modals';
import {
  logAnalyticsCommerceEvent,
  ANALYTICS_EVENT_NAME,
  createAnalyticsProduct,
} from 'helpers/loggers';
import {
  SELECT_ITEM_OPERATIONS,
  KEY_VALUES,
  REORDER_CATEGORY_NAME,
} from 'helpers/constants';
import { getIsDirectToMpRedirect } from 'helpers/configureRedirects';

import { CompanyContext } from 'context/CompanyContext';
import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';

import useSelectItem from 'hooks/useSelectItem';

import {
  getOrderItemData,
  removeOrderItemRequest,
  getOrderData,
} from 'modules/order';
import { getRestaurantDetails } from 'modules/restaurant';

import ButtonLink from 'primitives/ButtonLink';

import styles from './styles.module.scss';

function OrderItem(props) {
  const { isCheckout, isReadOnly, item, isExpanded, sourcePage } = props;

  const { isCanadian } = useContext(CompanyContext);
  const { menu } = useContext(MenuContext);
  const { openModal } = useContext(ModalContext);

  const dispatch = useDispatch();
  const onSelectItem = useSelectItem();
  const items = useSelector(getOrderItemData);

  const {
    tracking_id: trackingId,
    quantity,
    modifierNames,
    specialInstructions,
    name,
    item_sum_total: itemSumTotal,
    price,
    priceWithModifiers,
    size,
    id,
    image,
    categoryName,
    isOneClick,
    itemIndex,
  } = item;

  const filteredSize = size !== 'Regular' ? size : undefined;
  const hasModifiers = modifierNames && modifierNames.length > 0;
  // FIXME: (CN-23802)
  // if isReadOnly then user is on confirmation page, show item_sum_total that is taken from order.items
  // priceWithModifiers was added to orderstore for the getOrderItemData selector to be used for menu and checkout
  const itemPrice = isReadOnly ? itemSumTotal : priceWithModifiers;
  const restaurant = useSelector(getRestaurantDetails);
  const order = useSelector(getOrderData);

  function handleEditItem() {
    onSelectItem({
      itemId: trackingId,
      operation: SELECT_ITEM_OPERATIONS.edit,
      sourcePage,
    });
  }

  function handleRemoveItem() {
    const orderItem = find(items, {
      tracking_id: trackingId,
    });

    if (isCheckout) {
      openModal(MODAL_TYPE.dialog, {
        title: 'Remove Item',
        message: `Delete ${orderItem.name} from your order?`,
        confirmLabel: 'Remove',
        dismissLabel: 'Cancel',
        isAlert: true,
        onConfirm: () => {
          dispatch({
            type: removeOrderItemRequest.TYPE,
            payload: orderItem,
            meta: trackingId,
          });
        },
      });
    } else {
      dispatch({
        type: removeOrderItemRequest.TYPE,
        payload: orderItem,
        meta: trackingId,
      });
    }

    const product = [
      createAnalyticsProduct(
        name,
        id,
        price.toFixed(2),
        quantity,
        categoryName,
        isCanadian ? 'CAD' : 'USD'
      ),
    ];

    const menuItemSource =
      categoryName === REORDER_CATEGORY_NAME &&
      (isOneClick ? 'one_click_reorder' : 'reorder_carousel');

    const attributes = {
      item_has_photo: !!image,
      is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
      is_delete: true,
      is_embedded_site: window.top !== window,
      is_cart_update: false,
      menu_id: menu.id,
      menu_item_source: menuItemSource,
      menu_item_location: !!menuItemSource && itemIndex + 1,
      ...pick(orderAttributes(order), [
        ATTRIBUTES.orderAheadDatetime,
        ATTRIBUTES.orderIsAsap,
        ATTRIBUTES.orderType,
      ]),
      ...pick(restaurantAttributes(restaurant), [
        ATTRIBUTES.restaurantBrandId,
        ATTRIBUTES.restaurantLocationCategory,
        ATTRIBUTES.restaurantLocationId,
        ATTRIBUTES.restaurantLocationName,
      ]),
    };

    logAnalyticsCommerceEvent(
      ANALYTICS_EVENT_NAME.addToCart,
      product,
      attributes
    );
  }

  let nestedMods = [];
  function getNestedMods(modifierCategories) {
    if (!modifierCategories.length) return;
    modifierCategories.forEach((modCat) => {
      modCat.selections.forEach((selection) => {
        nestedMods.push(selection.name);
        getNestedMods(selection.modifier_categories);
      });
    });
  }

  function getModifierList() {
    // if on menu or checkout just return modifierNames
    if (!isReadOnly) return modifierNames;

    const modNameList = [];
    item.modifier_categories.forEach((modCat) => {
      modCat.selections.forEach((selection) => {
        if (selection.modifier_categories?.length) {
          getNestedMods(selection.modifier_categories);
          const childrenMods = nestedMods.length
            ? ` (${nestedMods.join(', ')})`
            : '';
          const nestedList = `${selection.name}${childrenMods}`;
          modNameList.push(nestedList);
          nestedMods = []; // clear out nested mods for future mods that have nested selections
        } else {
          modNameList.push(selection.name);
        }
      });
    });

    return modNameList;
  }

  return (
    <li
      className={classNames([
        styles.itemWrapper,
        {
          [styles.editableItem]: !isReadOnly,
        },
      ])}
    >
      <div
        className={styles.orderItem}
        onClick={() =>
          !isReadOnly &&
          onSelectItem({
            itemId: trackingId,
            operation: SELECT_ITEM_OPERATIONS.edit,
          })
        }
        onKeyDown={(e) =>
          !isReadOnly &&
          e.key === KEY_VALUES.enter &&
          onSelectItem({
            itemId: trackingId,
            operation: SELECT_ITEM_OPERATIONS.edit,
          })
        }
        role="button"
        tabIndex="0"
      >
        <div className={styles.quantity} data-testid="ItemQuantity">
          {quantity}
        </div>
        <div className={styles.label}>
          <div className={styles.itemNamePriceWrapper}>
            <span className={styles.itemName} data-testid="BagItem">
              {name}
            </span>
            <div className={styles.price} data-testid="BagItemPrice">
              {formatMoney(itemPrice)}
            </div>
          </div>

          {isExpanded && (hasModifiers || specialInstructions || filteredSize) && (
            <div className={styles.itemDetails}>
              {(filteredSize || hasModifiers) && (
                <ul>
                  {size && (
                    <li
                      className={styles.modifier}
                      data-testid="BagServingSize"
                    >
                      {size}
                      {modifierNames.length ? ', ' : ''}
                    </li>
                  )}
                  {getModifierList().map((mod, index) => (
                    <li
                      key={mod[index]}
                      className={styles.modifier}
                      data-testid="BagModifier"
                    >
                      <div className={styles.modName}>{mod}</div>
                      {index <= modifierNames.length - 2 && <span>, </span>}
                    </li>
                  ))}
                </ul>
              )}
              {specialInstructions && (
                <div
                  className={classNames(styles.specialInstructions, {
                    [styles.padTop]: hasModifiers,
                  })}
                  data-testid="BagItemInstructions"
                >
                  {specialInstructions}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!isReadOnly && isExpanded && (
        <div className={styles.itemButtonContainer}>
          <ButtonLink
            onClick={handleEditItem}
            size="small"
            dataTestId="EditItem"
          >
            Edit Item
          </ButtonLink>
          <ButtonLink
            onClick={handleRemoveItem}
            size="small"
            dataTestId="RemoveItem"
          >
            Remove
          </ButtonLink>
        </div>
      )}
    </li>
  );
}

OrderItem.propTypes = {
  isCheckout: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  item: orderItemType.isRequired,
  sourcePage: PropTypes.string,
};

OrderItem.defaultProps = {
  isCheckout: false,
  isExpanded: false,
  isReadOnly: false,
  sourcePage: undefined,
};

export default OrderItem;
