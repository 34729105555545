/* eslint-disable complexity, react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';

import { FULFILLMENT_METHODS } from 'helpers/order';
import { PLATFORM } from 'helpers/constants';
import { buildRoute, ROUTES } from 'helpers/routes';

import PlatformContext from 'context/PlatformContext';

import Tag from 'primitives/Tag';

import { ReactComponent as IconHeart } from 'images/icon-heart.svg';
import { ReactComponent as PictoCurbside } from 'images/picto-curbside.svg';
import { ReactComponent as PictoDelivery } from 'images/picto-delivery.svg';
import { ReactComponent as PictoDineIn } from 'images/picto-dinein.svg';
import { ReactComponent as PictoPickup } from 'images/picto-pickup.svg';
import { ReactComponent as IconGold } from 'images/icon-member-gold.svg';

import styles from './styles.module.scss';

const PICTO = {
  curbside: PictoCurbside,
  delivery: PictoDelivery,
  dineIn: PictoDineIn,
  pickup: PictoPickup,
};

function OrderDetails({
  isInProgress,
  isOrderCancelled,
  handleToggleImpactScoreModal,
  order,
  restaurant,
  showMembershipBanner,
}) {
  const {
    fulfill_method: fulfillMethod,
    order_restaurant_savings: restaurantSavings,
  } = order;
  const { platform } = useContext(PlatformContext);
  const isMarketplacePlatform = platform === PLATFORM.marketplace;
  const restaurantName = get(restaurant, 'short_name');
  const isDineIn = fulfillMethod === FULFILLMENT_METHODS.dineIn;
  const hqId = restaurant && restaurant.company_id;
  const restaurantId = restaurant && restaurant.id;
  const membershipPlansRoute = buildRoute({
    hqId,
    restaurantId,
    route: ROUTES.membershipPlans,
  });

  const Picto = isDineIn ? PICTO.dineIn : PICTO[fulfillMethod];

  // always show orderDetails for dine-in orders
  // and if order is not in progress
  const canShowOrderDetails = !isInProgress;

  return (
    <div className={styles.detailsModule}>
      {isOrderCancelled ? (
        <div className={styles.tagWrapper}>
          <Tag>canceled</Tag>
        </div>
      ) : (
        <div className={styles.sticker}>
          <Picto />
        </div>
      )}
      {!restaurant ? (
        <>
          <div className={styles.header}>
            {isOrderCancelled
              ? `Your order has been canceled`
              : 'We love keeping our restaurant data secure!'}
          </div>
          <p className={styles.bodyText}>
            To view more of your order details, check your email or create an
            account.
          </p>
        </>
      ) : (
        <>
          {canShowOrderDetails ? (
            <>
              {restaurant && (
                <a
                  className={styles.restaurantName}
                  href={`/order/${restaurant.company_id}/locations/${restaurant.id}`}
                  data-testid="ConfirmationRestaurantName"
                >
                  {isOrderCancelled
                    ? `Your order with ${restaurantName} has been canceled`
                    : `Thank you for ordering with ${restaurantName}`}
                </a>
              )}
            </>
          ) : (
            <>
              <div className={styles.header}>
                Thank you for ordering with {restaurantName}
              </div>
              <p className={styles.bodyText}>
                An email confirmation has been sent. Check back here to see
                updates.
              </p>
            </>
          )}
        </>
      )}
      {isMarketplacePlatform && !isOrderCancelled && restaurantSavings && (
        <div className={styles.subModule}>
          <IconHeart className={styles.iconHeart} />
          Your order saved this restaurant
          <button
            className={styles.savingsLink}
            onClick={handleToggleImpactScoreModal}
            type="button"
          >
            ${restaurantSavings.toFixed(2)}
          </button>
        </div>
      )}
      {showMembershipBanner && (
        <div className={classNames(styles.subModule, styles.centered)}>
          <IconGold className={styles.iconBadge} />
          <span className={styles.memberText}>
            Become a member to save up to 25% on every order.{' '}
            <a target="_blank" rel="noreferrer" href={membershipPlansRoute}>
              Learn more
            </a>
          </span>
        </div>
      )}
    </div>
  );
}

OrderDetails.propTypes = {
  isInProgress: PropTypes.bool,
  isOrderCancelled: PropTypes.bool,
  handleToggleImpactScoreModal: PropTypes.func,
  order: PropTypes.shape({
    fulfill_method: PropTypes.string,
    fulfillment_times: PropTypes.shape({
      created_at: PropTypes.string,
      estimated_arrival: PropTypes.string,
      estimated_pickup: PropTypes.string,
      requested_fulfillment: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    id: PropTypes.string,
    order_restaurant_savings: PropTypes.number,
  }).isRequired,
  restaurant: PropTypes.shape({
    company_id: PropTypes.string,
    id: PropTypes.string,
    phone: PropTypes.string,
    short_name: PropTypes.string,
  }),
  showMembershipBanner: PropTypes.bool.isRequired,
};

OrderDetails.defaultProps = {
  isInProgress: false,
  isOrderCancelled: false,
  handleToggleImpactScoreModal: () => {},
  restaurant: {},
};

export default OrderDetails;
