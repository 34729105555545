/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ModalContext } from 'context/ModalContext';

import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';

import usePlatform from 'hooks/usePlatform';

import { getRestaurantDetails } from 'modules/restaurant';

import { ReactComponent as IconPhone } from 'images/icon-phone.svg';

import styles from './styles.module.scss';

function DownloadAppLink() {
  const { openModal } = useContext(ModalContext);

  const { isMarketplacePlatform } = usePlatform();

  const restaurant = useSelector(getRestaurantDetails);

  const hasApp = restaurant?.ios_app_store_id || restaurant?.android_app_url;
  const canShowDownloadAppLink =
    isMarketplacePlatform ||
    (hasApp && restaurant?.display_chownow_app_banner) ||
    !hasApp;

  function handleDownloadClick() {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: { name: 'download_chownow', source: 'navbar' },
    });

    openModal(MODAL_TYPE.downloadApp);
  }

  return (
    <>
      {canShowDownloadAppLink && (
        <button
          className={styles.downloadLink}
          type="button"
          onClick={handleDownloadClick}
        >
          <IconPhone className={styles.iconPhone} />
          {isMarketplacePlatform ? 'Download App' : 'Get the ChowNow App'}
        </button>
      )}
    </>
  );
}

export default DownloadAppLink;
