import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

import styles from './styles.module.scss';

interface ModalHeaderProps {
  className?: string;
  title?: string;
  subtitle?: string | ReactElement;
  isSticky?: boolean; // used in ModalFrame
}

function ModalHeader({
  className = '',
  title,
  subtitle,
  isSticky = false,
}: ModalHeaderProps) {
  const [showStickyHeader, setShowStickyHeader] = useState(false);

  function handleIntersection(inView: boolean) {
    if (!isSticky) return;

    if (!inView) {
      setShowStickyHeader(true);
    } else {
      setShowStickyHeader(false);
    }
  }

  const defaultProps = { transform: 'translateY(-100%)' };
  const [springProps, set] = useSpring(() => ({
    ...defaultProps,
    config: { tension: 350, friction: 32 },
  }));

  if (showStickyHeader) {
    set({ transform: 'translateY(-33%)' });
  } else {
    set(defaultProps);
  }

  return (
    <div className={classNames(styles.modalheader, className)}>
      <animated.div
        className={classNames(styles.stickyHeader, {
          [styles.isVisible]: showStickyHeader,
        })}
        style={{ transform: springProps.transform }}
      >
        <div className={styles.title}>{title}</div>
      </animated.div>
      <InView onChange={handleIntersection} threshold={1}>
        {title && (
          <div
            className={classNames(styles.title, {
              [styles.withSubtitle]: !!subtitle,
            })}
          >
            {title}
          </div>
        )}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </InView>
    </div>
  );
}

export default ModalHeader;
