import React from 'react';

export default function IconCar() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_150_489)">
        <path
          d="M14.2224 7.57517C13.9239 7.42263 13.7218 7.13072 13.6839 6.7977C13.6123 6.16829 13.2095 5.39479 12.4789 4.74983C11.6721 4.03755 10.4849 3.5 8.97829 3.5C7.47163 3.5 6.2845 4.03755 5.47768 4.74983C4.75977 5.38363 4.35711 6.14293 4.27634 6.76761C4.22816 7.14021 3.97519 7.45437 3.62145 7.58093L1.00002 8.51878V11.5C1.00002 11.5 1.58212 11.4947 1.58917 11.4997H2.5631C2.52192 11.6596 2.50002 11.8272 2.50002 12C2.50002 12.1726 2.52189 12.3402 2.56302 12.5H1C0.5 12.5 0 12 0 11.5L1.52588e-05 8.16663C1.52588e-05 7.95542 0.132726 7.76699 0.331588 7.69585L3.28459 6.63937C3.40273 5.72577 3.95682 4.75855 4.81586 4.00017C5.80253 3.12911 7.22409 2.5 8.97829 2.5C10.7325 2.5 12.154 3.12911 13.1407 4.00017C14.014 4.77112 14.5721 5.75788 14.6775 6.68474L15.7276 7.22145C15.8948 7.30692 16 7.47888 16 7.66667L16 11.5C16 12 15.5 12.5 15 12.5H13.937C13.9781 12.3402 14 12.1726 14 12C14 11.8272 13.9781 11.6596 13.9369 11.4997L15 11.5L15 7.97263L14.2224 7.57517Z"
          fill="#1F1F1F"
        />
        <path
          d="M10.0631 11.4997H6.43693C6.47811 11.6596 6.50002 11.8272 6.50002 12C6.50002 12.1726 6.47814 12.3402 6.43701 12.5H10.063C10.0219 12.3402 10 12.1726 10 12C10 11.8272 10.0219 11.6596 10.0631 11.4997Z"
          fill="#1F1F1F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 13C5.32843 13 6 12.3284 6 11.5C6 10.6716 5.32843 10 4.5 10C3.67157 10 3 10.6716 3 11.5C3 12.3284 3.67157 13 4.5 13ZM4.5 14C5.88071 14 7 12.8807 7 11.5C7 10.1193 5.88071 9 4.5 9C3.11929 9 2 10.1193 2 11.5C2 12.8807 3.11929 14 4.5 14Z"
          fill="#1F1F1F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 13C12.8284 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8284 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13ZM12 14C13.3807 14 14.5 12.8807 14.5 11.5C14.5 10.1193 13.3807 9 12 9C10.6193 9 9.5 10.1193 9.5 11.5C9.5 12.8807 10.6193 14 12 14Z"
          fill="#1F1F1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_150_489">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
