import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { includes } from 'lodash';

import { BETTER_DEAL_APPLIED, INVALID_PROMO_CODE } from 'helpers/api';

import { ModalContext } from 'context/ModalContext';

import usePrevious from 'hooks/usePrevious';

import {
  getErrorsForOrder,
  getActiveDiscount,
  setPromoCodeRequest,
} from 'modules/order';

import Input from 'primitives/Input';
import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import styles from './styles.module.scss';

const PROMO_ERRORS = [INVALID_PROMO_CODE, BETTER_DEAL_APPLIED];

function PromoModal() {
  const dispatch = useDispatch();

  const { closeModal } = useContext(ModalContext);

  const discount = useSelector(getActiveDiscount);
  const errors = useSelector(getErrorsForOrder);

  const promoCode = discount?.promo_code || '';
  const promoErrors = errors.filter((error) =>
    includes(PROMO_ERRORS, error.code)
  );
  const betterPromo = errors.filter(
    (error) => error.code === BETTER_DEAL_APPLIED
  ).length;
  const invalidPromo = errors.filter(
    (error) => error.code === INVALID_PROMO_CODE
  ).length;

  const [promo, setPromo] = useState(promoCode);
  const [hasErrors, setHasErrors] = useState(false);

  const prevPromoCode = usePrevious(promoCode);
  const prevPromoInput = usePrevious(promo);

  useEffect(() => {
    if (
      prevPromoCode !== undefined &&
      prevPromoCode !== promoCode &&
      !promoErrors.length
    ) {
      closeModal();
    }
  }, [promoCode]);

  useEffect(() => {
    // only show the error state while the incorrectly enter promo is still in the input
    if (!!promoErrors.length && prevPromoInput === promo) {
      setHasErrors(true);
    }
  }, [promoErrors]);

  function handleInputChange(value) {
    setPromo(value);

    if (hasErrors) {
      setHasErrors(false);
    }
  }

  function handleApplyPromo(newPromo) {
    dispatch({
      type: setPromoCodeRequest.TYPE,
      payload: newPromo.trim(),
    });
  }

  return (
    <Modal>
      <ModalHeader title="Promo Code" />
      <div>
        <Input
          type="text"
          label="Promo code"
          name="promo"
          shouldHideLabel
          value={promo}
          onChange={handleInputChange}
          errorText={hasErrors && invalidPromo && promoErrors[0].message}
        />
        {hasErrors && betterPromo && !invalidPromo ? (
          <span className={styles.betterPromo}>{promoErrors[0].message}</span>
        ) : (
          ''
        )}
      </div>
      <ModalFooter>
        <ModalFooterButton
          handleClick={() => handleApplyPromo(promo)}
          disabled={hasErrors}
        >
          Apply
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
}

export default PromoModal;
