/* eslint-disable complexity */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import useFetchMenu from 'hooks/useFetchMenu';

import { FULFILLMENT_METHODS } from 'helpers/order';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';

import Button from 'primitives/Button';

import IconBag from 'svgs/IconBag';
import IconCar from 'svgs/IconCar';

import { handleOrderAgain } from './helpers';

import styles from './styles.module.scss';

export default function Dock({
  isReceipt,
  isReorderAllowed,
  order,
  restaurantId,
  showTipAdjustment,
}) {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  const [shouldRefetchMenu, setShouldRefetchMenu] = useState(false);

  const { fulfill_method: fulfillMethod, id } = order;
  const isDelivery = fulfillMethod === FULFILLMENT_METHODS.delivery;
  const isCurbside = fulfillMethod === FULFILLMENT_METHODS.curbside;

  const { description, tip_options: tipOptions } = order.adjustments?.tip || {};
  const dinerHasTipped = tipOptions && tipOptions.find((t) => t.is_current_tip);
  const adjustTipBtnLabel = dinerHasTipped ? 'Increase Tip' : 'Add Tip';
  const icon = isDelivery ? <IconCar /> : <IconBag />;

  const showReorder =
    (isReorderAllowed && !showTipAdjustment) || (isReceipt && isReorderAllowed);
  const showTipAndReorder = showTipAdjustment && isReorderAllowed && isReceipt;
  const showDescription = !showReorder && description;

  useFetchMenu({
    restaurantId,
    shouldRefetch: shouldRefetchMenu,
  });

  function handleTipAjustment() {
    openModal(MODAL_TYPE.tipAdjustment, {
      currentTip: dinerHasTipped,
      orderId: id,
      tipOptions,
    });

    const name = dinerHasTipped ? 'increase_tip' : 'add_tip';

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: {
        source: 'order_detail',
        name,
      },
    });
  }

  return (
    <div className={styles.adjustTipCta}>
      {showDescription && (
        <div className={styles.info}>
          <div className={styles.icon}>{icon}</div>
          <p className={styles.description}>{description}</p>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        {showTipAdjustment && (
          <Button
            className={classNames(styles.tipText, {
              [styles.adjustTipBtn]: showTipAndReorder,
            })}
            isFullWidth
            onClick={handleTipAjustment}
            variant="outline"
          >
            {adjustTipBtnLabel}
          </Button>
        )}
        {showReorder && (
          <Button
            className={classNames({ [styles.reorderBtn]: showTipAndReorder })}
            isFullWidth
            onClick={() => {
              handleOrderAgain({
                dispatch,
                isCurbside,
                isDelivery,
                order,
                setShouldRefetchMenu,
                user,
              });
            }}
          >
            Reorder
          </Button>
        )}
      </div>
    </div>
  );
}

Dock.propTypes = {
  isReceipt: PropTypes.bool.isRequired,
  isReorderAllowed: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    adjustments: PropTypes.shape({
      tip: PropTypes.shape({
        description: PropTypes.string,
        tip_options: PropTypes.arrayOf(
          PropTypes.shape({
            amount: PropTypes.number,
            rate: PropTypes.number,
            is_current_tip: PropTypes.bool,
          })
        ),
      }),
    }),
    fulfill_method: PropTypes.string,
    fulfillment_times: PropTypes.shape({
      estimated_arrival: PropTypes.string,
      estimated_pickup: PropTypes.string,
      requested_fulfillment: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    id: PropTypes.string,
    is_order_ahead: PropTypes.bool,
  }).isRequired,
  restaurantId: PropTypes.string,
  showTipAdjustment: PropTypes.bool.isRequired,
};

Dock.defaultProps = {
  restaurantId: undefined,
};
