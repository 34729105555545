import { setVehicleRequest, validateReorderXHR } from 'modules/order';
import { setSelectedAddress } from 'helpers/customer';

export function handleDeliveryReorder({ order, user }) {
  /* check if the address is saved, if not - replace id with null
    otherwise BE returns error on place order */
  const customerAddress = order.customer.delivery.address;
  const savedAddresses = user.delivery.addresses;
  const isSavedAddress = !!savedAddresses.filter(
    (address) => customerAddress.id === address.id
  ).length;

  setSelectedAddress({
    ...customerAddress,
    id: isSavedAddress ? customerAddress.id : null,
    delivery_instructions: order.delivery_instructions,
  });
}

export function handleCurbsideReorder({ order, dispatch }) {
  /* format vehicle values */
  const { vehicle } = order.customer.curbside;
  const vehicleType = vehicle.vehicle_type.toUpperCase();
  const vehicleColor = vehicle.color.toUpperCase();

  dispatch({
    type: setVehicleRequest.TYPE,
    payload: {
      vehicle_type: vehicleType,
      color: vehicleColor,
    },
  });
}

export function handleOrderAgain({
  dispatch,
  isCurbside,
  isDelivery,
  order,
  setShouldRefetchMenu,
  user,
}) {
  if (isDelivery) {
    handleDeliveryReorder({ order, user });
  }

  if (isCurbside) {
    handleCurbsideReorder({ order, dispatch });
  }

  // need to repopulate menu in order to get menu & category info for proper reorder
  setShouldRefetchMenu(true);
  // validating reorder xhr
  dispatch({
    type: validateReorderXHR.request.TYPE,
  });
}
