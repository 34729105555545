/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { buildRoute, ROUTES } from 'helpers/routes';
import { getHqIdFromUrl } from '@chownow/cn-web-utils/url';

import { getTimer, setOrderCompleted } from 'modules/order';

import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';

import styles from './styles.module.scss';

function PollingModal() {
  const history = useHistory();
  const dispatch = useDispatch();

  const timer = useSelector(getTimer);

  const hqId = getHqIdFromUrl();

  useEffect(() => {
    if (timer <= 0) {
      dispatch({
        type: setOrderCompleted.TYPE,
      });
      history.push(buildRoute({ hqId, route: ROUTES.support }));
    }
  }, [timer]);

  function timerToText() {
    if (timer <= 60) {
      // eslint-disable-next-line max-len
      return 'This is taking a little longer than we expected.  Sit tight and please do not refresh the page.';
    }
    if (timer <= 110) {
      return "We're still processing your order. Sit tight and please do not refresh this page.";
    }
  }

  return (
    <Modal
      className={styles.modalContentWrapper}
      centerAlign
      shouldShowCloseIcon={false}
    >
      <ModalHeader title="Sorry to keep you waiting" />
      <div className={styles.progressTracker}>
        {timerToText()}
        <div className={styles.progressBar}>
          <div className={styles.innerProgressBar} />
        </div>
      </div>
    </Modal>
  );
}

export default PollingModal;
