/* eslint-disable prefer-destructuring */
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { FULFILLMENT_METHODS } from 'helpers/order';

import { updateOrderRequest } from 'modules/order';

import SlideUpIn from 'components/Animations/SlideUpIn';
import FadeIn from 'components/Animations/FadeIn';

import { ModalContext } from 'context/ModalContext';

import useIsMobile from 'hooks/useIsMobile';
import usePlatform from 'hooks/usePlatform';
import { isYelpMobile } from 'helpers/yelp';

import styles from './styles.module.scss';

function transformTo({ isMobile, isYelpPlatform }) {
  if (isMobile) {
    return 'translate(-50%,-20%)';
  }

  if (isYelpPlatform) {
    return 'translate(-50%,0%)';
  }

  return 'translate(-50%,-50%)';
}

function getYelpMaxHeight() {
  // We need this to restrict the modal height within the Yelp iframe.
  // Without it we don't get a scrollbar since the modal stretches within
  // the "page" height which can be 1000's of px high depending on menu size
  const iframeHeight = window.outerHeight;
  return Math.floor(iframeHeight * 0.85);
}

function ModalWrapper(props) {
  const { children } = props;

  const { closeModal } = useContext(ModalContext);
  const { platform, isYelpPlatform } = usePlatform();
  const dispatch = useDispatch();

  const isYelpDesktop = isYelpPlatform && !isYelpMobile();
  const isMobile = useIsMobile();

  const maxHeight = isYelpDesktop ? getYelpMaxHeight() : undefined;
  const noCloseOverlay = children.props?.noCloseOverlay;

  const isAlert = children.props?.isAlert;
  const isInverse = children.props?.modalBackgroundInverse;

  function handleClose() {
    if (noCloseOverlay) return;

    // fulfillment details modal:
    // to be run in the case of delivery address error when user does not want to reenter address. we should default back to pickup
    if (children.props?.shouldDefaultToPickup) {
      dispatch({
        type: updateOrderRequest.TYPE,
        payload: {
          fulfillmentMethod: FULFILLMENT_METHODS.pickup,
        },
      });
    }

    if (children.props?.onClose) children.props?.onClose();

    closeModal();
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.modal, `theme-${platform}`, {
        [styles.alert]: isAlert,
      })}
    >
      <SlideUpIn
        maxHeight={maxHeight}
        className={classNames(styles.modalWrapper, {
          [styles.backgroundDark]: isInverse,
        })}
        transformFrom="translate(-50%,100%)"
        transformTo={transformTo({ isYelpPlatform, isMobile })}
      >
        <FadeIn duration={250} delay={350}>
          <div>{children}</div>
        </FadeIn>
      </SlideUpIn>
      <div
        className={styles.overlay}
        onClick={handleClose}
        aria-hidden="true"
        data-testid="ModalOverlay"
      />
    </div>,
    document.querySelector('#modal-root')
  );
}

export default ModalWrapper;
