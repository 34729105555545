/* eslint-disable complexity, no-console, import/prefer-default-export */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'focus-visible';
import 'intersection-observer';
import { PersistGate } from 'redux-persist/integration/react';
import { parse, stringify } from 'query-string';
import OutsideCallConsumer, { createCaller } from 'react-outside-call';
import { HelmetProvider } from 'react-helmet-async';

import {
  getRestaurantIdFromUrl,
  isMarketplacePlatform,
  isYelpPlatform,
} from '@chownow/cn-web-utils/url';
import {
  EXPERIMENT_COOKIE,
  NO_DIRECT_LIVE_CHANNEL,
  PLATFORM,
} from 'helpers/constants';
import {
  getURLRequiresRedirect,
  getRestaurantIsLiveForMarketplace,
  setIsDirectToMpRedirect,
  shouldRedirectToMarketplace,
  shouldDirectToWebsiteChannel,
} from 'helpers/configureRedirects';
import {
  initSentry,
  logMessage,
  logAnalyticsEvent,
  ANALYTICS_EVENT_NAME,
} from 'helpers/loggers';
import { initializeYelp } from 'helpers/yelp';
import { getHostnameForMarketplace } from 'helpers/url';
import { apiRequest } from '@chownow/cn-web-utils/api';

import React, { useContext } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import { CompanyContext, CompanyProvider } from 'context/CompanyContext';
import { GoogleMapsProvider } from 'context/GoogleMapsContext';
import { MenuContext, MenuProvider } from 'context/MenuContext';
import { ModalContext, ModalProvider } from 'context/ModalContext';
import { PlatformProvider } from 'context/PlatformContext';
import { RoktLauncherContextProvider } from 'context/RoktAdsContext';
import { UserContext, UserProvider } from 'context/UserContext';

import initStore from './initStore';
import App from './App';

import './styles/main.scss';

export function setExperimentCookie() {
  const cookies = new Cookies();
  // Set a 30 day cookie to store a UUID client side
  // Used for Launch Darkly experiments
  const activeCookieValue = cookies.get(EXPERIMENT_COOKIE);

  const expires = new Date();
  expires.setHours(expires.getDate() + 30 * 24); // 30 days from now

  let cookieAttrs = { expires, domain: '.chownow.com', path: '/' };

  if (!activeCookieValue) {
    if (isYelpPlatform()) {
      // We need sameSite: none and secure: true on Yelp since
      // this is a 3rdparty cookie and we're embedded in an iframe.
      //
      // See https://stackoverflow.com/questions/2117248/setting-cookie-in-iframe-different-domain
      cookieAttrs = {
        ...cookieAttrs,
        sameSite: 'none',
        secure: true,
      };
    }
    // The experiment cookie isn't set. Set it here.
    cookies.set(EXPERIMENT_COOKIE, `diner-${uuidv4()}`, cookieAttrs);
  } else {
    // The experiment cookie already exists. Rewrite it with the same value ensuring the domain is correct
    cookies.set(EXPERIMENT_COOKIE, `${activeCookieValue}`, cookieAttrs);
  }
}

// determine the platform we are going to theme based on cookie or url
function getPlatform() {
  const cookies = new Cookies();
  let platform = PLATFORM.direct;
  if (isMarketplacePlatform()) {
    platform = PLATFORM.marketplace;
  } else if (isYelpPlatform()) {
    platform = PLATFORM.yelp;
  }

  return cookies.get('cn_platform') || platform;
}

export const callConfig = createCaller({
  CompanyContext: () => useContext(CompanyContext),
  ModalContext: () => useContext(ModalContext),
  MenuContext: () => useContext(MenuContext),
  UserContext: () => useContext(UserContext),
});

// https://chownow.atlassian.net/browse/CN-19244
// Some restaurants are still sending traffic to direct and order subdomains via Instagram.
// These need to be redirected to eat./marketplace if the restaurant in question is listed in Marketplace.
// eslint-disable-next-line consistent-return
async function setUpApp() {
  setExperimentCookie();

  const path = window.location.pathname;
  const locationId = getRestaurantIdFromUrl();
  const isEmbedded = window.top !== window;
  const searchParams = parse(window.location.search);

  if (searchParams.is_mp_redirect) {
    setIsDirectToMpRedirect();
  }

  // check for instagram or facebook channels
  if (getURLRequiresRedirect(window.location)) {
    if (locationId && (await getRestaurantIsLiveForMarketplace(locationId))) {
      const domain = getHostnameForMarketplace();
      logMessage(`Redirecting OD Instagram URL to Marketplace ${domain}`);
      const redirect = `https://${domain}${path}${window.location.search}`;
      return redirect;
    }
  }

  if (locationId) {
    const request = apiRequest(`restaurant/${locationId}`);
    const response = await fetch(request.endpoint);
    const restaurant = await response.json();

    if (
      !isEmbedded &&
      (await shouldRedirectToMarketplace(locationId, restaurant))
    ) {
      setIsDirectToMpRedirect();
      searchParams.is_mp_redirect = true;

      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.redirectToMarketplaceMenu,
        attributes: {
          restaurant_location_id: locationId,
          source: 'direct-url',
        },
      });
      // flushes the event queue and forces the event to send to mParticle
      window.mParticle?.upload();
      const domain = getHostnameForMarketplace();
      const redirect = `https://${domain}${path}?${stringify(searchParams)}`;
      return redirect;
    }

    const queryParams = parse(window.location.search);
    const { cn_channel: cnChannelQuery } = queryParams;
    if (shouldDirectToWebsiteChannel(restaurant) && !cnChannelQuery) {
      // flushes the event queue and forces the event to send to mParticle
      window.mParticle?.upload();
      searchParams.cn_channel = NO_DIRECT_LIVE_CHANNEL;
      const redirect = `${window.location.origin}${path}?${stringify(
        searchParams
      )}`;
      return redirect;
    }
  }
}

// eslint-disable-next-line consistent-return
setUpApp().then((redirect) => {
  // If the promise returned by setUp resolves with a value, we bail on rendering and redirect
  if (redirect) {
    window.location.href = redirect;
    return false;
  }
  const { store, persistor } = initStore();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
  const cnPlatform = getPlatform();

  const isRoktAdsSandbox = process.env.REACT_APP_DEPLOY_ENV !== 'prod';

  initSentry(process.env.VERSION);
  initializeYelp();

  render(
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Elements stripe={stripePromise}>
              <PlatformProvider platform={cnPlatform}>
                <GoogleMapsProvider>
                  <CompanyProvider>
                    <UserProvider>
                      <MenuProvider>
                        <ModalProvider>
                          <OutsideCallConsumer config={callConfig}>
                            <RoktLauncherContextProvider
                              accountId={process.env.REACT_APP_ROKT_ACCOUNT_ID}
                              sandbox={isRoktAdsSandbox}
                            >
                              <App persistor={persistor} />
                            </RoktLauncherContextProvider>
                          </OutsideCallConsumer>
                        </ModalProvider>
                      </MenuProvider>
                    </UserProvider>
                  </CompanyProvider>
                </GoogleMapsProvider>
              </PlatformProvider>
            </Elements>
          </Router>
        </PersistGate>
      </Provider>
    </HelmetProvider>,
    document.getElementById('root')
  );
});
