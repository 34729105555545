import { useContext } from 'react';
import PlatformContext from 'context/PlatformContext';

import {
  isChowNow,
  isDirect,
  isMarketplace,
  isStandardPayment,
  isYelp,
} from 'helpers/platform';

function usePlatform() {
  const { platform } = useContext(PlatformContext);

  return {
    isChowNowPlatform: isChowNow(platform),
    isDirectPlatform: isDirect(platform),
    isMarketplacePlatform: isMarketplace(platform),
    isStandardPaymentPlatform: isStandardPayment(platform),
    isYelpPlatform: isYelp(platform),
    platform,
  };
}

export default usePlatform;
