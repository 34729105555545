/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

// Modified from https://usehooks.com/useScript/
const useScript = (src, body, removeScript) => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );

  useEffect(() => {
    if (!body) {
      return;
    }

    const isScriptPresent = !!Array.from(
      document.querySelectorAll('script')
    ).find((el) => el.textContent === body);

    // do not add script if already added
    if (isScriptPresent) {
      return;
    }

    // Create script
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = body;
    // Add script to document body
    document.body.appendChild(script);
  }, [body]);

  useEffect(() => {
    if (!removeScript) {
      return;
    }

    // Find script
    const script = Array.from(document.querySelectorAll('script')).find(
      (el) => el.textContent === removeScript
    );

    if (!script) {
      return;
    }

    // Remove script from document body
    document.body.removeChild(script);

    // reload page to remove script
    window.location.reload();
  }, [removeScript]);

  return status;
};

export default useScript;
