import { TipOption } from './types';

export function getIsCustomTipActive(
  tipOptions: TipOption[],
  currentTip: number
): boolean {
  if (!currentTip) return false;
  const currentTipIsPreselection = tipOptions.some(
    (option) => option.amount === currentTip
  );

  return !currentTipIsPreselection;
}

export function calculateTip(subTotal: number, percentage: number): number {
  return Number(((subTotal * percentage) / 100).toFixed(2));
}

export function getFormattedTipOptions(
  subTotal: number,
  percentages: number[]
): TipOption[] {
  return percentages.map((p) => ({
    rate: p,
    amount: calculateTip(subTotal, p),
  }));
}
