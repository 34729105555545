import React from 'react';

import { ReactComponent as IconBell } from 'images/icon-bell.svg';

import styles from './styles.module.scss';

function MenuPreviewBanner() {
  return (
    <div className={styles.wrapper}>
      <IconBell />
      <h4 className={styles.heading}>
        Sorry, we&apos;re currently not accepting orders
      </h4>
      <p className={styles.subText}>Ordering Unavailable</p>
    </div>
  );
}

export default MenuPreviewBanner;
