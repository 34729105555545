import { useLayoutEffect, useState } from 'react';
import { throttle } from 'lodash';

import breakpoints from 'helpers/breakpoints';
import usePlatform from './usePlatform';

// Hook to measure window size on the client. Default is true.
function useIsMobile() {
  const { isYelpPlatform } = usePlatform();
  const [isMobile, setIsMobile] = useState(true);

  // useLayoutEffect fires before the browser actually paints to the DOM. This avoids a flash
  // https://reactjs.org/docs/hooks-reference.html#uselayouteffect
  useLayoutEffect(() => {
    if (window.innerWidth >= breakpoints.xsMax) {
      setIsMobile(false);
    }
    const throttled = throttle(() => {
      if (window.innerWidth >= breakpoints.xsMax) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }, 100);


    // Yelp platform is not resizable (Yelp loads web-ordering into a non-resizeable iframe)
    if (isYelpPlatform) {
      // Explicitly return "undefined" to avoid ESLint flagging the consistent-return rule for this effect.
      return undefined;
    }

    const resizeListener = window.addEventListener('resize', throttled);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return isMobile;
}

export default useIsMobile;
