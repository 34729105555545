/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  differenceInMilliseconds,
  differenceInHours,
  startOfSecond,
} from 'date-fns';
import { get } from 'lodash';

import usePrevious from 'hooks/usePrevious';

import {
  FULFILLMENT_METHODS,
  ORDER_STATUSES,
  getIsOrderFulfilled,
  getOrderReadyTime,
} from 'helpers/order';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { getSelectedOrder } from 'modules/order';

import styles from './styles.module.scss';

const ANIMATION_DURATION = 2500;
const INITIAL_PROGRESS = 3;
let orderReadyTime;

function ProgressBar(props) {
  const { onNotification, onTransition } = props;

  const [barWidth, setBarWidth] = useState(3);
  const [barDuration, setBarDuration] = useState(0);

  const order = useSelector(getSelectedOrder);

  const { status } = order;

  const prevStatus = usePrevious(status);

  function getIsOutsideTimeRange() {
    return differenceInHours(orderReadyTime, new Date()) > 1;
  }

  function getStartTime() {
    const {
      fulfillment_times: fulfillmentTimes,
      local_ordered_time: localOrderedTime,
    } = order;

    return getDateForApiDateStr(
      get(fulfillmentTimes, 'created_at') || localOrderedTime
    );
  }

  function getStartingWidth() {
    const COMPLETED_PERCENT = 100;
    const ACCEPTED_START_PERCENT = 40;
    const now = new Date();
    const startTime = getStartTime();
    const timeTotal = differenceInMilliseconds(orderReadyTime, startTime);
    const timePassed = differenceInMilliseconds(now, startTime);
    /*
      This calculation starts the animation at the acceptedStart stage,
      which is always a minimum of 40% + time passed
    */
    return timePassed > timeTotal
      ? COMPLETED_PERCENT
      : (COMPLETED_PERCENT - ACCEPTED_START_PERCENT) *
          (timePassed / timeTotal) +
          ACCEPTED_START_PERCENT;
  }

  function handleOnTransition() {
    if (onTransition) onTransition();
  }

  function getEstimatedDuration() {
    return differenceInMilliseconds(orderReadyTime, startOfSecond(new Date()));
  }

  function handleAcceptedEndTimeout(isMounted) {
    setTimeout(() => {
      setBarWidth(100);
      setBarDuration(0);

      handleOnTransition();

      if (
        !isMounted &&
        order.fulfill_method === FULFILLMENT_METHODS.pickup &&
        onNotification
      ) {
        onNotification('Your order is ready for pickup!');
      }
    }, getEstimatedDuration());
  }

  function handleAcceptedStartTimeout(isMounted, transitionTime) {
    setTimeout(() => {
      setBarWidth(100);
      setBarDuration(getEstimatedDuration());

      handleAcceptedEndTimeout(isMounted);
    }, transitionTime);
  }

  function setProgressBar(isMounted) {
    const isOrderFulfilled = getIsOrderFulfilled(order);
    const transitionTime = isMounted ? 0 : ANIMATION_DURATION;

    if (status === ORDER_STATUSES.canceled || getIsOutsideTimeRange()) {
      setBarWidth(0);
      setBarDuration(0);

      if (!isMounted && onNotification) {
        onNotification('Your order has been canceled.');
      }
    } else if (isOrderFulfilled) {
      setBarWidth(100);
      setBarDuration(0);
    } else if (status === ORDER_STATUSES.accepted) {
      if (!isMounted && onNotification) {
        onNotification('Your order is now underway!');
      }
      const startingWidth = getStartingWidth();

      handleOnTransition();
      // Forces CSS animation to rerender
      window.requestAnimationFrame(() => {
        setBarWidth(startingWidth);
        setBarDuration(transitionTime);
      });

      // Transition between acceptedStart and acceptedEnd
      handleAcceptedStartTimeout(isMounted, transitionTime);
    }
  }

  useEffect(() => {
    orderReadyTime = getOrderReadyTime(order);
    setProgressBar(true);

    return () => {
      setProgressBar(true);
      clearTimeout(handleAcceptedStartTimeout);
      clearTimeout(handleAcceptedEndTimeout);
    };
  }, []);

  useEffect(() => {
    if (prevStatus && status !== ORDER_STATUSES.submitted) {
      orderReadyTime = getOrderReadyTime(order);
      setProgressBar();
    }
  }, [status]);

  return (
    <div className={styles.bar} data-testid="ProgressBar">
      <div
        className={styles.innerBar}
        style={{
          width: `${
            orderReadyTime || status === ORDER_STATUSES.canceled
              ? barWidth
              : INITIAL_PROGRESS
          }%`,
          transition: `width ${barDuration}ms ease`,
        }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  onNotification: PropTypes.func,
  onTransition: PropTypes.func,
};

ProgressBar.defaultProps = {
  onNotification: undefined,
  onTransition: undefined,
};

export default ProgressBar;
