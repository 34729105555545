import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface TagProps {
  children: React.ReactNode;
  dataTestId?: string;
  icon?: React.ReactNode;
  id?: string;
  isCaution?: boolean;
}

function Tag({ children, dataTestId, icon, id, isCaution = false }: TagProps) {
  return (
    <div
      data-testid={dataTestId}
      className={classNames(styles.tag, { [styles.caution]: isCaution })}
      id={id}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </div>
  );
}

export default Tag;
