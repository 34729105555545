import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SlideInDown from 'components/Animations/SlideInDown';

import styles from './styles.module.scss';

function DropdownWrapper({ className, children }) {
  return (
    <SlideInDown
      transformFrom="16px"
      className={classNames(styles.dropdownWrapper, className)}
    >
      {children}
    </SlideInDown>
  );
}

DropdownWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

DropdownWrapper.defaultProps = {
  className: '',
};

export default DropdownWrapper;
