/* eslint-disable complexity */
import { format, parse } from 'date-fns';

import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { formatMoney } from '@chownow/cn-web-utils/format';
import { FULFILLMENT_METHODS } from 'helpers/order';
import { logException } from 'helpers/loggers';

export function formatCuisineString(cuisine: [] | string): string {
  return Array.isArray(cuisine) ? cuisine.join(', ') : cuisine;
}

export function onlyNums(value?: string): string | null {
  if (value === null || value === undefined) {
    return null;
  }

  return value.replace(/[^\d]/g, '');
}

export function removePhoneCountryCode(value?: string): string {
  if (!value) {
    return '';
  }

  // @ts-expect-error its wrong
  return onlyNums(value).slice(-10);
}

export function formatPhone(value?: string): string {
  return value
    ? `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
    : '';
}

export function formatSimplePhone(value?: string): string {
  return value
    ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
    : '';
}

export function formatDate(dateTime: string): string {
  const timestamp = getDateForApiDateStr(dateTime);
  if (timestamp) {
    return format(timestamp, 'EEEE, MMM d') as string;
  }
  // @ts-expect-error can remove after helpers/loggers converted to TS
  logException({ message: 'Tried to format null dateTime value' });
  return '';
}

export function formatExpDate(value: string): {
  exp_month: number;
  exp_year: number;
} {
  return {
    exp_month: parseInt(value.slice(0, -3), 10),
    exp_year: parseInt(`20${value.slice(-2)}`, 10),
  };
}

export function formatFulfillMethod(method: string): string {
  return method === FULFILLMENT_METHODS.dineIn ? 'dine-in' : method;
}

export function reverseFormatExpDate(
  expMonth: number,
  expYear: number
): string {
  // NOTE: javascript Date constructor uses 0 for January
  return format(new Date(expYear, expMonth - 1), 'MM/yy') as string;
}

export function formatTime(time: string): string {
  const timeSplit = time.split(':');
  return format(
    new Date(0, 0, 0, Number(timeSplit[0]), Number(timeSplit[1])),
    'h:mm a'
  ) as string;
}

export function formatClosure(closure: string): string {
  const parsedDate = parse(closure, 'yyyy-MM-dd HH:mm:ss', new Date());
  const day = format(parsedDate, 'MMM dd');
  const time = format(parsedDate, 'h:mm a');
  return `${day} at ${time}`;
}

export function formatDiscount(price: number): string {
  if (Number.isInteger(price)) {
    return `$${price}`;
  }
  return formatMoney(price) as string;
}

export function toTitleCase(string?: string): string {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
}
