/* eslint-disable complexity, jsx-a11y/label-has-associated-control, jsx-a11y/role-supports-aria-props, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { KEY_VALUES } from 'helpers/constants';

import { MenuContext } from 'context/MenuContext';

import ButtonLink from 'primitives/ButtonLink';
import Checkbox from 'primitives/Checkbox';
import Radio from 'primitives/Radio';

import { ReactComponent as IconCaretRight } from 'images/icon-caretright.svg';

import styles from './styles.module.scss';

function Modifier({
  branch,
  categoryId,
  disabled,
  handleEditOptionsBtn,
  id,
  isRadio,
  isSelected,
  isSizeMod,
  name,
  nestedMods,
  onClick,
  price,
}) {
  const { getChildModifiers } = useContext(MenuContext);

  function getBannerList() {
    if (branch && !isEmpty(branch)) {
      const childModifierNames = getChildModifiers(branch);
      return childModifierNames.join(', ');
    }

    return '';
  }

  const bannerList = getBannerList();
  const hasNestedMods = !isSizeMod && nestedMods && !!nestedMods.length;

  function handleKeyPress(event) {
    if (event.key === KEY_VALUES.enter) {
      onClick();
    }
  }

  return (
    <li
      className={styles.modWrapper}
      aria-label={`Select ${name}`}
      aria-checked={isSelected}
    >
      <div
        role={isRadio ? 'radio' : 'checkbox'}
        className={classNames(styles.mod, {
          [styles.disabledText]: disabled,
        })}
        onClick={onClick}
        onKeyDown={handleKeyPress}
      >
        {isRadio ? (
          <Radio name={`${categoryId}_${id}`} checked={isSelected} />
        ) : (
          <Checkbox
            name={`${categoryId}_${id}`}
            checked={isSelected}
            disabled={disabled}
          />
        )}
        <label className={styles.modName} data-testid="Modifier">
          {name}
        </label>
        <span className={styles.price} data-testid="ModifierPrice">
          {!!price && formatMoney(price)}
          {hasNestedMods && !isSelected && <IconCaretRight />}
        </span>
      </div>
      {hasNestedMods && isSelected && (
        <div className={styles.banner}>
          {!!bannerList && <div className={styles.modList}>{bannerList}</div>}
          <ButtonLink onClick={handleEditOptionsBtn} size="small">
            Edit Options
          </ButtonLink>
        </div>
      )}
    </li>
  );
}

Modifier.propTypes = {
  branch: PropTypes.shape({}),
  categoryId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleEditOptionsBtn: PropTypes.func,
  id: PropTypes.string.isRequired,
  isRadio: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSizeMod: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nestedMods: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number,
};

Modifier.defaultProps = {
  branch: {},
  disabled: false,
  handleEditOptionsBtn: () => {},
  isRadio: false,
  isSelected: false,
  isSizeMod: false,
  nestedMods: [],
  price: undefined,
};

export default Modifier;
