/* eslint-disable complexity */
import { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';

import { UserContext } from 'context/UserContext';

import { isOnPage } from '@chownow/cn-web-utils/url';
import { buildRoute, ROUTES } from 'helpers/routes';

import usePrevious from 'hooks/usePrevious';

function useRequiresAuth() {
  const history = useHistory();
  const { hqId, restaurantId } = useParams();

  const { user, isUserLoaded } = useContext(UserContext);

  const prevUser = usePrevious(user);
  const prevIsUserLoaded = usePrevious(isUserLoaded);

  function checkAndRedirect() {
    if (!user.id && isUserLoaded) {
      if (hqId && restaurantId) {
        const isMembershipPage = isOnPage('membership');
        const isCheckout = isOnPage('checkout');

        let route;

        if (isMembershipPage) route = ROUTES.membershipPlans;
        if (isCheckout && !isMembershipPage) route = ROUTES.checkout;

        history.push(
          buildRoute({
            hqId,
            restaurantId,
            route: route || ROUTES.menu,
          })
        );
      } else {
        // NOTE: In case of logging out from Account Settings or Order History page
        history.goBack();
      }
    }
  }

  useEffect(() => {
    if (isUserLoaded !== prevIsUserLoaded || !isEqual(prevUser, user)) {
      checkAndRedirect();
    }
  }, [user || isUserLoaded]);

  return !!user.id;
}

export default useRequiresAuth;
