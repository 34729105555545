import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { MODAL_TYPE } from 'helpers/modals';

import Help from 'components/Help';

import styles from './styles.module.scss';

export default function HelpToggle({ isCentered, page, phone }) {
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const { openModal } = useContext(ModalContext);

  function handleMobileClick() {
    openModal(MODAL_TYPE.help, { page, phone });
  }

  function handleDesktopClick() {
    setShowHelpDialog(!showHelpDialog);
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <button
          className={styles.mobileButton}
          onClick={handleMobileClick}
          type="button"
          id="helpToggle"
        >
          Help
        </button>
        <button
          className={styles.desktopButton}
          onClick={handleDesktopClick}
          type="button"
          id="helpToggle"
        >
          Help
        </button>
      </div>
      <div
        className={classNames(styles.helpContents, {
          [styles.centered]: isCentered,
          [styles.show]: showHelpDialog,
        })}
        data-testid="help-dialog"
      >
        <div>
          <Help
            page={page}
            phone={phone}
            closeDialog={() => {
              setShowHelpDialog(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

HelpToggle.propTypes = {
  isCentered: PropTypes.bool,
  page: PropTypes.string,
  phone: PropTypes.string,
};

HelpToggle.defaultProps = {
  isCentered: false,
  page: undefined,
  phone: undefined,
};
