/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { logAnalyticsEvent } from 'helpers/loggers';
import { ANALYTICS_EVENT_NAME } from 'helpers/constants';

import useIsMobile from 'hooks/useIsMobile';
import usePrevious from 'hooks/usePrevious';
import {
  getIsOrderValidating,
  getTips,
  setRestaurantTipRequest,
} from 'modules/order';

import Stepper from 'primitives/Stepper';

import { ReactComponent as IconTipJarCoin } from 'images/icon-tip-jar-coin.svg';
import { ReactComponent as IconTipJarEmpty } from 'images/icon-tip-jar-empty.svg';
import { ReactComponent as IconTipJarSplash } from 'images/icon-tip-jar-splash.svg';

import styles from './styles.module.scss';

const DEFAULT_TIP = 0;
const DEFAULT_MAX_TIP = 100;
const DEFAULT_TIP_MESSAGE =
  'Add to the tip jar if you want to thank the restaurant staff who prepare your order.';
const BUTTON_DEBOUNCE_MS = 1000; // 1 second

function RestaurantTip() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const isOrderValidating = useSelector(getIsOrderValidating);
  const tips = useSelector(getTips);

  const restaurantTip = tips?.restaurant_tip;
  const existingTipAmount = restaurantTip?.restaurant_tip_amount;

  const prevExistingTipAmount = usePrevious(existingTipAmount);

  const [hasTipped, setHasTipped] = useState(false);
  const [hasAnimationTriggered, setHasAnimationTriggered] = useState(false);
  const [tempRestaurantTip, setTempRestaurantTip] = useState(
    existingTipAmount || DEFAULT_TIP
  );

  const maxTip = tips?.max_restaurant_tip || DEFAULT_MAX_TIP;
  const isAtMaxTip = tempRestaurantTip === maxTip;

  const tipMessage = tips?.restaurant_tip_label || DEFAULT_TIP_MESSAGE;
  const infoMessage = isAtMaxTip
    ? 'You’re so generous – you’ve hit the maximum amount you can tip a restaurant.'
    : null;

  function sendTipToAPI(tip) {
    // This triggers handleValidateOrder
    dispatch({
      type: setRestaurantTipRequest.TYPE,
      payload: tip,
    });

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectTip,
      attributes: {
        source: 'restaurant',
        amount: tip,
      },
    });
  }

  const sendTipToAPIDebounced = useCallback(
    debounce(sendTipToAPI, BUTTON_DEBOUNCE_MS),
    []
  );

  function handleUpdateQuantity(quantity) {
    const oldQuantity = existingTipAmount;
    if (!hasTipped && quantity > oldQuantity) {
      setHasTipped(true);
    }

    setTempRestaurantTip(quantity);
    sendTipToAPIDebounced(quantity);
  }

  // Need to do wait for the validation loading animation to finish
  // before we try to show _this_ animation
  useEffect(() => {
    if (hasAnimationTriggered) {
      return;
    }

    if (hasTipped && isOrderValidating) {
      setHasAnimationTriggered(true);
    }
  }, [hasAnimationTriggered, hasTipped, isOrderValidating]);

  useEffect(() => {
    // When existing tips from redux state change
    // we want to update the (temp) local state.
    // If WE actually trigger the change it shouldn't
    // matter because the value of both will be equal
    // and useState equality checking ensures we won't re-update:
    // https://react.dev/reference/react/useState#ive-updated-the-state-but-the-screen-doesnt-update
    if (existingTipAmount !== prevExistingTipAmount) {
      setTempRestaurantTip(existingTipAmount);
    }
  }, [existingTipAmount]);

  return (
    <div className={styles.card}>
      <div
        className={classNames(styles.tipjar, {
          [styles.tipping]: hasAnimationTriggered,
        })}
      >
        <IconTipJarCoin className={styles.tipjarCoin} />
        <IconTipJarSplash className={styles.tipjarSplash} />
        <IconTipJarEmpty className={styles.tipjarEmpty} />
      </div>
      <div>
        <p>{tipMessage}</p>
        {!isMobile && infoMessage && (
          <p className={styles.infoMessage}>{infoMessage}</p>
        )}
      </div>
      <div>
        {/*
            As of 2/23/24, while Stepper takes a "quantity" prop, it's only
            used for the _initial_ render so it won't let you update it externally.

            "key" lets us force a re-mount of the component which will
            reset the quantity; see
            https://react.dev/reference/react/useState#resetting-state-with-a-key
          */}
        <Stepper
          key={`${tempRestaurantTip}`}
          className={styles.tipStepper}
          dataTestId="TipStepper"
          quantity={tempRestaurantTip}
          onUpdateQuantity={handleUpdateQuantity}
          minQuantity={0}
          maxQuantity={maxTip}
        />
      </div>
      {isMobile && infoMessage && (
        <p className={styles.infoMessage}>{infoMessage}</p>
      )}
    </div>
  );
}

export default RestaurantTip;
