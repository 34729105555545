/* eslint-disable complexity */
import { get } from 'lodash';
import { COUNTRIES, MANAGED_DELIVERY_STATUSES } from 'helpers/constants';
import { FULFILLMENT_METHODS } from 'helpers/order';
/**
 * Checks for an order status and that it is not cancelled before returning a link
 *
 *@return {String} a tracking link or null if it does not exist
 */
export function getTrackingUrl(order) {
  const managedDeliveryStatus = get(order.managed_delivery, 'status');
  const trackingUrl = get(order.managed_delivery, 'tracking_url');
  if (
    managedDeliveryStatus &&
    managedDeliveryStatus !== MANAGED_DELIVERY_STATUSES.cancelled
  ) {
    return trackingUrl;
  }
  return null;
}

/**
 * Evaluates whether the Google map should be shown
 * @param fulfill_method {string} type of fulfill_method on the order
 * @param isAuthenticated {bool} represents whether we have a user available
 * @param isInProgress {bool} represents whether the order is still in progress
 * @param isMobile {bool} represents whether the client viewport is considered mobile
 * @return {Boolean}
 */
export function getCanShowMap({
  fulfill_method: fulfillMethod,
  isInProgress,
  isMobile,
}) {
  const isDineIn = fulfillMethod === FULFILLMENT_METHODS.dineIn;

  if (isDineIn) {
    return false;
  }

  if (!isMobile) {
    return true;
  }

  if (isMobile && isInProgress) {
    return true;
  }

  return false;
}

export function getCanShowBranchBanner({
  restaurant,
  restaurantFetched,
  isMarketplacePlatform,
  restaurantHasNativeApp,
}) {
  if (!restaurantFetched || !restaurant) {
    return false;
  }
  if (isMarketplacePlatform) {
    if (!restaurant.is_discover_live) return false;
    return true;
  }

  if (
    !isMarketplacePlatform &&
    (!restaurantHasNativeApp || restaurant.display_chownow_app_banner) &&
    restaurant.is_discover_live
  ) {
    return true;
  }
  return false;
}

export function getCustomerInitials(customer) {
  const { first_name: firstName = '', last_name: lastName } = customer;
  // Take the first letter from first + last names for the heart initials.
  // For all other cases (if the users doesn’t have a last name or if there is hyphenated name),
  // just take the first alphabetical initial from the first name.
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }

  return `${firstName[0].toUpperCase()}`;
}

export function getAdsPageIdentifier({ isMarketplacePlatform, hasObnChannel }) {
  if (isMarketplacePlatform) return 'Marketplace';

  if (hasObnChannel) return 'OBN';

  return 'Direct';
}

export function getAdsAttributes({ order, restaurant }) {
  if (!order?.customer?.email) return {};

  const { customer } = order;

  const currency =
    restaurant.address.country_alpha2 === COUNTRIES.canada.alpha2
      ? 'CAD'
      : 'USD';

  const zip =
    customer.billing?.address?.zip ||
    customer.delivery?.address?.zip ||
    restaurant.address?.zip;

  const cuisines = restaurant.cuisines?.toString(', ') || '';

  const cart = JSON.stringify(order.items);

  const customerType = customer.first_name ? 'member' : 'guest';

  return {
    email: customer.email,
    firstname: customer.first_name,
    currency,
    amount: order.total,
    country: restaurant.address.country_alpha2,
    paymenttype: customer.billing?.card?.type,
    zipcode: zip,
    language: 'EN',
    confirmationref: order.id,
    cartitems: cart,
    customertype: customerType,
    delivery: order.fulfill_method === FULFILLMENT_METHODS.delivery,
    mobile: customer.phone_number,
    majorcat: cuisines,
    restaurantId: restaurant.id,
    restaurantCity: restaurant.address?.city,
  };
}
