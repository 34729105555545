import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

import IconClose from 'svgs/IconClose';

import styles from './styles.module.scss';

function ItemHeader(props) {
  const { animateHeader, onModalClose, itemName } = props;

  const defaultProps = { transform: 'translateY(-100%)', opacity: 1 };
  const [springProps, set] = useSpring(() => ({
    ...defaultProps,
    config: { tension: 350, friction: 32 },
  }));

  if (animateHeader) {
    set({ transform: 'translateY(0)', opacity: 1 });
  } else {
    set(defaultProps);
  }

  return (
    <animated.div
      className={styles.headingWrapper}
      style={{ transform: springProps.transform, opacity: springProps.opacity }}
    >
      <div className={styles.itemHeader} data-testid="ScrollItemName">
        {itemName}
      </div>
      <button
        className={styles.closeIcon}
        onClick={onModalClose}
        data-testid="ScrollItemClose"
        type="button"
      >
        <IconClose />
      </button>
    </animated.div>
  );
}

ItemHeader.propTypes = {
  animateHeader: PropTypes.bool.isRequired,
  itemName: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default ItemHeader;
