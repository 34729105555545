/* eslint-disable complexity, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';
import { find, last } from 'lodash';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { creditCardType } from 'helpers/prop-types';
import {
  NEW_CARD_VALUE,
  APPLE_PAY_STRUCT,
  GOOGLE_PAY_STRUCT,
  getAnalyticsPaymentName,
  getIsCardExpired,
} from 'helpers/payments';
import { creditCardValidator } from 'helpers/validation';
import { nullToEmptyString } from 'helpers/util';
import { getSubmitOrderCount } from 'helpers/order';
import { isOnPage } from '@chownow/cn-web-utils/url';

import useRequiresAuth from 'hooks/useRequiresAuth';
import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';

import CreditCardForm from 'components/CreditCardForm';

import ButtonLink from 'primitives/ButtonLink';
import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';
import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';
import RadioButton from 'primitives/RadioButton';

import { ReactComponent as IconVisa } from 'images/icon-visa.svg';
import { ReactComponent as IconMasterCard } from 'images/icon-mastercard.svg';
import { ReactComponent as IconAmex } from 'images/icon-amex.svg';
import { ReactComponent as IconDinersClub } from 'images/icon-dinersclub.svg';
import { ReactComponent as IconDiscover } from 'images/icon-discover.svg';
import { ReactComponent as IconJCB } from 'images/icon-jcb.svg';
import { ReactComponent as IconUnionPay } from 'images/icon-unionpay.svg';
import { ReactComponent as IconApplePay } from 'images/icon-applepay.svg';
import { ReactComponent as IconGooglePay } from 'images/icon-googlepay.svg';
import { ReactComponent as IconBlankMarketplace } from 'images/icon-creditcard-marketplace.svg';
import { ReactComponent as IconBlankDirect } from 'images/icon-creditcard-direct.svg';
import { ReactComponent as IconLock } from 'images/icon-lock.svg';

import { handleFetchAddCard, handleFetchDeleteCard } from './helpers';
import styles from './styles.module.scss';

function PaymentModal(props) {
  const { cardForm, defaultToApplePay, defaultToGooglePay, cardId } = props;

  useRequiresAuth();

  const { closeModal } = useContext(ModalContext);
  const {
    selectedCard,
    selectedCardId,
    setCardForm,
    setSelectedCardId,
    user,
    setUser,
  } = useContext(UserContext);

  const savedCards = user.billing?.cards || [];

  const prevSavedCards = usePrevious(savedCards);
  const prevSelectedCardId = usePrevious(selectedCardId);

  const [initialValues, setInitialValues] = useState({
    ...nullToEmptyString(cardForm),
    id: NEW_CARD_VALUE,
  });
  // userSelectedId is for the radio button selected state
  const [userSelectedId, setUserSelectedId] = useState(selectedCardId);
  const [userSelectedCard, setUserSelectedCard] = useState(selectedCard);
  const [showAddForm, setShowAddForm] = useState(
    selectedCardId === NEW_CARD_VALUE || cardId === NEW_CARD_VALUE
  );
  const [showCardNotice, setShowCardNotice] = useState(false);
  const [isUpdatingCards, setIsUpdatingCards] = useState(false);

  const { isMarketplacePlatform } = usePlatform();

  const submittedOrderCount = getSubmitOrderCount();
  const isSelectedCardDeclined =
    !!submittedOrderCount?.ids.includes(userSelectedId);
  const isSelectedCardExpired = getIsCardExpired(
    userSelectedCard?.exp_month,
    userSelectedCard?.exp_year
  );
  const isSelectedCardInvalid = !userSelectedCard?.cvv_valid;

  const isSocialPaySelected =
    userSelectedId === APPLE_PAY_STRUCT.type ||
    userSelectedId === GOOGLE_PAY_STRUCT.type;

  const isAccountSettings = isOnPage('/account');

  async function handleSelectCard(id) {
    const selectedSavedCard = find(savedCards, { id });

    setUserSelectedId(id);
    setUserSelectedCard(selectedSavedCard);

    if (id === NEW_CARD_VALUE) {
      setShowAddForm(true);
      setShowCardNotice(false);
      setInitialValues({
        ...nullToEmptyString(cardForm),
        id: NEW_CARD_VALUE,
      });
    } else if (
      selectedSavedCard &&
      (!selectedSavedCard.cvv_valid ||
        getIsCardExpired(
          selectedSavedCard.exp_month,
          selectedSavedCard.exp_year
        ))
    ) {
      setShowAddForm(false);
      setShowCardNotice(true);
    } else {
      setShowAddForm(false);
      setShowCardNotice(false);
    }
  }

  useEffect(() => {
    // in Account Settings, we pass selected card id based off which "edit" user selects
    if (cardId && isAccountSettings) {
      setSelectedCardId(cardId);
    }

    if (cardId === NEW_CARD_VALUE) {
      handleSelectCard(NEW_CARD_VALUE);
    }

    return () => {
      if (isAccountSettings) {
        setSelectedCardId('');
      }
    };
  }, []);

  useEffect(() => {
    if (cardId === NEW_CARD_VALUE) return;

    if (!prevSelectedCardId && selectedCardId) {
      handleSelectCard(selectedCardId);
    }

    if (prevSelectedCardId !== selectedCardId) {
      setUserSelectedId(selectedCardId);
      setUserSelectedCard(selectedCard);
    }
  }, [selectedCardId]);

  useEffect(() => {
    if (prevSavedCards) {
      // If successfully deleted card, set selected card to last saved
      if (savedCards.length && prevSavedCards.length > savedCards.length) {
        setSelectedCardId(last(savedCards).id);
      } else if (!savedCards.length) {
        // If deleted all saved cards, but Apple Pay enabled, default back to Apple Pay
        if (defaultToApplePay) {
          setUserSelectedId(APPLE_PAY_STRUCT.id);
          setShowCardNotice(false);
          if (selectedCardId !== APPLE_PAY_STRUCT.type) {
            setSelectedCardId(APPLE_PAY_STRUCT.type);
          }
        } else if (defaultToGooglePay) {
          setUserSelectedId(GOOGLE_PAY_STRUCT.id);
          setShowCardNotice(false);
          if (selectedCardId !== GOOGLE_PAY_STRUCT.id) {
            setSelectedCardId(GOOGLE_PAY_STRUCT.id);
          }
        } else {
          setSelectedCardId(NEW_CARD_VALUE);
          handleSelectCard(NEW_CARD_VALUE);
        }
      }
    }
  }, [savedCards]);

  function getCardTypeIcon(cardType) {
    switch (cardType) {
      case 'Visa':
        return IconVisa;
      case 'American Express':
        return IconAmex;
      case 'MasterCard':
        return IconMasterCard;
      case 'Discover':
        return IconDiscover;
      case 'JCB':
        return IconJCB;
      case 'Diners Club':
        return IconDinersClub;
      case 'UnionPay':
        return IconUnionPay;
      default:
        return null;
    }
  }

  function handleFormSubmit(values) {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.attemptAddPaymentCard,
      attributes: {
        payment_type: values.type,
        is_success: true,
      },
    });

    if (isAccountSettings) {
      // only on account settings should we immediately save new cards to customer
      handleFetchAddCard(
        values,
        user,
        setIsUpdatingCards,
        setCardForm,
        setUser
      );
    } else {
      setCardForm(values);
      setSelectedCardId(NEW_CARD_VALUE);
    }

    closeModal();
  }

  function handleUpdateCard(submitForm) {
    if (userSelectedId === NEW_CARD_VALUE) {
      submitForm();
    } else if (userSelectedId !== selectedCardId) {
      setSelectedCardId(userSelectedId);

      const selectedSavedCard = savedCards.find((s) => s.id === userSelectedId);
      const attributes = {
        name: getAnalyticsPaymentName(userSelectedId, selectedSavedCard?.type),
        source: 'checkout_payment_modal',
      };

      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectX,
        attributes,
      });
    }

    closeModal();
  }

  function handleDeleteCard() {
    handleFetchDeleteCard(userSelectedId, user, setIsUpdatingCards, setUser);
    setUserSelectedCard(selectedCard);
    setUserSelectedId(selectedCard.id);
  }

  function getAnyCardError() {
    let hasError = false;
    savedCards.forEach((card) => {
      if (!card.cvv_valid) hasError = true;
    });

    return hasError;
  }

  function getCardMessaging() {
    if (isSelectedCardDeclined && !userSelectedCard.cvv_valid) {
      return (
        <>
          <IconLock className={styles.iconLock} />
          Please re-enter your card information at checkout.
        </>
      );
    }

    if (showCardNotice) {
      return (
        <>
          <IconLock className={styles.iconLock} />
          {`Please enter your ${
            isSelectedCardExpired ? 'expiration date and ' : ''
          }${
            isSelectedCardInvalid || isSelectedCardExpired
              ? 'security code'
              : ''
          } at checkout.`}
        </>
      );
    }

    return `This card expires on ${userSelectedCard?.exp_month}/${userSelectedCard?.exp_year}.`;
  }

  return (
    <ModalFrame>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={creditCardValidator}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, isValid, setFieldValue }) => (
          <>
            <ModalHeader title="Payment Method" isSticky />
            {isUpdatingCards && <LoadingSpinner overlay />}
            <div className={styles.modalContent}>
              <Grid
                container
                className={classNames(styles.cardSelectionList, {
                  [styles.fixedHeight]: getAnyCardError(),
                  [styles.noBorder]: isSocialPaySelected,
                })}
              >
                {savedCards.map((card) => {
                  const isSelected = card.id === userSelectedId;
                  const isDeclined = !!submittedOrderCount?.ids.includes(
                    card.id
                  );
                  return (
                    <div className={styles.radioWrapper} key={card.id}>
                      <RadioButton
                        name="card-option"
                        checked={isSelected}
                        sublabel={
                          <span>
                            *{card.last_four}
                            {!card.cvv_valid && isDeclined ? (
                              <span className={styles.sublabelError}>
                                (Declined)
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        }
                        icon={getCardTypeIcon(card.type)}
                        onChange={() => handleSelectCard(card.id)}
                      />
                    </div>
                  );
                })}
                {defaultToApplePay && (
                  <div className={styles.radioWrapper}>
                    <RadioButton
                      name="card-option"
                      checked={APPLE_PAY_STRUCT.id === userSelectedId}
                      sublabel="Apple Pay"
                      icon={IconApplePay}
                      onChange={() => handleSelectCard(APPLE_PAY_STRUCT.id)}
                    />
                  </div>
                )}
                {defaultToGooglePay && (
                  <div className={styles.radioWrapper}>
                    <RadioButton
                      name="card-option"
                      checked={GOOGLE_PAY_STRUCT.id === userSelectedId}
                      sublabel="Google Pay"
                      icon={IconGooglePay}
                      onChange={() => handleSelectCard(GOOGLE_PAY_STRUCT.id)}
                    />
                  </div>
                )}
                <div className={styles.radioWrapper}>
                  <RadioButton
                    name="card-option"
                    checked={NEW_CARD_VALUE === userSelectedId}
                    sublabel="New Card"
                    dataTestId="AddCard"
                    icon={
                      isMarketplacePlatform
                        ? IconBlankMarketplace
                        : IconBlankDirect
                    }
                    onChange={() => handleSelectCard(NEW_CARD_VALUE)}
                  />
                </div>
              </Grid>
              {showAddForm && (
                <div className={styles.addForm}>
                  <CreditCardForm
                    setFieldValue={setFieldValue}
                    cardId={NEW_CARD_VALUE}
                    isModal
                  />
                </div>
              )}
              {!showAddForm && !isSocialPaySelected && (
                <div className={styles.cardInfo}>
                  <div className={styles.expiryInfo}>{getCardMessaging()}</div>
                  <ButtonLink
                    className={styles.btnRemove}
                    onClick={handleDeleteCard}
                    dataTestId="RemoveCard"
                  >
                    Remove Card
                  </ButtonLink>
                </div>
              )}
            </div>
            <ModalFooter bottomFixed>
              <ModalFooterButton
                dataTestId="UpdatePayment"
                disabled={showAddForm && !isValid}
                handleClick={() => handleUpdateCard(handleSubmit)}
              >
                Update
              </ModalFooterButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </ModalFrame>
  );
}

PaymentModal.propTypes = {
  cardForm: creditCardType,
  cardId: PropTypes.string,
  defaultToApplePay: PropTypes.bool,
  defaultToGooglePay: PropTypes.bool,
};

PaymentModal.defaultProps = {
  cardForm: undefined,
  cardId: undefined,
  defaultToApplePay: false,
  defaultToGooglePay: false,
};

export default PaymentModal;
