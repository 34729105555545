import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';

import { UserContext } from 'context/UserContext';

import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';

import { restaurantBaseType } from 'helpers/prop-types';
import { buildRoute, ROUTES } from 'helpers/routes';

import useRequiresAuth from 'hooks/useRequiresAuth';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

import Button from 'primitives/Button';
import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';

import styles from './styles.module.scss';

function MembershipConfirmation(props) {
  const { restaurant } = props;

  const auth = useRequiresAuth();

  const { user, membership = {} } = useContext(UserContext);
  const { setSelectedMembership } = membership;

  const history = useHistory();
  const { hqId, restaurantId } = useParams();

  function getActiveMembership() {
    return user.memberships.find(
      (userMembership) =>
        userMembership.restaurant_id === restaurantId &&
        userMembership.is_active
    );
  }

  const activeMembership = getActiveMembership();
  const createdAt = format(
    getDateForApiDateStr(activeMembership?.created_at),
    'MM/dd/yyyy'
  );

  useEffect(() => {
    setSelectedMembership();

    if (!activeMembership) {
      history.push(
        buildRoute({ hqId, restaurantId, route: ROUTES.membershipPlans })
      );
    }
  }, []);

  function handleStartOrder() {
    history.push(buildRoute({ hqId, restaurantId, route: ROUTES.menu }));
  }

  if (!auth) {
    return <LoadingSpinner overlay />;
  }

  if (!activeMembership) return null;

  return (
    <>
      <Helmet>
        <title>{restaurant.short_name} Loyalty Membership Confirmation</title>
      </Helmet>
      <Navbar
        address={restaurant.address}
        restaurantName={restaurant.short_name}
        isMembership
      />
      <Grid container>
        <Grid sm={4} md={6} mdOffset={4}>
          <div className={styles.confirmationContent}>
            <div className={styles.header}>
              Thank you for supporting {restaurant.short_name}.
            </div>
            <div className={styles.subheader}>
              Your Loyal Local Membership activates on {createdAt}. Get ready to
              receive {activeMembership.percent}% off every order!
            </div>
            <div className={styles.btnWrapper}>
              <Button onClick={handleStartOrder}>Start New Order</Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

MembershipConfirmation.propTypes = {
  restaurant: restaurantBaseType.isRequired,
};

export default MembershipConfirmation;
