/* eslint-disable consistent-return */
import { useEffect, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CAN_ALPHA2_COUNTRY_CODE,
  CAN_CURRENCY,
  USA_ALPHA2_COUNTRY_CODE,
  USA_CURRENCY,
} from 'helpers/constants';
import { logException } from 'helpers/loggers';
import { serializeApplePay } from 'helpers/payments';
import { CC_ERROR_MODAL_COPY, MODAL_TYPE } from 'helpers/modals';
import { removeSpaces } from 'helpers/util';

import ApplePayContext from 'context/ApplePayContext';
import { CompanyContext } from 'context/CompanyContext';
import { ModalContext } from 'context/ModalContext';

import { getRestaurantDetails } from 'modules/restaurant';
import { getOrderData } from 'modules/order';

const MODAL_COPY = {
  applePayNameError: {
    title: "Let's Take a Second Look.",
    message:
      'Please enter both first and last name in Apple Pay contact information.',
  },
  applePayTotalError: {
    title: "Let's Take a Second Look.",
    message:
      'Apple Pay is not available for order totals of $0, please select another payment method.',
  },
};

function useApplePay(submitOrder) {
  const restaurant = useSelector(getRestaurantDetails);
  const order = useSelector(getOrderData);

  const { isCanadian } = useContext(CompanyContext);
  const { openModal } = useContext(ModalContext);

  // isAttemptingApplePay is used for disabling the submit button
  const [isAttemptingApplePay, setIsAttemptingApplePay] = useState(false);
  const [isApplePayEnabled, setIsApplePayEnabled] = useState(false);
  // startedApplePaySessionRef is used specifically for preventing double apple pay sessions
  const startedApplePaySessionRef = useRef(false);
  const { hasActiveApplePayCard, isApplePayLoading } =
    useContext(ApplePayContext);
  const applePayCurrency = isCanadian ? CAN_CURRENCY : USA_CURRENCY;
  const applePayCountryCode = isCanadian
    ? CAN_ALPHA2_COUNTRY_CODE
    : USA_ALPHA2_COUNTRY_CODE;

  function handleStripeMounted() {
    if (window.Stripe.ApplePaySession) {
      setIsApplePayEnabled(true);
      window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_API_KEY);
    }
  }

  useEffect(() => {
    if (window.Stripe) {
      handleStripeMounted();
    }
  }, []);

  function onResetApplePaySession() {
    setIsAttemptingApplePay(false);
    startedApplePaySessionRef.current = false;
  }

  function handleError(errorCopy, error) {
    onResetApplePaySession();

    if (error) {
      logException({ message: error });
    }

    openModal(MODAL_TYPE.dialog, {
      ...errorCopy,
      noCloseOverlay: true,
      isAlert: true,
    });
  }

  function initiateApplePay() {
    setIsAttemptingApplePay(true);

    if (order.total_due === 0) {
      onResetApplePaySession();
      return handleError(MODAL_COPY.applePayTotalError);
    }

    const paymentRequest = {
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['phone', 'name', 'email'],
      countryCode: applePayCountryCode,
      currencyCode: applePayCurrency,
      total: {
        label: restaurant.name,
        amount: order.total_due,
      },
    };

    const session = window.Stripe.applePay.buildSession(
      paymentRequest,
      (result, completion) => {
        // complete the ApplePay session, and defer to the SubmitOrderHOC for the API submission
        const parsedPayload = serializeApplePay(
          result,
          restaurant.payment_processor_id
        );
        completion(window.ApplePaySession.STATUS_SUCCESS);

        if (
          !removeSpaces(parsedPayload.first_name) ||
          !removeSpaces(parsedPayload.last_name)
        ) {
          return handleError(MODAL_COPY.applePayNameError);
        }

        submitOrder(parsedPayload);
        onResetApplePaySession();
      },
      (error) => {
        handleError(CC_ERROR_MODAL_COPY, error);
      }
    );

    session.oncancel = onResetApplePaySession;

    // Don't start a new apple pay session if one has already been started
    // State doesn't get set fast enough for quick clicks so used ref here
    if (!startedApplePaySessionRef.current) {
      session.begin();
      startedApplePaySessionRef.current = true;
    }
  }

  return {
    isAttemptingApplePay,
    isApplePayLoading,
    onApplePay: initiateApplePay,
    defaultToApplePay: isApplePayEnabled && hasActiveApplePayCard,
  };
}

export default useApplePay;
