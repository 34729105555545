/* eslint-disable import/prefer-default-export, complexity */
import { isEmpty } from 'lodash';
import { format, isToday, isTomorrow, differenceInDays } from 'date-fns';

import { formatTime, formatDate } from 'helpers/format';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';

/**
 * Returns a string that describes the next available order day
 */
export function displayNextAvailableDay(date: string): string {
  if (isToday(date)) return 'Today';
  if (isTomorrow(date)) return 'Tomorrow';
  return format(date, 'EEE, MMM d') as string; // Mon, Jan 1
}

/**
 * Returns a string that describes the next available open time
 */
export function getNextAvailableDisplayText({
  nextAvailableDateString,
  showDateTimeOnly = false,
}: {
  nextAvailableDateString: string;
  showDateTimeOnly: boolean;
}): string {
  const nextAvailableDate = getDateForApiDateStr(
    nextAvailableDateString
  ) as string;

  return `${showDateTimeOnly ? '' : 'Opens '}${displayNextAvailableDay(
    nextAvailableDate
  )} at ${format(nextAvailableDate, 'h:mm a')}`;
}

/**
 * Returns a string that describes the restaurant open time
 */
interface FulfillmentDetailsType {
  display_hours: {
    dow: string;
    ranges?: { to: string }[];
  }[];
  is_available_now: boolean;
  next_available_time: string;
}

export function getOpenTime(restaurant: {
  fulfillment: {
    delivery?: FulfillmentDetailsType;
    pickup?: FulfillmentDetailsType;
  };
}): string {
  const { fulfillment } = restaurant;
  const deliveryDisplayHours = fulfillment?.delivery?.display_hours;
  const pickupDisplayHours = fulfillment?.pickup?.display_hours;
  const isDeliveryOnly = deliveryDisplayHours && !pickupDisplayHours;

  const today = format(new Date(), 'EEEE');
  // Do not try to find pickup hours if restaurant is delivery only
  const todaysHours = isDeliveryOnly
    ? deliveryDisplayHours?.find((day) => day.dow === today)
    : pickupDisplayHours?.find((day) => day.dow === today);

  const lastHourOpen =
    todaysHours?.ranges &&
    !isEmpty(todaysHours.ranges) &&
    formatTime(todaysHours.ranges[todaysHours.ranges.length - 1].to);

  const isOpen =
    fulfillment?.pickup?.is_available_now ||
    fulfillment?.delivery?.is_available_now;
  const nextAvailableDateString =
    fulfillment?.pickup?.next_available_time ||
    fulfillment?.delivery?.next_available_time;

  if (!isOpen && nextAvailableDateString) {
    return getNextAvailableDisplayText({
      nextAvailableDateString,
      showDateTimeOnly: false,
    });
  }

  // in the case of menu preview
  if (!lastHourOpen) {
    return 'Restaurant not currently accepting orders';
  }

  return `Open until ${lastHourOpen}`;
}

export function isSameDayOfWeek(dow: string): boolean {
  const day = format(new Date(), 'EEEE');
  return day === dow;
}

// new Date() returns UTC time, so getLocalTime() adjusts for user's timezone
function getLocalTime(): string {
  const timezoneOffset = new Date().getTimezoneOffset();
  const todayInMilliseconds = Date.now();
  const timezoneAdjusted = todayInMilliseconds - timezoneOffset;
  const today = new Date(timezoneAdjusted);

  // Jan is considered 0, so need to add 1 for standard month format
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const year = today.getFullYear();

  // need to adjust to add 0 for months Jan - Sept
  const formattedMonth = month < 10 ? `0${month}` : month;

  // need to adjust to add 0 for days 1 - 9
  const formattedDay = day < 10 ? `0${day}` : day;

  const formattedToday = `${year}${formattedMonth}${formattedDay}`;

  return formattedToday;
}

export function isSameDay(date: string): boolean {
  // ASAP orders are null, so if we receive null for date, it will be same day
  if (!date) return true;

  const today = formatDate(getLocalTime());
  const orderDay = formatDate(date);
  return today === orderDay;
}

/**
 * Determines whether datetime is within 30 days of now
 */
export function isWithin30Days(date: string): boolean {
  const now = new Date();
  const dateString = getDateForApiDateStr(date) as string; // convert date to Date() accepted string
  const orderDate = new Date(dateString);
  return differenceInDays(now, orderDate) < 30;
}

/**
 * Determines whether datetime is within 24 hours of now
 */
export function isWithin24Hours(date: string): boolean {
  const now = new Date();
  const dateString = getDateForApiDateStr(date) as string; // convert date to Date() accepted string
  const orderDate = new Date(dateString);
  const oneDay = 60 * 60 * 24 * 1000; // 24 hours in milliseconds
  return now.valueOf() - orderDate.valueOf() < oneDay;
}
