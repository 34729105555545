/* eslint-disable complexity, react/jsx-no-useless-fragment */
import classNames from 'classnames';
import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import Cookies from 'universal-cookie';

import 'normalize.css';
import '@stripe/stripe-js';

import { GOOGLE_MAPS_API_KEYS } from 'helpers/constants';
import { getIsChrome, isIE } from '@chownow/cn-web-utils/url';

import {
  getIsLocalStorageAllowed,
  getIsSessionStorageAllowed,
} from 'helpers/customer';
import { hasApplePayCard } from 'helpers/payments';
import { isYelpMobile, handleYelpMessage } from 'helpers/yelp';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import usePlatform from 'hooks/usePlatform';

import ApplePayContext from 'context/ApplePayContext';
import GoogleMapsContext from 'context/GoogleMapsContext';
import { UserContext } from 'context/UserContext';

import Account from 'containers/Account';
import Checkout from 'containers/Checkout';
import Confirmation from 'containers/Confirmation';
import Forgot from 'containers/Forgot';
import Locations from 'containers/Locations';
import Membership from 'containers/Membership';
import NotFound from 'containers/NotFound';
import OrderHistory from 'containers/OrderHistory';
import Restaurant from 'containers/Restaurant';
import Support from 'containers/Support';
import ErrorBoundary from 'components/higher-order/ErrorBoundary';
import UnsupportedBrowser from 'containers/UnsupportedBrowser';
import YelpRestaurantRedirect from 'components/YelpRestaurantRedirect';

import './App.css';
import styles from './App.styles.module.scss';

const TEST_COOKIE = 'cn_test_cookie';

const cookies = new Cookies();
let parentWindow;
const IFRAME_EMBED_RECHECK_INTERVAL = 10;

export function getCanServeEmbeddedSite() {
  /**
   * Verify a cookie can actually be set and it's value retrieved. Sometimes cookies can be enabled in the
   * browser but disabled in preferences from "3rd party websites", "Cross-Site Cookies",
   * or sites the user has not visited, which can prevent authentication and some other features
   * in our app from working as expected when embedded on another site. CN-1927, CN-6855, CN-9327, etc...
   *
   * Additionally, we are only allowing users to order if sessionStorage is enabled as well CN-11377
   */

  const isSessionStorageAllowed = getIsSessionStorageAllowed();

  if (getIsChrome()) {
    const chromeVersion = /Chrome\/([0-9.]+)/.exec(navigator.userAgent)[1];
    const isUnsupportedInEmbedChrome = parseInt(chromeVersion, 10) >= 119;
    const isEmbedded = window.top !== window;
    // session cookie no longer works with embedded sites on Chrome 119
    if (isEmbedded && isUnsupportedInEmbedChrome) return false;

    // Currently, Chrome allows for same-site cookies, so this check isn't needed for Chrome browsers.
    if (isSessionStorageAllowed) return true;
  }
  // Set a cookie and ensure it was persisted. This will fail on Safari (and possibly other browsers)
  // as this JS is pulled from an outside domain (ours) and we are attempting to set a client side cookie
  // in the restaurant's environment.
  cookies.set(TEST_COOKIE, true);
  const testCookie = cookies.get(TEST_COOKIE);

  if (isSessionStorageAllowed && testCookie) {
    // Remove the cookie if we were able to set it.
    cookies.remove(TEST_COOKIE);
    return true;
  }
  return false;
}

function App() {
  const location = useLocation();
  const { isDirectPlatform, isMarketplacePlatform, isYelpPlatform, platform } =
    usePlatform();
  const [hasActiveApplePayCard, setHasActiveApplePayCard] = useState(false);
  const [isApplePayLoading, setIsApplePayLoading] = useState(true);

  const { setHasMapError, setHasMapLoaded } = useContext(GoogleMapsContext);
  const { setSessionId, handleFetchUserProfile } = useContext(UserContext);

  // save gm_authFailure if not undefined
  const { gm_authFailure } = window; // eslint-disable-line camelcase
  // wrap the original gm_authFailure
  window.gm_authFailure = () => {
    setHasMapError(true);
    // call original gm_authFailure if not undefined
    // eslint-disable-next-line camelcase
    if (gm_authFailure) {
      gm_authFailure();
    }
  };

  function checkIfCnEmbed(evt) {
    let data;

    // Try to parse message, run in try / catch since could come from anywhere or be malformed
    try {
      data = JSON.parse(evt.data);
    } catch (e) {
      // Do nothing
    }

    // Validating messages from our sources only and ignoring others(e.g. Facebook)
    if (
      get(data, 'source') === 'chownow.ordering' &&
      get(data, 'type') === 'init'
    ) {
      // save parent iframe reference
      parentWindow = evt.source;
    }
  }

  function onEmbeddedAppLoad() {
    const message = JSON.stringify({
      eventName: 'chownow.ordering.onload',
    });

    // Check if parent window has been set, if not check rapidly until we receive event
    function checkParentWindow() {
      if (parentWindow) {
        parentWindow.postMessage(message, '*');
      } else {
        setTimeout(() => {
          checkParentWindow();
        }, IFRAME_EMBED_RECHECK_INTERVAL);
      }
    }

    if (isDirectPlatform || isMarketplacePlatform) {
      checkParentWindow();
    }
  }

  useEffect(() => {
    handleFetchUserProfile();

    const messageProcessor = isYelpPlatform
      ? handleYelpMessage
      : checkIfCnEmbed;
    window.addEventListener('message', messageProcessor);

    // Note: react-route match isn't available yet to use for getting params
    const isEmbedded = window.top !== window;

    if (isEmbedded) {
      onEmbeddedAppLoad();
    }

    const isUnsupported =
      isIE() || (!getCanServeEmbeddedSite() && !isYelpPlatform);
    if (isUnsupported) return;

    // initiate user session ID (used by sift science)
    setSessionId(uuidv4());

    const isLocalStorageAllowed = getIsLocalStorageAllowed();
    if (isLocalStorageAllowed) {
      // Clear old local storage no longer used if exists
      const persistKeysToRemove = ['customer', 'menu', 'platform', 'root'];
      persistKeysToRemove.forEach((key) =>
        localStorage.removeItem(`directPersist:${key}`)
      );
    }

    async function checkApplePay() {
      if (!isEmbedded) {
        const hasActiveAppleCard = await hasApplePayCard();
        setHasActiveApplePayCard(hasActiveAppleCard);
        setIsApplePayLoading(false);
      } else {
        setIsApplePayLoading(false);
      }
    }

    if (isDirectPlatform || isMarketplacePlatform) {
      checkApplePay();
    }

    // Add Google Address API script with dynamic key
    const script = document.createElement('script');
    const mapKey = GOOGLE_MAPS_API_KEYS[platform];
    script.src = `https://maps.googleapis.com/maps/api/js?v=3.58&key=${mapKey}&libraries=places&callback=initMap`;
    document.head.appendChild(script);

    window.initMap = () => {
      setHasMapLoaded(true);
    };

    setTimeout(() => {
      if (!window.google?.maps) {
        logAnalyticsEvent({
          eventName: ANALYTICS_EVENT_NAME.viewErrorPage,
          attributes: {
            type: 'Error Boundary',
            message: 'Google Places API failed to load',
          },
        });
      }
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const applePayContextValue = {
    hasActiveApplePayCard,
    isApplePayLoading,
  };

  function checkBrowser(app) {
    const isUnsupported =
      isIE() || (!getCanServeEmbeddedSite() && !isYelpPlatform);
    const isEmbedded = window.top !== window;

    if (isUnsupported) {
      return (
        <UnsupportedBrowser
          isUnableToLoadSite={!getCanServeEmbeddedSite()}
          isEmbedded={isEmbedded}
        />
      );
    }

    return app;
  }

  return (
    <ErrorBoundary>
      <div
        className={classNames(`theme-${platform}`, {
          [styles.yelpOverflow]: isYelpPlatform && isYelpMobile(),
        })}
      >
        {checkBrowser(
          <>
            <Switch>
              {/* Redirect all paths with trailing slash to path w/o slash but still include query params */}
              <Redirect
                from="/:url*(/+)"
                to={`${location.pathname.slice(0, -1)}${location.search}`}
              />

              <Route exact path="/yelp/order/location/:restaurantId">
                <YelpRestaurantRedirect />
              </Route>

              <Redirect
                exact
                from="/direct/:hqId/locations"
                to="/order/:hqId/locations"
              />
              <Route
                exact
                path={['/order/:hqId/locations', '/dine-in/:hqId/locations']}
              >
                <Locations />
              </Route>

              <Redirect
                exact
                from="/direct/:hqId/locations/:restaurantId"
                to="/order/:hqId/locations/:restaurantId"
              />

              <Route
                exact
                path={[
                  '/order/:hqId/locations/:restaurantId',
                  '/dine-in/:hqId/locations/:restaurantId',
                ]}
              >
                <Restaurant />
              </Route>

              <Redirect
                exact
                from="/direct/:hqId/locations/:restaurantId/checkout"
                to="/order/:hqId/locations/:restaurantId/checkout"
              />
              <Route
                exact
                path={[
                  '/order/:hqId/locations/:restaurantId/checkout',
                  '/dine-in/:hqId/locations/:restaurantId/checkout',
                ]}
              >
                <ApplePayContext.Provider value={applePayContextValue}>
                  <Checkout />
                </ApplePayContext.Provider>
              </Route>

              <Redirect exact from="/direct/:hqId/account" to="/account" />
              <Redirect exact from="/order/:hqId/account'" to="/account" />
              <Redirect exact from="/dine-in/:hqId/account'" to="/account" />
              {/* Marketplace needs to send requests prepended with '/order' to invoke our cloud function
          and pull the Order Direct bundles  */}
              <Route exact path={['/account', '/order/account']}>
                <Account />
              </Route>

              <Redirect
                exact
                from="/direct/:hqId/history"
                to="/order/history"
              />
              <Redirect
                exact
                from="/order/:hqId/history'"
                to="/order/history"
              />
              <Route exact path={['/order/history', '/dine-in/history']}>
                <OrderHistory />
              </Route>

              <Redirect
                exact
                from="/direct/:hqId/history/:orderId"
                to="/order/history/:orderId"
              />
              <Redirect
                exact
                from="/order/:hqId/history/:orderId"
                to="/order/history/:orderId"
              />
              <Route
                exact
                path={['/order/history/:orderId', '/dine-in/history/:orderId']}
              >
                <Confirmation />
              </Route>

              {/* Membership Pages */}
              <Route
                path={[
                  '/order/:hqId/locations/:restaurantId/membership',
                  '/dine-in/:hqId/locations/:restaurantId/membership',
                ]}
              >
                <Membership />
              </Route>

              {/* Forgot page when coming from forgot password email */}
              <Route path="/order/forgot">
                <Forgot />
              </Route>

              <Route path={['/order/:hqId/support', '/dine-in/:hqId/support']}>
                <Support />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </>
        )}

        <div id="app-portal-container" />
      </div>
    </ErrorBoundary>
  );
}

export default App;
