import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { getFormattedWhenObject } from '@chownow/cn-web-utils/date';

import { getNextAvailableDisplayText, isSameDay } from 'helpers/date';
import { MODAL_TYPE } from 'helpers/modals';

import { getNextAvailableTime } from 'modules/restaurant';
import { getFulfillmentMethod } from 'modules/order';

import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import { ReactComponent as IconDate } from 'images/icon-date.svg';

import styles from './styles.module.scss';

function OrderingUnavailableModal(props) {
  const {
    isDeliveryAndDeliveryAheadUnavailable,
    isOrderThrottleError,
    nextAvailableWhen,
    when,
  } = props;

  const { closeModal, openModal } = useContext(ModalContext);

  const { nextAvailableTime } = useSelector(getNextAvailableTime);
  const fulfillmentMethod = useSelector(getFulfillmentMethod);

  // nextAvailableWhen is what BE sends when we get the order throttling error
  // nextAvailableTime is calculated on FE and using here as a safeguard
  const nextAvailableDate = nextAvailableWhen || nextAvailableTime;
  const { fulfillmentTimeStart } = getFormattedWhenObject(nextAvailableDate);

  async function handlePromptTime() {
    await closeModal();

    openModal(MODAL_TYPE.fulfillmentDetails, {
      fulfillmentMethod,
    });
  }

  function getModalTitle() {
    if (isOrderThrottleError) {
      if (isSameDay(nextAvailableWhen) || !isSameDay(when)) {
        return isDeliveryAndDeliveryAheadUnavailable
          ? 'Delivery is currently unavailable.'
          : "We're a little busy right now.";
      }

      return "We're currently no longer accepting orders for today.";
    }

    return <>Next available {fulfillmentMethod} time</>;
  }

  function getDescriptionText() {
    const orderThrottleErrorCopy = isOrderThrottleError // eslint-disable-line no-nested-ternary
      ? isDeliveryAndDeliveryAheadUnavailable
        ? "We're sorry but your original delivery time is unavailable. To continue, your order has been updated to pickup at:" // eslint-disable-line
        : "We're sorry the time you selected is no longer available. The next available time is:"
      : "Click 'start order' to accept the order time suggested above, or select another time.";

    return orderThrottleErrorCopy;
  }

  function getButtonText() {
    if (isOrderThrottleError) {
      // if nextAvailableWhen is null, that means ASAP is available
      const isNextAvailableToday =
        !nextAvailableWhen || isSameDay(nextAvailableWhen);
      const acceptTime = isNextAvailableToday
        ? fulfillmentTimeStart
        : getNextAvailableDisplayText({
            nextAvailableDateString: nextAvailableDate,
            showDateTimeOnly: isOrderThrottleError,
          });
      return `${
        isDeliveryAndDeliveryAheadUnavailable ? 'Switch to Pickup at' : 'Accept'
      } ${acceptTime}`;
    }
    return 'Start Order';
  }

  return (
    <Modal shouldShowCloseIcon={false} centerAlign>
      <ModalHeader title={getModalTitle()} />
      <div className={classNames({ [styles.reverse]: isOrderThrottleError })}>
        <div className={styles.displayTime}>
          <IconDate
            className={styles.iconDate}
            focusable="false"
            aria-hidden="true"
          />
          {getNextAvailableDisplayText({
            nextAvailableDateString: nextAvailableDate,
            showDateTimeOnly: isOrderThrottleError,
          })}
        </div>
        <p className={styles.description}>{getDescriptionText()}</p>
      </div>
      <ModalFooter>
        <ModalFooterButton dataTestId="ContinueToMenu" handleClick={closeModal}>
          {getButtonText()}
        </ModalFooterButton>
        <ModalFooterButton isSecondary handleClick={handlePromptTime}>
          Select Another Time
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
}

OrderingUnavailableModal.propTypes = {
  isDeliveryAndDeliveryAheadUnavailable: PropTypes.bool,
  isOrderThrottleError: PropTypes.bool,
  nextAvailableWhen: PropTypes.string,
  when: PropTypes.string,
};

OrderingUnavailableModal.defaultProps = {
  isDeliveryAndDeliveryAheadUnavailable: false,
  isOrderThrottleError: false,
  nextAvailableWhen: undefined,
  when: undefined,
};

export default OrderingUnavailableModal;
