import React from 'react';
import { useSelector } from 'react-redux';

import { prettifyPhoneNumber } from '@chownow/cn-web-utils/format';

import { getRestaurantDetails } from 'modules/restaurant';

import GoogleMap from 'components/GoogleMap';
import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

function RestaurantInfoBlock() {
  const { address, name, phone } = useSelector(getRestaurantDetails);

  const {
    formatted_address: formattedAddresss,
    city,
    state,
    street_address1: streetAddress1,
    street_address2: streetAddress2,
    latitude,
    longitude,
  } = address;

  function getStreetAddress() {
    if (streetAddress2) return `${streetAddress1}, ${streetAddress2}`;

    return streetAddress1;
  }

  return (
    <div className={styles.restaurantDetails}>
      <div className={styles.map}>
        <GoogleMap lat={latitude} lng={longitude} mapHeight="220px" />
      </div>
      <div className={styles.restaurantInfo}>
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.address} data-testid="Address">
            {getStreetAddress()}, {city}, {state}
          </div>
          {phone && (
            <Outlink
              to={`tel:${phone}`}
              className={styles.phoneLink}
              dataTestId="RestaurantPhone"
            >
              {prettifyPhoneNumber(phone)}
            </Outlink>
          )}
        </div>
        <Outlink
          to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
            formattedAddresss
          )}`}
        >
          Get Directions
        </Outlink>
      </div>
    </div>
  );
}

export default RestaurantInfoBlock;
