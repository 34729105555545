/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { onHeightChangedHandler } from 'helpers/yelp';

import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';

import { getErrorsForOrder } from 'modules/order';

import OrderToggle from './OrderToggle';
import OrderTotals from './OrderTotals';
import OrderItems from './OrderItems';
import PaymentBox from './PaymentBox';

import styles from './styles.module.scss';

export default function OrderSummary({
  hasDisclaimer,
  items,
  isAuthenticated,
  isCheckout,
  isConfirmation,
  isExpandedForced,
  isReadOnly,
  order,
  showLineItems,
  showOrderToggle,
  sourcePage,
}) {
  const { isYelpPlatform } = usePlatform();
  const prevItems = usePrevious(items);
  const errors = useSelector(getErrorsForOrder);

  const [isExpanded, setIsExpanded] = useState(
    isExpandedForced || (showOrderToggle && items && items.length <= 3)
  );

  function getOrderHasSubdetails() {
    let hasSubdetails = false;
    order?.items.forEach((item) => {
      if (!isEmpty(item.modifierNames) || item.specialInstructions) {
        hasSubdetails = true;
      }
    });
    return hasSubdetails;
  }

  useEffect(() => {
    // For Yelp platform, we must notify Yelp of the height of the Checkout container component
    // It could change anytime the Order Summary component is expanded or collapsed or is items
    // are added or removed.
    if (isYelpPlatform) {
      onHeightChangedHandler();
    }
  }, [isExpanded, order]);

  useEffect(() => {
    if (
      prevItems &&
      prevItems.length === 2 &&
      items.length === 3 &&
      isExpanded
    ) {
      setIsExpanded(false);
    }
  }, [items]);

  // on confirmation only, if no order item has modifiers or special instructions, we dont want to show the order toggle
  const orderHasNoSubdetails = !getOrderHasSubdetails(items);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.confirmationWrapper]: isConfirmation,
      })}
    >
      {showOrderToggle && (
        <OrderToggle
          hideToggle={isConfirmation ? orderHasNoSubdetails : false}
          isExpanded={isExpanded}
          toggleOrder={() => setIsExpanded(!isExpanded)}
        />
      )}
      <div
        className={classNames(styles.scrollWrapper, {
          [styles.noScroll]: isCheckout || isConfirmation,
          [styles.withErrors]: !!errors.length,
          [styles.withDisclaimer]: hasDisclaimer,
        })}
        data-testid="BagScroll"
      >
        <OrderItems
          isCheckout={isCheckout}
          isExpanded={isExpanded}
          isReadOnly={isReadOnly}
          items={isConfirmation ? order.items : items}
          sourcePage={sourcePage}
        />
      </div>
      {isConfirmation && isAuthenticated && (
        <PaymentBox customer={order.customer} />
      )}
      <div className={styles.gradient} />
      <OrderTotals
        order={order}
        showLineItems={showLineItems}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

OrderSummary.propTypes = {
  hasDisclaimer: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isConfirmation: PropTypes.bool,
  isExpandedForced: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  order: PropTypes.shape({
    customer: PropTypes.shape({
      billing: PropTypes.shape({
        card: PropTypes.shape({
          last_four: PropTypes.string,
          type: PropTypes.string,
        }),
      }),
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showLineItems: PropTypes.bool,
  showOrderToggle: PropTypes.bool,
  sourcePage: PropTypes.string,
};

OrderSummary.defaultProps = {
  hasDisclaimer: false,
  isAuthenticated: false,
  isCheckout: false,
  isConfirmation: false,
  isExpandedForced: false,
  isReadOnly: false,
  items: [],
  order: [],
  showLineItems: false,
  showOrderToggle: false,
  sourcePage: '',
};
