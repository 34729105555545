import PropTypes from 'prop-types';

const { arrayOf, bool, number, oneOfType, shape, string } = PropTypes;

export const restaurantType = shape({
  id: string,
  name: string,
  company_id: string,
});

export const addressType = shape({
  address_string: string,
  city: string,
  country: string,
  country_alpha2: string,
  formatted_address: string,
  geo: string,
  id: oneOfType([number, string]), // API returns restaurant address id as number
  is_google_place: bool,
  latitude: number,
  longitude: number,
  name: string,
  place_formatted_address: string,
  state: string,
  street_address1: string,
  street_address2: string,
  zip: string,
});

const tipType = shape({
  allows_tip: bool,
  deafult_rate: number,
  suggested_rates: arrayOf(number),
});

export const displayHoursType = arrayOf(
  shape({
    day_id: number,
    dow: string,
    ranges: arrayOf(
      shape({
        from: string,
        label: string,
        to: string,
      })
    ),
  })
);

const deliveryType = shape({
  customizations: string,
  delivery_ranges: arrayOf(
    shape({
      allows_tip: bool,
      delivery_charge: number,
      delivery_charge_type: string,
      id: string,
      managed_delivery_id: string,
      max_order_amt: number,
      min_order_amt: number,
    })
  ),
  display_hours: displayHoursType,
  hours: arrayOf(
    shape({
      date: string,
      dow: string,
    })
  ),
  is_available_now: bool,
  managed_delivery_id: string,
  next_available_time: string,
  order_ahead: shape({
    max_lead_time: number,
    min_lead_time: number,
    precision: number,
  }),
  tip: tipType,
});

const pickupType = shape({
  customizations: string,
  display_hours: displayHoursType,
  hours: arrayOf(
    shape({
      date: string,
      dow: string,
    })
  ),
  is_available_now: bool,
  max_order_amt: number,
  min_order_amt: number,
  next_available_time: string,
  order_ahead: shape({
    max_lead_time: number,
    min_lead_time: number,
    precision: number,
  }),
  tip: tipType,
});

const miscFeeType = shape({
  min_order: number,
  rate: number,
  unit: string,
  label: string,
});

export const itemModifierDetailType = shape({
  description: string,
  id: string,
  is_default: bool,
  is_taxable: bool,
  name: string,
  price: number,
});

export const orderItemType = shape({
  id: string,
  modifierNames: arrayOf(string),
  modifierPrice: number,
  name: string,
  price: number,
  quantity: number,
  size: string,
  specialInstructions: string,
  tracking_id: string,
});

export const itemSizeType = shape({
  id: string,
  price: number,
  size: string,
});

export const menuItemType = shape({
  categoryName: string,
  delivery_charge: number,
  description: string,
  id: string,
  is_meta: bool,
  modifier_categories: arrayOf(string),
  name: string,
  sizeId: string,
  image: shape({
    base_url: string,
    crop_height: number,
    crop_width: number,
    file_key: string,
    gravity_x: number,
    gravity_y: number,
  }),
});

export const menuCategoryType = shape({
  childIds: arrayOf(string),
  id: string,
  items: arrayOf(menuItemType),
  name: string,
});

export const orderAheadType = shape({
  current_time_index: number,
  days: arrayOf(
    shape({
      date: string,
      delivery: arrayOf(number),
      delivery_index_ranges: arrayOf(
        shape({
          from: number,
          to: number,
        })
      ),
      dow: string,
      pickup: arrayOf(number),
      pickup_index_ranges: arrayOf(
        shape({
          from: number,
          to: number,
        })
      ),
    })
  ),
  is_delivery_ahead_available: bool,
  is_pickup_ahead_available: bool,
  max_lead_time: number,
  min_lead_time: number,
  num_days: number,
  precision: number,
});

export const tempClosuresType = arrayOf(
  shape({
    description: string,
    from: string,
    fulfill_method: string,
    to: string,
  })
);

export const restaurantBaseType = shape({
  address: addressType,
  allows_order_for_now: bool,
  android_app_url: string,
  closures: tempClosuresType,
  company_id: string,
  display_properties: shape({
    enable_special_instructions: bool,
    menu_disclaimer: string,
    special_instructions_text: string,
  }),
  fees: shape({
    forced_gratuity: shape({
      charge_type: string,
      min_order: number,
      amount: number,
    }),
    service: shape({
      charge_type: string,
      min_order: number,
      amount: number,
    }),
  }),
  force_managed_delivery: bool,
  fulfillment: shape({
    delivery: deliveryType,
    pickup: pickupType,
  }),
  has_automatic_discount: bool,
  has_promo_code: bool,
  id: string,
  ios_app_store_id: string,
  is_discover_live: bool,
  is_post_pay: bool,
  large_order: shape({
    min_lead_time: number,
    amount: number,
  }),
  misc_fee: miscFeeType,
  name: string,
  order_ahead: orderAheadType,
  payment_processor_id: string,
  phone: string,
  short_name: string,
  tax_rate: number,
  tax_type: string,
  website_url: string,
});

export const creditCardType = shape({
  address: addressType,
  cvv_valid: bool,
  exp_month: number,
  exp_year: number,
  id: string,
  is_visible: bool,
  last_four: string,
  name: string,
  type: string,
  type_id: string,
});

export const orderPropTypeV3 = shape({
  fulfill_method: string,
  fulfillment_times: shape({
    estimated_arrival: string,
    estimated_pickup: string,
    created_at: string,
  }),
  local_ordered_time: string,
  requested_delivery_time: string,
  status: string,
  errors: arrayOf(shape({ code: string })),
});

export const userMembershipPlanType = shape({
  id: string,
  plan_xid: string,
  subscription_xid: string,
  restaurant_id: string,
  expires_at: string,
  percent: number,
  is_active: bool,
  created_at: string,
  customer_id: string,
  name: string,
});

export const vehicleType = shape({
  color: string,
  vehicle_type: string,
});

export const menuImageType = shape({
  base_url: PropTypes.string,
  file_key: PropTypes.string,
  gravity_x: PropTypes.number,
  gravity_y: PropTypes.number,
});

export const orderTotalsType = shape({
  credits: arrayOf(shape()),
  delivery_fee: number,
  discounts: arrayOf(shape()),
  fulfill_method: string,
  fulfillmentMethod: string,
  item_total: number,
  misc_fee: shape(),
  sales_tax: number,
  status: string,
  tip: number,
  total_due: number,
});
