import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { BASE_SUPPORT_URL } from '@chownow/cn-web-utils/paths';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';

import Footer from 'components/Footer';

import { ReactComponent as ImageSupport } from 'images/support.svg';

import styles from './styles.module.scss';

function Support(props) {
  const { isErrorBoundary, isOrderMismatch } = props;

  useEffect(() => {
    // Already logging this for error boundaries in ErrorBoundary component
    if (!isErrorBoundary) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.viewErrorPage,
        attributes: {
          type: 'Support',
        },
      });
    }
  }, []);

  function getBodyCopy() {
    if (isOrderMismatch) {
      // eslint-disable-next-line max-len
      return `We're sorry, we couldn't find that order. If you believe you have reached this page in error, `;
    }
    if (isErrorBoundary) {
      return `We're sorry, please try refreshing the page. If the error persists, `;
    }

    return 'We were unable to verify your order. If you do not receive an email shortly, ';
  }

  return (
    <div className={styles.supportWrapper}>
      <Helmet>
        <title>Something Went Wrong. Contact Customer Support</title>
      </Helmet>
      <div
        className={styles.container}
        data-testid={isOrderMismatch ? 'OrderNotFound' : 'SupportPage'}
      >
        <ImageSupport className={styles.image} />
        <p className={styles.headline}>
          Something went wrong but we&apos;re here to help
        </p>
        <div className={styles.body}>
          {`${getBodyCopy()} please visit our support page. We’re here to help.`}
        </div>
        <a
          href={BASE_SUPPORT_URL}
          alt="ChowNow Support"
          className={styles.button}
          target="_blank"
          rel="noreferrer"
        >
          View Support
        </a>
      </div>
      <Footer />
    </div>
  );
}

Support.propTypes = {
  isErrorBoundary: PropTypes.bool,
  isOrderMismatch: PropTypes.bool,
};

Support.defaultProps = {
  isErrorBoundary: false,
  isOrderMismatch: false,
};

export default Support;
