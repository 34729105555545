import React from 'react';
import classNames from 'classnames';

import { ReactComponent as IconNeutral } from 'images/icon-badge-info.svg';
import { ReactComponent as IconSuccess } from 'images/icon-badge-check.svg';
import { ReactComponent as IconCaution } from 'images/icon-caution.svg';

import styles from './styles.module.scss';

const icons = {
  neutral: IconNeutral,
  success: IconSuccess,
  caution: IconCaution,
};

interface TagNoticeProps {
  children: string;
  size?: 'small' | 'large';
  variant?: 'neutral' | 'success' | 'caution';
}

function TagNotice({
  children,
  size = 'small',
  variant = 'neutral',
}: TagNoticeProps) {
  const TagIcon = icons[variant] || null;

  return (
    <div
      className={classNames(styles.tagNotice, styles[size], styles[variant])}
    >
      <div className={styles.icon}>
        <TagIcon />
      </div>
      {children}
    </div>
  );
}
export default TagNotice;
