import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSingleUseItem, updateOrderRequest } from 'modules/order';

import Switch from 'primitives/Switch';

import { ReactComponent as IconUtensils } from './icon-utensils.svg';

import styles from './styles.module.scss';

function SingleUseItems({ label }: { label: string }) {
  const dispatch = useDispatch();

  const includeSingleUse = useSelector(getSingleUseItem);

  const [isToggled, setIsToggled] = useState(includeSingleUse);

  function handleOnToggle(toggle: boolean) {
    setIsToggled(toggle);

    dispatch({
      // @ts-expect-error dispatching TYPE
      type: updateOrderRequest.TYPE,
      payload: {
        include_single_use_items: toggle,
      },
    });
  }

  return (
    <div className={styles.singleUseWrapper}>
      <div className={styles.singleUse}>
        <IconUtensils className={styles.icon} />
        {label}
      </div>
      <Switch isToggled={isToggled} onToggle={handleOnToggle} />
    </div>
  );
}

export default SingleUseItems;
