/* eslint-disable complexity, jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getDeviceType, DEVICE_TYPE } from '@chownow/cn-web-utils/url';
import { handleAppLinkClick } from 'helpers/loggers';

import { getRestaurantDetails } from 'modules/restaurant';

import Outlink from 'primitives/Outlink';

import { ReactComponent as IconAndroidApp } from 'images/icon-android-app.svg';
import { ReactComponent as IconIosApp } from 'images/icon-ios-app.svg';
import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

function AppBanner() {
  const restaurant = useSelector(getRestaurantDetails);
  const device = getDeviceType();
  const isIos = device === DEVICE_TYPE.IOS;
  const isAndroid = device === DEVICE_TYPE.ANDROID;
  const iosAppId = isIos && restaurant && restaurant.ios_app_store_id;
  const androidApp = isAndroid && restaurant && restaurant.android_app_url;

  const [isBannerVisible, setIsBannerVisible] = useState(
    iosAppId || androidApp
  );

  function handleBannerClick() {
    handleAppLinkClick({
      isNativeApp: true,
      source: 'native_app_banner',
    });
  }

  function handleDismiss() {
    setIsBannerVisible(false);
  }

  return (
    <div>
      {isBannerVisible && (
        <div className={styles.appBannerWrapper}>
          <IconClose onClick={handleDismiss} className={styles.iconClose} />
          <div
            className={styles.appBannerContentWrapper}
            onClick={handleBannerClick}
            role="button"
            tabIndex="0"
          >
            <Outlink
              to={
                isIos
                  ? `https://apps.apple.com/us/app/id${iosAppId}`
                  : restaurant.android_app_url
              }
              ariaLabel={`${isIos ? `iOS` : `Android`} App Download`}
              className={styles.appBannerContent}
            >
              <>
                <div>
                  <div>Download the app</div>
                  <h4 className={styles.appName}>{restaurant.short_name}</h4>
                </div>
                {isIos ? (
                  <IconIosApp className={styles.iconApp} />
                ) : (
                  <IconAndroidApp className={styles.iconApp} />
                )}
              </>
            </Outlink>
          </div>
        </div>
      )}
    </div>
  );
}

export default AppBanner;
