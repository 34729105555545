import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ButtonLinkProps {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  onClick: () => void;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  secondary?: boolean;
}

function ButtonLink({
  children,
  className,
  dataTestId,
  disabled = false,
  onClick,
  size = 'medium',
  secondary = false,
}: ButtonLinkProps) {
  return (
    <button
      className={classNames(styles.buttonLink, className, styles[size], {
        [styles.disabled]: disabled,
        [styles.linkSecondary]: secondary,
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
}

export default ButtonLink;
