/* eslint-disable complexity */
import luhn from 'luhn';
import { get } from 'lodash';

import { creditCardStringToType, NEW_CARD_VALUE } from 'helpers/payments';
import { formatExpDate } from 'helpers/format';
import { numberRegex } from 'helpers/util';

export function isFirstNumberValid(values: {
  phone: { number?: string };
}): boolean {
  const number = values.phone?.number;
  if (!number) return false;
  const firstNumber = parseInt(number.charAt(0), 10);
  const invalidFirstDigits = [0, 1];
  return !invalidFirstDigits.includes(firstNumber);
}

export function isValidPhoneNumber(values: {
  phone: { number?: string };
}): boolean {
  return values.phone?.number?.length === 10;
}

export function isAddressValid(address: {
  street_address1?: string;
  city?: string;
  state?: string;
  zip?: string;
}): boolean {
  if (
    !address.street_address1 ||
    address.street_address1 === 'undefined' ||
    !address.city ||
    !address.state ||
    !address.zip
  ) {
    return false;
  }

  return true;
}

interface CCErrors {
  number?: string;
  expDate?: string;
  cvv?: string;
  address?: { zip?: string };
}
export function creditCardValidator(values: {
  type_id?: string;
  number?: string;
  type?: string;
  cvv?: string;
  address?: { zip?: string };
  expDate?: string;
  cvv_valid: boolean;
  id?: string;
}) {
  let errors: CCErrors = {};

  if (
    !values ||
    values.type_id === creditCardStringToType.ApplePay ||
    values.type_id === creditCardStringToType.GooglePay
  ) {
    return errors;
  }

  const {
    number = '',
    type,
    cvv = '',
    address = {},
    expDate,
    cvv_valid: cvvValid,
    id,
  } = values;
  const date = new Date();
  const rawNumber = number.replace(/\s/g, '');
  const { exp_month: expMonth, exp_year: expYear } = formatExpDate(
    expDate || ''
  );

  if (!number && id === NEW_CARD_VALUE) {
    errors.number = 'Number required';
  } else if (
    (rawNumber && !luhn.validate(rawNumber)) ||
    (rawNumber.length < 13 && id === NEW_CARD_VALUE) ||
    (rawNumber &&
      rawNumber.length < 13 &&
      !cvvValid &&
      id !== NEW_CARD_VALUE) ||
    (rawNumber.length >= 13 && !type)
  ) {
    errors.number = 'Invalid credit card';
  } else if (!cvvValid && !rawNumber && !type && id !== NEW_CARD_VALUE) {
    errors.number = 'Please re-enter or replace your card info';
  }

  if (!expDate) {
    errors.expDate = 'Exp. date required';
  } else if (
    !expMonth ||
    !expYear ||
    String(expYear).length < 4 ||
    expMonth > 12
  ) {
    errors.expDate = 'Exp. date invalid';
  } else if (
    expYear < date.getFullYear() ||
    (expMonth < date.getMonth() + 1 && expYear <= date.getFullYear())
  ) {
    errors.expDate = 'Credit card expired';
  }

  if (!cvv && !cvvValid) {
    errors.cvv = 'CVV required';
  } else if (
    (!cvvValid &&
      ((type === 'American Express' && cvv.length !== 4) ||
        (type !== 'American Express' && cvv.length !== 3))) ||
    !numberRegex.test(cvv)
  ) {
    errors.cvv = 'Invalid CVV';
  }

  if (!address.zip) {
    errors = {
      ...errors,
      address: {
        zip: 'Zip code / postal code required',
      },
    };
  } else if (address.zip.length < 5) {
    errors = {
      ...errors,
      address: {
        zip: 'Invalid zip code / postal code',
      },
    };
  }

  return errors;
}

interface ResetPwErrors {
  password?: string;
  verificationCode?: string;
  confirmPassword?: string;
}
export function resetPasswordValidator(values: {
  verificationCode?: string;
  password?: string;
  confirmPassword?: string;
}): ResetPwErrors {
  const errors: ResetPwErrors = {};

  if (!values.verificationCode) {
    errors.verificationCode = 'Verification code is required';
  }

  if (!values.password) {
    errors.password = 'Password is required.';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required.';
  }

  if (values.password && values.password.length < 8) {
    errors.password = 'Minimum 8 characters.';
  }

  if (
    values.confirmPassword?.length &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = 'Passwords must match.';
  }

  return errors;
}

interface PhoneErrors {
  phone?: { number?: string };
}
export function phoneValidator(values: {
  phone: { number?: string };
}): PhoneErrors {
  let errors: PhoneErrors = {};

  if (!get(values, 'phone.number')) {
    errors = {
      ...errors,
      phone: {
        number: 'Phone is required.',
      },
    };

    return errors;
  }

  if (!isValidPhoneNumber(values)) {
    errors = {
      ...errors,
      phone: {
        number: 'Must be 10-digit phone number.',
      },
    };
  }

  if (!isFirstNumberValid(values)) {
    errors = {
      ...errors,
      phone: {
        number: 'Invalid phone number. Please remove country code.',
      },
    };
  }

  return errors;
}

export function isValidString(string: string): boolean {
  // Matches A-Z case insensitive
  // Matches 0-9
  // Matches `/`
  // Matches `-`
  return /^[A-Z0-9_-]*$/i.test(string);
}
