/* eslint-disable react/jsx-no-constructed-context-values, array-callback-return */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getItemsFromMenuData } from 'helpers/menu';
import { getFlatModIds, mapIdToName } from 'helpers/order';

const MenuContext = createContext({
  menu: {},
  menuCategoriesWithItem: [],
  menuItems: [],
  isMenuLoading: false,
  setMenu: () => {},
  setIsMenuLoading: () => {},
});

function MenuProvider(props) {
  const { children } = props;

  const [menuState, setMenuState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [menuCategoriesWithItem, setMenuCategoriesWithItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [modifiers, setModifiers] = useState([]);

  function getMenuCategoriesWithItems() {
    const filteredCategories = menuState.menu_categories.map((category) => {
      const filteredItems = [];
      const servingSizes = [];
      category.items.forEach((item) => {
        const servingSizeGroup = item.meta_item_details?.serving_size_group;
        if (servingSizeGroup) {
          const defaultSizeId = servingSizeGroup.default_id;
          const defaultSize = servingSizeGroup.child_items.find(
            (catItem) => catItem.id === defaultSizeId
          ) || servingSizeGroup.child_items[0];
          
          filteredItems.push({ ...item, price: defaultSize.price });
          servingSizeGroup.child_items.forEach((childId) =>
            servingSizes.push(childId)
          );
        } else if (!servingSizes.includes(item.id)) {
          filteredItems.push({ ...item });
        }
      });

      return { ...category, items: filteredItems };
    });

    setMenuCategoriesWithItems(filteredCategories);
  }

  function getMenuItems() {
    const fullItems = getItemsFromMenuData(menuState);
    setMenuItems(fullItems);
    setModifiers(menuState.modifiers);
  }

  function getCompleteModifierDataForItem(itemId) {
    const menuItem = menuItems.find((item) => item.id === itemId);
    const modifierCategoriesForItem = menuItem.modifier_categories;
    const completedModData = modifierCategoriesForItem.map(
      (modifierCategoryId) => {
        const modCat = menuState.modifier_categories.find(
          (category) => category.id === modifierCategoryId
        );
        const mods = modCat.modifiers.map((modifierId) =>
          menuState.modifiers.find((mod) => mod.id === modifierId)
        );
        return { ...modCat, modifiers: mods };
      }
    );
    return completedModData;
  }

  function getNestedModData(itemId) {
    const modifier = modifiers.find((item) => item.id === itemId);
    const modifierCategoriesForItem = modifier.modifier_categories;
    const modData = modifierCategoriesForItem.map((modifierCategoryId) => {
      const modCat = menuState.modifier_categories.find(
        (category) => category.id === modifierCategoryId
      );
      const mods = modCat.modifiers.map((modifierId) =>
        menuState.modifiers.find((mod) => mod.id === modifierId)
      );
      return { ...modCat, modifiers: mods };
    });
    return modData;
  }

  function getSizeDataForItem(itemId) {
    const menuItem = menuItems.find((item) => item.id === itemId);
    let sizes = [];
    const servingSizeGroup = menuItem.meta_item_details?.serving_size_group;
    if (servingSizeGroup) {
      sizes = servingSizeGroup.child_items;
    }
    return sizes;
  }

  function getChildModifiers(branch) {
    const nestedIds = getFlatModIds(branch?.modifier_categories) || [];
    const nestedNames = nestedIds.map((id) =>
      mapIdToName(id, modifiers)
    );
    return nestedNames;
  }

  function getParentModifierName(parentId) {
    const modifier = modifiers.find((item) => item.id === parentId);
    return modifier.name || '';
  }

  useEffect(() => {
    if (!menuState.menu_categories) return;

    getMenuCategoriesWithItems();
    getMenuItems();
  }, [menuState]);

  return (
    <MenuContext.Provider
      value={{
        menu: menuState,
        menuCategoriesWithItem,
        menuItems,
        isMenuLoading: isLoading,
        setMenu: setMenuState,
        setIsMenuLoading: setIsLoading,
        getCompleteModifierDataForItem,
        getNestedModData,
        getSizeDataForItem,
        getChildModifiers,
        getParentModifierName,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MenuContext, MenuProvider };
