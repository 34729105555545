import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getSelectedAddress } from 'helpers/customer';

import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as IconMapPin } from 'images/icon-mappin-24.svg';

import styles from './styles.module.scss';

function DeliveryAddress(props) {
  const { onAddressChange } = props;

  const selectedAddress = getSelectedAddress();

  // if logging out on Checkout, we clear selected address
  if (!selectedAddress) {
    return null;
  }

  const {
    street_address1: streetAddress1,
    street_address2: streetAddress2,
    city,
    state,
    zip,
    delivery_instructions: deliveryInstructions,
  } = selectedAddress;

  return (
    <button
      type="button"
      onClick={onAddressChange}
      className={styles.deliveryAddressWrapper}
    >
      <div className={styles.deliveryAddress}>
        <IconMapPin className={styles.icon} />
        <div>
          <p
            data-testid="CheckoutMobileAddress"
            className={styles.checkoutMobileAddress}
          >
            {streetAddress1}
            {streetAddress2 ? (
              <span data-testid="CheckoutMobileAddress2">
                {' '}
                {streetAddress2}
              </span>
            ) : (
              ''
            )}
            , {city}, {state} {zip}
          </p>
          <p
            className={classNames(styles.deliveryInstructions, {
              [styles.default]: !deliveryInstructions,
            })}
          >
            {deliveryInstructions || 'Add delivery instructions'}
          </p>
        </div>
      </div>
      <IconEdit data-testid="EditIcon" className={styles.iconEdit} />
    </button>
  );
}

DeliveryAddress.propTypes = { onAddressChange: PropTypes.func.isRequired };

export default DeliveryAddress;
