import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Grid from 'primitives/Grid';

import styles from './styles.module.scss';

export default function Card({ content, header, subheader }) {
  return (
    <Grid sm={4} md={12} lg={4}>
      <div className={styles.cardWrapper}>
        {header && <div className={styles.cardHeader}>{header}</div>}
        <div
          className={classNames(styles.cardContent, {
            [styles.contentWithHeader]: header,
          })}
        >
          <div
            className={classNames(styles.cardSubheader, {
              [styles.subheaderWithHeader]: header,
            })}
          >
            {subheader}
          </div>
          {content}
        </div>
      </div>
    </Grid>
  );
}

Card.propTypes = {
  content: PropTypes.string.isRequired,
  header: PropTypes.string,
  subheader: PropTypes.string.isRequired,
};

Card.defaultProps = {
  header: '',
};
