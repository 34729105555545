/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Route, Switch, useParams, useHistory } from 'react-router-dom';

import { getFromChownowApi } from 'helpers/api';
import { buildRoute, ROUTES } from 'helpers/routes';

import usePrevious from 'hooks/usePrevious';

import {
  setSelectedRestaurantId,
  getRestaurantDetails,
  getRestaurantIsLoading,
} from 'modules/restaurant';

import MembershipCheckout from 'containers/MembershipCheckout';
import MembershipConfirmation from 'containers/MembershipConfirmation';
import MembershipPlans from 'containers/MembershipPlans';
import NotFound from 'containers/NotFound';
import LoadingSpinner from 'primitives/LoadingSpinner';

// fetch feature flags from Hermosa
async function handleFetchHermosaFeatureFlags(
  setIsFlagLoading,
  setIsMembershipEnabled
) {
  setIsFlagLoading(true);

  const { data } = await getFromChownowApi({
    endpoint: 'internal/feature-flags',
  });

  if (data?.membership_enabled) setIsMembershipEnabled(data.membership_enabled);
  setIsFlagLoading(false);
}

function Membership() {
  const dispatch = useDispatch();

  const history = useHistory();
  const { hqId, restaurantId } = useParams();

  const [isFlagLoading, setIsFlagLoading] = useState();
  const [isMembershipEnabled, setIsMembershipEnabled] = useState(true);

  const isRestaurantLoading = useSelector(getRestaurantIsLoading);
  const restaurant = useSelector(getRestaurantDetails);

  const prevIsRestaurantLoading = usePrevious(isRestaurantLoading);
  const prevIsFlagLoading = usePrevious(isFlagLoading);

  useEffect(() => {
    handleFetchHermosaFeatureFlags(setIsFlagLoading, setIsMembershipEnabled);

    if (!restaurant || restaurant.id !== restaurantId) {
      dispatch({ type: setSelectedRestaurantId.TYPE, payload: restaurantId });
    }
  }, []);

  useEffect(() => {
    if (prevIsRestaurantLoading && !isRestaurantLoading && !restaurant) {
      return history.replace(
        buildRoute({ hqId, restaurantId, route: ROUTES.menu })
      );
    }

    if (
      prevIsRestaurantLoading &&
      !isRestaurantLoading &&
      !restaurant.is_live
    ) {
      return history.replace(buildRoute({ route: ROUTES.membershipList }));
    }
  }, [isRestaurantLoading]);

  useEffect(() => {
    if (prevIsFlagLoading && !isFlagLoading && !isMembershipEnabled) {
      return history.replace(
        buildRoute({ hqId, restaurantId, route: ROUTES.menu })
      );
    }
  }, [isFlagLoading]);

  if (restaurant) {
    const membership = get(restaurant, 'membership', {});
    const plans = get(membership, 'plans', []);

    if (restaurant.id !== restaurantId) {
      return <LoadingSpinner />;
    }

    if (!membership.is_enabled || !plans.length) {
      const redirect = buildRoute({ hqId, restaurantId, route: ROUTES.menu });
      return history.replace(redirect);
    }

    return (
      <Switch>
        <Route exact path="/order/:hqId/locations/:restaurantId/membership">
          <MembershipPlans restaurant={restaurant} />
        </Route>
        <Route path="/order/:hqId/locations/:restaurantId/membership/checkout">
          <MembershipCheckout restaurant={restaurant} />
        </Route>
        <Route path="/order/:hqId/locations/:restaurantId/membership/confirmation">
          <MembershipConfirmation restaurant={restaurant} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  }

  return <LoadingSpinner />;
}

export default Membership;
