/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector } from 'react-redux';

import { getSelectedAddress } from 'helpers/customer';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { getFindMoreRestaurantsUrl } from 'helpers/url';

import { getFulfillmentMethod } from 'modules/order';

import { ReactComponent as IconBack } from 'images/icon-arrow-left.svg';

import styles from './styles.module.scss';

interface FindMoreRestaurantsProps {
  address?: object;
}

function FindMoreRestaurants({ address }: FindMoreRestaurantsProps) {
  const fulfillmentMethod = useSelector(getFulfillmentMethod);

  function handleFindMoreRestaurants() {
    const selectedAddress = getSelectedAddress();

    const findMoreRestaurantsUrl = getFindMoreRestaurantsUrl({
      cityAddress: address,
      fulfillmentMethod,
      selectedAddress,
    });

    window.open(findMoreRestaurantsUrl, '_blank');

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: {
        name: 'find_more_restaurants_menu',
        source: 'navbar',
      },
    });
  }
  return (
    <button
      className={styles.findMoreRestaurants}
      onClick={handleFindMoreRestaurants}
      type="button"
    >
      <IconBack />
      Find More Restaurants
    </button>
  );
}

export default FindMoreRestaurants;
