import React, { ReactNode, useContext } from 'react';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { ReactComponent as CloseIcon } from 'images/icon-close-24.svg';

import styles from './styles.module.scss';

export interface ModalFrameProps {
  className?: string;
  children: ReactNode;
  onModalClose?: () => void;
}

function ModalFrame({
  className = '',
  children,
  onModalClose,
}: ModalFrameProps) {
  const { closeModal } = useContext(ModalContext);

  function handleClose() {
    if (onModalClose) onModalClose();

    closeModal();
  }

  return (
    <div className={classNames(styles.modalFrameWrapper, className)}>
      <button
        type="button"
        className={styles.iconWrapper}
        onClick={handleClose}
      >
        <CloseIcon className={styles.iconClose} data-testid="Close" />
      </button>
      <div className={styles.modalFrame}>{children}</div>
    </div>
  );
}

export default ModalFrame;
