import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { find, findIndex } from 'lodash';

import { MODAL_TYPE } from 'helpers/modals';
import { getRepeatedItem } from 'helpers/order';
import { logException } from 'helpers/loggers';
import { SELECT_ITEM_OPERATIONS } from 'helpers/constants';

import {
  addItemToOrderRequest,
  getOrderItemData,
  removeOrderItemRequest,
  setActiveItemId,
  updateOrderItemRequest,
} from 'modules/order';

import { ModalContext } from 'context/ModalContext';
import { MenuContext } from 'context/MenuContext';

/**
 * Custom hook used to select a menu or order item (previously WithItemDetail component)
 * This handles open and closing of the item modal and also handles adding an item to the order
 * or updating an existing item
 */
function useSelectItem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = parse(location.search);

  const orderItems = useSelector(getOrderItemData);

  const { openModal } = useContext(ModalContext);
  const { menu, menuItems: menuItemsData } = useContext(MenuContext);

  const isMenuItemParamPresent = queryParams && queryParams.menu_item_id;
  const {
    modifier_categories: modifierCategoriesData,
    modifiers: modifierData,
  } = menu;
  const isMenuPreview = queryParams.menu_status === 'preview';

  /**
   * On close of item modal, remove appropriate query params from URL
   */
  function handleCloseItem() {
    if (isMenuItemParamPresent) {
      delete queryParams.menu_item_id;
    } else {
      delete queryParams.order_item_id;
    }
    history.replace(
      `${location.pathname}${queryParams && `?${stringify(queryParams)}`}`
    );

    // Clear out active item
    dispatch({
      type: setActiveItemId.TYPE,
    });
  }

  /**
   * Dispatch Add or Update action then close item modal
   * @param  {object} itemData - item data to send to API
   * @param  {boolean} isEditingItem - is editing an existing order item
   */
  function addItem(itemData, isEditingItem) {
    const repeatedItem = getRepeatedItem(itemData, orderItems);
    const isItemRepeated = !!repeatedItem;

    if (isEditingItem || isItemRepeated) {
      let itemIndex = findIndex(orderItems, {
        tracking_id: itemData.tracking_id,
      });
      let itemDetails = itemData;
      if (isItemRepeated) {
        itemDetails = {
          ...repeatedItem,
          price:
            itemDetails.price * (repeatedItem.quantity + itemData.quantity),
          quantity: repeatedItem.quantity + itemData.quantity,
        };

        if (isEditingItem) {
          if (repeatedItem.tracking_id === itemData.tracking_id) {
            itemDetails = {
              ...repeatedItem,
              quantity: itemData.quantity,
            };
          }
          // remove old item then increment repeated item
          dispatch({
            type: removeOrderItemRequest.TYPE,
            payload: itemData,
            meta: itemData.tracking_id,
          });
        }

        itemIndex = findIndex(orderItems, {
          tracking_id: itemDetails.tracking_id,
        });
      }

      dispatch({
        type: updateOrderItemRequest.TYPE,
        payload: itemDetails,
        meta: itemIndex,
      });
    } else {
      dispatch({
        type: addItemToOrderRequest.TYPE,
        payload: itemData,
      });
    }

    handleCloseItem();
  }

  /**
   * Returns item data for existing order items to allow for editing of item
   * @param  {string} trackingId
   */
  function getItemInformation(trackingId) {
    const orderItem = find(orderItems, { tracking_id: trackingId });

    if (!orderItem) return undefined;

    const item = menuItemsData.find((itemData) => itemData.id === orderItem.id);

    return {
      ...item,
      ...orderItem,
    };
  }

  /**
   * Display item modal if item data exists
   * @param  {string} itemId - this could be menu item ID or order item ID (trackingId)
   * @param  {boolean} isEditingItem
   */
  function showItemModal(
    itemId,
    isEditingItem,
    recentlyOrderedItem,
    itemIndex,
    sourcePage
  ) {
    const itemModalData =
      recentlyOrderedItem ||
      (isEditingItem
        ? getItemInformation(itemId)
        : menuItemsData.find((item) => item.id === itemId));

    const servingSizeGroup =
      itemModalData.meta_item_details?.serving_size_group;
    const defaultSize =
      servingSizeGroup?.child_items.find(
        (item) => item.id === servingSizeGroup.default_id
      ) || servingSizeGroup?.child_items[0];

    if (!itemModalData) {
      // if no item exists for ID, don't attempt to load modal
      handleCloseItem();
    } else {
      openModal(MODAL_TYPE.menuItem, {
        defaultSizeId: defaultSize?.id,
        modifierCategoriesData,
        modifierData,
        onAddItem: (itemData) => addItem(itemData, isEditingItem),
        onModalClose: handleCloseItem,
        showCloseIcon: false,
        noPadding: true,
        isMenuPreview,
        recentlyOrderedItem,
        itemIndex,
        sourcePage,
        specialInstructions: itemModalData.user_selection?.special_instructions,
        modifierCategories: itemModalData.user_selection?.modifier_categories,
        ...itemModalData,
      });
    }
  }

  /**
   * There's certain cases where the menu item doesn't have all the necessary info (CN-11789)
   * We need to check it has what we expect in order to open the modal
   * @param  {object} itemModalData - item data
   */
  function checkIsItemValid(itemModalData) {
    if (!itemModalData) return false;

    // need to convert modifierCategoriesData in order to map through
    const categoriesData = modifierCategoriesData
      ? Object.values(modifierCategoriesData)
      : [];
    const modifierCategories = [];

    itemModalData.modifier_categories.map((categoryId) =>
      categoriesData.map(
        (category) =>
          categoryId === category.id && modifierCategories.push(categoryId)
      )
    );

    return (
      itemModalData.modifier_categories.length === modifierCategories.length
    );
  }

  /**
   * This is fired when a user clicks on either a menu item or order item and should show the menu item modal
   * @param  {string} itemId - this can be menu item ID or order item ID (trackingId)
   * @param  {string} operation - either 'add' (menu item) or 'edit' (order item)
   * @param  {object} recentlyOrderedItem - appends additional data if item is in recently ordered category
   * @param  {number} itemIndex - index of item if it's from recently ordered category. Used for logging position of item in carousel.
   */
  function selectItem(props) {
    const { itemId, operation, recentlyOrderedItem, itemIndex, sourcePage } =
      props;

    const isEditingItem = operation === SELECT_ITEM_OPERATIONS.edit;
    const itemModalData = isEditingItem
      ? getItemInformation(itemId)
      : menuItemsData.find((item) => item.id === itemId);
    const isItemValid = checkIsItemValid(itemModalData);

    if (isItemValid) {
      // Could be coming from url that already has menu_item_id, don't double add query if so
      if (!isMenuItemParamPresent) {
        const query = isEditingItem
          ? { order_item_id: itemId }
          : { menu_item_id: itemId };

        history.replace(
          `${location.search ? `${location.search}&` : '?'}${stringify(query)}`
        );
      }

      dispatch({
        type: setActiveItemId.TYPE,
        payload: { operation, itemId },
      });

      showItemModal(
        itemId,
        isEditingItem,
        recentlyOrderedItem,
        itemIndex,
        sourcePage
      );
    } else {
      logException({
        name: 'Menu Modifier Error',
        message: 'err: unable to find modifiers for item modifier category',
      });

      openModal(MODAL_TYPE.dialog, {
        message:
          'Oops, we’re having trouble accessing this menu item. Please choose another option or check back later.',
        confirmLabel: 'Return to Menu',
        noCloseOverlay: true,
        isAlert: true,
      });
    }
  }

  return selectItem;
}

export default useSelectItem;
