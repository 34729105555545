/* eslint-disable complexity, @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
import { call, put } from 'redux-saga/effects';
import { isFunction, isObject } from 'lodash';
import { normalize } from 'normalizr';

import { setFbPixelId } from 'helpers/customer';
import { logException } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';
import { apiRequest } from '@chownow/cn-web-utils/api';

import { callConfig } from 'index';

export const genericErrorMsg =
  'It looks like there are a few errors with your request. ' +
  'Please double check all of your information and try again.';
export const genericDeclineTitle = 'Payment Declined';
export const genericDeclineMsg =
  'Sorry, you have entered invalid information. For your security, please re-enter your card information.';

export const HTTP_STATUS_SERVER_MIN = 500;
export const HTTP_STATUS_SERVER_MAX = 599;

export const UNEXPECTED_ERROR = null; // Could be any number of codes
export const UNAUTHORIZED = '401';
export const NOT_FOUND = '404';
export const TOO_MANY_REQUESTS = '429';
export const INTERNAL_SERVER_ERROR = '500';
export const GATEWAY_TIME_OUT = '504';
export const LENGTH_BELOW_MIN = '10204';
export const INVALID_JSON_SCHEMA = '10205';
export const USER_ACCOUNT_ID_MISMATCH = '20900';
export const NO_ACCOUNT = '20100';
export const PASSWORD_INCORRECT = '20103';
export const GOOGLE_ACCOUNT_INVALID = '20107';
export const GOOGLE_ACCOUNT_INACTIVE = '20108';
export const APPLE_ACCOUNT_INVALID = '20110';
export const APPLE_ACCOUNT_INACTIVE = '20111';
export const CVV_REQUIRED = '20506';
export const INVALID_MISSING_PHONE_NUMBER = '21003';
export const RESTAURANT_NOT_LIVE = '31000';
export const ORDER_LEAD_TIME_EXPIRED = '31001';
export const RESTAURANT_DOES_NOT_ALLOW_TIP = '31050';
export const RESTAURANT_DOES_NOT_ALLOW_SINGLE_USE = '31060';
export const PICKUP_UNAVAILABLE = '32001';
export const BELOW_ORDER_PICKUP_MIN = '32003';
export const ABOVE_ORDER_PICKUP_MAX = '32004';
export const BELOW_PICKUP_MINIMUM_WITH_DISCOUNT = '32005';
export const DELIVERY_UNAVAILABLE = '33001';
export const ADDRESS_OUTSIDE_DELIVERY_AREAS = '33002';
export const BELOW_ORDER_DELIVERY_MIN = '33003';
export const ABOVE_ORDER_DELIVERY_MAX = '33004';
export const TIMESLOT_ORDER_THROTTLED = '33006';
export const BELOW_DELIVERY_MINIMUM_WITH_DISCOUNT = '33005';
export const MENU_EXPIRED = '34001';
export const JSON_REQUIRED_FIELD_MISSING = '10101';
export const ITEM_EXPIRED = '34002';
export const MOD_CATEGORY_EXPIRED = '34003';
export const MOD_EXPIRED = '34004';
export const MODS_INVALID_FOR_ITEM = '34005';
export const INVALID_PROMO_CODE = '35001';
export const BETTER_DEAL_APPLIED = '35002';
export const INVALID_ORDER_TOTAL = '36001';
export const TIP_TOO_BIG = '36003';
export const TIP_AND_TIP_PERCENTAGE_EXCLUSIVE = '36006';
export const PREPTIME_CHANGED = '36008';
export const PREPTIME_REMOVED = '36009';
export const CURBSIDE_CLOSED = '37001';
export const CURBSIDE_INVALID_TABLET_VERSION = '37002';
export const CURBSIDE_MISSING_VEHICLE = '37003';
export const CURBSIDE_MISSING_VEHICLE_COLOR = '37004';
export const CURBSIDE_MISSING_VEHICLE_TYPE = '37005';
export const CURBSIDE_INVALID_VEHICLE_COLOR = '37006';
export const CURBSIDE_INVALID_VEHICLE_TYPE = '37007';
export const CURBSIDE_UNAVAILABLE = '37008';
export const DINE_IN_TABLE_REQUIRED = '38004';
export const DINE_IN_TABLE_NOT_ALLOWED = '38005';
export const MEMBERSHIP_EXISTS = '51114';
export const ADMIN_PRIVILEGES_REQUIRED = '25005';
export const ORDER_ADUSTMENT_EXPIRED = '36010';
export const TIP_ADJUSTMENT_MINIMUM = '36011';
export const TIP_ADJUSTMENT_MAXIMUM = '36012';
export const SIFT_ERROR = '41000';

export const MENU_ID_ERROR_FIELD = 'menu_id';
export const CITY_ERROR_FIELD = 'city';
export const STREET_ERROR_FIELD = 'street_address1';

export const apiErrors = {
  [UNAUTHORIZED]: {
    code: '401',
    name: 'unauthorized',
  },
  [NOT_FOUND]: {
    code: '404',
    name: 'notFound',
  },
  [TOO_MANY_REQUESTS]: {
    code: '429',
    name: 'tooManyRequests',
  },
  [INTERNAL_SERVER_ERROR]: {
    code: '500',
    name: 'internalServerError',
    message: 'Sorry about that. Please go back and try again.',
  },
  [GATEWAY_TIME_OUT]: {
    code: '504',
    name: 'gatewayTimeOut',
    message: 'Gateway Time-out',
  },
  [INVALID_JSON_SCHEMA]: {
    code: '10205',
    name: 'invalidJsonSchema',
    message: genericErrorMsg,
  },
  [PASSWORD_INCORRECT]: {
    code: '20103',
    name: 'passwordIncorrect',
    message: 'Password incorrect',
  },
  [GOOGLE_ACCOUNT_INVALID]: {
    code: '20107',
    message: 'Account creation via Google failed',
  },
  [GOOGLE_ACCOUNT_INACTIVE]: {
    code: '20108',
    message: 'Google account inactive',
  },
  [APPLE_ACCOUNT_INVALID]: {
    code: '20110',
    message: 'Account creation via Apple failed',
  },
  [APPLE_ACCOUNT_INACTIVE]: {
    code: '20111',
    message: 'Apple account inactive',
  },
  [CVV_REQUIRED]: {
    code: '20506',
    name: 'cvvRequired',
    message: 'CVV Required',
  },
  [ORDER_LEAD_TIME_EXPIRED]: {
    code: '31001',
    name: 'orderLeadTimeExpired',
    title: 'Not Enough Time for Advanced Order',
    message:
      'Too much time passed for %@ to accept your order for %@. Please select a new time for your order.',
  },
  [PICKUP_UNAVAILABLE]: {
    code: '32001',
    name: 'pickupUnavailable',
    message: 'Pickup is unavailable at this time for this location.',
  },
  [BELOW_ORDER_PICKUP_MIN]: {
    code: '32003',
    name: 'belowOrderPickupMin',
  },
  [ABOVE_ORDER_PICKUP_MAX]: {
    code: '32004',
    name: 'aboveOrderPickupMax',
  },
  [DELIVERY_UNAVAILABLE]: {
    code: '33001',
    name: 'deliveryUnavailable',
    message: 'Delivery is unavailable at this time for this location.',
  },
  [ADDRESS_OUTSIDE_DELIVERY_AREAS]: {
    code: '33002',
    name: 'addressOutsideDeliveryAreas',
    message: 'This location cannot deliver to your address.',
  },
  [BELOW_ORDER_DELIVERY_MIN]: {
    code: '33003',
    name: 'belowOrderDeliveryMin',
  },
  [ABOVE_ORDER_DELIVERY_MAX]: {
    code: '33004',
    name: 'aboveOrderDeliveryMax',
  },
  [MENU_EXPIRED]: {
    code: '34001',
    name: 'menuExpired',
    message: 'The menu has expired and must be reloaded.',
  },
  [ITEM_EXPIRED]: {
    code: '34002',
    name: 'itemExpired',
  },
  [MOD_CATEGORY_EXPIRED]: {
    code: '34003',
    name: 'modCategoryExpired',
  },
  [MOD_EXPIRED]: {
    code: '34004',
    name: 'modExpired',
  },
  [MODS_INVALID_FOR_ITEM]: {
    code: '34005',
    name: 'modsInvalidForItem',
  },
  [INVALID_ORDER_TOTAL]: {
    code: '36001',
    name: 'invalidOrderTotal',
    message: 'Your order total has been changed to: $%@',
  },
  [TIP_TOO_BIG]: {
    code: '36003',
    name: 'tipTooBig',
  },
  [TIP_AND_TIP_PERCENTAGE_EXCLUSIVE]: {
    code: '36006',
    name: 'tipAndTipPercentageExclusive',
  },
  [MEMBERSHIP_EXISTS]: {
    code: '51114',
    name: 'membershipExists',
    message: 'You are already a member for this location.',
  },
  [ADMIN_PRIVILEGES_REQUIRED]: {
    code: '25005',
    name: 'adminPrivilegesRequired',
  },
};

export const SOCIAL_LOGIN_ERRORS = [
  APPLE_ACCOUNT_INVALID,
  GOOGLE_ACCOUNT_INVALID,
  APPLE_ACCOUNT_INACTIVE,
  GOOGLE_ACCOUNT_INACTIVE,
  JSON_REQUIRED_FIELD_MISSING,
];

export const reorderWarnings = {
  CURBSIDE_UNAVAILABLE: '37008',
  DELIVERY_UNAVAILABLE: '33001',
  MENU_ITEM_UNAVAILABLE: '34006',
};

export const MENU_API_VERSION = '5.0';
export const ORDER_DETAILS_API_VERSION = '6.0';
export const ORDER_SUBMIT_API_VERSION = '6.0';
export const ORDER_VALIDATE_API_VERSION = '6.0';
export const RECENTLY_ORDERED_API_VERISON = '5.0';
export const REORDER_VALIDATE_API_VERSION = '5.0';

// TODO: consider moving this to @chownow/cn-web-utils

// Paths will already get "/api" prefix appended when they are used with apiRequest()
// from @chownow/cn-web-utils (ie via helpers/api.js/request())

interface IdType {
  id?: string;
  restaurantId?: string;
  whenId?: string;
}

export const paths = {
  restaurant: ({ id }: IdType) => `restaurant/${id}`,
  restaurantMenu: ({ restaurantId }: IdType) =>
    `restaurant/${restaurantId}/menu`,
  restaurantMenuWhen: ({ restaurantId, whenId }: IdType) =>
    `restaurant/${restaurantId}/menu/${whenId}`,
  yelp: {
    restaurant: ({ id }: IdType) => `yelp/${id}/restaurant`,
  },
};

export function getIs5xxError(code: number) {
  return code >= HTTP_STATUS_SERVER_MIN && code <= HTTP_STATUS_SERVER_MAX;
}

function* handleError(result: any, requestAction: any, meta: object): any {
  let payload = {};
  const code = result.status;
  const is5xxError = getIs5xxError(code);
  const unexpectedError = {
    name: 'unexpectedError',
    message: 'Sorry about that. Please try again.',
    code: code || 'code unknown',
  };
  const errors = []; // API format is array of error objects
  errors.push(apiErrors[code as keyof typeof apiErrors] || unexpectedError);
  const error: {
    name?: string;
    message?: string;
  } = errors[0];

  if (result instanceof TypeError) {
    // @ts-expect-error remove this once logException is converted to TS
    logException({ message: result });
  }

  try {
    payload = yield result.json();

    // Some errors like 404 we would allow to fail silently, but we always want to catch 500s
    if (is5xxError) throw error;

    yield put(requestAction.failure(payload, meta));
  } catch (err) {
    // Log to Sentry if 5xx error, 429 error
    if (err instanceof TypeError) {
      // Log to Sentry if 5xx error, 429 error
      if (code && (is5xxError || code.toString() === TOO_MANY_REQUESTS)) {
        const message = code
          ? `${code}${err.name && ` - ${err.name}`}`
          : error.name;
        // @ts-expect-error remove this once logException is converted to TS
        logException({ message: `RequestError: ${message}` });
        yield callConfig.call.ModalContext?.openModal(MODAL_TYPE.dialog, {
          message:
            // eslint-disable-next-line max-len
            "We've been notified of the error and are hard at work cooking up a fix. Please try refreshing the page soon.",
          confirmLabel: 'Refresh Page',
          onConfirm: () => {
            window.location.reload();
          },
          noCloseOverlay: true,
          isAlert: true,
        });
      }
    }

    yield put(requestAction.failure({ errors }, meta));
  }
}

/**
 * Makes API request and returns the proper action handler based on status returned
 * @param  {function} requestAction
 * @param  {string} path
 * @param  {Object} [params]
 * @param  {string} [method='GET']
 * @param  {Object} [meta]
 * @param  {function | Object} [serializer] - could be custom serializer or a normalizr schema
 * schema docs - https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#schema
 */
function* resolveRequest(
  request: any,
  requestAction: any,
  path: string,
  params: string,
  method = 'GET', // eslint-disable-line @typescript-eslint/no-unused-vars, default-param-last
  meta: object,
  serializer: any
): any {
  const { endpoint, ...options } = request;

  try {
    const result = yield fetch(endpoint, options);

    // verify request had 2xx status code
    if (result.ok) {
      let payload = yield result.json();

      if (isFunction(serializer)) {
        payload = serializer(payload);
      } else if (isObject(serializer)) {
        payload = normalize(payload, serializer);
      }

      yield put(requestAction.success(payload, meta));
    } else {
      yield call(handleError, result, requestAction, meta);
    }
  } catch (err) {
    yield call(handleError, err, requestAction, meta);
  }
}

// Using unsafe any per this question https://stackoverflow.com/questions/58522383/typescript-use-types-on-call-from-redux-saga
interface RequestType {
  requestAction?: any;
  path: string;
  params?: any;
  method?: any;
  meta?: any;
  data?: any;
  apiVersion?: string;
}

export function* request({
  requestAction,
  path,
  params,
  method,
  meta,
  data,
  apiVersion,
}: RequestType) {
  const request = apiRequest(path, params, method, '', apiVersion);

  yield call(
    resolveRequest,
    request,
    requestAction,
    path,
    params,
    method,
    meta,
    data,
    apiVersion
  );
}

export async function fetchFbPixel(restaurantId: string) {
  const baseUrl = process.env.REACT_APP_PIXEL_API_HOST;
  // NOTE this request will only work in `prod`, `qa` and `stg` environments.
  await fetch(`${baseUrl}/fbe/restaurant/${restaurantId}`)
    .then((response) => response.json())
    .then(
      (data) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        data.pixel_id &&
        setFbPixelId({
          pixelId: data.pixel_id,
          restaurantId: data.restaurant_id,
        })
    )
    .catch((e) => {
      if (e instanceof TypeError) {
        // @ts-expect-error remove this once logException is converted to TS
        logException({ message: e });
      }
    });
}

export function formatError(err: { errors: string }) {
  if (!err) return undefined;

  const responseError = err.errors ? err.errors[0] : { message: err };
  return { errors: [responseError] };
}

interface GetFromApiType {
  endpoint: string;
  params: object;
  customHost?: string;
  apiVersion?: string;
}

// Method for making GET requests to chownow API.
// Properly formats requests for different domains.
export async function getFromChownowApi({
  endpoint,
  params,
  customHost,
  apiVersion,
}: GetFromApiType) {
  const { endpoint: formattedEndpoint, ...options } = apiRequest(
    endpoint,
    params,
    'GET',
    customHost,
    apiVersion
  );
  let data;
  let error;
  try {
    const response = await fetch(formattedEndpoint, options);
    if (response.status === 404) {
      error = { message: 'Error fetching resource' };
      return { data, error };
    }
    data = await response.json();
  } catch (e) {
    error = e;
  }

  return { data, error };
}

interface PostToApiType {
  endpoint: string;
  body?: object;
  method?: string;
}

// Method for making POST, PUT and DELETE requests to chownow API.
// Properly formats requests for different domains.
export async function postToChownowApi({
  endpoint,
  body,
  method,
}: PostToApiType) {
  const { endpoint: formattedEndpoint, ...options } = apiRequest(
    endpoint,
    body,
    method || 'POST'
  );

  try {
    const response = await fetch(formattedEndpoint, options);
    const { status } = response;
    const data = await response.json();
    switch (status) {
      case 404:
        return {
          data: null,
          error: {
            message: 'Error fetching resource',
          },
        };
      case 400:
        // 400 responses do not cause fetch to throw
        // in this case the "data" should be reassigned as an error
        return {
          error: data.errors,
          data: null,
        };
      default:
        return {
          error: null,
          data,
        };
    }
  } catch (e) {
    return { data: null, error: e };
  }
}
