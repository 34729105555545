import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  logException,
  logAnalyticsEvent,
  ANALYTICS_EVENT_NAME,
} from 'helpers/loggers';

import Support from 'containers/Support';

// Using this to capture all unhandled JavaScript render errors
// https://reactjs.org/docs/error-boundaries.html
// Note: getDerivedStateFromError is currently not available in hook form
class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    const errorDesc = error.message ? error.message : error;

    logException({ message: `ErrorBoundary - err: ${errorDesc}` });

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.viewErrorPage,
      attributes: {
        type: 'Error Boundary',
        message: errorDesc,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Support isErrorBoundary />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: undefined,
};

export default ErrorBoundary;
