import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams, useLocation } from 'react-router-dom';

import { logException } from 'helpers/loggers';

import {
  setSelectedRestaurantId,
  getSelectedRestaurantHqId,
} from 'modules/restaurant';

function YelpRestaurantRedirect() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { restaurantId } = useParams();
  const hqId = useSelector(getSelectedRestaurantHqId);

  const canRedirect = restaurantId && hqId;

  useEffect(() => {
    if (!restaurantId) {
      logException({ message: ':restaurantId URL param is needed to fetch company ID' });
      return;
    }
    // If the API call succeeds it will set restaurant.selectedRestaurant.company_id
    // which we retrieve with getSelectedRestaurantHqId above
    dispatch({
      type: setSelectedRestaurantId.TYPE,
      payload: restaurantId,
    });
  }, [restaurantId]);

  return canRedirect ? (
    <Redirect
      to={`/order/${hqId}/locations/${restaurantId}${location.search || ''}`}
    />
  ) : null;
}

export default YelpRestaurantRedirect;
