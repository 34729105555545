/* eslint-disable complexity */
/**
 * Returns a boolean regarding whether the order is submitting and we are waiting for response from API
 * and Stripe. Checkout uses this to show Loading Spinner.
 * Returns submit order dispatch used in Checkout in various ways depending on the method of payment. We
 * can receive from Checkout either meta, payload or neither for order submit. Order submit is also passed
 * through Checkout to both useApplePay and useGooglePay.
 * @returns {boolean} returns true/false for isOrderSubmitting, to be used to show loading spinner
 * @returns {function} returns submit order dispatch
 */
import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { some, get, isEmpty, pick } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { CompanyContext } from 'context/CompanyContext';
import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';
import useInterval from 'hooks/useInterval';

import { MODAL_TYPE } from 'helpers/modals';
import {
  ANALYTICS_EVENT_NAME,
  ANALYTICS_EVENT_TYPES,
  createAnalyticsProduct,
  logAnalyticsEvent,
  logAnalyticsCommerceEvent,
} from 'helpers/loggers';
import { SIFT_ERROR, UNAUTHORIZED, getIs5xxError } from 'helpers/api';
import { CONFIRMATION_ROKT_ADS_COOKIE } from 'helpers/constants';
import {
  ORDER_STATUSES,
  resetSubmitOrderCount,
  // setTriggerPostPurchaseModal,
} from 'helpers/order';
import { buildRoute, ROUTES } from 'helpers/routes';
import {
  ATTRIBUTES as A,
  orderAttributes,
  restaurantAttributes,
} from 'helpers/analytics';
import { getIsDirectToMpRedirect } from 'helpers/configureRedirects';

import {
  INITIAL_TIMER,
  fetchOrderStatusXHR,
  getErrorsForOrder,
  getIsProcessed,
  getIsLoading,
  getIsComplete,
  getOrderGuid,
  getOrderId,
  getOrderStatus,
  getOrderTotal,
  getSetSubmitting,
  getTimer,
  resetOrder,
  resetSubmitOrderRequest,
  setOrderCompleted,
  submitOrderXHR,
  submitYelpOrderXHR,
  updateTimer,
  getOrderData,
} from 'modules/order';
import { getRestaurantDetails } from 'modules/restaurant';

const TIMER_INTERVAL = 1000;
const SHOW_POLLING_THRESHOLD = 110;
const HIDE_LOADING_THRESHOLD = 108;

function isEven(n) {
  return n % 2 === 0;
}

function useSubmitOrder() {
  const cookies = new Cookies();

  const { isCanadian } = useContext(CompanyContext);
  const { menu } = useContext(MenuContext);
  const { openModal, isModalOpen } = useContext(ModalContext);
  const { selectedCard, selectedCardId, setCardForm, setSelectedCardId, user } =
    useContext(UserContext);

  const dispatch = useDispatch();
  const history = useHistory();
  const { hqId, restaurantId } = useParams();

  const isOrderProcessed = useSelector(getIsProcessed);
  const isOrderComplete = useSelector(getIsComplete);
  const order = useSelector(getOrderData);
  const orderErrors = useSelector(getErrorsForOrder);
  const orderId = useSelector(getOrderId);
  const guid = useSelector(getOrderGuid);
  const orderStatus = useSelector(getOrderStatus);
  const orderTotal = useSelector(getOrderTotal);
  const isLoading = useSelector(getIsLoading);
  const restaurant = useSelector(getRestaurantDetails);
  const setSubmitting = useSelector(getSetSubmitting);
  const timer = useSelector(getTimer);

  const prevIsLoading = usePrevious(isLoading);
  const prevIsOrderComplete = usePrevious(isOrderComplete);
  const prevTimer = usePrevious(timer);

  const { isMarketplacePlatform, isYelpPlatform } = usePlatform();

  const isAuthenticated = !!user?.id;

  const shouldShowLoadingSpinner =
    (isLoading || (orderId && !isOrderProcessed && !isModalOpen)) &&
    timer > HIDE_LOADING_THRESHOLD;

  // should only allow timer to run if we have an order status and do not have an order id,
  // the order is not processed (returned if order is canceled or declined), we do not have
  // any order errors or if we have an unidentified error and the timer has not run out.
  const shouldStartTimer =
    orderStatus &&
    !isOrderProcessed &&
    (!orderErrors.length || (orderErrors.length && !orderErrors[0].code)) &&
    timer !== 0;

  // Update the timer
  function checkUpdateTimer() {
    dispatch({
      type: updateTimer.TYPE,
      payload: timer - 1,
    });
  }

  useInterval(checkUpdateTimer, shouldStartTimer ? TIMER_INTERVAL : null);

  // Fetch order status. If status not yet completed and has been attempting
  // to complete for longer than 108s, show "Sorry to keep you waiting." modal
  function checkStatus() {
    if (timer && timer <= SHOW_POLLING_THRESHOLD) {
      openModal(MODAL_TYPE.polling, { noCloseOverlay: true });
    }

    dispatch({
      type: fetchOrderStatusXHR.request.TYPE,
      payload: orderId,
    });
  }

  // If we have certain errors (500s and 401), complete order and route to support
  function redirectToSupport() {
    dispatch({ type: setOrderCompleted.TYPE });

    return history.push(buildRoute({ hqId, route: ROUTES.support }));
  }

  // If order is successful, route to confirmation
  function orderDidComplete() {
    dispatch({ type: setOrderCompleted.TYPE });

    const products =
      order.items &&
      order.items.map((item) =>
        createAnalyticsProduct(
          item.name,
          item.id,
          item.price.toFixed(2),
          item.quantity,
          item.categoryName,
          isCanadian ? 'CAD' : 'USD'
        )
      );
    // TO DO: INVESTIGATE SENTRY ERROR https://chownow.atlassian.net/browse/CN-27284
    // mParticle
    // let userAttributes;
    // if (window.mParticle) {
    //   try {
    //     const currentUser = window.mParticle.Identity.getCurrentUser();
    //     userAttributes = currentUser && currentUser.getAllUserAttributes();
    //   } catch (err) {
    //     // fail silently when error occurs
    //     return;
    //   }
    // }

    const attributes = {
      menu_id: menu.id,
      payment_type: selectedCard?.type,
      is_embedded_site: window.top !== window,
      is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
      // cn_channel: userAttributes?.cn_channel,
      // utm_campaign: userAttributes?.utm_campaign,
      // utm_medium: userAttributes?.utm_medium,
      // utm_source: userAttributes?.utm_source,
      ...pick(orderAttributes(order), [
        A.orderAheadDatetime,
        A.orderIsAsap,
        A.orderType,
        A.orderIsReorder,
        A.hasDeliveryFee,
        A.deliveryFeeAmount,
        A.hasMiscFee,
        A.miscFeeAmount,
      ]),
      ...pick(restaurantAttributes(restaurant), [
        A.restaurantBrandId,
        A.restaurantLocationCategory,
        A.restaurantLocationId,
        A.restaurantLocationName,
      ]),
    };
    const transactionAttributes = {
      Id: orderId,
      Revenue: orderTotal,
      Tax: order.sales_tax,
    };
    const marketplaceAttributes = {
      ...attributes,
      is_embedded_site: undefined, // only send atttribute when not on MP
      has_marketplace_diner_fee: !isEmpty(order.marketplace_diner_fee),
      marketplace_diner_fee_amount:
        get(order.marketplace_diner_fee, 'amount') || 0,
    };
    logAnalyticsCommerceEvent(
      ANALYTICS_EVENT_NAME.purchase,
      products,
      isMarketplacePlatform ? marketplaceAttributes : attributes,
      null,
      transactionAttributes
    );

    // checks if card was previously declined, and if so, removes from declined array in session storage
    resetSubmitOrderCount(selectedCardId);

    // Reset selected card
    setSelectedCardId(null);

    // QUIET POST PURCHASE MODAL https://chownow.atlassian.net/browse/CN-38535
    // setTriggerPostPurchaseModal();

    const canUseToken = !isAuthenticated;

    cookies.set(CONFIRMATION_ROKT_ADS_COOKIE, 'true');

    history.push(
      buildRoute({
        route: ROUTES.confirmation,
        orderId,
        query: {
          name: 'guid',
          value: canUseToken ? guid : '',
        },
      })
    );
  }

  useEffect(() => {
    const isTimedOut = timer === INITIAL_TIMER || timer <= 0;
    if (orderId && isTimedOut) {
      dispatch({ type: resetOrder.TYPE });
      history.replace(
        buildRoute({
          hqId,
          restaurantId,
          route: restaurantId ? ROUTES.menu : ROUTES.locations,
        })
      );
    }
  }, []);

  useEffect(() => {
    // on every other timer update, check status of order (every 2 seconds)
    if (prevTimer && isEven(timer)) {
      checkStatus();
    }

    // if timer runs out, redirect to support
    if (timer <= 0) {
      redirectToSupport();
    }
  }, [timer]);

  useEffect(() => {
    // If there are errors, show the error modal
    if (orderErrors.length) {
      const errorCode =
        orderErrors[0].code && parseInt(orderErrors[0].code, 10);
      const is5xxError = getIs5xxError(errorCode);
      const didAttemptOrderSubmit = prevIsLoading && !isLoading;

      if (orderErrors[0].code === SIFT_ERROR) {
        const errorMessage = orderErrors[0].message;

        openModal(MODAL_TYPE.dialog, {
          message: errorMessage,
          noCloseOverlay: true,
        });

        logAnalyticsEvent({
          eventName: ANALYTICS_EVENT_NAME.purchaseFailure,
          eventType: ANALYTICS_EVENT_TYPES.Transaction,
          attributes: {
            error_reason: errorMessage,
            payment_type: selectedCard?.type,
            is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
            restaurant_location_id: restaurant?.id,
          },
        });
      }

      if (
        (is5xxError && (didAttemptOrderSubmit || orderStatus)) ||
        some(orderErrors, { code: UNAUTHORIZED })
      ) {
        // Reset selected card and redirect to Support page
        setSelectedCardId(null);
        redirectToSupport();
      }

      // Reset Formik form submit on order errors
      if (setSubmitting && orderStatus !== ORDER_STATUSES.declined) {
        dispatch({ type: resetSubmitOrderRequest.TYPE });
        setSubmitting(false);
      }
    }
  }, [orderErrors]);

  useEffect(() => {
    // If the order is declined, reset the order request, the timer and Formik form submit
    if (orderStatus === ORDER_STATUSES.declined) {
      dispatch({ type: resetSubmitOrderRequest.TYPE });
      // reset card form to initial state
      setCardForm();
      if (setSubmitting) setSubmitting(false); // Need to reset formik form
    }
  }, [orderStatus]);

  useEffect(() => {
    if (isOrderComplete && !prevIsOrderComplete) {
      orderDidComplete();
    }
  }, [isOrderComplete]);

  useEffect(() => {
    // If the order changes to a processed state, it means the order declined/canceled, so reset it
    if (isOrderProcessed && !orderErrors.length) {
      dispatch({ type: resetOrder.TYPE });
    }
  }, [isOrderProcessed]);

  // For Yelp orders, dispatch a Yelp-specific submit order action.
  const submitOrderAction = isYelpPlatform
    ? submitYelpOrderXHR.request.TYPE
    : submitOrderXHR.request.TYPE;

  return {
    isOrderSubmitting: shouldShowLoadingSpinner,
    submitOrder: (payload, meta) =>
      dispatch({
        type: submitOrderAction,
        payload,
        meta,
      }),
  };
}

export default useSubmitOrder;
