import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ModalFooterProps {
  children: ReactNode;
  bottomFixed?: boolean; // used in ModalFrame
}

function ModalFooter({ children, bottomFixed }: ModalFooterProps) {
  return (
    <div
      className={classNames(styles.modalfooter, {
        [styles.fixed]: bottomFixed,
      })}
    >
      {children}
    </div>
  );
}

export default ModalFooter;
