import React, { useEffect, useState } from 'react';

import { ReactComponent as IconArrowRight } from 'images/icon-arrow-right.svg';

import styles from './styles.module.scss';

const TIME_LIMIT = 13;
const FULL_DASH_ARRAY = 283;

function ButtonTimer() {
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;

  const [circleDasharray, setCircleDasharray] = useState();

  function calculateTimeFraction() {
    return timeLeft / TIME_LIMIT;
  }

  function getCircleDasharray() {
    const dasharray = `${(
      FULL_DASH_ARRAY -
      calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)} 283`;

    setCircleDasharray(dasharray);
  }

  useEffect(() => {
    getCircleDasharray();

    // start timer
    const interval = setInterval(() => {
      timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;

      getCircleDasharray();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.baseTimer}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g className={styles.baseTimerCircle}>
          <circle className={styles.baseTimerPath} cx="50" cy="50" r="45" />
          <path
            id="base-timer-path-remaining"
            strokeDasharray="283"
            className={styles.baseTimerPathRemaining}
            style={{ strokeDasharray: circleDasharray }}
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
          />
        </g>
      </svg>
      <div className={styles.interiorButton}>
        <IconArrowRight className={styles.iconArrow} />
      </div>
    </div>
  );
}

export default ButtonTimer;
