/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { some } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';

import { restaurantBaseType } from 'helpers/prop-types';
import { MODAL_TYPE } from 'helpers/modals';
import { apiErrors, MEMBERSHIP_EXISTS, postToChownowApi } from 'helpers/api';
import { buildRoute, ROUTES } from 'helpers/routes';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import usePrevious from 'hooks/usePrevious';
import useRequiresAuth from 'hooks/useRequiresAuth';
import useIsMobile from 'hooks/useIsMobile';

import Navbar from 'components/Navbar';
import StripeCard from 'components/StripeCard';
import OrderButton from 'containers/Checkout/OrderButton';
import Footer from 'components/Footer';

import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';
import TagNotice from 'primitives/TagNotice';

import { ReactComponent as BadgeGold } from 'images/icon-member-gold.svg';
import { ReactComponent as BadgeSilver } from 'images/icon-member-silver.svg';
import { ReactComponent as BadgeBronze } from 'images/icon-member-bronze.svg';
import { ReactComponent as Restaurant } from 'images/icon-restaurant-24.svg';
import { ReactComponent as CreditCard } from 'images/icon-creditcard-24.svg';
import MembershipOrderSummary from './MembershipOrderSummary';

import styles from './styles.module.scss';

function MembershipCheckout(props) {
  const { restaurant } = props;

  const auth = useRequiresAuth();
  const isMobile = useIsMobile();

  const { openModal } = useContext(ModalContext);
  const { user, membership, setUser, handleFetchUserProfile } =
    useContext(UserContext);
  const { memberships: userMemberships } = user;
  const { selectedMembership, setSelectedMembership } = membership;

  const history = useHistory();
  const { hqId, restaurantId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingMembership, setIsCreatingMembership] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [errors, setErrors] = useState([]);

  const prevIsCreatingMembership = usePrevious(isCreatingMembership);

  const Badges = {
    Gold: <BadgeGold />,
    Silver: <BadgeSilver />,
    Bronze: <BadgeBronze />,
  };

  function onPurchaseSuccess() {
    return history.push(
      buildRoute({ hqId, restaurantId, route: ROUTES.membershipConfirmation })
    );
  }

  useEffect(() => {
    if (selectedMembership?.restaurantId !== restaurantId) {
      setSelectedMembership();
    }
  }, []);

  async function fetchCreateMembership(result) {
    setIsCreatingMembership(true);
    const { error, data } = await postToChownowApi({
      endpoint: `customer/${user.id}/membership`,
      body: {
        payment_method_id: result.paymentMethod.id,
        plan_id: selectedMembership.id,
        restaurant_id: restaurantId,
      },
    });

    if (!error) {
      const memberships = user.memberships || [];
      memberships.push(data);
      setUser({ ...user, memberships });
      setIsEnrolled(true);
    } else {
      setErrors(error);
    }

    setIsCreatingMembership(false);
    handleFetchUserProfile();
  }

  async function handleSubmit(event, stripe, elements) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsSubmitting(true);

    // TODO: allow for use of saved stripe payment
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setIsSubmitting(false);

      openModal(MODAL_TYPE.dialog, {
        title: 'Unable to Complete Purchase',
        message: 'Please try again.',
        confirmLabel: 'Go Back',
        isAlert: true,
      });
    } else {
      // Otherwise send paymentMethod.id to your server
      fetchCreateMembership(result);
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (prevIsCreatingMembership && !isCreatingMembership) {
      setIsSubmitting(false);
      if (errors.length) {
        const memberExistsError = some(errors, { code: MEMBERSHIP_EXISTS });

        // NOTE: In some cases user can get 500 AND membership is creating, checking that here and sending to
        // confirmation page in that case
        if (
          !memberExistsError &&
          some(userMemberships, {
            restaurant_id: restaurantId,
            is_active: true,
          })
        ) {
          return onPurchaseSuccess();
        }

        openModal(MODAL_TYPE.dialog, {
          title: 'Unable to Complete Purchase',
          message: memberExistsError
            ? apiErrors[MEMBERSHIP_EXISTS].message
            : 'Please try again.',
          isAlert: true,
          onConfirm: () => {
            setIsEnrolled(false);
            setIsCreatingMembership(false);
          },
        });
      } else if (isEnrolled) {
        return onPurchaseSuccess();
      }
    }
  }, [isCreatingMembership]);

  if (!auth) {
    return <LoadingSpinner />;
  }

  if (selectedMembership) {
    return (
      <>
        <Helmet>
          <title>{restaurant.short_name} Loyalty Membership Checkout</title>
        </Helmet>
        {isCreatingMembership && (
          <div className={styles.fixedSpinner}>
            <LoadingSpinner />
          </div>
        )}
        <Navbar />
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <Grid container className={styles.membershipCheckout}>
              <Grid sm={4} md={7} lg={7}>
                <p className={styles.header}>
                  Hey {user.first_name}, <br />
                  let&apos;s review your order.
                </p>
                <div className={styles.card}>
                  <div className={styles.cardTitle}>Membership Details</div>
                  <div className={styles.cardContent}>
                    <div
                      className={classNames(
                        styles.contentWithIcon,
                        styles.contentWithBorder
                      )}
                    >
                      {Badges[selectedMembership.name]}
                      {selectedMembership.name}, {selectedMembership.percent}%
                      off every order
                    </div>
                    <div
                      className={classNames(
                        styles.contentWithIcon,
                        styles.contentWithBorder
                      )}
                    >
                      <Restaurant />
                      {restaurant.name} <br />
                      {restaurant.address.street_address1}
                    </div>
                    <div className={styles.contentWithIcon}>
                      <CreditCard />${selectedMembership.amount} / 1 year
                    </div>
                  </div>
                </div>
                {isMobile && (
                  <MembershipOrderSummary
                    selectedMembership={selectedMembership}
                  />
                )}
                <div className={styles.card}>
                  <div className={styles.cardTitle}>Payment Details</div>
                  <div className={styles.cardContent}>
                    <form onSubmit={(e) => handleSubmit(e, stripe, elements)}>
                      <StripeCard />
                    </form>
                  </div>
                </div>
                <div className={styles.orderBtnWrapper}>
                  <div
                    className={classNames(styles.tagWrapper, styles.centerTag)}
                  >
                    <TagNotice>
                      Membership purchase is non-refundable and only valid at
                      this location.
                    </TagNotice>
                  </div>
                  <OrderButton
                    onSubmit={(e) => handleSubmit(e, stripe, elements)}
                    isOrderBtnDisabled={!stripe || isSubmitting}
                    total={selectedMembership.total}
                    fulfillmentMethod=""
                    isApplePaySelected={false}
                    isGooglePaySelected={false}
                  />
                </div>
              </Grid>
              {!isMobile && (
                <Grid sm={0} md={5} lg={4} className={styles.rightRail}>
                  <OrderButton
                    onSubmit={(e) => handleSubmit(e, stripe, elements)}
                    isOrderBtnDisabled={!stripe || isSubmitting}
                    total={selectedMembership.total}
                    fulfillmentMethod=""
                    isApplePaySelected={false}
                    isGooglePaySelected={false}
                  />
                  <div className={styles.tagWrapper}>
                    <TagNotice>
                      Membership purchase is non-refundable and only valid at
                      this location.
                    </TagNotice>
                  </div>
                  <MembershipOrderSummary
                    selectedMembership={selectedMembership}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </ElementsConsumer>
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </>
    );
  }

  if (!selectedMembership) {
    const redirect = buildRoute({
      hqId,
      restaurantId,
      route: ROUTES.membershipPlans,
    });
    history.replace(redirect);
  }
}

MembershipCheckout.propTypes = {
  restaurant: restaurantBaseType.isRequired,
};

export default MembershipCheckout;
