import { get } from 'lodash';

import {
  formatError,
  getFromChownowApi,
  RECENTLY_ORDERED_API_VERISON,
} from 'helpers/api';
import { getItemsFromMenuData, formatQueries } from 'helpers/menu';
import { getModifierNames } from 'helpers/order';

/**
 * Fetch recently ordered items from customer ID
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @returns {object} - returns recently ordered items object after filtering child items
 */

export async function fetchRecentlyOrderedItems({
  restaurantId,
  when,
  channel,
}) {
  const queries = {
    requested_time: when,
    cn_channel: channel,
  };

  const formattedQueries = formatQueries(queries);
  const { data, error } = await getFromChownowApi({
    endpoint: `customer/me/restaurant/${restaurantId}/recently-ordered${
      formattedQueries ? `?${formattedQueries}` : ''
    }`,
    apiVersion: RECENTLY_ORDERED_API_VERISON,
  });

  if (!error) {
    const recentlyOrderedCategories = data.menu_categories || [];

    if (recentlyOrderedCategories.length) {
      // return all items and modifiers (includes child modifiers)
      const itemData = getItemsFromMenuData(data);

      // ADD USER MODIFIERS + SIZE
      // get string array of modifierNames for displaying
      const allModifiers = data.modifiers || [];
      itemData.forEach((item) => {
        // for each item, grab modifier_categories to create array of modifier strings
        const userModifierCategories = get(
          item.user_selection,
          'modifier_categories',
          []
        );
        let modifierNames = [];

        if (item.is_meta) {
          const sizeId = item.user_selection.selected_size_id;
          // NOTE: this should maybe live in 'user_selection' but needed to put it here to populate
          // menu item modal correctly
          /* eslint-disable no-param-reassign */
          item.sizeId = sizeId;

          // Find the modifier with the size id and add that to modifier names
          const sizeMod =
            item?.meta_item_details?.serving_size_group?.child_items.find(
              (i) => i.id === sizeId
            );

          if (sizeMod?.size) {
            modifierNames.push(sizeMod.size);
          }
        }

        if (userModifierCategories.length) {
          const modNames = getModifierNames(
            userModifierCategories,
            allModifiers
          );

          modifierNames = [...modifierNames, ...modNames];
        }

        // dont want this to be null, but empty string for bundling items
        item.user_selection.special_instructions =
          item.user_selection.special_instructions || '';

        // Adding modifierNames to item object for display
        item.user_selection.modifierNames = modifierNames;
        /* eslint-enable no-param-reassign */
      });
      // END MODIFIERS + SIZE

      return itemData;
    }
    return null;
  }
  return formatError(error);
}

export default fetchRecentlyOrderedItems;
