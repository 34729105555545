import React, { useState } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { menuImageType } from 'helpers/prop-types';

import styles from './styles.module.scss';

function MenuItemImage(props) {
  const {
    alt,
    fit,
    height,
    image,
    isOnMenu,
    isRecentlyOrdered,
    quality,
    width,
    handlePhotoError,
  } = props;
  const [hasError, setHasError] = useState(false);
  const [imageRef, inView] = useInView({ triggerOnce: true });
  const { cropped_url: croppedUrl } = image;
  const options = {
    height,
    width,
    quality,
    fit,
  };
  const hdSize = {
    height: height * 2,
    width: width * 2,
  };
  const params = new URLSearchParams(options).toString();
  const hdParams = new URLSearchParams({ ...options, ...hdSize }).toString();

  return (
    <div
      className={classNames(styles.thumbnailContainer, {
        [styles.thumbnailContainerSmall]: isRecentlyOrdered,
        [styles.hidden]: hasError,
      })}
      ref={imageRef}
    >
      {inView && !hasError && (
        <img
          src={`${croppedUrl}&${params}`}
          alt={alt}
          className={classNames(styles.image, { [styles.rounded]: isOnMenu })}
          srcSet={`${croppedUrl}&${hdParams} 2x`}
          onError={() => {
            handlePhotoError(true);
            setHasError(true);
          }}
        />
      )}
    </div>
  );
}

MenuItemImage.propTypes = {
  alt: PropTypes.string.isRequired,
  fit: PropTypes.oneOf(['contain', 'cover', 'crop']),
  handlePhotoError: PropTypes.func,
  height: PropTypes.number.isRequired,
  image: menuImageType.isRequired,
  isOnMenu: PropTypes.bool,
  isRecentlyOrdered: PropTypes.bool,
  quality: PropTypes.number,
  width: PropTypes.number.isRequired,
};

MenuItemImage.defaultProps = {
  fit: 'contain',
  quality: 85,
  handlePhotoError: () => {},
  isOnMenu: false,
  isRecentlyOrdered: false,
};

export default MenuItemImage;
