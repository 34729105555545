/* eslint-disable max-len */
import React from 'react';

export default function PhoneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.33333C3 1.30319 3.86172 0.5 4.88462 0.5H11.1154C12.1383 0.5 13 1.30319 13 2.33333V13.6667C13 14.6968 12.1383 15.5 11.1154 15.5H4.88462C3.86172 15.5 3 14.6968 3 13.6667V2.33333ZM4.88462 1.5C4.3781 1.5 4 1.89071 4 2.33333V13.6667C4 14.1093 4.3781 14.5 4.88462 14.5H11.1154C11.6219 14.5 12 14.1093 12 13.6667V2.33333C12 1.89071 11.6219 1.5 11.1154 1.5H4.88462Z"
        fill="#142138"
      />
      <path
        d="M9 12.75C9 13.1642 8.66421 13.5 8.25 13.5C7.83579 13.5 7.5 13.1642 7.5 12.75C7.5 12.3358 7.83579 12 8.25 12C8.66421 12 9 12.3358 9 12.75Z"
        fill="#142138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3.5C5.5 3.22386 5.72386 3 6 3H10C10.2761 3 10.5 3.22386 10.5 3.5C10.5 3.77614 10.2761 4 10 4H6C5.72386 4 5.5 3.77614 5.5 3.5Z"
        fill="#142138"
      />
    </svg>
  );
}
