/* eslint-disable complexity, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-static-element-interactions */
import React, { createRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import scrollToElement from 'scroll-to-element';
import { useSpring, animated } from 'react-spring';
import { findIndex } from 'lodash';

import { menuCategoryType } from 'helpers/prop-types';
import { MODAL_TYPE } from 'helpers/modals';
import { yelpScrollToElement } from 'helpers/yelp';

import { ModalContext } from 'context/ModalContext';

import usePlatform from 'hooks/usePlatform';
import useIsMobile from 'hooks/useIsMobile';
import usePrevious from 'hooks/usePrevious';

import DropdownWrapper from 'components/DropdownWrapper';
import MenuSimple from 'primitives/MenuSimple';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { ReactComponent as IconSearch } from 'images/icon-search.svg';
import { ReactComponent as IconCaretDown } from 'images/icon-caretdown.svg';

import styles from './styles.module.scss';
import SearchInput from './SearchInput';

export function countSearchResults(categories) {
  return categories.reduce(
    (count, category) => count + category.items.length,
    0
  );
}

function MenuCategoryNav(props) {
  const {
    activeCategory,
    categories,
    searchQuery,
    onSearchQuery,
    stickyHeader,
  } = props;

  const { openModal, closeModal } = useContext(ModalContext);

  const [showSearch, setShowSearch] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const mobileInputRef = createRef();

  const { isYelpPlatform } = usePlatform();
  const isMobile = useIsMobile();

  const prevCategory = usePrevious(activeCategory);
  const reset = prevCategory !== activeCategory;
  const prevIndex = findIndex(categories, { name: prevCategory });
  const activeIndex = findIndex(categories, { name: activeCategory });
  const springProps = useSpring({
    config: { duration: 150 },
    from: {
      opacity: 0,
      transform: `translate3d(0,${
        prevIndex < activeIndex ? '100%' : '-100%'
      },0)`,
    },
    opacity: 1,
    reset,
    transform: 'translate3d(0,0,0)',
  });

  useEffect(() => {
    if (showSearch && !stickyHeader) {
      scrollToElement('#mobile_search', {
        offset: -80, // scroll to top of menu
        ease: 'inOutExpo',
        duration: 1500,
      });
    }
  }, [showSearch]);

  function toggleDropdown() {
    setIsDropdownVisible(!isDropdownVisible);
  }

  async function handleScroll(index) {
    document.activeElement.blur();

    const offset = isMobile ? -154 : -176;

    if (isMobile) {
      await closeModal();
    } else toggleDropdown();

    // Yelp platform requires special handling when
    // we wish to scroll the viewport to a menu category.
    if (isYelpPlatform) {
      yelpScrollToElement(`#category_${index}`, stickyHeader);
    } else {
      scrollToElement(`#category_${index}`, {
        offset,
        ease: 'inOutExpo',
        duration: 800,
      });
    }
  }

  function handleOpenCategoryModal() {
    openModal(MODAL_TYPE.category, { activeIndex, categories, handleScroll });
  }

  return (
    <>
      <div
        className={classNames(styles.menuCategoryNavWrapper, {
          [styles.stickyHeaderPadding]: stickyHeader && isMobile,
        })}
      >
        <div
          className={classNames({
            [styles.sticky]: stickyHeader && isMobile,
            [styles.categoryNavWrap]: isMobile,
          })}
        >
          <div
            className={classNames(styles.categoryNav, {
              [styles.hide]: showSearch,
            })}
            data-testid="CategoryNav"
            onClick={isMobile ? handleOpenCategoryModal : toggleDropdown}
            onKeyDown={isMobile ? handleOpenCategoryModal : toggleDropdown}
            tabIndex={0}
          >
            <animated.div className={styles.select} style={springProps}>
              <span className={styles.activeCategory}>{activeCategory}</span>
              <IconCaretDown
                className={classNames(styles.iconCaret, {
                  [styles.rotate]: isDropdownVisible,
                })}
              />
            </animated.div>
            {isDropdownVisible && (
              <DropdownWrapper className={styles.menuCategoryDropdown}>
                {categories.map((category, index) => (
                  <MenuSimple
                    label={category.name}
                    handleClick={() => handleScroll(index)}
                    key={category.id}
                  />
                ))}
              </DropdownWrapper>
            )}
          </div>

          {isMobile && (
            <>
              <div
                id="mobile_search"
                className={classNames(styles.mobileSearchInput, {
                  [styles.hide]: !showSearch,
                })}
              >
                <SearchInput
                  onChange={onSearchQuery}
                  searchQuery={searchQuery}
                  inputRef={mobileInputRef}
                />
                <IconClose
                  className={styles.iconClear}
                  onClick={() => {
                    onSearchQuery('');
                    setShowSearch(false);
                  }}
                  role="button"
                />
              </div>

              <IconSearch
                alt="clear search"
                className={classNames(
                  styles.iconSearch,
                  styles.iconSearchMobile,
                  {
                    [styles.hide]: showSearch,
                  }
                )}
                onClick={() => {
                  setShowSearch(true);
                  mobileInputRef.current.focus();
                }}
                role="button"
              />
            </>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className={styles.searchWrapper}>
          <SearchInput onChange={onSearchQuery} searchQuery={searchQuery} />
        </div>
      )}
      <div
        className={classNames(styles.dropdownOverlay, {
          [styles.isActive]: isDropdownVisible,
        })}
        onClick={toggleDropdown}
      />
    </>
  );
}

MenuCategoryNav.propTypes = {
  activeCategory: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(menuCategoryType).isRequired,
  onSearchQuery: PropTypes.func,
  searchQuery: PropTypes.string,
  stickyHeader: PropTypes.bool,
};

MenuCategoryNav.defaultProps = {
  onSearchQuery: undefined,
  searchQuery: undefined,
  stickyHeader: false,
};

export default MenuCategoryNav;
