/* eslint-disable complexity, consistent-return */
import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { ModalContext } from 'context/ModalContext';

import { buildRoute, ROUTES } from 'helpers/routes';
import { reorderWarnings } from 'helpers/api';
import { MODAL_TYPE } from 'helpers/modals';

import { resetOrder } from 'modules/order';
import { shouldDirectToWebsiteChannel } from 'helpers/configureRedirects';
import { NO_DIRECT_LIVE_CHANNEL } from 'helpers/constants';

function handleReorderWarnings({
  dispatch,
  warnings,
  hqId,
  restaurantId,
  history,
  menuRoute,
  openModal,
}) {
  const isFulfillmentWarning = warnings.find(
    (warning) =>
      warning.code === reorderWarnings.CURBSIDE_UNAVAILABLE ||
      warning.code === reorderWarnings.DELIVERY_UNAVAILABLE
  );
  const isMenuWarning = warnings.find(
    (warning) => warning.code === reorderWarnings.MENU_ITEM_UNAVAILABLE
  );

  // if previous fulfillment not available, show modal notifying that and default back to pickup
  if (isFulfillmentWarning) {
    return openModal(MODAL_TYPE.dialog, {
      title: isFulfillmentWarning.message,
      confirmLabel: 'Reorder with Pickup',
      dismissLabel: 'Start New Order',
      isAlert: true,
      onConfirm: () => {
        history.push(menuRoute);
      },
      onDismiss: () => {
        dispatch({
          type: resetOrder.TYPE,
        });
        history.push(menuRoute);
      },
    });
  }

  // if menu item or modifier is unavailable, show item expired modal
  if (isMenuWarning) {
    return openModal(MODAL_TYPE.itemExpired, {
      title: isMenuWarning.message,
      rejectedItems: isMenuWarning.value,
      isReorder: true,
      hqId,
      restaurantId,
    });
  }

  // default case should show reordering unavailable modal
  openModal(MODAL_TYPE.dialog, {
    title: 'Sorry, reordering is unavailable for this order',
    message:
      "We can't re-create this exact order, but you can place a new order from our current menu.",
    confirmLabel: 'Start New Order',
    isAlert: true,
    onConfirm: () => {
      dispatch({
        type: resetOrder.TYPE,
      });
      history.push(menuRoute);
    },
  });
}

export default function useReorder({
  prevIsValidating,
  isValidating,
  warnings,
  errors,
  hqId,
  restaurant,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { openModal } = useContext(ModalContext);

  const restaurantId = restaurant?.id;
  const query = shouldDirectToWebsiteChannel(restaurant) && {
    name: 'cn_channel',
    value: NO_DIRECT_LIVE_CHANNEL,
  };
  const menuRoute = buildRoute({
    hqId,
    restaurantId,
    route: ROUTES.menu,
    query,
  });

  useEffect(() => {
    // handle reorder warnings (like fulfillment, order time or menu changes)
    if (prevIsValidating && !isValidating && warnings.length) {
      handleReorderWarnings({
        dispatch,
        warnings,
        hqId,
        restaurantId,
        history,
        menuRoute,
        openModal,
      });
    }

    // Send to menu when user attempts to reorder with valid items
    if (
      prevIsValidating &&
      !isValidating &&
      !errors.length &&
      !warnings.length
    ) {
      return history.push(menuRoute);
    }
  }, [isValidating]);
}
