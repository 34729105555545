/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { formatSimplePhone } from 'helpers/format';
import { phoneValidator } from 'helpers/validation';
import { CHOWNOW_URL } from '@chownow/cn-web-utils/paths';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import Button from 'primitives/Button';
import Input from 'primitives/Input';
import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import Outlink from 'primitives/Outlink';

import { ReactComponent as IconPoweredLogo } from 'images/icon-poweredby.svg';

import styles from './styles.module.scss';

export function formatPhone(phone, focused) {
  if (focused) return phone.replace(/[^\d]/g, '');

  return formatSimplePhone(phone);
}

function ConfirmAccountModal({ noCloseOverlay }) {
  const { closeModal } = useContext(ModalContext);
  const { user, handleUpdateUser } = useContext(UserContext);

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phone, setPhone] = useState(user.phone?.number || '');
  const [errors, setErrors] = useState({});
  const [focused, setFocused] = useState(false);

  function handleSubmit() {
    const validationErrors = phoneValidator({ phone: { number: phone } });
    setErrors(validationErrors);

    if (isEmpty(validationErrors)) {
      handleUpdateUser({
        id: user.id,
        first_name: firstName,
        last_name: lastName,
        phone: { number: phone },
      });

      closeModal();
    }
  }

  return (
    <Modal shouldShowCloseIcon={!noCloseOverlay} centerAlign>
      <ModalHeader
        title="Confirm Account Info"
        subtitle="Please confirm the information below is accurate."
      />
      <div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={setFirstName}
            placeholder="Jane"
          />
          <Input
            type="text"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={setLastName}
            placeholder="Doe"
          />
        </div>
        <div className={styles.input}>
          <Input
            type="text"
            label="Email"
            name="email"
            value={user.email}
            disabled
          />
        </div>
        <div className={styles.input}>
          <Input
            label="Phone Number"
            name="phoneNumber"
            maxLength={10}
            value={formatPhone(phone, focused)}
            onChange={setPhone}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            type="tel"
            placeholder="555-555-5555"
            errorText={errors?.phone?.number}
          />
        </div>
        <Button
          className={styles.button}
          onClick={handleSubmit}
          isFullWidth
          disabled={!firstName || !lastName || !phone}
        >
          Submit
        </Button>
      </div>
      <Outlink
        to={CHOWNOW_URL}
        ariaLabel="ChowNow website"
        className={styles.poweredBy}
      >
        <IconPoweredLogo />
      </Outlink>
    </Modal>
  );
}

ConfirmAccountModal.propTypes = {
  noCloseOverlay: PropTypes.bool,
};

ConfirmAccountModal.defaultProps = {
  noCloseOverlay: false,
};

export default ConfirmAccountModal;
