import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Amex from '../Icons/Amex';
import ApplePay from '../Icons/ApplePay';
import Cash from '../Icons/Cash';
import Discover from '../Icons/Discover';
import GooglePay from '../Icons/GooglePay';
import MasterCard from '../Icons/MasterCard';
import Visa from '../Icons/Visa';
import JCB from '../Icons/JCB';
import DinersClub from '../Icons/DinersClub';
import UnionPay from '../Icons/UnionPay';

import styles from './styles.module.scss';

export const creditCardTypeToImage = {
  1: Visa,
  2: MasterCard,
  3: Amex,
  4: Discover,
  5: ApplePay,
  6: GooglePay,
  7: GooglePay,
  8: ApplePay,
  10: JCB,
  11: DinersClub,
  12: UnionPay,
};

function getMobilePayment(cardType) {
  if (cardType === 'GooglePay' || cardType === 'AndroidPay') {
    return 'Google Pay';
  }
  if (cardType === 'ApplePay' || cardType === 'ChowNow Apple Pay') {
    return 'Apple Pay';
  }
  return false;
}

export default function PaymentBox({ customer }) {
  const card = get(customer, 'billing.card');
  const cardType = card && card.type;
  const typeID = card && card.type_id;
  const lastFour = get(customer, 'billing.card.last_four', '');
  const isCashPayment = !lastFour && !card;
  const SVG = creditCardTypeToImage[typeID];

  if (isCashPayment) {
    return (
      <div className={styles.wrapper}>
        <Cash />
        <p className={styles.cardNumber}>Cash Only (payment due at pickup)</p>
      </div>
    );
  }
  if (getMobilePayment(cardType)) {
    return (
      <div className={styles.wrapper}>
        <SVG />
        <p className={styles.cardNumber}>{getMobilePayment(cardType)}</p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <SVG />
      <p data-testid="LastFour" className={styles.cardNumber}>
        Ending {lastFour}
      </p>
    </div>
  );
}

PaymentBox.propTypes = {
  customer: PropTypes.shape({
    billing: PropTypes.shape({
      card: PropTypes.shape({
        last_four: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
};

PaymentBox.defaultProps = {
  customer: undefined,
};
