import React from 'react';
import classNames from 'classnames';

import { creditCardTypeToImage } from 'helpers/payments';
import { CreditCardType, CreditCardIdType } from 'types/api';

import styles from './styles.module.scss';

interface Props {
  card: CreditCardType;
  className: string;
  error: string;
}

export function getCardDescription(
  cardTypeId: CreditCardIdType,
  cardLastFour: string
): string {
  const googlePayIds = ['6', '7'];
  const applePayIds = ['5', '8'];
  if (googlePayIds.includes(cardTypeId)) {
    return 'Google Pay';
  }

  if (applePayIds.includes(cardTypeId)) {
    return 'Apple Pay';
  }

  if (!cardTypeId && !cardLastFour) {
    return 'Cash Only (payment due at pickup)';
  }
  return `Ending in ${cardLastFour}`;
}

function CreditCardDisplay({ card, className, error }: Props) {
  return (
    <div className={classNames(className, styles.cardOption)}>
      {card.type && (
        <img
          src={creditCardTypeToImage[card.type_id]}
          alt={card.type}
          className={styles.icon}
        />
      )}
      <span className={styles.lastFour} data-testid="LastFour">
        {getCardDescription(card.type_id, card.last_four)}
        {!!error && <span className={styles.invalid}> ({error})</span>}
      </span>
    </div>
  );
}

export default CreditCardDisplay;
