import React, { useEffect, useState, useContext } from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';
import classNames from 'classnames';

import GoogleMapsContext from 'context/GoogleMapsContext';
import styles from './styles.module.scss';

interface GeosuggestWrapperProps {
  className: string;
  country: string;
  geosuggestRef: React.RefObject<Geosuggest>;
  hasErrors: boolean;
  initialValue: string;
  onBlur: () => void;
  onChange: () => void;
  onFocus: () => void;
  onSelectAddress: () => void;
  placeholder: string;
  skipSuggest: () => boolean;
}

// Limit Google Place details to just basic data to avoid extra charges
// https://developers.google.com/places/web-service/usage-and-billing#data-skus
const PLACE_BASIC_DATA_FIELDS = [
  'address_component',
  'adr_address',
  'formatted_address',
  'geometry',
  'name',
  'place_id',
  'scope',
  'type',
  'url',
  'utc_offset_minutes',
  'vicinity',
];

function GeosuggestWrapper({
  className,
  country,
  geosuggestRef,
  hasErrors,
  initialValue,
  onBlur,
  onChange,
  onFocus,
  onSelectAddress,
  placeholder,
  skipSuggest,
}: GeosuggestWrapperProps) {
  const [canLoad, setCanLoad] = useState(false);
  const { hasMapLoaded } = useContext(GoogleMapsContext);

  useEffect(() => {
    // In cases where the address modal loads on app launch, we want to wait until Google Maps
    // is available globally.
    setCanLoad(hasMapLoaded);
  }, [hasMapLoaded]);

  if (!canLoad) return null;
  if (!hasMapLoaded) return null;
  return (
    <Geosuggest
      autoComplete="off"
      country={country}
      initialValue={initialValue}
      inputClassName={classNames(styles.geosuggest__input, {
        [styles.error]: hasErrors,
      })}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      className={className}
      onSuggestSelect={onSelectAddress}
      placeDetailFields={PLACE_BASIC_DATA_FIELDS}
      ref={geosuggestRef}
      skipSuggest={skipSuggest}
      suggestItemActiveClassName={styles.geosuggestActive}
      suggestItemClassName={styles.geosuggest__item}
      suggestsClassName={styles.geosuggest__suggests}
      suggestsHiddenClassName={styles.geosuggestHidden}
      types={['address']}
      data-testid="AddressSearch"
    />
  );
}

export default GeosuggestWrapper;
