import React from 'react';

import classNames from 'classnames';

import { KEY_VALUES } from 'helpers/constants';

import useIsMobile from 'hooks/useIsMobile';

import { ReactComponent as IconBadgeInfo } from 'images/icon-badge-info.svg';

import Truncate from './LegacyTruncate';

import styles from './styles.module.scss';

interface DisclaimerProps {
  disclaimer: string;
  handleClick: () => void;
  isDineIn?: boolean;
  isMenu?: boolean;
}

function Disclaimer({
  disclaimer,
  isDineIn = false,
  handleClick,
  isMenu = false,
}: DisclaimerProps) {
  const isMobile = useIsMobile();

  function handleKeyPress(event: { key: string }) {
    if (event.key === KEY_VALUES.enter) {
      handleClick();
    }
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.clearMargin]: isDineIn,
      })}
      onClick={handleClick}
      onKeyDown={(e) => handleKeyPress(e)}
      role="button"
      tabIndex={0}
    >
      <div className={styles.disclaimerContent}>
        <IconBadgeInfo className={styles.iconInfo} />
        <div className={styles.disclaimer} data-testid="Disclaimer">
          {isMenu ? (
            <Truncate
              lines={isMobile ? 2 : 1}
              ellipsis={
                <span>
                  ...
                  <span className={styles.more}>View More</span>
                </span>
              }
            >
              {disclaimer}
            </Truncate>
          ) : (
            disclaimer
          )}
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
