import React from 'react';
import classNames from 'classnames';

import { formatMoney } from '@chownow/cn-web-utils/format';

import Grid from 'primitives/Grid';
import RadioButton from 'primitives/RadioButton';

import { TipOption } from 'containers/Checkout/TipCalculator/types';
import styles from './styles.module.scss';

interface TipOptionsProps {
  customTip: number;
  handleSelectCustomOption: () => void;
  handleSelectOption: (rate: number, amount: number) => void;
  isCustomTipActive: boolean;
  tipOptions: TipOption[];
  getIsOptionSelected: (percentage: number) => boolean;
}

function TipOptions({
  customTip,
  handleSelectCustomOption,
  handleSelectOption,
  isCustomTipActive,
  tipOptions,
  getIsOptionSelected,
}: TipOptionsProps) {
  return (
    <Grid container className={styles.tipOptions}>
      {tipOptions.map((tipOption) => (
        <div className={styles.radioWrapper} key={tipOption.rate}>
          <RadioButton
            name="tip-option"
            dataTestId="TipButton"
            checked={getIsOptionSelected(tipOption.rate)}
            label={`${tipOption.rate}%`}
            sublabel={formatMoney(tipOption.amount)}
            className={styles.radioButton}
            value={`${tipOption.rate}%`}
            onChange={() => {
              handleSelectOption(tipOption.rate, tipOption.amount);
            }}
          />
        </div>
      ))}
      <div className={classNames(styles.radioWrapper, styles.other)}>
        <RadioButton
          name="tip-option"
          dataTestId="OtherTipButton"
          checked={isCustomTipActive}
          label="Other"
          sublabel={
            <span data-testid="OtherTipAmount">{formatMoney(customTip)}</span>
          }
          className={styles.radioButton}
          value={formatMoney(customTip)}
          onChange={handleSelectCustomOption}
        />
      </div>
    </Grid>
  );
}

export default TipOptions;
