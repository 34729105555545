/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';

import { getFromChownowApi } from 'helpers/api';
import { COUNTRIES } from 'helpers/constants';

import { CompanyDataType, LocationType } from 'types/api';

import usePlatform from 'hooks/usePlatform';

interface CompanyProviderProps {
  children: React.ReactNode;
}

export type CompanyContextProps = {
  company: CompanyDataType | undefined;
  handleFetchCompany: (
    id: string,
    params: { location: string }
  ) => Promise<void>;
  locations: LocationType[] | [];
  isCanadian: boolean;
  isCompanyLoading: boolean;
};

const CompanyContext = createContext<CompanyContextProps>({
  company: undefined,
  handleFetchCompany: () => new Promise<void>(() => {}),
  locations: [],
  isCanadian: false,
  isCompanyLoading: false,
});

function CompanyProvider({ children }: CompanyProviderProps) {
  const { isMarketplacePlatform } = usePlatform();

  const [companyState, setCompanyState] = useState<CompanyDataType>();
  const [isCanadian, setIsCanadian] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);

  function getCompanyLocations() {
    if (!companyState) return [];
    if (isMarketplacePlatform) {
      return companyState.locations.filter(
        (location) => location.is_discover_live
      );
    }
    return companyState.locations;
  }

  async function handleFetchCompany(
    id: string,
    params: { location: string }
  ): Promise<void> {
    setIsCompanyLoading(true);

    const { data, error } = await getFromChownowApi({
      endpoint: `company/${id}`,
      params,
    });

    if (!error) {
      setCompanyState(data as CompanyDataType);

      setIsCanadian(data.address.country_alpha2 === COUNTRIES.canada.alpha2);
    }

    setIsCompanyLoading(false);
  }

  return (
    <CompanyContext.Provider
      value={{
        company: companyState,
        handleFetchCompany,
        locations: getCompanyLocations(),
        isCanadian,
        isCompanyLoading,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export { CompanyContext, CompanyProvider };
