import React from 'react';
import PropTypes from 'prop-types';

import { orderItemType } from 'helpers/prop-types';

import OrderItem from '../OrderItem';

function OrderItems(props) {
  const { items, isCheckout, isReadOnly, isExpanded, sourcePage } = props;
  return (
    <ul>
      {items.map((item) => (
        <OrderItem
          isCheckout={isCheckout}
          isReadOnly={isReadOnly}
          isExpanded={isExpanded}
          item={item}
          key={item.tracking_id || item.id}
          sourcePage={sourcePage}
        />
      ))}
    </ul>
  );
}

OrderItems.propTypes = {
  isCheckout: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  items: PropTypes.arrayOf(orderItemType),
  sourcePage: PropTypes.string,
};

OrderItems.defaultProps = {
  isCheckout: false,
  isExpanded: false,
  isReadOnly: false,
  items: undefined,
  sourcePage: undefined,
};

export default OrderItems;
