import React from 'react';

import Modal from 'primitives/Modals/Modal';

import { ReactComponent as QRCode } from 'images/qr-code.svg';

import styles from './styles.module.scss';

function DownloadAppModal() {
  return (
    <Modal>
      <div className={styles.content}>
        <h2 className={styles.title}>Download the ChowNow App</h2>
        <p className={styles.desc}>
          Discover new local restaurants and easily reorder your favorites with
          the ChowNow app.
        </p>
        <div className={styles.qrCard}>
          <QRCode className={styles.qr} />
          <span className={styles.qrCall}>
            Scan the code to download the Chownow app.
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default DownloadAppModal;
