import useFeatureFlagRequest from 'hooks/useFeatureFlagRequest';

import { FEATURE_FLAGS } from 'helpers/constants';

// https://chownow.atlassian.net/browse/CN-32670

function useShowRestaurantTipping() {
  const { showFeature: canShowRestaurantTipping } = useFeatureFlagRequest({
    doLoad: true,
    flag: FEATURE_FLAGS.showRestaurantTipping,
  });

  return canShowRestaurantTipping;
}

export default useShowRestaurantTipping;
