import React from 'react';

import { handleAppLinkClick } from 'helpers/loggers';

import useIsMobile from 'hooks/useIsMobile';

import Button from 'primitives/Button';

import QRCode from 'images/QR-impact-modal.png';

import styles from './styles.module.scss';

function DownloadApp() {
  const isMobile = useIsMobile();

  function handleDirectToApp() {
    handleAppLinkClick({
      isNativeApp: false,
      source: 'confirmation_impact_modal',
    });
    window.open('https://chownow.app.link/Download-CN-App-Impact');
  }

  return (
    <div className={styles.downloadWrapper}>
      {isMobile && (
        <>
          <div className={styles.headline}>
            Track your impact with the ChowNow app.
          </div>
          <Button
            className={styles.button}
            isFullWidth
            isInverse
            onClick={handleDirectToApp}
          >
            Get the App
          </Button>
        </>
      )}
      {!isMobile && (
        <div className={styles.qrWrapper}>
          <img src={QRCode} alt="ChowNow QR Code" className={styles.qrCode} />
          <div>
            <div className={styles.qrHead}>Track your impact in the app.</div>
            <p className={styles.qrCopy}>
              Scan the code to download the ChowNow app.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default DownloadApp;
