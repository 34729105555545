import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFetchMenu from 'hooks/useFetchMenu';

import { ModalContext } from 'context/ModalContext';

import {
  isOnPage,
  getHqIdFromUrl,
  getRestaurantIdFromUrl,
} from '@chownow/cn-web-utils/url';
import { buildRoute, ROUTES } from 'helpers/routes';

import { getFulfillmentTime, resetOrder, getOrderData } from 'modules/order';

import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

import styles from './styles.module.scss';

function ItemExpiredModal(props) {
  const { isReorder, rejectedItems, hqId, restaurantId, title } = props;

  const { closeModal } = useContext(ModalContext);

  const dispatch = useDispatch();
  const history = useHistory();

  const [shouldRefetchMenu, setShouldRefetchMenu] = useState(false);

  const order = useSelector(getOrderData);
  const when = useSelector(getFulfillmentTime);

  useFetchMenu({
    restaurantId: getRestaurantIdFromUrl(),
    when,
    shouldRefetch: shouldRefetchMenu,
  });

  const isOrderEmpty = !order.items?.length;

  const isCheckout = isOnPage('checkout');

  const menuRoute = buildRoute({
    hqId: hqId || getHqIdFromUrl(),
    restaurantId: restaurantId || getRestaurantIdFromUrl(),
    route: ROUTES.menu,
  });

  async function handleBackToMenu() {
    // only fetch menu when on menu page and not on checkout as navigating
    // from checkout to menu will run fetchMenu and we want to prevent
    // double fetching
    if (!isCheckout && !isReorder) {
      await setShouldRefetchMenu(true);
    }

    closeModal();

    history.push(menuRoute);
  }

  function handleNewOrder() {
    dispatch({ type: resetOrder.TYPE });
    history.push(menuRoute);
  }

  // eslint-disable-next-line consistent-return
  function getSecondaryButton() {
    if (isCheckout && !isOrderEmpty) {
      return (
        <ModalFooterButton handleClick={closeModal} isSecondary>
          Continue with Checkout
        </ModalFooterButton>
      );
    }

    if (isReorder) {
      return (
        <ModalFooterButton handleClick={handleNewOrder} isSecondary>
          View Menu
        </ModalFooterButton>
      );
    }
  }

  return (
    <Modal>
      <ModalHeader title={isReorder ? title : 'This menu has changed'} />
      <div>
        <p className={styles.message}>
          We&apos;re sorry, some items in your order
          {isReorder && ' might have changed or'} are no longer available right
          now.
        </p>
        {!!rejectedItems?.length && (
          <>
            {rejectedItems.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={styles.itemWrapper} key={index}>
                <span className={styles.quantity}>{item.quantity}</span>
                <div>
                  <div className={styles.itemName}>{item.name}</div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <ModalFooter>
        <ModalFooterButton handleClick={handleBackToMenu}>
          {isReorder ? 'Continue with Reorder' : 'View Menu'}
        </ModalFooterButton>
        {getSecondaryButton()}
      </ModalFooter>
    </Modal>
  );
}

ItemExpiredModal.propTypes = {
  hqId: PropTypes.string,
  isReorder: PropTypes.bool,
  rejectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      modifierNames: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      quantity: PropTypes.number,
    })
  ).isRequired,
  restaurantId: PropTypes.string,
  title: PropTypes.string,
};

ItemExpiredModal.defaultProps = {
  hqId: undefined,
  isReorder: false,
  restaurantId: undefined,
  title: undefined,
};

export default ItemExpiredModal;
