import { useState } from 'react';
import PropTypes from 'prop-types';

import { geocode } from 'helpers/geocode';

function GetCurrentPosition(props) {
  const { children, onError, onSelectAddress } = props;

  const [isLoading, setIsLoading] = useState(false);

  function getCoordinates() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  function handleError() {
    setIsLoading(false);

    if (onError) onError();
  }

  async function calculateCurrentPosition() {
    setIsLoading(true);

    if (navigator && navigator.geolocation) {
      try {
        const position = await getCoordinates();
        const { latitude, longitude } = position.coords || {};

        const geocodeResult = await geocode({
          location: {
            lat: parseFloat(latitude, 10),
            lng: parseFloat(longitude, 10),
          },
        });

        setIsLoading(false);
        onSelectAddress(geocodeResult[0]);
      } catch (error) {
        handleError(error);
      }
    } else {
      handleError();
    }
  }

  return children(calculateCurrentPosition, isLoading);
}

GetCurrentPosition.propTypes = {
  children: PropTypes.func,
  onError: PropTypes.func,
  onSelectAddress: PropTypes.func.isRequired,
};

export default GetCurrentPosition;
