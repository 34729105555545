import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ModalContext } from 'context/ModalContext';

import usePlatform from 'hooks/usePlatform';

import { MODAL_TYPE } from 'helpers/modals';
import { FULFILLMENT_METHODS } from 'helpers/order';
import { logAnalyticsEvent } from 'helpers/loggers';
import { ANALYTICS_EVENT_NAME } from 'helpers/constants';

import {
  getOrderData,
  getTips,
  setManagedDeliveryTipRequest,
  setRestaurantTipRequest,
} from 'modules/order';

import TipOptions from 'components/TipOptions';

import {
  getIsCustomTipActive,
  getFormattedTipOptions,
  calculateTip,
} from './helpers.ts';

import styles from './styles.module.scss';

export const TIP_TYPE = {
  managedDelivery: 'managedDelivery',
  restaurant: 'restaurant',
};

function TipCalculator({ percentages = [], restaurantName, tipType }) {
  const isManagedDelivery = tipType === TIP_TYPE.managedDelivery;
  const tipHeader = isManagedDelivery ? 'Driver Tip' : 'Add a Tip';

  const order = useSelector(getOrderData);
  const tips = useSelector(getTips);
  const { item_total: subTotal, fulfillmentMethod } = order;
  const managedDeliveryTip = tips?.managed_delivery_tip;
  const restaurantTip = tips?.restaurant_tip;
  const currentTip = isManagedDelivery
    ? managedDeliveryTip?.managed_delivery_tip_amount
    : restaurantTip?.restaurant_tip_amount;

  const dispatch = useDispatch();
  const { openModal, closeModal } = useContext(ModalContext);

  const { isMarketplacePlatform } = usePlatform();
  const isDelivery = fulfillmentMethod === FULFILLMENT_METHODS.delivery;

  const [customTip, setCustomTip] = useState(0);

  const tipOptions = getFormattedTipOptions(subTotal, percentages);

  useEffect(() => {
    setCustomTip(getIsCustomTipActive(tipOptions, currentTip) ? currentTip : 0);
  }, [order]);

  function setTip(tipAmount, percentage) {
    const action = isManagedDelivery
      ? setManagedDeliveryTipRequest.TYPE
      : setRestaurantTipRequest.TYPE;

    dispatch({
      type: action,
      payload: tipAmount,
    });

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectTip,
      attributes: {
        source: isManagedDelivery ? 'delivery' : 'restaurant',
        amount: tipAmount,
        percentage,
      },
    });
  }

  function getIsOptionSelected(percentage) {
    // The logic needed to show which tip option is selected
    return calculateTip(subTotal, percentage) === currentTip;
  }

  function handleSelectTip(percentage) {
    const amount = calculateTip(subTotal, percentage);

    setCustomTip(0);

    if (amount === currentTip) {
      setTip(0);
    } else {
      const tipAmount = calculateTip(subTotal, percentage);

      setTip(tipAmount, percentage);
    }
  }

  function handleSubmitCustomTip(newCustomTip) {
    const numCustomTip = Number(newCustomTip);

    closeModal();
    setCustomTip(numCustomTip || 0);
    setTip(numCustomTip, 'Other');
  }

  function handleOtherTip() {
    openModal(MODAL_TYPE.tip, {
      onSubmitTip: handleSubmitCustomTip,
      customTip,
    });
  }

  function getTipMessage() {
    if (isManagedDelivery) {
      return '100% of your tip goes to the delivery driver.';
    }

    if (!isDelivery) {
      return `100% of your tip supports ${
        isMarketplacePlatform ? 'the' : 'our'
      } restaurant and its staff who prepare and pack your order.`;
    }

    return `Your order is being delivered by ${restaurantName} — 100% of the tip supports their drivers.`;
  }

  const tipMessage = getTipMessage();

  return (
    <div className={styles.tipWrapper}>
      <h3 className={styles.tipHeader} data-testid="TipHeader">
        {tipHeader}
      </h3>
      <div className={styles.tipDetailsWrapper}>
        {tipMessage && (
          <p data-testid="TipMessage" className={styles.subheading}>
            {tipMessage}
          </p>
        )}
        <TipOptions
          customTip={customTip}
          handleSelectCustomOption={handleOtherTip}
          handleSelectOption={handleSelectTip}
          isCustomTipActive={getIsCustomTipActive(tipOptions, currentTip)}
          tipOptions={tipOptions}
          getIsOptionSelected={getIsOptionSelected}
        />
      </div>
    </div>
  );
}

TipCalculator.propTypes = {
  percentages: PropTypes.arrayOf(PropTypes.number),
  restaurantName: PropTypes.string,
  tipType: PropTypes.oneOf(Object.values(TIP_TYPE)),
};

TipCalculator.defaultProps = {
  percentages: [],
  restaurantName: undefined,
  tipType: TIP_TYPE.restaurant,
};

export default TipCalculator;
