/* eslint-disable complexity */
import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';

import { ORDER_STATUSES, getIsOrderFulfilled } from 'helpers/order';
import { fetchOrderDetailsXHR, getSelectedOrder } from 'modules/order';

let pollStatusInterval;
let pollCount = 0;
const STATUS_INTERVAL = 60000;
const MAX_POLL_COUNT = 100;

export default function usePolling() {
  const location = useLocation();
  const { orderId: orderIDFromURL } = useParams();
  const dispatch = useDispatch();
  const order = useSelector(getSelectedOrder);
  const { status: orderStatus, id: orderId } = order;
  const isCancelled = orderStatus === ORDER_STATUSES.canceled;
  const isOrderInProgress = !getIsOrderFulfilled(order) && !isCancelled;
  // https://chownow.atlassian.net/browse/CN-20979
  // Ensure we don't start polling until the order in the url matches that of the Redux store.
  const canPoll = orderIDFromURL === order.id;

  function stopPolling() {
    clearInterval(pollStatusInterval);
    pollStatusInterval = null;
  }

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  function checkStatus() {
    const queryParams = parse(location.search);
    const guid = queryParams?.guid;

    dispatch({
      type: fetchOrderDetailsXHR.request.TYPE,
      payload: { orderId, guid },
    });
    // eslint-disable-next-line no-plusplus
    pollCount++;
  }

  // We poll for in progress order updates every 1 minute for 100 polls / minutes
  // If a user hits 100 polls, we clear the interval
  useEffect(() => {
    const isAwaitingUpdate =
      isOrderInProgress &&
      (orderStatus === ORDER_STATUSES.submitted ||
        orderStatus === ORDER_STATUSES.accepted);

    if (!pollStatusInterval && isAwaitingUpdate && canPoll) {
      pollStatusInterval = setInterval(checkStatus, STATUS_INTERVAL);
    }

    if (
      (pollStatusInterval && !isAwaitingUpdate) ||
      pollCount > MAX_POLL_COUNT
    ) {
      stopPolling();
    }
  }, [orderStatus, pollCount, order.id]);
}
