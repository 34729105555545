import React, { useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// This is a hook that is based off of two old deps
// https://github.com/FezVrasta/react-resize-aware
// https://github.com/FezVrasta/react-resize-aware/tree/v2.7.2

const useOnResize = (ref, onResize) => {
  const getTarget = () =>
    ref.current &&
    ref.current.contentDocument &&
    ref.current.contentDocument.defaultView;
  function run() {
    const target = getTarget();
    // eslint-disable-next-line no-unused-expressions
    target && target.addEventListener('resize', onResize);
  }
  useEffect(() => {
    run();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
};

const style = {
  display: 'block',
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  pointerEvents: 'none',
  zIndex: -1,
};

// Insert iFrame into the DOM at full width to track resizes
function ResizeListener({ onResize }) {
  const ref = useRef();
  useOnResize(ref, () => onResize(ref));

  return (
    <iframe
      style={style}
      src="about:blank"
      ref={ref}
      tabIndex={-1}
      title="resizer"
    />
  );
}

ResizeListener.propTypes = {
  onResize: PropTypes.func.isRequired,
};

const defaultReporter = (target) => ({
  width: target != null ? target.offsetWidth : null,
  height: target != null ? target.offsetHeight : null,
});

export default function useResizeAware(reporter = defaultReporter) {
  const [sizes, setSizes] = useState(reporter(null));
  const onResize = (ref) => setSizes(reporter(ref.current));

  const resizeListenerNode = useMemo(
    () => <ResizeListener onResize={onResize} />,
    [onResize]
  );

  return [resizeListenerNode, sizes];
}
