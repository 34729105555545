import React from 'react';

import { PRIVACY_URL, TERMS_URL } from '@chownow/cn-web-utils/paths';

import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

function Terms() {
  return (
    <div className={styles.terms}>
      By signing up you agree to our{' '}
      <Outlink to={TERMS_URL} ariaLabel="ChowNow Terms" className={styles.link}>
        Terms
      </Outlink>{' '}
      and{' '}
      <Outlink
        to={PRIVACY_URL}
        ariaLabel="ChowNow Privacy Policy"
        className={styles.link}
      >
        Privacy Policy
      </Outlink>
    </div>
  );
}

export default Terms;
