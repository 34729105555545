/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import usePlatform from 'hooks/usePlatform';
import useFetchManifest from 'hooks/useFetchManifest';
import useClickOutside from 'hooks/useClickOutside';

import breakpoints from 'helpers/breakpoints';
import { formatPhone, removePhoneCountryCode } from 'helpers/format';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { BASE_SUPPORT_URL } from '@chownow/cn-web-utils/paths';

import styles from './styles.module.scss';

import PhoneIcon from './Icons/phone';
import ChatIcon from './Icons/chat';

export default function Help({ page, phone, closeDialog }) {
  const [isMobile, setIsMobile] = useState(true);

  const { isMarketplacePlatform } = usePlatform();

  const { customer_service_phone: cusomerServicePhone } = useFetchManifest();
  const csPhone = removePhoneCountryCode(cusomerServicePhone);

  function clickOutsideTest({ ref, e }) {
    return (
      ref.current &&
      !ref.current.contains(e.target) &&
      e.target.id !== 'helpToggle'
    );
  }
  const outsideClickRef = useClickOutside({
    handler: closeDialog,
    test: clickOutsideTest,
  });

  useEffect(() => {
    if (window.innerWidth >= breakpoints.smMin) {
      setIsMobile(false);
    }
  }, []);

  function onSupportClick(contactMethod, sourcePage) {
    if (isMarketplacePlatform) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectCustomerSupportContact,
        attributes: {
          contact_method: contactMethod,
          source: sourcePage,
        },
      });
      // flushes the event queue and forces the event to send to mParticle
      // only needed when linking out to prevent CORS error
      window.mParticle.upload();
    }

    return true;
  }

  return (
    <div className={styles.wrapper} ref={outsideClickRef}>
      {phone && (
        <a
          className={styles.contentWrapper}
          href={`tel:${phone}`}
          onClick={() => onSupportClick('call_restaurant', page)}
          data-testid="CallRestaurant"
        >
          <div className={styles.innerFlexWrapper}>
            <div className={styles.icon}>
              <PhoneIcon />
            </div>
            <div>
              <p className={styles.call}>
                Call restaurant for help with your order
              </p>
              <p className={styles.supportText}>{formatPhone(phone)}</p>
            </div>
          </div>
        </a>
      )}
      <a
        className={styles.contentWrapper}
        target={!isMobile ? '_blank' : ''}
        rel="noreferrer"
        href={isMobile ? `sms:+${csPhone}` : `${BASE_SUPPORT_URL}/customer/s/`}
        onClick={
          isMobile
            ? () => onSupportClick('text_support', page)
            : () => onSupportClick('chat_support', page)
        }
        data-testid="ChatOrSmsSupport"
      >
        <div className={styles.innerFlexWrapper}>
          <div className={styles.icon}>
            <ChatIcon />
          </div>
          <div>
            <p className={styles.text}>
              {isMobile
                ? 'Text/SMS support for help with ChowNow'
                : 'Chat with support for help with ChowNow'}
            </p>
            <p className={styles.supportText}>
              {isMobile ? formatPhone(csPhone) : 'Chat with us now'}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}

Help.propTypes = {
  closeDialog: PropTypes.func,
  page: PropTypes.string.isRequired,
  phone: PropTypes.string,
};

Help.defaultProps = {
  closeDialog: undefined,
  phone: undefined,
};
