import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { find } from 'lodash';

import { VEHICLE_COLORS, VEHICLE_TYPES } from 'helpers/constants';
import { vehicleType } from 'helpers/prop-types';

import { ReactComponent as IconCar } from 'images/icon-car.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';

import styles from './styles.module.scss';

function VehicleDisplay(props) {
  const { vehicle, onVehicleChange } = props;

  const { label: vehicleLabelType } = find(VEHICLE_TYPES, {
    value: vehicle.vehicle_type,
  });
  const vehicleColor = find(VEHICLE_COLORS, { value: vehicle.color });

  return (
    <button
      type="button"
      onClick={onVehicleChange}
      className={styles.curbsideWrapper}
    >
      <div className={styles.vehicleDetails}>
        <IconCar className={styles.icon} />
        <span
          className={classNames(styles.circle, {
            [styles.white]: vehicleColor.value === 'WHITE',
            [styles.other]: vehicleColor.value === 'OTHER',
          })}
          style={{
            backgroundColor: vehicleColor.color,
          }}
        />
        {vehicleColor.label} {vehicleLabelType}
      </div>
      <IconEdit data-testid="EditIcon" className={styles.iconEdit} />
    </button>
  );
}

VehicleDisplay.propTypes = {
  onVehicleChange: PropTypes.func.isRequired,
  vehicle: vehicleType.isRequired,
};

export default VehicleDisplay;
