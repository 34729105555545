import {
  ITEM_EXPIRED,
  MENU_EXPIRED,
  JSON_REQUIRED_FIELD_MISSING,
  ORDER_LEAD_TIME_EXPIRED,
} from 'helpers/api';

export const MODAL_TYPE = {
  account: 'ACCOUNT',
  auth: 'AUTH_SHARED',
  address: 'ADDRESS',
  bag: 'BAG',
  category: 'CATEGORY',
  confirmAccount: 'CONFIRM_ACCOUNT',
  dialog: 'DIALOG',
  downloadApp: 'DOWNLOAD_APP',
  fulfillmentDetails: 'FULFILLMENT_DETAILS',
  help: 'HELP',
  hours: 'HOURS',
  impactScore: 'IMPACT_SCORE',
  itemExpired: 'ITEM_EXPIRED',
  menuItem: 'MENU_ITEM',
  orderingUnavailable: 'ORDERING_UNAVAILABLE',
  payment: 'PAYMENT',
  polling: 'POLLING',
  postPurchaseImpact: 'POST_PURCHASE_IMPACT',
  promo: 'PROMO',
  table: 'TABLE',
  timeUnavailable: 'TIME_UNAVAILABLE',
  tipAdjustment: 'TIP_ADJUSTMENT',
  tip: 'TIP',
  vehicle: 'VEHICLE',
};

export const AUTH_MODAL_TYPE = {
  loginSignUp: 'LOGIN_SIGN_UP',
  loginForm: 'LOGIN_FORM',
  signUpForm: 'SIGN_UP_FORM',
  resetPassword: 'RESET_PASSWORD',
  forgotPassword: 'FORGOT',
  verification: 'VERIFICATION',
};

export const ACCOUNT_MODAL_TYPE = {
  addAddress: 'Add New Address',
  name: 'Name',
  phone: 'Phone Number',
  updateAddress: 'Edit Your Address',
  addPaymentMethod: 'Add New Card',
  deletePaymentMethod: 'Credit Card',
};

export const CC_ERROR_MODAL_COPY = {
  title: "Let's Take a Second Look.",
  message:
    'Unable to process your payment. Please verify your card details and try again.',
};

/**
 * Returns a boolean ID representing the presence of an error included
 * in a hardcoded list of error types
 * @param {array} errors - a list of errors returned from the API.
 */
export function hasMatchingError(errors = []) {
  const errorCodes = [
    ITEM_EXPIRED,
    MENU_EXPIRED,
    JSON_REQUIRED_FIELD_MISSING,
    ORDER_LEAD_TIME_EXPIRED,
  ];
  return errorCodes.find((code) => errors.some((error) => error.code === code));
}

function getIsOrderHistory(path) {
  // if path includes history and also has query param, source page is order history
  return path.match('history') || (path.match('history') && path.includes('?'));
}

function getPage(path) {
  if (path.match('checkout')) {
    return 'checkout';
  }

  if (path.match('account')) {
    return 'account_settings';
  }

  if (getIsOrderHistory(path)) {
    return 'order_history';
  }

  // if path includes history ending with forward slash, source page is confirmation
  if (path.match('history/d$')) {
    return 'order_confirmation';
  }

  if (path.match('dine-in')) {
    return 'dine_in_menu';
  }

  return undefined;
}

export function getSourcePage({ path, isMobile }) {
  const page = getPage(path);

  if (page) {
    return isMobile ? `mobile_${page}` : `desktop_${page}`;
  }

  return isMobile ? 'mobile_order_summary' : 'desktop_menu';
}
