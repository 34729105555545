import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import * as LDClient from 'launchdarkly-js-client-sdk';

import {
  logException,
  logAnalyticsEvent,
  ANALYTICS_EVENT_NAME,
} from 'helpers/loggers';
import { getUserExperimentId } from 'helpers/customer';
import { PLATFORM, PLATFORM_DISPLAY } from 'helpers/constants';

import PlatformContext from 'context/PlatformContext';

/**
 * Custom hook used make a call to LaunchDarkly to check for a given feature flag.
 * Ensures a component can render and subscribe to feature flags once they are returned from LaunchDarkly
 * Currently only supports boolean feature flags
 */

/**
 * @param {string} flag  - the name of the flag referenced within LaunchDarkly
 * @param {boolean} doLoad  - can be used to prevent loading on page re-renders.
 * Callers may want to pass custom data/attributes that are dependent on an async call to the API,
 * and therefore they may want to delay passing custom attributes to LaunchDarkly until those calls
 * are resolved.
 * @param {object} customAttributes  - a lookup of custom attributes to be passed to LaunchDarkly
 */

export default function useFeatureFlagRequest({
  flag,
  doLoad,
  customAttributes = {},
}) {
  const location = useLocation();

  const { platform } = useContext(PlatformContext);
  const isMarketplacePlatform = platform === PLATFORM.marketplace;

  const [hasLoaded, setHasLoaded] = useState(false);
  const [showFeature, setShowFeature] = useState(false);
  const [hasError, setHasError] = useState(false);
  const userExperimentId = getUserExperimentId();
  const context = {
    kind: 'user',
    key: userExperimentId,
    url: location.pathname,
    platform: isMarketplacePlatform
      ? PLATFORM_DISPLAY.marketplace
      : PLATFORM_DISPLAY.direct,
    ...customAttributes,
  };
  const clientId = isMarketplacePlatform
    ? process.env.REACT_APP_MARKETPLACE_LAUNCH_DARKLY_CLIENT_ID
    : process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;

  useEffect(() => {
    if (flag && !hasLoaded && doLoad) {
      try {
        // From https://docs.launchdarkly.com/sdk/client-side/javascript
        const ldclient = LDClient.initialize(clientId, context);
        ldclient.on('ready', () => {
          const shouldShowFeature = ldclient.variation(flag, false);
          setShowFeature(shouldShowFeature);
          setHasLoaded(true);
          logAnalyticsEvent({
            eventName: ANALYTICS_EVENT_NAME.assessFeatureFlag,
            attributes: {
              flag_name: flag,
              variant: shouldShowFeature,
              ...customAttributes,
            },
          });
        });
      } catch {
        logException({ message: 'Failed to initialize Launch Darkly service' });
        setShowFeature(false);
        setHasError(true);
      }
    }
  }, [doLoad]);
  return {
    hasLoaded,
    hasError,
    showFeature,
  };
}
