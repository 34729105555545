import React from 'react';
import { useSelector } from 'react-redux';

import { getCustomerInitials } from 'containers/Confirmation/helpers';

import { getSelectedOrder } from 'modules/order';

import Modal from 'primitives/Modals/Modal';

import Hearts from 'components/Hearts';

import DownloadApp from './DownloadApp';
import Savings from './Savings';

import heartsAnimation from './impact-heart.gif';

import styles from './styles.module.scss';

function ImpactScoreModal({
  impactModalOpened,
  isNewUser,
}: {
  impactModalOpened: boolean;
  isNewUser: boolean;
}) {
  const order = useSelector(getSelectedOrder);

  const firstName = order.customer.first_name;

  return (
    <Modal isDark>
      <div>
        <Hearts
          previouslyPlayed={impactModalOpened}
          imgSrc={heartsAnimation}
          customerInitials={getCustomerInitials(order.customer)}
        />
        <div className={styles.greeting}>
          Nicely done, {firstName}! You made an impact.
        </div>
        <Savings order={order} isNewUser={isNewUser} />
        <DownloadApp />
      </div>
    </Modal>
  );
}

export default ImpactScoreModal;
