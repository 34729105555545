import React, { useContext } from 'react';

import { isOnMenu } from '@chownow/cn-web-utils/url';

import { ModalContext } from 'context/ModalContext';

import Alert from 'primitives/Modals/Alert';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalHeader from 'primitives/Modals/ModalHeader';

interface DialogModalProps {
  confirmLabel?: string;
  dismissLabel?: string;
  isCentered?: boolean;
  isError?: boolean;
  message?: string;
  noCloseOverlay?: boolean;
  onConfirm?: () => void;
  onDismiss?: () => void;
  title?: string;
}

function DialogModal({
  confirmLabel = 'Go Back',
  dismissLabel,
  isCentered = true,
  isError = false,
  message = '',
  noCloseOverlay = false,
  onConfirm,
  onDismiss,
  title = 'Something Went Wrong',
}: DialogModalProps) {
  const { closeModal } = useContext(ModalContext);

  function handleConfirm() {
    closeModal();
    if (onConfirm) onConfirm();

    if (isError && isOnMenu) {
      window.location.reload();
    }
  }

  function handleDismiss() {
    closeModal();
    if (onDismiss) onDismiss();
  }

  return (
    <Alert shouldShowCloseIcon={!noCloseOverlay} centerAlign={isCentered}>
      <ModalHeader title={title} />
      <p>{message}</p>
      <ModalFooter>
        <ModalFooterButton handleClick={handleConfirm}>
          {confirmLabel}
        </ModalFooterButton>
        {dismissLabel && (
          <ModalFooterButton isSecondary handleClick={handleDismiss}>
            {dismissLabel}
          </ModalFooterButton>
        )}
      </ModalFooter>
    </Alert>
  );
}

export default DialogModal;
