/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { padStart, replace, noop, omit } from 'lodash';

import styles from './styles.module.scss';

function CurrencyInput(props) {
  const { max, onInputChange, precision, separator, value, className } = props;

  const [rawValue, setRawValue] = useState(value);

  const inputProps = omit(props, [
    'precision',
    'separator',
    'max',
    'onInputChange',
  ]);

  useEffect(() => {
    if (value !== rawValue && !Number.isNaN(Number(value))) {
      setRawValue(value);
    }
  }, [value]);

  function getRawValue(displayedValue) {
    let result = displayedValue;

    result = replace(result, separator, '');

    return parseInt(result, 10);
  }

  function getFormattedRawValue(newRawValue) {
    if (!newRawValue) return '';
    const minChars = precision + 1;

    let result = replace(newRawValue.toString(), separator, '');

    // pad 0's while our value is not long enough (precision + 1 chars)
    if (result.length < minChars) {
      result = padStart(result, minChars, '0');
    }

    // create a string consisting of values before and after the decimal
    const beforeSeparator = result.slice(0, result.length - precision);
    const afterSeparator = result.slice(result.length - precision);

    // concatenate the string to result
    result = beforeSeparator + separator + afterSeparator;

    return result;
  }

  function formatCurrency(newValue) {
    return parseFloat(getFormattedRawValue(newValue)).toFixed(2);
  }

  function handleInputChange(e) {
    e.persist();
    let newRawValue = getRawValue(e.target.value);
    const display = formatCurrency(newRawValue);

    if ((max && display > max) || newRawValue < 0) return;

    if (!newRawValue) {
      newRawValue = 0;
    }

    onInputChange(newRawValue, display);

    setRawValue(newRawValue);
  }

  return (
    <input
      {...inputProps}
      data-testid="CurrencyInput"
      onChange={handleInputChange}
      value={getFormattedRawValue(rawValue)}
      className={classNames(styles.currencyInput, className)}
    />
  );
}

CurrencyInput.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  onInputChange: PropTypes.func,
  precision: PropTypes.number,
  separator: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CurrencyInput.defaultProps = {
  className: undefined,
  max: undefined,
  value: 0,
  precision: 2,
  separator: '.',
  onInputChange: noop,
};

export default CurrencyInput;
