import { MENU_CATEGORY_IDS } from 'helpers/constants';

export function formatQueries(queries: {
  [key: string]: string | null;
}): string {
  const mapped = Object.keys(queries)
    .map((key) => (queries[key] ? `${key}=${queries[key]}` : null))
    .filter(Boolean);
  return mapped.length ? mapped.join('&') : '';
}

export function isItemInPopularCategory(
  itemId: string,
  categories: { id: string; items: { id: string }[] }[]
): boolean {
  const popularItemCategory = categories.find(
    (category) => category.id === MENU_CATEGORY_IDS.popularItems
  );
  if (popularItemCategory) {
    return popularItemCategory.items.some((item) => item.id === itemId);
  }

  return false;
}

interface ItemType {
  id: string;
  name: string;
  description?: string;
  price: number;
  size: string;
  modifier_categories: string[];
  categoryName: string;
  categoryId: string;
  meta_item_details?: {
    serving_size_group: {
      default_id: string;
      child_items: { id: string; modifier_categories: string[] }[];
      modifier_categories: string[];
    };
  };
}

interface FilteredItemType extends ItemType {
  categoryName: string;
  categoryId: string;
}

export function getItemsFromMenuData(data: {
  menu_categories: {
    name: string;
    id: string;
    items: ItemType[];
  }[];
}): FilteredItemType[] {
  const items = data.menu_categories.map((category) => {
    const filteredItems: FilteredItemType[] = [];
    category.items.forEach((item) => {
      const servingSizeGroup = item.meta_item_details?.serving_size_group;
      if (!servingSizeGroup) {
        filteredItems.push({
          ...item,
          categoryName: category.name,
          categoryId: category.id,
        });
      } else {
        const defaultSizeId = servingSizeGroup.default_id;
        const defaultItem =
          servingSizeGroup.child_items.find(
            (catItem) => catItem.id === defaultSizeId
          ) || servingSizeGroup.child_items[0];
        filteredItems.push({
          ...item,
          modifier_categories: defaultItem.modifier_categories,
          categoryName: category.name,
          categoryId: category.id,
        });
      }
    });
    return filteredItems;
  });
  return items.flat();
}
