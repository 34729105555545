import { get, forOwn } from 'lodash';
import * as Sentry from '@sentry/browser';

import { ANALYTICS_EVENT_NAME as ORIG_ANALYTICS_EVENT_NAME } from 'helpers/constants';
import { getFullAttributes } from 'helpers/analytics';

export const ANALYTICS_EVENT_NAME = ORIG_ANALYTICS_EVENT_NAME;

export const ANALYTICS_EVENT_TYPES = {
  Navigation: 1,
  Location: 2,
  Search: 3,
  Transaction: 4,
  UserContent: 5,
  UserPreference: 6,
  Social: 7,
  Other: 8,
};

export function logAnalyticsEvent({
  eventName,
  eventType = ANALYTICS_EVENT_TYPES.Other,
  attributes = {},
}) {
  const fullAttributes = getFullAttributes({ attributes, eventName });
  const logEvent = get(window, 'mParticle.logEvent');
  if (logEvent) logEvent(eventName, eventType, fullAttributes);
}

export function handleAppLinkClick({ source, isNativeApp }) {
  const attributes = {
    source,
    mobile_app_type: isNativeApp ? 'direct_app' : 'marketplace_app',
  };

  const upload = get(window, 'mParticle.upload');

  return (
    upload &&
    upload(
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectDownloadApp,
        attributes,
      })
    )
  );
}

// Sentry Message Logging
export function logMessage(message, context, level) {
  Sentry.captureMessage(message, {
    extra: context,
    level, // one of 'info', 'warning', or 'error'
  });

  // eslint-disable-next-line no-console
  if (window.console && console.info) console.info(message, context, level);
}

export function createAnalyticsProduct(
  name,
  id,
  price,
  quantity,
  categoryName,
  currency
) {
  const createProduct = get(window, 'mParticle.eCommerce.createProduct');
  return createProduct
    ? createProduct(
        name,
        id,
        price,
        quantity,
        null,
        categoryName,
        null,
        null,
        null,
        { currency }
      )
    : null;
}

export function logAnalyticsCommerceEvent(
  eventName,
  products = [], // eslint-disable-line default-param-last
  attributes = {}, // eslint-disable-line default-param-last
  customFlags,
  transactionAttributes
) {
  const fullAttributes = getFullAttributes({ attributes, eventName });
  const logProductAction = get(window, 'mParticle.eCommerce.logProductAction');

  if (logProductAction) {
    logProductAction(
      window.mParticle.ProductActionType[eventName],
      products,
      fullAttributes,
      customFlags,
      transactionAttributes
    );
  }
}

// Sentry Exception Logging
export function logException({ message, context, tags, name }) {
  const err = new Error(message);

  if (name) {
    err.name = name;
  }

  Sentry.withScope((scope) => {
    forOwn(context, (value, key) => {
      scope.setExtra(key, value);
    });
    forOwn(tags, (value, key) => {
      scope.setTag(key, value);
    });

    Sentry.captureException(err);
  });

  // eslint-disable-next-line no-console
  if (window.console && console.error) console.error(message);
}

export function initSentry(version) {
  const sentryOptions = {
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Ignore failed localStorage capability checks which will throw errors if user has strict browser
      // privacy settings or is in private browsing mode
      "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
      // This is also almost always due to storage capability checks being denied due to strict browser
      // privacy settings or private browsing mode
      'The operation is insecure.',
      // Generic / inspecific errors usually caught by window handler w/o any valuable context
      /object Object/i,
      "Cannot read property '_avast_submit' of undefined",
      // Chrome Bug: https://bugs.chromium.org/p/chromium/issues/detail?id=590375
      "undefined is not an object (evaluating '__gCrWeb.autofill.extractForms')",
      /from accessing a frame with origin "https:\/\/staticxx\.facebook\.com"/i,
      /from accessing a frame with origin "https:\/\/accounts\.google\.com"/i,
      "Can't execute code from a freed script",
      "Can't find variable: auto",
      "Can't find variable: chrome",
      /Can't find variable: SymBrowser_/i,
      /*
      Windows IE 11 desktop heap memory limits:
      https://support.microsoft.com/en-us/help/947246/you-may-
      receive-an-out-of-memory-error-message-because-of-the-desktop
      */
      'Out of memory',
      // Yandex referrer error
      'Access is denied.',
      // Firefox Bug: https://github.com/getsentry/sentry-javascript/issues/2186
      "null is not an object (evaluating 'n.title')",
      "null is not an object (evaluating 'a.title')",
      // Google Translate Extension error: https://github.com/getsentry/sentry-javascript/issues/2418
      'a[b].target.className.indexOf is not a function',
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      "undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
      // https://community.adobe.com/t5/captivate/course-crashing-in-internet-explorer
      // -after-45-minutes-memory-leak-issue/td-p/11045967
      // https://social.msdn.microsoft.com/Forums/en-US/5a3381c5-b7fd-4277-80a8-61e2debbf525
      // /exception-gpu-device-instance-has-been-suspended?forum=winappswithhtml5
      // eslint-disable-next-line max-len
      'The GPU device instance has been suspended. Use GetDeviceRemovedReason to determine the appropriate action.',
      // eslint-disable-next-line max-len
      // Safari bug: https://stackoverflow.com/questions/65839034/sentry-error-indexsizeerror-the-index-is-not-in-the-allowed-range
      'IndexSizeError: The index is not in the allowed range.',
      // Firefox only error thrown when trying to call something before it's init'ed
      'NS_ERROR_NOT_INITIALIZED',
      // Only happening on Andriod Facebook Browser for 11 users. Not blocking user from checking out.
      // https://chownow.atlassian.net/browse/CN-13357
      'SyntaxError: Unexpected token >',
      // CN-14807 ignore certain TypeErrors that won't be addressed
      'TypeError: cancelled',
      'TypeError: Failed to fetch',
      // https://github.com/getsentry/sentry-javascript/issues/3040
      "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers')",
      // eslint-disable-next-line max-len
      "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedTextHandler.postMessage')",
      'TypeError: Preflight response is not successful',
      'TypeError: Network request failed',
      'TypeError: Failed to fetch',
      'TypeError: The request timed out.',
      'TypeError: The Internet connection appears to be offline.',
      'The network connection was lost.',
      // eslint-disable-next-line max-len
      "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
      // seems to be related to google docs https://github.com/mozilla/fxa-content-server/issues/4138
      // eslint-disable-next-line max-len
      'undefined is not an object (evaluating \'document.getElementsByClassName("docs-homescreen-gb-container")[0].style\')',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cannot parse response',
      'TypeError: Could not connect to the server.',
      'TypeError: Load failed',
      // seems to be happening on older browsers, solved for this as best we could and now unable to repro.
      // this error effects very few users but fires in a loop so gets noisy.
      'TypeError: Invalid character in header field name',
      // seems to be related embedded browsers within social media apps
      "ReferenceError: Can't find variable: _AutofillCallbackHandler",
      'ResizeObserver loop completed with undelivered notifications.',
      // CN-36699 related to onetrust script
      "undefined is not an object (evaluating 'r.DomainData')",
      "Cannot read properties of undefined (reading 'DomainData')",
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    whitelistUrls: [/chownow\.com/],
  };

  /**
   * react-scripts / dotenv will fall back to the value in `.env` if the env
   * var is set to a falsey value (eg. '' or false) so a hard coded "disabled"
   * string value is used as a simple work around.
   */
  if (
    process.env.REACT_APP_SENTRY_URL &&
    process.env.REACT_APP_SENTRY_URL !== 'disabled'
  ) {
    Sentry.init({
      ...sentryOptions,
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment: process.env.REACT_APP_DEPLOY_ENV || process.env.DEPLOY_ENV,
      release: version,
    });
  }
}

// Sift Science User and Pageview tracking
export function siftPageView(sessionId, userId = '') {
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const sift = (window._sift = window._sift || []);
  const apiKey = process.env.REACT_APP_SIFT_SCIENCE_API_KEY;

  sift.push(['_setAccount', apiKey]);
  sift.push(['_setUserId', userId]);
  sift.push(['_setSessionId', sessionId]);
  sift.push(['_trackPageview']);
}
