import React from 'react';

export default function IconBag() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon-Bag">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5V14H14V5H2ZM2 4C1.5 4 1 4.5 1 5V14C1 14.5 1.5 15 2 15H14C14.5 15 15 14.5 15 14V5C15 4.5 14.5 4 14 4H2Z"
          fill="#142138"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.98395 1.98358C5.36144 2.31315 5 2.75927 5 3.33337V7.5C5 7.77614 4.77614 8 4.5 8C4.22386 8 4 7.77614 4 7.5V3.33337C4 2.24081 4.72189 1.52025 5.51605 1.0998C6.30842 0.680296 7.26647 0.5 8 0.5C8.73353 0.5 9.69158 0.680296 10.4839 1.0998C11.2781 1.52025 12 2.24081 12 3.33337V7.5C12 7.77614 11.7761 8 11.5 8C11.2239 8 11 7.77614 11 7.5V3.33337C11 2.75927 10.6386 2.31315 10.0161 1.98358C9.39175 1.65306 8.59981 1.5 8 1.5C7.40019 1.5 6.60825 1.65306 5.98395 1.98358Z"
          fill="#142138"
        />
      </g>
    </svg>
  );
}
