import React from 'react';
import PropTypes from 'prop-types';

import Outlink from 'primitives/Outlink';

import styles from './styles.module.scss';

function ManagedDeliveryLink({ trackingUrl }) {
  return (
    <div className={styles.managedDelivery}>
      <div className={styles.header}>
        <Outlink
          to={trackingUrl}
          ariaLabel="Track Delivery"
          className={styles.trackingLink}
        >
          Track Delivery
        </Outlink>
      </div>
      <p className={styles.infoText}>
        To get food to your door, we work with third-party partners to provide
        delivery options at a fair price.
      </p>
    </div>
  );
}

ManagedDeliveryLink.propTypes = {
  trackingUrl: PropTypes.string.isRequired,
};

export default ManagedDeliveryLink;
