/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { CompanyContext } from 'context/CompanyContext';
import { UserContext } from 'context/UserContext';

import { COUNTRIES } from 'helpers/constants';
import { getSelectedAddress, setSelectedAddress } from 'helpers/customer';
import { deserializeAddress } from 'helpers/address';

import GeosuggestWrapper from 'components/GeosuggestWrapper';
import DefaultDropdown from 'components/DefaultDropdown';

import { ReactComponent as IconPin } from 'images/icon-mappin.svg';
import { ReactComponent as IconClear } from 'images/icon-clear.svg';

import styles from './styles.module.scss';

function AddressBar(props) {
  const {
    clearOnMount,
    hasErrors,
    hideDefaultDropdown,
    hideLocateMe,
    onSelectAddress,
  } = props;

  const { handleFetchCompany, isCanadian } = useContext(CompanyContext);
  const { user } = useContext(UserContext);

  const [showDefaultDropdown, setShowDefaultDropdown] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [hasError, setHasError] = useState(false);

  const savedAddresses = user.delivery?.addresses || [];

  const { hqId } = useParams();

  const geosuggestRef = useRef();

  const selectedAddress = getSelectedAddress();

  useEffect(() => {
    if (clearOnMount) geosuggestRef?.current?.update('');

    if (geosuggestRef.current?.input?.props?.value) setShowClearIcon(true);
  }, []);

  function handleOnFocus() {
    setShowDefaultDropdown(true);
    setHasError(false);
  }

  function handleOnBlur() {
    setShowDefaultDropdown(false);
    setHasError(false);
  }

  function handleOnChange(value) {
    setShowDefaultDropdown(!value);
    setShowClearIcon(value);
  }

  function handleClickOutside(e) {
    // we shouldn't change anything if the user clicks inside the address input
    if (e.target.value) return;
    setShowDefaultDropdown(false);
  }

  function handleOnError() {
    setShowDefaultDropdown(true);
    setHasError(true);
  }

  function handleOnSelectAddress(address) {
    if (address) {
      const deserializedAddress = deserializeAddress(address);

      if (onSelectAddress) {
        onSelectAddress(deserializedAddress);
      } else {
        setSelectedAddress(deserializedAddress);

        if (hqId) {
          handleFetchCompany(hqId, {
            location: `${deserializedAddress.latitude},${deserializedAddress.longitude}`,
          });
        }

        setShowDefaultDropdown(false);
      }

      if (geosuggestRef.current) {
        geosuggestRef.current.update(deserializedAddress.formatted_address);
      }
    }
  }

  function handleOnClear() {
    geosuggestRef.current.update('');
    setShowClearIcon(false);
    setShowDefaultDropdown(false);
  }

  return (
    <div className={styles.searchInputWrap}>
      <IconPin
        alt="search icon"
        className={classNames(styles.icon, styles.iconSearch, {
          [styles.active]: showDefaultDropdown || showClearIcon,
        })}
      />
      <GeosuggestWrapper
        initialValue={get(selectedAddress, 'formatted_address')}
        onFocus={handleOnFocus}
        onblur={handleOnBlur}
        placeholder="Your Address"
        country={isCanadian ? COUNTRIES.canada.alpha2 : COUNTRIES.usa.alpha2}
        className={styles.searchInput}
        onSelectAddress={handleOnSelectAddress}
        onChange={handleOnChange}
        geosuggestRef={geosuggestRef}
        skipSuggest={() => showDefaultDropdown}
        hasErrors={hasErrors}
        aria-describedby={hasErrors ? 'invalidAddressError' : null}
      />
      {showClearIcon && (
        <IconClear
          className={classNames(styles.icon, styles.iconClear)}
          onClick={handleOnClear}
        />
      )}
      {showDefaultDropdown && !hideDefaultDropdown && (
        <DefaultDropdown
          hasError={hasError}
          onError={handleOnError}
          onSelectAddress={handleOnSelectAddress}
          handleClickOutside={handleClickOutside}
          savedAddresses={savedAddresses}
          hideLocateMe={hideLocateMe}
        />
      )}
    </div>
  );
}

AddressBar.propTypes = {
  clearOnMount: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hideDefaultDropdown: PropTypes.bool,
  hideLocateMe: PropTypes.bool,
  onSelectAddress: PropTypes.func,
};

AddressBar.defaultProps = {
  clearOnMount: false,
  hasErrors: false,
  hideDefaultDropdown: false,
  hideLocateMe: false,
  onSelectAddress: undefined,
};

export default AddressBar;
