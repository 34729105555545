import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import classNames from 'classnames';

function SlideUpIn({
  children,
  className,
  delay,
  transformFrom,
  transformTo,
  maxHeight,
}) {
  const { transform, opacity } = useSpring({
    config: { tension: 400, friction: 35 },
    from: {
      transform: transformFrom,
    },
    to: { transform: transformTo },
    delay,
  });

  return (
    <animated.div
      style={{
        transform,
        opacity,
        height: 'inherit',
        transition: `transform ease-out 0.08s`,
        maxHeight,
      }}
      className={classNames(className)}
    >
      {children}
    </animated.div>
  );
}

SlideUpIn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  delay: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transformFrom: PropTypes.string,
  transformTo: PropTypes.string,
};

SlideUpIn.defaultProps = {
  children: undefined,
  className: undefined,
  delay: undefined,
  maxHeight: undefined,
  transformFrom: 'translateY(100%)',
  transformTo: 'translateY(0)',
};

export default SlideUpIn;
