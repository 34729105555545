import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useFetchManifest from 'hooks/useFetchManifest';

import styles from './styles.module.scss';

const DEFAULT_CHOWNOW_AGGREGATE_SAVINGS_AMOUNT = '640'; // Current as of 03/2023

function parseFromMillions(amount) {
  return amount / 1000000;
}

function Savings(props) {
  const { order, isNewUser } = props;

  const { aggregate_restaurant_savings: restaurantSavings } =
    useFetchManifest();
  const chownowAggregateSavings =
    parseFromMillions(restaurantSavings?.dollar_amount) ||
    DEFAULT_CHOWNOW_AGGREGATE_SAVINGS_AMOUNT;

  function getSavings() {
    const savings = order.diner_restaurant_savings;

    if (savings >= 1000) {
      const removeDecimal = savings.toFixed(0);
      return Number(removeDecimal).toLocaleString();
    }

    return savings.toFixed(2);
  }

  return (
    <div className={styles.savingsWrapper}>
      {isNewUser && (
        <div className={classNames(styles.savingsSection, styles.borderBottom)}>
          <div className={styles.amount}>${chownowAggregateSavings}M</div>
          <div className={styles.description}>
            Every order is commission free, helping restaurants save $
            {chownowAggregateSavings} million since 2011.
          </div>
        </div>
      )}
      <div className={styles.savingsSection}>
        <div className={styles.amount}>
          ${order.order_restaurant_savings.toFixed(2)}
        </div>
        <div className={styles.description}>
          {isNewUser
            ? `Your order saved ${
                order.restaurant.short_name
              } $${order.order_restaurant_savings.toFixed(2)} in commissions. `
            : `The amount of money you saved ${order.restaurant.short_name} in
          commissions.`}
        </div>
      </div>
      {!isNewUser && (
        <div className={classNames(styles.savingsSection, styles.borderTop)}>
          <div className={styles.amount}>${getSavings()}</div>
          <div className={styles.description}>
            The total amount of restaurant savings from all your ChowNow orders.
          </div>
        </div>
      )}
    </div>
  );
}

Savings.propTypes = {
  order: PropTypes.shape({
    diner_restaurant_savings: PropTypes.number,
    restaurant: PropTypes.shape({ short_name: PropTypes.string.isRequired })
      .isRequired,
    order_restaurant_savings: PropTypes.number.isRequired,
  }).isRequired,
  isNewUser: PropTypes.bool.isRequired,
};

export default Savings;
