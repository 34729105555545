import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as IconCaretDown } from 'images/icon-caretdown.svg';

import styles from './styles.module.scss';

function OrderToggle(props) {
  const { hideToggle, isExpanded, toggleOrder } = props;

  return (
    <div className={styles.orderToggle}>
      <div className={styles.orderHeader}>Order Summary</div>
      {!hideToggle && (
        <button
          className={styles.expand}
          onClick={toggleOrder}
          type="button"
          data-testid="DetailsButton"
        >
          <IconCaretDown
            className={classNames(styles.icon, {
              [styles.isExpanded]: isExpanded,
            })}
          />
        </button>
      )}
    </div>
  );
}

OrderToggle.propTypes = {
  hideToggle: PropTypes.bool,
  isExpanded: PropTypes.bool,
  toggleOrder: PropTypes.func,
};

OrderToggle.defaultProps = {
  hideToggle: false,
  isExpanded: false,
  toggleOrder: undefined,
};

export default OrderToggle;
