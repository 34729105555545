import { postToChownowApi } from 'helpers/api';
import { serializeCard, creditCardStringToType } from 'helpers/payments';

export async function handleFetchAddCard(
  values,
  user,
  setIsUpdatingCards,
  setCardForm,
  setUser
) {
  setIsUpdatingCards(true);
  const cardPayload = {
    ...serializeCard(values),
    preauthorize: true,
  };

  const { data, error } = await postToChownowApi({
    endpoint: `customer/${user.id}/billing-card`,
    body: cardPayload,
  });
  if (!error) {
    // reset card form to initial state
    setCardForm();

    const fullCardData = {
      ...data,
      /**
       * The API doesn't return cvv_valid in the successful create response but most of the time
       * this should be true since we are pre-authorizing newly added cards. Hard coding this to
       * `true` will allow the user to use the new card at checkout and just be presented with a
       * payment error if there ends up being a billing issue.
       */
      cvv_valid: true,
      is_visible: true,
      // type_id is also missing from the response
      type_id: creditCardStringToType[values.type],
    };
    setUser({
      ...user,
      billing: {
        ...user.billing,
        cards: [...user.billing.cards, fullCardData],
      },
    });
  }
  setIsUpdatingCards(false);
}

export async function handleFetchDeleteCard(
  cardId,
  user,
  setIsUpdatingCards,
  setUser
) {
  setIsUpdatingCards(true);

  const { error } = await postToChownowApi({
    endpoint: `customer/${user.id}/billing-card/${cardId}`,
    method: 'DELETE',
  });
  if (!error) {
    setUser({
      ...user,
      billing: {
        ...user.billing,
        cards: user.billing?.cards?.filter((card) => card.id !== cardId),
      },
    });
  }
  setIsUpdatingCards(false);
}
