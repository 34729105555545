import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as IconInfo } from 'images/icon-badge-info.svg';

import styles from './styles.module.scss';

function InfoTooltip(props) {
  const { children, className, id, label, showIcon } = props;

  return (
    <>
      <button aria-describedby={id} className={styles.btnInfo} type="button">
        <span className={classNames(styles.tooltipContainer, className)}>
          {label}
          {showIcon && <IconInfo className={styles.iconInfo} />}
        </span>
      </button>

      <div className={styles.tooltip} role="tooltip" id={id}>
        {children}
      </div>
    </>
  );
}

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  showIcon: PropTypes.bool,
};

// Legacy tooltips will show an icon, so default that to true for now. Going forward, new tooltips won't.
InfoTooltip.defaultProps = {
  className: undefined,
  id: undefined,
  label: undefined,
  showIcon: true,
};

export default InfoTooltip;
