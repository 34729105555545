import React, { useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as IconMinus } from 'images/icon-minus.svg';

import styles from './styles.module.scss';

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 99;

export interface StepperProps {
  /** Optional CSS class to be applied */
  className?: string;
  /** To run on change of stepper */
  onUpdateQuantity: (value: number) => void;
  /** To run on click of Plus */
  quantity: number;
  /** MinQuantity allowed for counter */
  minQuantity?: number;
  /** MaxQuantity allowed for counter */
  maxQuantity?: number;
  /** Data-testid */
  dataTestId?: string;
}

const Stepper = ({
  className = '',
  onUpdateQuantity = () => {},
  quantity,
  minQuantity = MIN_QUANTITY,
  maxQuantity = MAX_QUANTITY,
  dataTestId,
}: StepperProps) => {
  const [newQuantity, setNewQuantity] = useState(quantity);

  const isMinDisabled = !!(newQuantity <= minQuantity);
  const isMaxDisabled = !!(newQuantity >= maxQuantity);

  function updateQuantity(value: number) {
    const displayValue = Math.min(Math.max(value, minQuantity), maxQuantity);

    onUpdateQuantity(displayValue);
    setNewQuantity(displayValue);
  }

  function handleDecrement() {
    updateQuantity(newQuantity - 1);
  }

  function handleIncrement() {
    updateQuantity(newQuantity + 1);
  }

  return (
    <div className={classNames(styles.stepperCount, className)}>
      <button
        className={classNames(styles.stepperIcon, {
          [styles.disabled]: isMinDisabled,
        })}
        data-testid="minus"
        onClick={handleDecrement}
        disabled={isMinDisabled}
        type="button"
      >
        <IconMinus />
      </button>
      <div className={styles.count} data-testid={dataTestId}>
        {newQuantity}
      </div>
      <button
        className={classNames(styles.stepperIcon, {
          [styles.disabled]: isMaxDisabled,
        })}
        data-testid="plus"
        onClick={handleIncrement}
        disabled={isMaxDisabled}
        type="button"
      >
        <IconPlus />
      </button>
    </div>
  );
};

export default Stepper;
