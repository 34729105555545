import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { includes, pick, isEqual } from 'lodash';

import { SOCIAL_LOGIN_ERRORS } from 'helpers/api';
import {
  EXPERIMENT_COOKIE,
  MEMBERSHIP_STATUS,
  MEMBERSHIP_PLANS,
} from 'helpers/constants';

export function getIsSessionStorageAllowed() {
  let isAllowed = false;

  try {
    const storage = window.sessionStorage;
    storage.setItem('test', 'test');
    storage.removeItem('test');
    isAllowed = true;
  } catch (exception) {
    isAllowed = false;
  }

  return isAllowed;
}

export function getIsLocalStorageAllowed() {
  let isAllowed = false;

  try {
    if (window.localStorage) isAllowed = true;
  } catch (exception) {
    isAllowed = false;
  }

  return isAllowed;
}

export function getSelectedAddress() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let selectedAddress = '';

  if (isSessionStorageAllowed) {
    selectedAddress = JSON.parse(sessionStorage.getItem('selectedAddress'));
  }

  return selectedAddress;
}

export function setSelectedAddress(address) {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  if (isSessionStorageAllowed) {
    sessionStorage.setItem('selectedAddress', JSON.stringify(address));
  }
}

export function removeSelectedAddressId(address) {
  const selectedAddress = address;
  selectedAddress.id = null;
  setSelectedAddress(selectedAddress);
}

export function clearSelectedAddress() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  if (isSessionStorageAllowed) {
    sessionStorage.removeItem('selectedAddress');
  }
}

export function getCnChannel() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let cnChannel = '';

  if (isSessionStorageAllowed) {
    cnChannel = JSON.parse(sessionStorage.getItem('cnChannel'));
  }

  return cnChannel;
}

export function setCnChannel(channel) {
  const isSessionStorageAllowed = getIsLocalStorageAllowed();
  if (isSessionStorageAllowed) {
    sessionStorage.setItem('cnChannel', JSON.stringify(channel));
  }
}

export function getCnChannelXid() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let cnChannelXid = '';

  if (isSessionStorageAllowed) {
    cnChannelXid = JSON.parse(sessionStorage.getItem('cnChannelXid'));
  }

  return cnChannelXid;
}

export function setCnChannelXid(id) {
  const isSessionStorageAllowed = getIsLocalStorageAllowed();

  if (isSessionStorageAllowed) {
    sessionStorage.setItem(
      'cnChannelXid',
      JSON.stringify(id.substring(0, 100)) // cut off any characters if longer than 100
    );
  }
}

export function getUserAuthStatus() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let status;

  if (isSessionStorageAllowed) {
    status = JSON.parse(sessionStorage.getItem('userAuthStatus'));
  }

  return status;
}

export function setUserAuthStatus(bool) {
  const isSessionStorageAllowed = getIsLocalStorageAllowed();

  if (isSessionStorageAllowed) {
    sessionStorage.setItem('userAuthStatus', bool);
  }
}

export function getChannelInfoForOrderMeta() {
  // if we don't have a channel, don't set anything as BE requires a channel to accept any other info
  const hasChannelInfo = !!getCnChannel();
  return hasChannelInfo
    ? { cn_channel: getCnChannel(), cn_channel_xid: getCnChannelXid() }
    : null;
}

export function getUserExperimentId() {
  const cookies = new Cookies();
  const cookie = cookies.get(EXPERIMENT_COOKIE);
  return cookie;
}

export function getUserSessionId() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  let userId = isSessionStorageAllowed && sessionStorage.getItem('userId');

  if (!userId) {
    userId = uuidv4();
    if (isSessionStorageAllowed) {
      sessionStorage.setItem('userId', userId);
    }
  }

  return userId;
}

export function getFbPixelId() {
  const isSessionStorageAllowed = getIsSessionStorageAllowed();
  const fbPixelId =
    isSessionStorageAllowed && sessionStorage.getItem('fbPixelId');

  return fbPixelId !== 'undefined' ? JSON.parse(fbPixelId) : null;
}

export function setFbPixelId(id) {
  const isSessionStorageAllowed = getIsLocalStorageAllowed();
  if (isSessionStorageAllowed) {
    sessionStorage.setItem('fbPixelId', JSON.stringify(id));
  }
}

export function getSocialErrors(errors) {
  return (
    (errors.length &&
      errors.filter((error) => includes(SOCIAL_LOGIN_ERRORS, error.code))) ||
    []
  );
}

export function getRemoveSocialErrors(errors) {
  return (
    (errors.length &&
      errors.filter(
        (error) => !includes(SOCIAL_LOGIN_ERRORS, error.code) // all errors other than social errors
      )) ||
    []
  );
}

// Ensure any null/undefined values from DB are cast as strings for comparison against API response
export function convertValuesToStrings(address) {
  const formatted = { ...address };
  if (!formatted.delivery_instructions) {
    formatted.delivery_instructions = '';
  }
  return Object.fromEntries(
    Object.entries(formatted).map(([k, v]) => [k, v ? v.toString() : ''])
  );
}

export async function checkDuplicateAddressesAgainstStorage(addressesFromUser) {
  const keysToCompare = [
    'street_address1',
    'street_address2',
    'country_alpha2',
    'zip',
    'city',
    'state',
    'delivery_instructions',
  ];

  const selectedAddress = getSelectedAddress();
  if (!selectedAddress) return;
  const sanitizedSelectedAddress = pick(
    convertValuesToStrings(selectedAddress),
    keysToCompare
  );
  const matchedAddress = addressesFromUser.find((addressFromStore) => {
    const sanitzedAddressFromAccount = pick(
      convertValuesToStrings(addressFromStore),
      keysToCompare
    );
    return isEqual(sanitizedSelectedAddress, sanitzedAddressFromAccount);
  });
  if (matchedAddress) {
    setSelectedAddress(matchedAddress);
  }
}

export function getUserMembershipPlan({ userMemberships, restaurant }) {
  if (!restaurant) return;

  const userMembership = userMemberships.find(
    ({ restaurant_id: restaurantId, is_active: isActive }) =>
      restaurantId === restaurant.id && isActive
  );
  // eslint-disable-next-line camelcase
  const { plan_xid = '', expires_at } = userMembership || {};
  // eslint-disable-next-line camelcase
  const userMembershipPlan = MEMBERSHIP_PLANS.find(({ id }) => plan_xid === id);

  return { ...userMembershipPlan, expires_at }; // eslint-disable-line consistent-return
}

export function getHasActiveMembership({ userMemberships, restaurant }) {
  if (!restaurant) return;

  const userMembership = userMemberships.find(
    ({ restaurant_id: restaurantId, is_active: isActive }) =>
      restaurantId === (restaurant && restaurant.id) && isActive
  );
  const hasActiveMembership = typeof userMembership !== 'undefined';

  return hasActiveMembership; // eslint-disable-line consistent-return
}

export function getExpiredMemberships({ userMemberships, restaurant }) {
  if (!restaurant) return;

  const userMembership = userMemberships.filter(
    ({ restaurant_id: restaurantId, status }) =>
      restaurantId === restaurant.id && status === MEMBERSHIP_STATUS.expired
  );
  return userMembership || {}; // eslint-disable-line consistent-return
}
