import { useEffect, useRef } from 'react';

type UseClickOutsideArgs = {
  handler: () => void;
  test: ({
    ref,
    e,
  }: {
    ref: { current: HTMLElement | null };
    e: MouseEvent;
  }) => boolean;
};

// Custom hook to add click outside behavior. Takes an event handler and a test function and returns a ref for the calling component to use
export default function useClickOutside({
  handler,
  test,
}: UseClickOutsideArgs) {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (test({ ref, e })) {
        handler();
      }
    }
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);

  return ref;
}
