/* eslint-disable max-len */
import React from 'react';

export default function Visa() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.5 0.5 5 1 5H31C31.5 5 32 5.5 32 6V26C32 26.5 31.5 27 31 27H1C0.5 27 0 26.5 0 26V6ZM1 6V26H31V6H1Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 19.8666H26.9771L26.719 18.7189H23.918L23.4588 19.8666H21.1627L24.448 12.7139C24.448 12.7139 24.6475 12.1382 25.4655 12.1382H27.2364L29 19.8666ZM25.7105 14.2402L24.5531 17.133H26.3621L25.7105 14.2402Z"
        fill="#0066B2"
      />
      <path
        d="M21.3347 13.9966L21.6493 12.3369C21.6493 12.3369 20.6789 12 19.6678 12C18.5744 12 15.978 12.4364 15.978 14.5585C15.978 16.5555 19.0256 16.5804 19.0256 17.6283C19.0256 18.6769 16.2923 18.4897 15.3898 17.8283L15.0626 19.5632C15.0626 19.5632 16.046 20 17.5496 20C19.0526 20 21.3214 19.2887 21.3214 17.3541C21.3214 15.3447 18.2465 15.1572 18.2465 14.2836C18.2465 13.4098 20.3923 13.5221 21.3347 13.9966Z"
        fill="#0066B2"
      />
      <path
        d="M13.7047 19.8666H11.5017L12.8785 12.1382H15.0822L13.7047 19.8666Z"
        fill="#0066B2"
      />
      <path
        d="M7.29919 16.3094L7.548 17.4539L9.64819 12.1382H12.0195L8.51414 19.8666H6.21871L4.30514 13.1523C3.06214 12.4706 2.00036 12.2689 2 12.2689L2.04063 12.1382H3.77676L7.29919 16.3094Z"
        fill="#0066B2"
      />
      <path
        d="M7 16L6.30045 12.6673C6.30045 12.6673 6.21631 12 5.31456 12H2.03834L2 12.1253C2.00034 12.1253 3.57518 12.4283 5.08556 13.5629C6.52979 14.6481 7 16 7 16Z"
        fill="#F9A533"
      />
    </svg>
  );
}
