/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { isPast, isToday, format, startOfToday } from 'date-fns';

import {
  getDateForApiDateStr,
  calculateTimes,
} from '@chownow/cn-web-utils/date';
import { ORDER_WHEN_OPTIONS } from 'helpers/order';

import { getFulfillmentTime, getOrderData } from 'modules/order';
import {
  getOrderAhead,
  getNextAvailableTime,
  getIsFulfillmentAvailableForWhen,
} from 'modules/restaurant';

import SelectStandard from 'primitives/SelectStandard';

import styles from './styles.module.scss';

function DateTimePicker({
  fulfillMethod,
  isErrorModal,
  isSelectedFulfillmentAvailableNow,
  onWhenChange,
  showLabels,
}) {
  const order = useSelector(getOrderData);
  const when = useSelector(getFulfillmentTime);
  const nextAvailableTime = useSelector((state) => {
    const nextTime = getNextAvailableTime(
      state,
      fulfillMethod || order.fulfillmentMethod
    );
    return nextTime.nextAvailableTime;
  });
  const isSelectedFulfillmentAvailableForWhen = useSelector((state) =>
    getIsFulfillmentAvailableForWhen(
      state,
      fulfillMethod || order.fulfillmentMethod,
      when
    )
  );
  const orderAhead = useSelector(getOrderAhead);

  const labelFulfillmentText =
    fulfillMethod === 'curbside' ? 'pickup' : fulfillMethod;
  let selectedDate;
  let selectedTime;

  if (
    when &&
    !isPast(getDateForApiDateStr(when)) &&
    !isErrorModal &&
    isSelectedFulfillmentAvailableForWhen
  ) {
    selectedDate = when.substring(0, 8); // date is first 8 digits of when 'YYYYMMDD'
    selectedTime = when.substring(8, 12); // time is last 4 digits 'HHmm'
  } else {
    selectedDate = nextAvailableTime
      ? nextAvailableTime.substring(0, 8)
      : format(startOfToday(), 'yyyyMMdd');
    selectedTime = ORDER_WHEN_OPTIONS.now.label;
  }

  function calculateDates() {
    const dateOptions = [];
    if (!orderAhead) return [];
    orderAhead.days.forEach((day) => {
      const dateObject = getDateForApiDateStr(day.date);
      const nextAvailableDateObject = getDateForApiDateStr(nextAvailableTime);

      if (
        isToday(dateObject) &&
        (!nextAvailableTime || isToday(nextAvailableDateObject))
      ) {
        dateOptions.push({
          label: 'Today',
          value: day.date.replace(/-/g, ''),
        });
      } else if (day[`${fulfillMethod}_index_ranges`].length) {
        dateOptions.push({
          label: format(dateObject, 'EEE, MMM d'),
          value: day.date.replace(/-/g, ''),
        });
      }
    });

    return dateOptions;
  }

  const [selectedDateOption, setSelectedDateOption] = useState(selectedDate);
  const [selectedTimeOption, setSelectedTimeOption] = useState(
    isErrorModal ? null : selectedTime
  );
  const [selectedWhen, setSelectedWhen] = useState(
    !isErrorModal ? when : undefined
  );
  const [timeOptions, setTimeOptions] = useState(
    calculateTimes({
      dateStr: selectedDate,
      isSelectedFulfillmentAvailableNow,
      orderAhead,
      fulfillMethod,
    }) || []
  );

  const dateOptions = calculateDates();

  useEffect(() => {
    // specifically undefined as ASAP is null
    if (selectedWhen !== undefined) {
      onWhenChange(selectedWhen);
    }
  }, [selectedWhen]);

  function handleSelectDate(dateVal) {
    setSelectedDateOption(dateVal);
    setSelectedTimeOption('');
    setTimeOptions(
      calculateTimes({
        dateStr: dateVal,
        isSelectedFulfillmentAvailableNow,
        orderAhead,
        fulfillMethod,
      })
    );

    // NOTE: Send 'undefined' to parent so it knows it's an order ahead
    setSelectedWhen(undefined);
  }

  function handleSelectTime(currentSelectedTimeOption) {
    setSelectedTimeOption(currentSelectedTimeOption);
    setSelectedWhen(
      currentSelectedTimeOption === ORDER_WHEN_OPTIONS.now.label
        ? null
        : selectedDateOption + currentSelectedTimeOption
    );
  }

  return (
    <div className={styles.dateTimePicker}>
      <div className={styles.pickerType}>
        <SelectStandard
          label={
            showLabels && `Select ${capitalize(labelFulfillmentText)} Date`
          }
          onChange={handleSelectDate}
          value={selectedDateOption || ''}
        >
          <option value="" disabled>
            Select Date
          </option>
          {dateOptions.map((date) => (
            <option value={date.value} key={date.value}>
              {date.label}
            </option>
          ))}
        </SelectStandard>
      </div>
      <div className={styles.pickerType}>
        <SelectStandard
          label={
            showLabels && `Select ${capitalize(labelFulfillmentText)} Time`
          }
          onChange={handleSelectTime}
          value={selectedTimeOption || ''}
        >
          <option value="" disabled>
            Select Time
          </option>
          {timeOptions.map((time) => (
            <option value={time.value} key={time.value}>
              {time.label}
            </option>
          ))}
        </SelectStandard>
      </div>
    </div>
  );
}

DateTimePicker.propTypes = {
  fulfillMethod: PropTypes.string.isRequired,
  isErrorModal: PropTypes.bool,
  isSelectedFulfillmentAvailableNow: PropTypes.bool,
  onWhenChange: PropTypes.func,
  showLabels: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  isErrorModal: false,
  isSelectedFulfillmentAvailableNow: false,
  onWhenChange: () => {},
  showLabels: false,
};

export default DateTimePicker;
