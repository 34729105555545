import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import ModCategory from 'components/ModCategory';

import styles from './styles.module.scss';

export default function NestedModifier({
  currentSelections,
  getErrors,
  handleEditOptionsBtn,
  isCheckingNestedMod,
  modifierCategoryError,
  onAddModifier,
  selectedNestedMod,
}) {
  const { parentModName, nestedMod } = selectedNestedMod;
  return (
    <div>
      <div className={styles.modifierTitle}>{parentModName}</div>
      {nestedMod.map((modCategory) => (
        <div className={styles.modCategoryWrapper} key={modCategory.id}>
          <ModCategory
            handleEditOptionsBtn={handleEditOptionsBtn}
            maxQty={modCategory.max_qty}
            minQty={modCategory.min_qty}
            modifierCategoryId={modCategory.id}
            modifiers={modCategory.modifiers}
            name={modCategory.name}
            onAddModifier={onAddModifier}
            selectedModifiers={
              currentSelections.find((modCat) => modCat.id === modCategory.id)
                ?.modifiers
            }
            flashError={modifierCategoryError === modCategory.id}
            hasError={isCheckingNestedMod && some(getErrors(currentSelections, nestedMod), {
              id: modCategory.id,
            })}
          />
        </div>
      ))}
    </div>
  );
}

NestedModifier.propTypes = {
  currentSelections: PropTypes.arrayOf(PropTypes.object).isRequired,
  getErrors: PropTypes.func.isRequired,
  handleEditOptionsBtn: PropTypes.func.isRequired,
  isCheckingNestedMod: PropTypes.bool,
  modifierCategoryError: PropTypes.string,
  onAddModifier: PropTypes.func.isRequired,
  selectedNestedMod: PropTypes.shape({
    nestedMod: PropTypes.arrayOf(PropTypes.shape({})),
    parentModName: PropTypes.string,
  }).isRequired,
};

NestedModifier.defaultProps = {
  isCheckingNestedMod: false,
  modifierCategoryError: null,
};
