import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from 'react';

import styles from './styles.module.scss';

export interface RadioProps {
  checked?: boolean;
  disabled?: boolean;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

function Radio({
  checked = false,
  disabled = false,
  name,
  onChange = () => {},
}: RadioProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event);
  }

  return (
    <div className={styles.radioWrapper}>
      <input
        checked={isChecked}
        readOnly
        disabled={disabled}
        name={name}
        onChange={handleOnChange}
        type="radio"
      />
      <div className={styles.radioVisible} />
    </div>
  );
}

export default Radio;
