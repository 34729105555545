/* eslint-disable complexity */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { some } from 'lodash';

import {
  getHqIdFromUrl,
  getRestaurantIdFromUrl,
} from '@chownow/cn-web-utils/url';
import {
  ABOVE_ORDER_DELIVERY_MAX,
  ABOVE_ORDER_PICKUP_MAX,
  BELOW_ORDER_DELIVERY_MIN,
  BELOW_ORDER_PICKUP_MIN,
} from 'helpers/api';
import { getAppliedPromo } from 'helpers/order';
import { formatDiscount } from 'helpers/format';
import { userMembershipPlanType } from 'helpers/prop-types';

import { getErrorsForOrder, getOrderData } from 'modules/order';

import usePlatform from 'hooks/usePlatform';

import { ModalContext } from 'context/ModalContext';

import BagErrors from 'components/BagErrors';
import PromoBanner from 'components/PromoBanner';
import MembershipBadge from 'components/MembershipBadge';
import MembershipBanner from 'components/MembershipBanner';
import Order from 'components/Order';

import Button from 'primitives/Button';
import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';

import { ReactComponent as IconBadgeCheck } from 'images/icon-badge-check.svg';

import styles from './styles.module.scss';

export function ButtonMessage({ hasBagErrors, appliedPromo = [] }) {
  if (hasBagErrors) return <BagErrors />;

  if (appliedPromo?.length) {
    return (
      <div className={styles.promoApplied}>
        <IconBadgeCheck className={styles.badgeCheck} />
        {formatDiscount(appliedPromo[0].amount)} promo will be applied at
        checkout
      </div>
    );
  }
}

ButtonMessage.propTypes = {
  appliedPromo: PropTypes.arrayOf({}).isRequired,
  hasBagErrors: PropTypes.bool.isRequired,
};

export function BagModal(props) {
  const {
    handleCheckout,
    showMembershipBanner,
    showPromoBanner,
    hasActiveMembership,
    userMembershipPlan,
    isRenewExpirationBanner,
    expiredMembership,
  } = props;
  const { closeModal } = useContext(ModalContext);

  const { isMarketplacePlatform } = usePlatform();

  const errors = useSelector(getErrorsForOrder);
  const order = useSelector(getOrderData);

  const hqId = getHqIdFromUrl();
  const restaurantId = getRestaurantIdFromUrl();

  const hasBagErrors =
    some(errors, { code: ABOVE_ORDER_PICKUP_MAX }) ||
    some(errors, { code: BELOW_ORDER_PICKUP_MIN }) ||
    some(errors, { code: ABOVE_ORDER_DELIVERY_MAX }) ||
    some(errors, { code: BELOW_ORDER_DELIVERY_MIN });

  const appliedPromo = getAppliedPromo(order.discounts);

  function goToCheckout() {
    closeModal();
    handleCheckout();
  }

  const canShowPromoBanner = showPromoBanner && !showMembershipBanner;

  return (
    <ModalFrame>
      <ModalHeader
        title="Order Summary"
        className={styles.modalHeader}
        isSticky
      />
      <div
        className={classNames(styles.modalContent, {
          [styles.withPromoBanner]: canShowPromoBanner,
        })}
      >
        {showMembershipBanner && (
          <MembershipBanner
            restaurantId={restaurantId}
            hqId={hqId}
            isRenewBanner={isRenewExpirationBanner}
            expiredMembership={expiredMembership}
          />
        )}
        {hasActiveMembership && !isMarketplacePlatform && (
          <div className={styles.banner}>
            <MembershipBadge userMembershipPlan={userMembershipPlan} withName />
          </div>
        )}
        {canShowPromoBanner && (
          <div className={styles.banner}>
            <PromoBanner promos={[{}]} isOrderBag />
          </div>
        )}
        <Order isExpandedForced isModal />
        <Button
          className={styles.checkoutBtn}
          onClick={goToCheckout}
          isFullWidth
          dataTestId="CheckoutButton"
          disabled={hasBagErrors}
        >
          Checkout
        </Button>
        <ButtonMessage
          hasBagErrors={hasBagErrors}
          appliedPromo={appliedPromo}
        />
      </div>
    </ModalFrame>
  );
}

BagModal.propTypes = {
  expiredMembership: userMembershipPlanType,
  handleCheckout: PropTypes.func.isRequired,
  hasActiveMembership: PropTypes.bool,
  isRenewExpirationBanner: PropTypes.bool,
  showMembershipBanner: PropTypes.bool,
  showPromoBanner: PropTypes.bool,
  userMembershipPlan: userMembershipPlanType,
};

BagModal.defaultProps = {
  expiredMembership: undefined,
  hasActiveMembership: false,
  isRenewExpirationBanner: false,
  showMembershipBanner: false,
  showPromoBanner: false,
  userMembershipPlan: undefined,
};
