import React from 'react';

import { ReactComponent as IconEmptyBag } from './icon-bag.svg';

import styles from './styles.module.scss';

function BagEmpty() {
  return (
    <div className={styles.emptyBag} data-testid="EmptyBag">
      <IconEmptyBag className={styles.iconBag} />
      <div className={styles.message}>
        Choose an item from the menu to get started
      </div>
    </div>
  );
}

export default BagEmpty;
