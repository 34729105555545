import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import usePlatform from 'hooks/usePlatform';

import { paths, getFromChownowApi, MENU_API_VERSION } from 'helpers/api';
import { getCnChannel } from 'helpers/customer';

import { MenuContext } from 'context/MenuContext';

import { triggerValidate } from 'modules/order';

function useFetchMenu({
  restaurantId,
  when,
  shouldFetchOnLoad,
  shouldRefetch,
}) {
  const { setMenu, setIsMenuLoading } = useContext(MenuContext);

  const dispatch = useDispatch();
  const { isYelpPlatform } = usePlatform();

  function getChannelParam() {
    const cnChannel = isYelpPlatform ? 'yelp' : getCnChannel();
    return cnChannel ? `?cn_channel=${cnChannel}` : '';
  }

  function getEndpoint() {
    return when
      ? paths.restaurantMenuWhen({
          restaurantId,
          whenId: `${when}${getChannelParam()}`,
        })
      : `${paths.restaurantMenu({ restaurantId })}${getChannelParam()}`;
  }

  function handleFetch() {
    setIsMenuLoading(true);
    async function fetchMenu() {
      const { data, error } = await getFromChownowApi({
        endpoint: getEndpoint(),
        apiVersion: MENU_API_VERSION,
      });

      if (!error) {
        setMenu(data);

        dispatch({ type: triggerValidate.TYPE });
      }
      setIsMenuLoading(false);
    }
    fetchMenu();
  }

  useEffect(() => {
    if (shouldFetchOnLoad) {
      handleFetch();
    }
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      handleFetch();
    }
  }, [shouldRefetch]);
}

export default useFetchMenu;
