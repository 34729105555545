import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import orderReducer, { orderSagas } from 'modules/order';
import restaurantReducer, { restaurantSagas } from 'modules/restaurant';
import { migrations } from './migrations';

const PERSISTANCE_KEY_PREFIX = 'directPersist:';

const orderPersistConfig = {
  whitelist: ['orderData', 'timer', 'id'],
  keyPrefix: PERSISTANCE_KEY_PREFIX,
  key: 'order',
  version: 0,
  storage,
  migrate: createMigrate(migrations.order),
};

export const rootReducer = combineReducers({
  order: persistReducer(orderPersistConfig, orderReducer),
  restaurant: restaurantReducer,
});

/**
 * Saga initializer. Export your sagas in modules and spread them here
 */
export function* rootSaga() {
  yield all(
    [...orderSagas, ...restaurantSagas].map((saga) =>
      fork(saga)
    )
  );
}
