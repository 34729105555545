import { configureStore } from '@reduxjs/toolkit';

import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import { rootReducer, rootSaga } from 'modules';

import { logException } from 'helpers/loggers';

const isProd = process.env.NODE_ENV === 'production';

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    logException({
      name: 'Sagas error',
      message: error,
    });
  },
});

// https://redux-toolkit.js.org/api/getDefaultMiddleware
const customMiddleware = isProd
  ? [sagaMiddleware]
  : [
      sagaMiddleware,
      createLogger({
        collapsed: true,
        duration: true,
      }),
    ];

export default function initStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(customMiddleware),
    devTools: !isProd,
  });
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
