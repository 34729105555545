/* eslint-disable complexity, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorMessage, getIn } from 'formik';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us'; // Needed for phone region code

import { ReactComponent as IconClear } from 'images/icon-clear.svg';

import styles from './styles.module.scss';

const { bool, func, shape, string } = PropTypes;

function renderInput(fieldName, field, props, touched, errors) {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  function cleanPhoneInput(event) {
    event.target.value = event.target.rawValue; // eslint-disable-line no-param-reassign
    field.onChange(event);
  }

  function handleInputChange(event) {
    const { value } = event.target;
    if (fieldName === 'expDate') {
      if (value.match(/^\d{2}$/) !== null) {
        event.target.value = value + '/'; // eslint-disable-line no-param-reassign, prefer-template
      }
    }

    field.onChange(event);
  }

  // TO DO: remove Cleave - https://chownow.atlassian.net/browse/CN-24479
  switch (fieldName) {
    case 'phone.number':
      return (
        <Cleave
          id="phone.number"
          options={{
            delimiters: ['(', ') ', '-'],
            blocks: [0, 3, 3, 4],
            numericOnly: true,
          }}
          {...field}
          {...props}
          onChange={cleanPhoneInput}
          className={classNames(styles.input, {
            [styles.inputError]: !!touch && !!error,
          })}
          aria-describedby={!!touch && !!error ? `${field.name}-error` : null}
        />
      );
    case 'number':
      return (
        <Cleave
          id="number"
          options={{
            creditCard: true,
            onCreditCardTypeChanged: props.onChangeCardType,
          }}
          {...field}
          className={classNames(styles.input, styles.cardInput, {
            [styles.inputError]: !!touch && !!error,
          })}
          aria-describedby={!!touch && !!error ? `${field.name}-error` : null}
        />
      );
    default:
      const { onClear, ...rest } = props; // eslint-disable-line no-case-declarations
      return (
        <input
          id={field.name}
          {...field}
          {...rest}
          className={classNames(styles.input, {
            [styles.inputError]: !!touch && !!error,
            [styles.withIcon]: props.icon,
          })}
          aria-describedby={!!touch && !!error ? `${field.name}-error` : null}
          onKeyPress={handleInputChange}
        />
      );
  }
}

renderInput.propTypes = {
  onChangeCardType: func,
};

function FormikInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const wasTouched = getIn(touched, field.name);
  const hasError = getIn(errors, field.name);

  const shouldShowClearIcon =
    field.value &&
    props.onClear &&
    field.name !== 'number' &&
    field.name !== 'phone.number';

  function onClearField() {
    props.onClear(field.name);
  }

  return (
    <div className={styles.inputWrapper}>
      {props.label && (
        <label htmlFor={field.name} className={styles.label}>
          {props.label}
        </label>
      )}
      <div className={styles.inputBox}>
        {props.icon && <img src={props.icon} className={styles.icon} alt="" />}
        {renderInput(field.name, field, props, touched, errors)}
        {shouldShowClearIcon && (
          <button
            onClick={onClearField}
            className={styles.clearIcon}
            type="button"
          >
            <IconClear />
          </button>
        )}
      </div>
      {hasError && wasTouched && !props.noErrorLabel && (
        <div id={`${field.name}-error`} className={styles.error}>
          <ErrorMessage name={field.name} />
        </div>
      )}
    </div>
  );
}

FormikInput.propTypes = {
  field: shape({
    name: string,
    onChange: func,
  }),
  form: shape({
    touched: shape({}),
    errors: shape({}),
  }),
  hidden: bool,
  icon: string,
  label: string,
  onClear: func,
  noErrorLabel: bool,
};

FormikInput.defaultProps = {
  field: {},
  form: {},
  hidden: false,
  icon: undefined,
  label: undefined,
  onClear: undefined,
  noErrorLabel: false,
};

export default FormikInput;
