/* eslint-disable complexity */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import {
  logAnalyticsEvent,
  ANALYTICS_EVENT_NAME,
  ANALYTICS_EVENT_TYPES,
} from 'helpers/loggers';
import { FULFILLMENT_METHODS } from 'helpers/order';
import { MODAL_TYPE } from 'helpers/modals';

import { getFulfillmentMethod, getFulfillmentTime } from 'modules/order';
import {
  getFulfillmentAvailability,
  getIsBusy,
  getIsPromptingOrderTime,
} from 'modules/restaurant';

import { ReactComponent as IconCaution } from 'images/icon-caution.svg';

import OrderInfoButton from './OrderInfoButton';

import styles from './styles.module.scss';
import FulfillmentToggle from './FulfillmentToggle';

function FulfillmentPicker(props) {
  const { isCheckingOut, isClosed } = props;
  const { openModal } = useContext(ModalContext);

  const [pendingFulfillment, setPendingFulfillment] = useState(null);

  const fulfillmentAvailability = useSelector(getFulfillmentAvailability);
  const fulfillmentMethod = useSelector(getFulfillmentMethod);
  const isPromptingOrderTime = useSelector(getIsPromptingOrderTime);
  const fulfillmentTime = useSelector(getFulfillmentTime) || null;
  const isBusy = useSelector(getIsBusy);

  function logFulfillmentChange(value) {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectFulfillmentToggle,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        source: isCheckingOut ? 'checkout' : 'menu',
        order_type: value,
      },
    });
  }

  function handleModalClose(value) {
    logFulfillmentChange(value);
    setPendingFulfillment(null);
  }

  function displayWhenPicker(pendingFulfillmentMethod) {
    openModal(MODAL_TYPE.fulfillmentDetails, {
      fulfillmentMethod: pendingFulfillmentMethod || fulfillmentMethod,
      onClose: () =>
        handleModalClose(pendingFulfillmentMethod || fulfillmentMethod),
    });
  }

  useEffect(() => {
    if (isPromptingOrderTime) {
      displayWhenPicker();
    }
  }, [isPromptingOrderTime]);

  return (
    <div
      className={classNames(styles.fulfillmentOptions, {
        [styles.checkout]: isCheckingOut,
      })}
      data-testid="FulfillmentModal"
    >
      <FulfillmentToggle
        displayWhenPicker={displayWhenPicker}
        fulfillmentAvailability={fulfillmentAvailability}
        fulfillmentMethod={fulfillmentMethod}
        fulfillmentTime={fulfillmentTime}
        logFulfillmentChange={logFulfillmentChange}
        pendingFulfillment={pendingFulfillment}
        setPendingFulfillment={setPendingFulfillment}
      />
      {!isCheckingOut && (
        <OrderInfoButton
          displayWhenPicker={displayWhenPicker}
          fulfillmentAvailability={fulfillmentAvailability}
          fulfillmentMethod={fulfillmentMethod}
          fulfillmentTime={fulfillmentTime}
          isCheckingOut={isCheckingOut}
          isClosed={isClosed}
        />
      )}
      {isBusy && !isCheckingOut && (
        <div className={styles.isBusy}>
          <IconCaution className={styles.iconCaution} />
          <div>
            Heads up! We’re busy. Your estimated {fulfillmentMethod}{' '}
            {fulfillmentMethod === FULFILLMENT_METHODS.curbside && 'pickup'}{' '}
            time may change at checkout.
          </div>
        </div>
      )}
    </div>
  );
}

FulfillmentPicker.propTypes = {
  isCheckingOut: PropTypes.bool,
  isClosed: PropTypes.bool,
};

FulfillmentPicker.defaultProps = {
  isCheckingOut: false,
  isClosed: false,
};

export default FulfillmentPicker;
