import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';

import useIsMobile from 'hooks/useIsMobile';

import { saveRestaurantXHR } from 'modules/restaurant';

import Button from 'primitives/Button';
import ButtonIcon from 'primitives/ButtonIcon';

import { ReactComponent as HeartEmpty } from 'images/icon-heart-empty.svg';
import { ReactComponent as HeartFull } from 'images/icon-heart-full-red.svg';

import styles from './styles.module.scss';

function SaveButton({ className: givenClassName, restaurant }) {
  const dispatch = useDispatch();

  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const isSaved = restaurant.is_favorite;

  const isMobile = useIsMobile();

  const BUTTON_DEBOUNCE_MS = 300;

  function saveRestaurantAnalytics(saved) {
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectSaveRestaurant,
      attributes: {
        is_saved: saved,
        restaurant_location_id: restaurant.id,
      },
    });
  }

  async function handleSaveRestaurant(userId) {
    saveRestaurantAnalytics(true);

    dispatch({
      type: saveRestaurantXHR.request.TYPE,
      payload: {
        restaurantId: restaurant.id,
        userId: userId || user.id,
        isSaved: true,
      },
    });
  }

  async function handleUnsaveRestaurant() {
    saveRestaurantAnalytics(false);

    dispatch({
      type: saveRestaurantXHR.request.TYPE,
      payload: {
        restaurantId: restaurant.id,
        userId: user.id,
        isSaved: false,
      },
    });
  }

  const handleSaveRestaurantDebounced = useCallback(
    debounce(handleSaveRestaurant, BUTTON_DEBOUNCE_MS),
    []
  );

  const handleUnsaveRestaurantDebounced = useCallback(
    debounce(handleUnsaveRestaurant, BUTTON_DEBOUNCE_MS),
    []
  );

  const handleSaveRestaurantAuthed = () => {
    if (isSaved) {
      return handleUnsaveRestaurantDebounced();
    }

    return handleSaveRestaurantDebounced();
  };

  function handleUnauthReturn(userId) {
    handleSaveRestaurant(userId);
  }

  function handleSaveRestaurantUnauthed() {
    openModal(MODAL_TYPE.auth, {
      authRedirect: handleUnauthReturn,
    });
  }

  const onButtonClicked = async () => {
    if (user.id) {
      return handleSaveRestaurantAuthed();
    }
    return handleSaveRestaurantUnauthed();
  };

  const className = givenClassName || styles.saveButton;
  const icon = isSaved ? HeartFull : HeartEmpty;

  if (isMobile) {
    return (
      <ButtonIcon
        variant="outline"
        size="small"
        dataTestId="SaveButton"
        onClick={onButtonClicked}
        className={classNames(className, {
          [styles.isSaved]: isSaved,
        })}
      >
        {isSaved ? <HeartFull /> : <HeartEmpty />}
      </ButtonIcon>
    );
  }

  return (
    <Button
      variant="outline"
      dataTestId="SaveButton"
      onClick={onButtonClicked}
      icon={icon}
      className={classNames(className, {
        [styles.isSaved]: isSaved,
      })}
    >
      {isSaved ? 'Saved' : 'Save'}
    </Button>
  );
}

SaveButton.propTypes = {
  className: PropTypes.string,
  restaurant: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    is_favorite: PropTypes.bool.isRequired,
  }).isRequired,
};

SaveButton.defaultProps = {
  className: undefined,
};

export default SaveButton;
