import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface LoadingSpinnerProps {
  isCentered?: boolean;
  overlay?: boolean;
  size?: 'small' | 'medium' | 'large' | 'x-large';
}

function LoadingSpinner({
  isCentered = true,
  overlay = false,
  size = 'x-large',
}: LoadingSpinnerProps) {
  return (
    <div
      className={classNames(styles.spinnerOverlay, {
        [styles.overLayActive]: overlay,
      })}
    >
      <div
        data-testid="LoadingSpinner"
        className={classNames(styles.spinner, styles[size], {
          [styles.centered]: isCentered,
        })}
      />
    </div>
  );
}

export default LoadingSpinner;
