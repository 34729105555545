/* eslint-disable complexity */
import { isEmpty } from 'lodash';
import { MODAL_TYPE } from 'helpers/modals';
import { NEW_CARD_VALUE, getIsCardExpired } from 'helpers/payments';
import { reverseFormatExpDate } from 'helpers/format';
import { getSubmitOrderCount } from 'helpers/order';
import { phoneValidator } from 'helpers/validation';

export function validateTableForm({
  isMissingTable,
  diningSection,
  setTableNameError,
  order,
}) {
  if (isMissingTable) {
    diningSection.current.scrollIntoView({ block: 'center' });
    setTableNameError(!order.tableName);
  }
}

export function getHasUserEditedDeliveryAddress({
  savedAddresses,
  savedAddress,
}) {
  const userSavedAddress = savedAddresses?.find(
    (address) => address.id === savedAddress.id
  );

  return userSavedAddress?.street_address2 !== savedAddress?.street_address2;
}

export async function handleSubmitOrder({
  cardForm,
  diningSection,
  errors,
  handleFormSubmit,
  isApplePay,
  isDineIn,
  isGooglePay,
  isLoggedIn,
  isNewDeliveryAddress,
  isEditedDeliveryAddress,
  isTableRequired,
  isYelpPlatform,
  onApplePay,
  onGooglePay,
  openModal,
  order,
  restaurant,
  selectedCard,
  selectedCardId,
  setFieldValue,
  setSubmitting,
  setTableNameError,
  submitForm,
  submitOrder,
  user,
}) {
  // Yelp order submit does not require the validation steps below related to
  // missing info, auth check, apple pay, etc.
  if (isYelpPlatform) {
    submitOrder();
    return;
  }

  const isMissingTable = isDineIn && isTableRequired && !order.tableName;
  // If user registers in Discover but never places an order, or logs in from social, it's possible
  // to be missing phone number or last name
  const phoneValidationErrors = phoneValidator({
    phone: { number: user.phone?.number },
  });
  const isMissingInfo =
    isLoggedIn && (!user.last_name || !isEmpty(phoneValidationErrors));

  if (isMissingInfo) {
    openModal(MODAL_TYPE.confirmAccount);
  } else if (isLoggedIn || isApplePay) {
    if (!order.total) {
      // if validate order fails, we will have no order total which will cause JSON errors
      // in Hermosa. Don't try to submit order if missing, instead reload page to re-trigger
      // order validate.
      openModal(MODAL_TYPE.dialog, {
        message: errors.length > 0 && errors[0].message,
        onConfirm: () => {
          window.location.reload();
        },
        noCloseOverlay: true,
        isAlert: true,
      });
    } else if (restaurant.is_post_pay) {
      submitOrder();
    } else if (isApplePay) {
      validateTableForm({
        isMissingTable,
        diningSection,
        setTableNameError,
        order,
      });

      if (!isMissingTable) {
        onApplePay();
      }
    } else if (isGooglePay) {
      validateTableForm({
        isMissingTable,
        diningSection,
        setTableNameError,
        order,
      });
      if (!isMissingTable) {
        onGooglePay();
      }
    } else if (selectedCardId === NEW_CARD_VALUE) {
      validateTableForm({
        isMissingTable,
        diningSection,
        setTableNameError,
        order,
      });
      if (cardForm.id) {
        handleFormSubmit(cardForm, { setSubmitting });
      } else {
        // In cases where the new card order got declined, we need to submit this form instead
        submitForm();
      }
    } else if (!selectedCard || isMissingTable) {
      // table input is not part of Formik forms
      validateTableForm({
        isMissingTable,
        diningSection,
        setTableNameError,
        order,
      });

      submitForm();
    } else if (
      !selectedCard.cvv_valid ||
      (selectedCard.cvv_valid &&
        (isNewDeliveryAddress || isEditedDeliveryAddress)) ||
      getIsCardExpired(selectedCard.exp_month, selectedCard.exp_year)
    ) {
      // table input is not part of Formik forms
      validateTableForm({
        isMissingTable,
        diningSection,
        setTableNameError,
        order,
      });

      const submittedOrderCount = getSubmitOrderCount();
      const isSelectedCardDeclined = submittedOrderCount?.ids.includes(
        selectedCard.id
      );

      // If filling in form for an already existing card, need to set the id to the existing card ID as
      // to not create duplicate cards
      await setFieldValue('id', selectedCard.id);

      // If only CVV is invalid, set the remaining fields with valid data
      await setFieldValue('type', selectedCard.type);

      // only fill in expiry if the card is not expired and card is not declined
      if (
        !getIsCardExpired(selectedCard.exp_month, selectedCard.exp_year) &&
        !isSelectedCardDeclined
      ) {
        await setFieldValue(
          'expDate',
          reverseFormatExpDate(selectedCard.exp_month, selectedCard.exp_year)
        );
      }
      // only fill in zip if card is not declined
      if (!isSelectedCardDeclined) {
        await setFieldValue('address.zip', selectedCard.address.zip);
      }

      // need to wait for all the field values to be set before submitting the form
      submitForm();
    } else {
      submitOrder(selectedCard);
    }
  } else {
    openModal(MODAL_TYPE.auth);
  }
}
