import { useEffect } from 'react';

function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);

    if ('scrollRestoration' in window.history) {
      // disable browser auto scroll to last saved scroll position
      window.history.scrollRestoration = 'manual';
    }

    return () => {
      if ('scrollRestoration' in window.history) {
        // enable again when unmounting
        window.history.scrollRestoration = 'auto';
      }
    };
  }, []);

  return null;
}

export default ScrollToTop;
