import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconSearch } from 'images/icon-search.svg';

import styles from './styles.module.scss';

function SearchInput(props) {
  const { inputRef, onChange, searchQuery } = props;

  return (
    <div className={styles.searchInputWrap}>
      <IconSearch alt="search icon" className={styles.iconSearch} />
      <input
        type="search"
        name="search"
        placeholder="Search"
        className={styles.searchInput}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={searchQuery}
        autoComplete="off"
        maxLength={50}
        ref={inputRef}
        aria-label="Search Items"
      />
    </div>
  );
}

SearchInput.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
  onChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

SearchInput.defaultProps = {
  inputRef: undefined,
  searchQuery: undefined,
};

export default SearchInput;
