/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getFormattedWhenObject } from '@chownow/cn-web-utils/date';

import { getFulfillmentTime } from 'modules/order';

import { ReactComponent as IconDate } from 'images/icon-date.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as IconTime } from 'images/icon-time.svg';

import styles from './styles.module.scss';

function OrderTime(props) {
  const { isSelectedFulfillmentAvailableLater, openWhen } =
    props;

  const fulfillmentTime = useSelector(getFulfillmentTime) || null;
  const { fulfillmentDate, fulfillmentTimeStart } =
    getFormattedWhenObject(fulfillmentTime);

  const isASAP = !fulfillmentTime;

  return (
    <button
      type="button"
      className={styles.orderTimeWrapper}
      onClick={openWhen}
      disabled={!isSelectedFulfillmentAvailableLater}
    >
      <div className={styles.orderTime}>
        {isASAP ? (
          <IconTime className={styles.icon} />
        ) : (
          <IconDate className={styles.icon} />
        )}
        {isASAP ? (
          'ASAP'
        ) : (
          <>
            {fulfillmentDate} at {fulfillmentTimeStart.toLowerCase()}
          </>
        )}
      </div>
      {isSelectedFulfillmentAvailableLater && (
        <IconEdit data-testid="EditIcon" className={styles.iconEdit} />
      )}
    </button>
  );
}

OrderTime.propTypes = {
  isSelectedFulfillmentAvailableLater: PropTypes.bool,
  openWhen: PropTypes.func.isRequired,
};

OrderTime.defaultProps = {
  isSelectedFulfillmentAvailableLater: false,
};

export default OrderTime;
