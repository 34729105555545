/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { RefObject, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface TabsProps {
  size?: 'small' | 'medium';
  variant?: 'fill' | 'hug';
  tabs: {
    name: string;
    label: string;
    body: JSX.Element | string;
  }[];
}

function Tabs({ size = 'small', variant = 'hug', tabs }: TabsProps) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const selectionMarkerRef = useRef<HTMLDivElement>(null);

  const REFS: RefObject<HTMLDivElement>[] = [];

  // Handle sliding selection bar under tabs
  const selectTab = (index: number) => {
    const firstLabel = REFS[0].current;
    const label = REFS[index].current;
    const marker = selectionMarkerRef.current;
    if (firstLabel && label && marker) {
      const newLeft =
        label.getBoundingClientRect().left -
        firstLabel.getBoundingClientRect().left;
      marker.style.left = `${newLeft}px`;
      marker.style.width = `${label.offsetWidth}px`;
    }
  };

  useLayoutEffect(() => {
    selectTab(currentTabIndex);
  }, [currentTabIndex, variant, size]);

  return (
    <div className={classNames(styles.tabs, styles[size])}>
      <div className={classNames(styles.labels, styles[variant])}>
        {tabs.map((tab, index) => {
          const ref = useRef<HTMLDivElement>(null);
          REFS.push(ref);
          return (
            <span
              key={tab.name}
              ref={ref}
              className={classNames({
                [styles.selected]: index === currentTabIndex,
              })}
              onClick={() => setCurrentTabIndex(index)}
            >
              {tab.label}
            </span>
          );
        })}
      </div>
      <div className={styles.selectionMarker} ref={selectionMarkerRef} />
      <hr />
      {tabs[currentTabIndex].body}
    </div>
  );
}

export default Tabs;
