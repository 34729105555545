import React, { useState } from 'react';

import Input from 'primitives/Input';
import Modal from 'primitives/Modals/Modal';
import ModalHeader from 'primitives/Modals/ModalHeader';
import ModalFooter from 'primitives/Modals/ModalFooter';
import ModalFooterButton from 'primitives/Modals/ModalFooter/ModalFooterButton';

function TableModal({
  onSubmitTable,
  savedTable,
}: {
  onSubmitTable: (table: string) => void;
  savedTable?: string;
}) {
  const [tableInput, setTableInput] = useState(savedTable || '');

  return (
    <Modal>
      <ModalHeader title="Table Number" />
      <Input
        type="text"
        label="table number"
        name="table"
        maxLength={32}
        shouldHideLabel
        value={tableInput}
        onChange={setTableInput}
      />
      <ModalFooter>
        <ModalFooterButton handleClick={() => onSubmitTable(tableInput)}>
          Apply
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
}

export default TableModal;
