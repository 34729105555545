/* eslint-disable complexity, arrow-body-style, no-useless-return, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';

import { FULFILLMENT_METHODS } from 'helpers/order';
import { getGooglePaymentsClient } from 'helpers/payments';
import { formatMoney } from '@chownow/cn-web-utils/format';

import Button from 'primitives/Button';

import { ReactComponent as ApplePayLogo } from './logo-applePayButton.svg';

import styles from './styles.module.scss';

function OrderButton(props) {
  const {
    isApplePaySelected,
    isGooglePaySelected,
    isOrderBtnDisabled,
    onSubmit,
    total,
    fulfillmentMethod,
  } = props;

  const { isChowNowPlatform, isYelpPlatform } = usePlatform();

  const isDineIn = fulfillmentMethod === FULFILLMENT_METHODS.dineIn;
  const orderBtnLabel = isYelpPlatform
    ? 'Proceed to Payment'
    : `Place ${!isDineIn ? fulfillmentMethod : 'Dine In'} Order`;

  const paymentsClient = window.google && getGooglePaymentsClient();

  const googlePayButtonRef = useRef(null);

  const prevPaymentsClient = usePrevious(paymentsClient);

  const isSocialPaySelected = isApplePaySelected || isGooglePaySelected;

  useEffect(() => {
    if (!prevPaymentsClient && paymentsClient && googlePayButtonRef) {
      const button = paymentsClient.createButton({
        buttonColor: 'default',
        buttonType: 'plain',
        onClick: () => {
          // can't run onSubmit here as GooglePay then does not update with any changed info (i.e. logging in on Checkout)
          return;
        },
        allowedPaymentMethods: [],
      });

      googlePayButtonRef.current?.appendChild(button);
    }
  }, [paymentsClient, googlePayButtonRef]);
  return (
    <>
      {isSocialPaySelected && (
        <span className={styles.total}>Total {formatMoney(total)}</span>
      )}
      {isApplePaySelected && (
        <button
          className={classNames(styles.socialPayButton, styles.applePay, {
            [styles.disabled]: isOrderBtnDisabled,
          })}
          onClick={onSubmit}
          data-testid="PlaceApplePayOrder"
          type="button"
          disabled={isOrderBtnDisabled}
        >
          <ApplePayLogo />
        </button>
      )}
      <div
        className={classNames(styles.socialPayButton, {
          [styles.hide]: !isGooglePaySelected,
          [styles.disabled]: isOrderBtnDisabled,
        })}
        ref={googlePayButtonRef}
        onClick={onSubmit}
      />
      {!isSocialPaySelected && (
        <Button
          disabled={isOrderBtnDisabled}
          onClick={onSubmit}
          isFullWidth
          className={styles.btnSubmit}
          dataTestId="PlaceOrder"
        >
          {orderBtnLabel}
          {isChowNowPlatform && (
            <span className={styles.secondaryButtonContent}>
              {formatMoney(total)}
            </span>
          )}
        </Button>
      )}
    </>
  );
}

OrderButton.propTypes = {
  fulfillmentMethod: PropTypes.string,
  isApplePaySelected: PropTypes.bool,
  isGooglePaySelected: PropTypes.bool,
  isOrderBtnDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

OrderButton.defaultProps = {
  fulfillmentMethod: undefined,
  isApplePaySelected: false,
  isGooglePaySelected: false,
  isOrderBtnDisabled: false,
};

export default OrderButton;
