import React, { useContext } from 'react';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import MenuSimple from 'primitives/MenuSimple';
import ModalFrame from 'primitives/Modals/ModalFrame';
import ModalHeader from 'primitives/Modals/ModalHeader';

import styles from './styles.module.scss';

function CategoryModal({
  activeIndex,
  categories,
  handleScroll,
}: {
  activeIndex?: number;
  categories: { id: number; name: string }[];
  handleScroll: (id: number) => void;
}) {
  const { closeModal } = useContext(ModalContext);

  function handleCategoryClick(index: number) {
    closeModal();
    handleScroll(index);
  }

  return (
    <ModalFrame>
      <ModalHeader title="Categories" isSticky />
      <div className={styles.categoriesWrapper}>
        {categories.map((category, index) => (
          <div
            key={category.id}
            className={classNames({ [styles.selected]: index === activeIndex })}
          >
            <MenuSimple
              label={category.name}
              handleClick={() => handleCategoryClick(index)}
              dataTestId="CategorySelect"
              selected={index === activeIndex}
            />
          </div>
        ))}
      </div>
    </ModalFrame>
  );
}

export default CategoryModal;
